import { Injectable } from '@angular/core';
import { TableContainerManager, TableInputs } from '@unifii/components';
import { FilterEntry, FilterValue, TableConfig } from '@unifii/library/common';
import { Device } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { UcApps } from 'client';
import { TABLE_SEARCH_MIN_LENGTH } from 'constant';
import { AppDetailsComponent } from 'pages/apps/app-details.component';
import { AppDevicesDataSource } from 'pages/apps/app-devices-datasource';

@Injectable()
export class AppDevicesTableManager implements TableContainerManager<Device, FilterValue, FilterEntry> {

    tableConfig: TableConfig<Device>;
    showSearch = true;
    searchMinLength = TABLE_SEARCH_MIN_LENGTH;
    reload = new Subject<void>();

    constructor(
        private ucApps: UcApps,
        private appDetails: AppDetailsComponent,
    ) {
        this.tableConfig = {
            id: 'app-devices',
            columnToggles: true,
            rowLink: (item) => item.id,
            pageSize: 50,
            columns: [{
                name: 'id',
                label: 'Id',
            }, {
                name: 'name',
                label: 'Name',
            }, {
                name: 'os',
                label: 'Os',
            }, {
                name: 'osVersion',
                label: 'Os Version',
            }, {
                name: 'manufacturer',
                label: 'Manufacturer',
            }, {
                name: 'model',
                label: 'Model',
            }],
        };
    }

    createDataSource({ q }: TableInputs<FilterValue>) {
        return new AppDevicesDataSource(this.ucApps, this.appDetails.id, q);
    }

}
