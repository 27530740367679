import { DataSourceLoader, FilterEntry, FilterType, SourceConfig } from '@unifii/library/common';
import { stringsCaseInsensitiveLocalCompare } from '@unifii/library/smart-forms';
import { DataSeed, UserStatus } from '@unifii/sdk';

import { SystemRole, UcRoles } from 'client';
import { ContextService } from 'services/context.service';

export const createFilterEntries = (ucRoles: UcRoles, context: ContextService): FilterEntry[] => [{
    label: 'Status',
    identifier: 'status',
    type: FilterType.Choice,
    options: Object.keys(UserStatus).sort(stringsCaseInsensitiveLocalCompare).map((v) => ({ identifier: v, name: v })),
}, {
    label: 'Authentication',
    identifier: 'isExternal',
    type: FilterType.Choice,
    options: [
        { identifier: 'false', name: 'Internal' },
        { identifier: 'true', name: 'External' },
    ],
}, {
    label: 'Identity Provider',
    identifier: 'authProvider',
    type: FilterType.Choice,
    options: context.tenantSettings?.authProviders?.map((v) => ({ identifier: `${v.id}`, name: `${v.type} - ${v.providerLoginLabel ?? v.tenant}` })) ?? [],
}, {
    label: 'System Roles',
    identifier: 'systemRoles',
    type: FilterType.OptionArray,
    options: Object.keys(SystemRole).map((v) => ({ identifier: v, name: v })),
}, {
    label: 'Roles',
    identifier: 'roles',
    type: FilterType.DataSeedArray,
    loader: new RoleFilterLoader(ucRoles),
}];

export class RoleFilterLoader implements DataSourceLoader {

    // TODO: Remove this after UNIFII-7371 - as this is not the ideal solution
    sourceConfig: SourceConfig = {} as unknown as SourceConfig;

    constructor(private ucRoles: UcRoles) { }

    async getOptions(): Promise<DataSeed[]> {
        const roles = await this.ucRoles.get();

        return roles.map((r) => this.mapToSeed(r.name));
    }

    findAllBy(match: string): Promise<DataSeed[]> {
        console.warn('datasource does not support findByAll, falling back on search');

        return this.search(match);
    }

    async search(q?: string): Promise<DataSeed[]> {
        const roles = await this.ucRoles.get(q);

        return roles.map((r) => this.mapToSeed(r.name));
    }

    get(id: string): Promise<DataSeed> {
        return Promise.resolve({ _id: id, _display: id });
    }

    mapToSeed(role: string) {
        return { _id: role, _display: role };
    }

}

