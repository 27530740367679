import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { FeatureFlagService } from '@unifii/library/common';
import { ClaimConfig } from '@unifii/sdk';

import { UcUserClaims } from 'client';

export interface UsersTableData {
    claimConfig: ClaimConfig[];
    companiesEnabled: boolean;
}

export const usersTableDataResolver: ResolveFn<UsersTableData> = async() => {

    const ucUserClaims = inject(UcUserClaims);
    const featureFlagService = inject(FeatureFlagService);
    const companiesEnabled = await featureFlagService.isEnabled('companies');
    let claimConfig: ClaimConfig[] = [];

    try {
        claimConfig = await ucUserClaims.list();
    } catch (e) {
        console.error('missing claim config');
    }

    return { companiesEnabled, claimConfig };
};
