import { Injectable, inject } from '@angular/core';
import { ClientGetOptions, amendOptionsParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { Integration, IntegrationFeature, IntegrationInfo } from './models';
import { UcClient } from './uc-client';

@Injectable({ providedIn: 'root' })
export class UcIntegrations {

    private client = inject(UcClient);
    
    list(options?: ClientGetOptions): Promise<IntegrationInfo[]> {
        return this.client.get(this.url(), amendOptionsParams(DefaultPaginationParams, options));
    }

    get(id: string): Promise<Integration> {
        return this.client.get(this.url(id));
    }

    save(integration: Integration): Promise<Integration> {

        if (integration.id) {
            return this.client.put(this.url(integration.id), integration);
        }

        return this.client.post(this.url(), { body: integration });
    }

    updateFeature(feature: IntegrationFeature, integrationId: string): Promise<IntegrationFeature> {
        return this.client.put(this.url(integrationId, 'features', feature.id), feature);
    }

    deleteFeature(id: string, integrationId: string): Promise<void> {
        return this.client.delete(this.url(integrationId, 'features', id));
    }

    private url(...extra: string[]): string {
        const urlParts = ['integrations', ...extra];

        return this.client.buildUrl(urlParts);
    }

}
