<uf-panel *ngIf="!error" class="container">

    <div class="header-pane uf-app-bar transparent">
        <uc-builder-header />
    </div>

    <div class="content-pane scrollable">
        <uf-tabs>
            <uf-tab label="Table Configuration" path="" />
            <uf-tab *ngIf="info.table.id != null" label="Details Page" path="details-page" />
        </uf-tabs>
    </div>

</uf-panel>

<uc-error-message [error]="error" />