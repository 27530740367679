<uf-expander *ngIf="option && control" [isExpanded]="isExpanded" class="uf-box flat draggable">
    <div expanderHeader dragHandle class="uf-app-bar flat">
        <div class="title primary">
            {{ labelPrefix }}{{ option.name }}
        </div>
        <uf-icon *ngIf="control.invalid" name="warning" class="icon-warning" />
        <button *ngIf="field.type !== fieldType.Bool" (click)="removeOption($event)" type="button" title="Delete"
            class="uf-action tertiary">
            <uf-icon name="delete" />
        </button>
    </div>
    <div expanderBody class="uf-grid pad">
        <uf-text *ngIf="control.controls.name" [control]="control.controls.name | asUfControl" [(value)]="option.name"
            (valueChange)="nameChange($event)" label="Name" placeholder="Option name" class="col-12" />
        <uf-text *ngIf="field.type !== fieldType.Bool && control.controls.identifier" [control]="control.controls.identifier | asUfControl"
            [maxLength]="identifierMaxLength" [(value)]="option.identifier" label="Identifier"
            placeholder="Option identifier" class="col-12" />

        <uc-markdown *ngIf="showContentField && control.controls.content" [control]="control.controls.content | asUfControl"
            [showAlignmentOptions]="showAlignmentOptions" [(value)]="option.content"
            label="Content" class="col-12" />

    </div>
</uf-expander>