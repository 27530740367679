import { Component, HostListener, Inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';

import { MenuConfig } from './nav-models';

@Component({
    selector: 'uc-nav-context-menu',
    templateUrl: './nav-context-menu.html',
    styleUrls: ['./nav-context-menu.less'],
})
export class NavContextMenuComponent implements Modal<MenuConfig, void> {

    constructor(
        @Inject(ModalData) public data: MenuConfig,
        public runtime: ModalRuntime<MenuConfig, void>,
    ) { }

    @HostListener('mouseleave') onLeave = () => this.runtime.close();
    @HostListener('click') onClick = () => this.runtime.close();

}
