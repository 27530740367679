import { ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb, ExpandersService, MessageLevel, ModalService, UfControlArray, UfControlGroup, WindowWrapper } from '@unifii/library/common';
import { CompoundType, DefinitionPublishState, ErrorType, StructureNodeType, ensureUfError } from '@unifii/sdk';
import { Subscription, interval } from 'rxjs';

import { StructureService, SystemRole, UcStructure } from 'client';
import { EditData, SaveAndApprove, SaveAndClose, SaveOption, SaveOptionType } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { ConflictModalComponent } from 'components/conflict-modal';
import { reloadCurrentRoute } from 'pages/utils';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

import { STRUCTURE_CONSTANTS } from './structure-constants';
import { StructureNodeControlKeys } from './structure-control-keys';
import { StructureEditorCache } from './structure-editor-cache';
import { StructureEditorService } from './structure-editor.service';
import { StructureFormCtrl, flattenControls } from './structure-form-ctrl';
import { StructureFunctions } from './structure-functions';
import { ConsoleStructure } from './structure-model';
import { StructureStatus } from './structure-status';

@Component({
    selector: 'uc-structure',
    templateUrl: './structure.html',
    providers: [
        StructureStatus,
        StructureFormCtrl,
        StructureEditorService,
        ExpandersService,
        StructureEditorCache,
    ],
    styleUrls: ['./structure.less'],
})
export class StructureComponent implements OnInit, OnDestroy, EditData {

    protected readonly itemGroups = STRUCTURE_CONSTANTS.PICKER_GROUPS;
    protected readonly compoundTypes = CompoundType;

    protected breadcrumbs: Breadcrumb[] = [];
    protected saveOptions: SaveOption[] = [];
    protected status = inject(StructureStatus);
    protected service = inject(StructureEditorService);

    private subscriptions: Subscription = new Subscription();
    private revisionSubscription: Subscription | undefined;
    private modalService = inject(ModalService);
    private sfb = inject(StructureFormCtrl);
    private ucStructure = inject(StructureService);
    private context = inject(ContextService);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private cdr = inject(ChangeDetectorRef);
    private breadcrumbService = inject(BreadcrumbService);
    private builderHeaderService = inject(BuilderHeaderService);
    private expanders = inject(ExpandersService);
    private window = inject<Window>(WindowWrapper);
    private checkConflict = true;

    async ngOnInit() {
        // Load data
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);
        const structure = await this.loadStructure();

        void this.setup(structure);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.revisionSubscription?.unsubscribe();
    }

    get form(): UfControlGroup {
        return this.status.root;
    }

    get disabled(): boolean {
        return this.status.root.disabled;
    }

    get children(): UfControlArray {
        return this.form.get(StructureNodeControlKeys.Children) as UfControlArray;
    }

    get structure(): ConsoleStructure {
        return this.form.value as ConsoleStructure;
    }

    get edited(): boolean {
        return this.status.edited;
    }

    private get revision(): string | undefined {
        return this.structure.revision;
    }

    protected toggleExpanders(expand: boolean, list: HTMLElement) {
        expand ?
            this.expanders.expandAll(list) :
            this.expanders.collapseAll(list);
    }

    protected filterStructureNodeControls(control: UfControlGroup): boolean {
        return StructureFunctions.isANodeControl(control);
    }

    private async onAction(saveOption?: SaveOption) {

        if (!this.isFormValid()) {
            return;
        }

        // Disable save button so it won't be pressed twice mid save
        this.builderHeaderService.config.disabledSave = true;
        this.checkConflict = false;

        try {
            this.cdr.detectChanges();
            let structure = this.getStructure();

            structure = await this.ucStructure.save(structure, saveOption?.id === SaveOptionType.Approve);

            this.status.edited = false;

            this.builderHeaderService.notify.next({
                message: 'Structure saved',
                level: MessageLevel.Success,
            });

            if (saveOption?.id === SaveOptionType.Close) {
                this.back();
            }

            this.updateStructureFormValue(structure);
            this.buildHeaderConfig(structure);
        } catch (e) {
            const ufError = ensureUfError(e);

            if (ufError.type === ErrorType.Conflict) {
                void this.onConflictDetected();

                return;
            }

            this.builderHeaderService.notify.next({
                message: 'An unexpected error occurred',
                level: MessageLevel.Error,
            });
        } finally {
            this.builderHeaderService.config.disabledSave = false;
            this.checkConflict = true;
            this.cdr.detectChanges();
        }
    }

    private async loadStructure(): Promise<UcStructure> {
        try {
            return await this.ucStructure.get();
        } catch (e) {
            return {
                rev: undefined as any,
                children: [],
                type: StructureNodeType.Empty,
                nodeId: '0',
                lastNodeId: '0',
                structurePublishState: DefinitionPublishState.Draft,
            };
        }
    }

    private back() {
        void this.router.navigate(['../'], { relativeTo: this.route });
    }

    private getFirstSelectableNode(): UfControlGroup | null {
        if (this.form.get(StructureNodeControlKeys.Type)?.value !== StructureNodeType.Empty) {
            return this.form;
        }

        if (this.children.length) {
            return this.children.controls[0] as UfControlGroup;
        }

        return null;
    }

    private updateSaveActions() {
        const canPublish = this.context.checkRoles(SystemRole.Publisher);

        this.saveOptions = [SaveAndClose];

        if (canPublish) {
            this.saveOptions.push(SaveAndApprove);
        }
    }

    private getStructure(): UcStructure {
        const controlStructure: ConsoleStructure = this.form.getRawValue();
        const structure = this.sfb.mapControlValueToData(controlStructure);

        StructureFunctions.cleanNodeAttributes(structure);

        return structure;
    }

    private async setup(structure: UcStructure) {
        this.subscriptions.add(this.service.fieldDeselected.subscribe(() => {
            this.cdr.detectChanges();
        }));
        this.subscriptions.add(this.service.fieldSelected.subscribe(() => {
            this.cdr.detectChanges();
        }));

        // Build root control
        const rootControl = this.sfb.buildRoot(structure);

        if (!this.context.checkRoles(SystemRole.ProjectManager, SystemRole.ContentEditor)) {
            rootControl.disable();
        }

        this.status.root = rootControl;

        this.subscriptions.add(this.form.valueChanges.subscribe(() => {
            this.status.edited = true;
            this.builderHeaderService.config.edited = true;
        }));

        this.updateSaveActions();

        await this.service.selectNode(this.getFirstSelectableNode() ?? undefined);
        this.buildHeaderConfig(structure);
        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => { void this.onAction(saveOption); }));

        this.initConflictDetection();
    }

    private buildHeaderConfig(structure: UcStructure) {
        this.builderHeaderService.buildConfig({
            ...structure,
            title: 'Structure',
            cancelRoute: ['../'],
            saveOptions: this.saveOptions,
            publishState: structure.structurePublishState,
            breadcrumbs: this.breadcrumbs,
            restrictSave: `${SystemRole.ContentEditor},${SystemRole.Publisher}`,
        });
    }

    private updateStructureFormValue(structure: UcStructure) {
        this.form.patchValue(this.sfb.mapDataToControlValue(structure), { emitEvent: false });
    }

    private isFormValid(): boolean {
        if (!this.form.valid) {
            const items = flattenControls(this.form);

            for (const entry of items) {
                entry.control.markAsTouched();

                if (entry.control.errors != null) {
                    console.log(entry.key, entry.control.errors);
                }
            }

            this.builderHeaderService.notify.next({
                message: 'Unable to save. There are errors in your Structure',
                level: MessageLevel.Error,
            });

            return false;
        }

        return true;
    }

    private initConflictDetection() {
        this.revisionSubscription?.unsubscribe();
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.revisionSubscription = interval(15000).subscribe(async() => {
            if (!this.checkConflict) {
                return;
            }

            const revision = await this.ucStructure.getRevision();

            if (revision !== this.revision) {
                void this.onConflictDetected();
            }
        });
    }

    private async onConflictDetected() {
        this.revisionSubscription?.unsubscribe();

        const result = await this.modalService.openMedium(ConflictModalComponent, undefined, { guard: true });

        if (!result) {
            return;
        }

        switch (result) {
            case 'Discard':
                this.status.edited = false;
                reloadCurrentRoute(this.router);
                break;
            case 'OpenNewTab': {
                const openFn = this.window.open.bind(this.window);

                openFn(location.href);
                break;
            }
        }
    }

}
