<uf-expander *ngIf="control" class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Settings</div>
        <uf-icon *ngIf="isInvalid" name="error" class="error" />
        <a *ngIf="canCopy" (click)="copy()" class="uf-action tertiary" title="Copy">
            <uf-icon name="copy" />
        </a>
    </div>
    <div [formGroup]="control" expanderBody class="uc-group-content grid">
        <uf-spinner *ngIf="!ready" class="col-1of1" />
        <ng-template [ngIf]="ready">
            <uf-text [formControlName]="formKeys.Name" label="Menu Name" class="col-1of1" />
            <uf-text *ngIf="showDefinitionIdentifier" [formControlName]="formKeys.DefinitionIdentifier"
                label="Identifier" class="col-1of1 small" />
            <uf-text *ngIf="showURL" [formControlName]="formKeys.Url" label="URL" class="col-1of1" />
            <uf-chips *ngIf="showTags" [formControlName]="formKeys.Tags" [allowCustom]="true" [options]="tagOptions"
                (searchChange)="searchTags()" label="Tags" class="col-1of1" />
            <div *ngIf="appUrl" class="grid center-all col-1of1">
                <uf-data-display-list [items]="[appUrl]" class="small-label" />
                <a (click)="copyAppURL()" class="uf-action tertiary right" title="Copy App URL">
                    <uf-icon name="copy" />
                </a>
            </div>

            <uf-select *ngIf="showTemplate" [options]="templateOptions" [formControlName]="formKeys.Template"
                label="Template" nameProperty="name" valueProperty="identifier" class="col-1of1" />
            <uf-text *ngIf="showTemplate && control.get(formKeys.EmptyMessage)?.enabled"
                [formControlName]="formKeys.EmptyMessage" label="Empty message" class="col-1of1" />
        </ng-template>
    </div>
</uf-expander>