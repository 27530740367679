import { TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { RequestAnalyticsOrigin, mergeParams } from '@unifii/sdk';

import { ActivitiesParams, UcWorkflow, WorkflowRule } from 'client';

export class WorkflowRulesTableDataSource extends TableDataSource<WorkflowRule> {

    sorted: boolean;
    filtered: boolean;

    constructor(
        private ucWorkflow: UcWorkflow,
        private params?: ActivitiesParams,
    ) {
        super();
    }

    load(options?: TableDataSourceQueryOptions) {
        this.ucWorkflow.getRules({
            params: mergeParams(this.params as Record<string, unknown>, { offset: options?.offset, limit: options?.limit }),
            analytics: { origin: RequestAnalyticsOrigin.Table },
        }).then(
            (tables) => this.stream.next({ data: tables }),
            (error) => this.stream.next({ error }),
        );
    }

}
