<uf-panel class="container">
    <div class="header-pane uf-app-bar transparent">
        <uc-builder-header />
    </div>

    <div class="content-pane">
        <uf-tabs>
            <uf-tab label="General" path="general" />
            <uf-tab label="Theming" path="theming" />
            <uf-tab label="Data Sources" path="data-sources" />
            <uf-tab label="Data Transactions" path="data-transactions" />
        </uf-tabs>
    </div>
</uf-panel>