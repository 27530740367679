<div class="uf-app-bar">
    <button (click)="close()" type="button" class="uf-action tertiary" title="Close">
        <uf-icon name="close" />
    </button>
    <h3>{{data.subject}}</h3>
</div>
<uf-panel class="grow">
    <div class="pad-sides">
        <div [markdownRenderer]="data.introMessage"></div>
        <button [ngStyle]="{'background-color': data.emailColour}" type="button"
            class="uf-button primary">{{data.buttonLabel}}</button>
        <div [markdownRenderer]="data.bodyMessage"></div>
    </div>
</uf-panel>