<uf-panel class="container">
    <div class="header-pane uf-app-bar transparent">
        <uf-breadcrumbs [breadcrumbs]="[{ name: 'Template Table' }]" class="large" />
        <uf-search [search]="query" class="right" />
        <uf-select [value]="status" [options]="publishStates" valueProperty="value" placeholder="Active" />
        <uf-table-sort [table]="pageTable" />
        <uf-table-column-toggles [table]="pageTable" />
        <button class="uf-action primary" type="button" title="Add">
            <uf-icon name="add" />
        </button>
    </div>
    <uf-panel class="content-pane">
        <uf-table #pageTable [config]="config" [datasource]="datasource" class="gap-lg-bottom accent" />
    </uf-panel>
</uf-panel>