<div class="grid">
    <uf-text *ngIf="control.controls.title" [control]="control.controls.title | asUfControl" [(value)]="page._title"
        label="Title" class="col-1of1" />

    <uf-text *ngIf="control.controls.recordName" [control]="control.controls.recordName | asUfControl"
        [(value)]="page.recordName" label="Record Name" class="col-1of1" />

    <uf-text *ngIf="control.controls.identifier" [control]="control.controls.identifier | asUfControl"
        [maxLength]="maxLength" [(value)]="page.identifier" label="Identifier"
        class="col-1of1" />

    <uf-chips *ngIf="control.controls.tags" [control]="control.controls.tags | asUfControl" [allowCustom]="true"
        [options]="filteredTags" [(value)]="page.tags"
        (searchChange)="filterTags($event)" (valueChange)="builderService.refreshTags()" label="Tags" class="col-1of1" />
</div>