import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'uc-lists',
    templateUrl: './lists.html',
    styleUrls: ['./lists.less'],
})
export class ListsComponent {

    @HostBinding('class.stretch-component') class = true;

}

