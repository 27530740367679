<div class="grid">
    <uf-text *ngIf="control.controls.label" [control]="control.controls.label | asUfControl"
        [(value)]="definition.label" label="Label" class="col-1of1" />

    <uf-text *ngIf="control.controls.identifier" [control]="control.controls.identifier | asUfControl"
        [maxLength]="maxLength" [(value)]="definition.identifier" label="Identifier"
        class="col-1of1" />

    <div restrict="FormDesigner" class="row space-children col-1of1">
        <button (click)="copyFields()" type="button" class="uf-button right">
            Copy Fields
        </button>
        <button (click)="pasteFields()" type="button" class="uf-button">
            Paste Fields
        </button>
    </div>
</div>