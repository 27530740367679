import { Injectable, inject } from '@angular/core';
import { TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import { FilterEntry, FilterValue, HierarchyUnitProvider, TableConfig } from '@unifii/library/common';
import { Subject } from 'rxjs';

import { IntegrationInfo, UcIntegrations } from 'client';

import { SystemIntegrationsDataSource } from './system-integrations-datasource';

@Injectable()
export class SystemIntegrationsTableManager implements TableContainerManager<IntegrationInfo, FilterValue, FilterEntry> {

    tableConfig: TableConfig<IntegrationInfo>;
    showSearch = true;
    addActionConfig = true; // TODO, handle using AddActionConfig
    reload = new Subject<void>();
    inputManager = new TableInputManager<FilterValue, FilterEntry>([], inject(HierarchyUnitProvider), null, null);

    constructor(
        private ucIntegrations: UcIntegrations,
    ) {
        this.tableConfig = {
            columns: [{
                name: 'providerName',
                label: 'Provider',
                value: (item) => item.provider.name,
            }],
            row: {
                image: (item) => item.provider?.logo,
                label: (item) => item.name,
                link: (item) => item.id as string,
            },
            pageSize: 50,
        };
    }

    createDataSource({ q }: TableInputs<any> = {}) {
        return new SystemIntegrationsDataSource(this.ucIntegrations, q);
    }

}
