<label *ngIf="label">
    {{label}}
    <ng-content></ng-content>
</label>

<div *ngIf="supported" class="row">
    <input #input [formControl]="control" [attr.placeholder]="placeholder" [readonly]="disabled"
        (focus)="focused = true" (blur)="focused = false" class="colour-picker" type="color" />
    <uf-text [value]="control.value" (valueChange)="digitColor($event)" placeholder="#FFFFFF" class="grow" />
    <uf-icon *ngIf="control.value" (click)="delete()" name="backspace" title="Clear" />
</div>

<div *ngIf="!supported">
    <input [attr.placeholder]="placeholder" [readonly]="disabled" (focus)="focused = true" (blur)="focused = false"
        type="text">
</div>
<uf-error [control]="control" />