<div *ngIf="fm.variations && field.variations?.length" class="grid">
    <uf-select [options]="field.variations" [value]="variation" (valueChange)="variationChange($event)" class="col-1of1"
        label="Variation" nameProperty="name" placeholder="Default" />
</div>

<ng-container *ngIf="variation || field">

    <uf-expander [isExpanded]="expanded" class="uf-box flat gap--bottom-small">
        <div expanderHeader class="uf-app-bar flat">
            <span class="title primary">Settings</span>
            <uf-icon *ngIf="invalids.settings" [class.icon-warning-submitted]="field.isSubmitted" name="warning"
                class="icon-warning" />
        </div>
        <div expanderBody class="uc-group-content">
            <uc-field-settings [field]="variation || field" (refresh)="refresh($event)" (edited)="edited($event)" />
        </div>
    </uf-expander>

    <uf-expander *ngIf="visibleGroups.display" class="uf-box flat gap--bottom-small">
        <div expanderHeader class="uf-app-bar flat">
            <span class="title primary">Display</span>
            <uf-icon *ngIf="invalids.display" [class.icon-warning-submitted]="field.isSubmitted" name="warning"
                class="icon-warning" />
        </div>
        <div expanderBody class="uc-group-content">
            <uc-field-display [field]="variation || field" (refresh)="refresh($event)" (edited)="edited($event)" />
        </div>
    </uf-expander>

    <uf-expander *ngIf="visibleGroups.visibility" class="uf-box flat gap--bottom-small">
        <div expanderHeader class="uf-app-bar flat">
            <span class="title primary">Visibility</span>
            <uf-icon *ngIf="invalids.visibility" [class.icon-warning-submitted]="field.isSubmitted" name="warning"
                class="icon-warning" />
        </div>
        <div expanderBody class="uc-group-content">
            <uc-field-visibility [field]="variation || field" (refresh)="refresh($event)" (edited)="edited($event)" />
        </div>
    </uf-expander>

    <uf-expander *ngIf="visibleGroups.nested" class="uf-box flat gap--bottom-small">
        <div expanderHeader class="uf-app-bar flat">
            <span class="title primary">Visible Fields</span>
            <uf-icon *ngIf="invalids.nested" [class.icon-warning-submitted]="field.isSubmitted" name="warning"
                class="icon-warning " />
        </div>
        <div expanderBody class="uc-group-content">
            <uc-field-nested [field]="variation || field" (refresh)="refresh($event)" (edited)="edited($event)" />
        </div>
    </uf-expander>

    <uf-expander *ngIf="visibleGroups.options" class="uf-box flat gap-sm-bottom small">
        <div expanderHeader class="uf-app-bar flat">
            <span class="title primary">Options</span>
            <uf-icon *ngIf="invalids.options" [class.icon-warning-submitted]="field.isSubmitted" name="warning"
                class="icon-warning" />
            <uc-expander-controls *ngIf="(variation || field).options?.length" [container]="optionsWrap" />
        </div>
        <div #optionsWrap expanderBody class="col pad-sm-top">
            <uc-field-options [field]="variation || field" (refresh)="refresh($event)" (edited)="edited($event)" />

            <div *ngIf="field?.type !== fieldType.Bool" class="row pad-sm">
                <button (click)="addOption()" type="button" class="uf-button x-small right">
                    Add Option
                </button>
            </div>
        </div>
    </uf-expander>

    <uf-expander *ngIf="visibleGroups.validators" class="uf-box flat gap--bottom-small">
        <div expanderHeader class="uf-app-bar flat">
            <span class="title primary">Validators</span>
            <uf-icon *ngIf="invalids.validators" [class.icon-warning-submitted]="field.isSubmitted" name="warning"
                class="icon-warning" />
            <uc-expander-controls *ngIf="field?.validators?.length" [container]="validatorsWrap" />
        </div>
        <div #validatorsWrap expanderBody class="uc-group-content grid">
            <uc-field-validators [field]="variation || field" (refresh)="refresh($event)" (edited)="edited($event)"
                class="col-1of1" />
            <div class="col-1of1 row">
                <button (click)="addValidator()" type="button" class="uf-button x-small right">
                    Add Validator
                </button>
            </div>
        </div>
    </uf-expander>

</ng-container>