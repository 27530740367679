<ng-template [ngForOf]="groups" ngFor let-group>
    <uf-expander *ngIf="group.title && group.items.length " class="gap-bottom-sm" class="uf-box flat">
        <div expanderHeader class="uf-app-bar flat">
            <span class="title primary">{{ group.title }}</span>
        </div>
        <div expanderBody class="pad-none gap-none">
            <ng-template [ngForOf]="group.items" let-item ngFor>
                <div [dragItem]="item" class="uf-box draggable uf-app-bar flat gap-sm">
                    <uf-icon [name]="item.icon" />
                    <div class="title primary">{{ item.label }}</div>
                    <a *ngIf="item.deletable" (click)="onDelete(item)" class="uf-action tertiary" title="Delete">
                        <uf-icon name="delete" />
                    </a>
                </div>
            </ng-template>
        </div>
    </uf-expander>
    <ng-template [ngIf]="group.title == null">
        <ng-template [ngForOf]="group.items" let-item ngFor>
            <div [dragItem]="item" class="uf-box draggable uf-app-bar flat gap-sm">
                <uf-icon [name]="item.icon" />
                <div class="title primary">{{ item.label }}</div>
                <a *ngIf="item.deletable" (click)="onDelete(item)" class="uf-action tertiary" title="Delete">
                    <uf-icon name="delete" />
                </a>
            </div>
        </ng-template>
    </ng-template>
</ng-template>