import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedTermsTranslationKey } from '@unifii/library/common';
import { AppAuthProviderConfiguration, AuthProvider, AuthProviderConfiguration, Dictionary, TenantClient, decrypt, encrypt, isNotNull } from '@unifii/sdk';

import { Config } from 'app-config';

import { ContextService } from './context.service';

const DirectoryBaseURL = 'https://directory.unifii.net';

export const Auth0DirectoryURL = DirectoryBaseURL + '/auth0/callback';
export const AzureDirectoryURL = DirectoryBaseURL + '/azure/callback';

/**
 *  The SSOService
 *  Class duplicated in Discover any changes need to be made in both,
 *  this class will be simplified when the getRedirectUrl is replaced with backend call
 *  will only need to render buttons
 */
@Injectable({ providedIn: 'root' })
export class SSOService {

    private _authProviders?: AuthProviderConfiguration[];
    private config = inject(Config);
    private tenantClient = inject(TenantClient);
    private translate = inject(TranslateService);
    private context = inject(ContextService);

    set authProviders(v: AuthProviderConfiguration[]) {
        this._authProviders = v;
    }

    get authProviders(): AuthProviderConfiguration[] {
        return this._authProviders ?? this.context.tenantSettings?.authProviders ?? [];
    }

    get providerList(): AppAuthProviderConfiguration[] {
        return this.authProviders.map((config) => this.getDisplayInfo(config)).filter(isNotNull);
    }

    getProviderUrl(provider: AuthProviderConfiguration, redirectUri: string, username?: string): Promise<string | undefined> {

        // Class will be replaced with API call when ready
        switch (provider.type) {
            case AuthProvider.Azure:
                return this.getAzureUrl(provider, redirectUri, username);
            case AuthProvider.Auth0:
                return this.getAuth0Url(provider, redirectUri);
            case AuthProvider.Okta:
                return this.getOktaUrl(provider, redirectUri);
            default:
                return Promise.resolve(undefined);
        }
    }

    async getManualOIDState(config: Dictionary<any>): Promise<string> {
        const params = new URLSearchParams(config);
        const encrypted = await encrypt(this.config.appId, params.toString());

        return encodeURIComponent(encrypted.byteString);
    }

    decryptState(state: string): Promise<string> {
        return decrypt({ key: this.config.appId, byteString: decodeURIComponent(state) });
    }

    private async getAuth0Url(provider: AuthProviderConfiguration, redirectUri: string): Promise<string> {

        const params = new URLSearchParams([
            ['response_type', 'code'],
            ['scope', 'openid profile email phone'],
            ['client_id', provider.clientId as string],
        ]);

        if (provider.useDirectory !== false) {
            // Apply state required by directory and set redirect_uri as directory
            const stateInfo = await this.tenantClient.getOIDCState(redirectUri, { providerId: `${provider.id}` });

            params.append('response_mode', 'form_post');
            params.append('state', stateInfo.state);
            params.append('redirect_uri', Auth0DirectoryURL);

            return `${provider.authorizationEndpoint}?${params.toString()}`;
        }

        // Encrypt redirectUri and providerId into state for decryption on redirect
        const state = await this.getManualOIDState({ redirectUri, providerId: provider.id });

        params.append('state', state);

        return `${provider.authorizationEndpoint}?${params.toString()}&redirect_uri=${redirectUri}`;
    }

    private async getAzureUrl(provider: AuthProviderConfiguration, redirectUri: string, username?: string): Promise<string> {

        const params = new URLSearchParams([
            ['response_type', 'code'],
            ['scope', 'openid offline_access user.read'],
            ['client_id', provider.clientId as string],
        ]);

        if (username) {
            params.append('login_hint', username);
        }

        if (provider.useDirectory !== false) {
            const stateResponse = await this.tenantClient.getOIDCState(redirectUri, { providerId: `${provider.id}` });

            params.append('response_mode', 'form_post');
            params.append('state', stateResponse.state);
            params.append('redirect_uri', AzureDirectoryURL);

            return `${provider.authorizationEndpoint}?${params.toString()}`;
        }

        const state = await this.getManualOIDState({ redirectUri, providerId: provider.id });

        params.append('state', state);

        // Unable to encode return URL at the moment
        return `${provider.authorizationEndpoint}?${params.toString()}&redirect_uri=${redirectUri}`;
    }

    private async getOktaUrl(provider: AuthProviderConfiguration, redirectUri: string): Promise<string> {

        const params = new URLSearchParams([
            ['response_type', 'code'],
            ['scope', 'openid profile email phone'],
            ['client_id', provider.clientId as string],
        ]);

        const state = await this.getManualOIDState({ redirectUri, providerId: provider.id });

        params.append('state', state);
        params.append('redirect_uri', redirectUri);

        return `${provider.authorizationEndpoint}?${params.toString()}`;
    }

    private getDisplayInfo(config: AuthProviderConfiguration): AppAuthProviderConfiguration | null {

        const display = Object.assign({}, config);

        switch (config.type) {
            case AuthProvider.Azure:
                return Object.assign(display, {
                    loginLabel: this.translate.instant(SharedTermsTranslationKey.ActionSignInWithMicrosoft),
                    loginIcon: 'assets/svg/azure.svg',
                });
            case AuthProvider.Auth0:
                return Object.assign(display, {
                    loginLabel: this.translate.instant(SharedTermsTranslationKey.ActionSignInWithAuth0),
                    loginIcon: 'assets/svg/auth0.svg',
                });
            case AuthProvider.Okta:
                return Object.assign(display, {
                    loginLabel: this.translate.instant(SharedTermsTranslationKey.ActionSignInWithOkta),
                    loginIcon: 'assets/svg/okta.svg',
                });

            default:
                return null;
        }
    }

}
