import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { UfControl, UfControlGroup } from '@unifii/library/common';
import { HierarchyUnit, Option } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { WorkflowNotificationUserHierarchyMatchTypes } from 'client';
import { buildOptionsFromEnum } from 'pages/workflows/workflow-utils';

import { ControlKeys } from '../workflow-notification.controller';

@Component({
    selector: 'uc-workflow-notification-hierarchy-field',
    templateUrl: 'workflow-notification-hierarchy-field.html',
})
export class WorkflowNotificationHierarchyFieldComponent implements OnInit, OnDestroy {

    @Input({ required: true }) controlGroup: UfControlGroup;
    @Input({ required: true }) fields: Option[];

    @HostBinding('class.uf-grid') protected readonly gridClass = true;

    protected readonly matchTypeValues = WorkflowNotificationUserHierarchyMatchTypes;
    protected readonly matchTypeOptions: Option[] = buildOptionsFromEnum(WorkflowNotificationUserHierarchyMatchTypes);

    protected filteredFields: Option[];
    protected valueControl: UfControl;
    protected matchTypeControl: UfControl;
    protected formDataControl: UfControl;
    protected hierarchyControl: UfControl;

    private subscriptions = new Subscription();

    ngOnInit() {
        const valueControl = this.controlGroup.get(ControlKeys.Value) as UfControl | undefined;
        const matchTypeControl = this.controlGroup.get(ControlKeys.MatchType) as UfControl | undefined;
        const formDataControl = this.controlGroup.get(ControlKeys.FormData) as UfControl | undefined;
        const hierarchyControl = this.controlGroup.get(ControlKeys.HierarchyUnit) as UfControl | undefined;

        if (!valueControl || !matchTypeControl || !hierarchyControl || !formDataControl) {
            throw Error('Missing controllers for Hierarchy recipient/condition');
        }

        this.valueControl = valueControl;
        this.matchTypeControl = matchTypeControl;
        this.formDataControl = formDataControl;
        this.hierarchyControl = hierarchyControl;

        this.subscriptions.add(
            this.matchTypeControl.valueChanges.subscribe(() => {
                this.hierarchyControl.reset();
                this.formDataControl.reset();
                this.valueControl.reset();
            }),
        );

        this.subscriptions.add(
            this.hierarchyControl.valueChanges.subscribe((value: HierarchyUnit | undefined) => {
                this.valueControl.setValue(value?.id);
            }),
        );

        this.subscriptions.add(
            this.formDataControl.valueChanges.subscribe((value: Option | undefined) => {
                this.valueControl.setValue(value?.identifier);
            }),
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    protected searchHierarchyField(query?: string) {
        const lowerCaseQuery = query?.toLowerCase();

        this.filteredFields = this.fields.filter((field) => !lowerCaseQuery || field.name.toLowerCase().includes(lowerCaseQuery));
    }

}
