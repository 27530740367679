<ng-template #headerButtons>
    <button (click)="sendTest()" type="button" class="uf-button secondary">Test</button>
    <button *ngIf="canDelete" (click)="delete()" type="button" class="uf-button secondary right">Delete</button>
</ng-template>

<uf-panel class="content-pane content scrollable">
    <div *ngIf="!loading" class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">

            <uf-blockquote *ngIf="error && error.message" [content]="error.message" icon="errorSolid"
                class="col-12 error" />

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        SMTP Set Up
                    </div>
                </div>
                <div class="content uf-grid pad">
                    <uf-text *ngIf="form.controls.smtpHost" [control]="form.controls.smtpHost | asUfControl"
                        label="Host" class="col-9" />

                    <uf-number *ngIf="form.controls.smtpPort" [control]="form.controls.smtpPort | asUfControl"
                        label="Port" class="col-3" />

                    <uf-text *ngIf="form.controls.smtpUsername" [control]="form.controls.smtpUsername | asUfControl"
                        label="Username" class="col-6" />

                    <uc-secret-input [control]="form.controls.smtpPassword | asUfControl"
                        *ngIf="form.controls.smtpPassword" [value]="form.controls.smtpPassword.value" label="Password"
                        class="col-6" />

                    <uf-text *ngIf="form.controls.smtpSystemName" [control]="form.controls.smtpSystemName | asUfControl"
                        label="System Name" class="col-12" />

                    <uf-email [control]="form.controls.smtpSystemEmail | asUfControl"
                        *ngIf="form.controls.smtpSystemEmail" label="System Email" class="col-12" />
                </div>
            </div>
        </div>
    </div>
</uf-panel>