import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedTermsTranslationKey, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { ClaimConfig } from '@unifii/sdk';
import { secondsInMinute, secondsInWeek } from 'date-fns';

import { EmailInfo, EmailTemplate, EmailTemplateCondition, UcUserClaims } from 'client';

export enum ConditionTypes {
    Claim = 'claim',
    Role = 'role',
    Combination = 'combination'
}

export enum EmailSettingsControlKey {
    InviteEmailExpiry = 'inviteEmailExpiry',
    EmailColour = 'emailColour',
    InviteEmailDefaultTemplate = 'inviteEmailDefaultTemplate',
    InviteEmailTemplates = 'inviteEmailTemplates',
    ResetPasswordEmailDefaultTemplate = 'resetPasswordEmailDefaultTemplate',
    ResetPasswordEmailTemplates = 'resetPasswordEmailTemplates',
    ReplyTo = 'replyTo'
}

export enum EmailTemplateControlKey {
    Title = 'title',
    Subject = 'subject',
    IntroMessage = 'introMessage',
    ButtonLabel = 'buttonLabel',
    BodyMessage = 'bodyMessage',
    BaseUrl = 'baseUrl',
    Conditions = 'conditions',
    ReplyTo = 'replyTo'
}

export enum ConditionControlKey {
    Claim = 'claim',
    Role = 'role',
    ConditionType = 'conditionType'
}

export enum ConditionClaimControlKey {
    Type = 'type',
    Value = 'value'
}

export interface EmailInfoFormModel extends Omit<EmailInfo, 'inviteEmailTemplates' | 'resetPasswordEmailTemplates'> {
    inviteEmailDefaultTemplate: EmailTemplateFormModel;
    inviteEmailTemplates: EmailTemplateFormModel[];
    resetPasswordEmailDefaultTemplate: EmailTemplateFormModel;
    resetPasswordEmailTemplates: EmailTemplateFormModel[];
}

interface EmailTemplateFormModel extends Omit<EmailTemplate, 'conditions'> {
    conditions: EmailTemplateConditionFormModel[];
}

interface EmailTemplateConditionFormModel extends Omit<EmailTemplateCondition, 'claim'> {
    conditionType: ConditionTypes;
    claim?: ConditionClaimFormModel;
}

interface ConditionClaimFormModel {
    type: ClaimConfig;
    value: string | string[];
}

const MAXIMUM_EXPIRATION_TIME = secondsInWeek;
const MINIMUM_EXPIRATION_TIME = secondsInMinute * 6; // 6 minutes..

@Injectable({ providedIn: 'root' })
export class SystemEmailSettingsController {

    constructor(
        private ufb: UfFormBuilder,
        private translate: TranslateService,
        private ucUserClaims: UcUserClaims,
    ) { }

    async buildRoot(data: EmailInfo): Promise<UfControlGroup> {
        const formModel = await this.toFormModel(data);

        return this.ufb.group({
            [EmailSettingsControlKey.InviteEmailExpiry]: this.ufb.control(formModel.inviteEmailExpiry, ValidatorFunctions.compose([
                ValidatorFunctions.custom((v) => v <= MAXIMUM_EXPIRATION_TIME, 'Maximum expiry is 7 days'),
                ValidatorFunctions.custom((v) => v > MINIMUM_EXPIRATION_TIME, 'Minimum expiry is 1 hour'),
            ])),
            [EmailSettingsControlKey.EmailColour]: [formModel?.emailColour ?? '#177e89'],
            [EmailSettingsControlKey.InviteEmailDefaultTemplate]: this.buildTemplateControl((formModel.inviteEmailTemplates || []).at(formModel.inviteEmailTemplates.length - 1), true),
            [EmailSettingsControlKey.InviteEmailTemplates]: this.ufb.array((formModel.inviteEmailTemplates || []).slice(0, -1).map((template) => this.buildTemplateControl(template))),
            [EmailSettingsControlKey.ResetPasswordEmailDefaultTemplate]: this.buildTemplateControl((formModel.resetPasswordEmailTemplates || []).at(formModel.resetPasswordEmailTemplates.length - 1), true),
            [EmailSettingsControlKey.ResetPasswordEmailTemplates]: this.ufb.array((formModel.resetPasswordEmailTemplates || []).slice(0, -1).map((template) => this.buildTemplateControl(template))),
            [EmailSettingsControlKey.ReplyTo]: formModel?.replyTo,
        });
    }

    buildTemplateControl(template?: EmailTemplateFormModel, isDefault?: boolean): UfControlGroup {
        return this.ufb.group({
            [EmailTemplateControlKey.Title]: [template?.title, ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired))],
            [EmailTemplateControlKey.Subject]: [template?.subject, ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired))],
            [EmailTemplateControlKey.IntroMessage]: [template?.introMessage, ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired))],
            [EmailTemplateControlKey.ButtonLabel]: [template?.buttonLabel, ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired))],
            [EmailTemplateControlKey.BodyMessage]: [template?.bodyMessage, ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired))],
            [EmailTemplateControlKey.BaseUrl]: template?.baseUrl,
            [EmailTemplateControlKey.Conditions]: this.ufb.array(
                (template?.conditions ?? []).map((condition) => this.buildConditionControl(condition)),
                ValidatorFunctions.custom((v) => {
                    if (!isDefault) {
                        return v.length > 0;
                    }

                    return true;
                }, 'At least one condition is needed')),
            [EmailTemplateControlKey.ReplyTo]: template?.replyTo,
        });
    }

    buildConditionControl(condition?: EmailTemplateConditionFormModel): UfControlGroup {
        const conditionType = this.getConditionType(condition);

        return this.ufb.group({
            [ConditionControlKey.Claim]: this.buildClaimControl(conditionType, condition?.claim),
            [ConditionControlKey.Role]: [{ value: condition?.role, disabled: conditionType === ConditionTypes.Claim }, ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired))],
            [ConditionControlKey.ConditionType]: conditionType,
        });
    }

    async toFormModel(dataModel: EmailInfo): Promise<EmailInfoFormModel> {
        dataModel.inviteEmailTemplates.sort(this.templateSort);
        dataModel.resetPasswordEmailTemplates.sort(this.templateSort);

        const formModel = Object.assign({}, dataModel) as any;

        for (const templates of [formModel.inviteEmailTemplates, formModel.resetPasswordEmailTemplates]) {
            for (let template of templates) {
                template = await this.toTemplateFormModel(template);
            }
        }

        return Object.assign({}, dataModel) as unknown as EmailInfoFormModel;
    }

    async toTemplateFormModel(template?: EmailTemplate): Promise<EmailTemplateFormModel> {
        const claimConfig = await this.ucUserClaims.list();

        for (const condition of template?.conditions ?? []) {
            if (condition.claim?.type) {
                (condition as any).claim.type = claimConfig.find((c) => c.type === condition.claim?.type) ?? condition.claim.type;
            }
        }

        return template as unknown as EmailTemplateFormModel;
    }

    toDataModel(formModel: EmailInfoFormModel): EmailInfo {
        formModel.inviteEmailTemplates = [...formModel.inviteEmailTemplates, formModel.inviteEmailDefaultTemplate];
        formModel.resetPasswordEmailTemplates = [...formModel.resetPasswordEmailTemplates, formModel.resetPasswordEmailDefaultTemplate];

        delete (formModel as any).inviteEmailDefaultTemplate;
        delete (formModel as any).resetPasswordEmailDefaultTemplate;

        for (const templates of [formModel.inviteEmailTemplates, formModel.resetPasswordEmailTemplates]) {
            for (const template of templates) {

                // set to null rather than empty string for back end
                template.baseUrl = template.baseUrl?.length ? template.baseUrl : null;

                for (const condition of template.conditions) {
                    switch (condition.conditionType) {
                        case ConditionTypes.Role:
                            delete condition.claim;
                            break;
                        case ConditionTypes.Claim:
                            delete condition.role;
                            break;
                    }
                    if (condition.claim) {
                        (condition as any).claim.type = condition.claim?.type.type;
                    }
                    delete (condition as any).conditionType;
                }
            }
        }

        return formModel as unknown as EmailInfo;
    }

    private buildClaimControl(conditionType: ConditionTypes, claim?: ConditionClaimFormModel): UfControlGroup {
        const control = this.ufb.group({
            [ConditionClaimControlKey.Type]: [claim?.type, ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired))],
            [ConditionClaimControlKey.Value]: [claim?.value, ValidatorFunctions.required(this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired))],
        });

        if (conditionType === ConditionTypes.Role) {
            control.disable();
        }

        return control;
    }

    private getConditionType(condition?: EmailTemplateConditionFormModel): ConditionTypes {
        if (condition?.claim && condition?.role) {
            return ConditionTypes.Combination;
        } else if (condition?.role && !condition.claim) {
            return ConditionTypes.Role;
        }

        return ConditionTypes.Claim;
    }

    private templateSort(a: EmailTemplate, b: EmailTemplate): number {
        return (a.index ?? 0) - (b.index ?? 0);
    }

}
