import { Injectable, inject } from '@angular/core';
import { ClientGetOptions, amendOptionsParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { AuditEntry } from './models';
import { UcClient } from './uc-client';

export interface AuditQuery {
    from?: string;
    to?: string;
    login?: string;
    resource?: string;
    action?: string;
    offset?: number;
    limit?: number;
}

@Injectable({ providedIn: 'root' })
export class UcAuditLog {

    private client = inject(UcClient);

    query(options?: ClientGetOptions): Promise<AuditEntry[]> {
        return this.client.get(this.client.buildUrl(['audit-log']), amendOptionsParams(DefaultPaginationParams, options));
    }

}
