<div *ngIf="form && !error" class="header-pane uf-app-bar transparent">
    <uc-builder-header />
</div>
<uf-panel *ngIf="!error" class="content-pane">
    <div class="uf-container-lg">

        <uf-skeleton *ngIf="!form" />

        <div *ngIf="form" [formGroup]="form" class="uf-grid row-gap-lg pad-top">
            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Details
                    </div>
                </div>
                <div class="uf-grid pad">
                    <uf-text [formControlName]="controlKeys.Label" label="Title" class="col-12">
                        <span class="suffix">*</span>
                    </uf-text>
                    <uf-autocomplete [allowCustom]="false" [options]="bucketsResult"
                        [formControlName]="controlKeys.Bucket" (searchChange)="searchBuckets($event)" nameProperty="id"
                        label="Form Data Repository" placeholder="Search form data repository" class="col-12" />
                </div>
            </div>
            <!-- Only show when you select a bucket  -->
            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Timer
                    </div>
                </div>
                <div class="uf-grid pad">
                    <uf-textarea [formControlName]="controlKeys.Expression" label="Run At (Expression)" class="col-12">
                        <span class="suffix">*</span>
                    </uf-textarea>
                </div>
            </div>

            <uf-expander [actionBtn]="true" [isExpanded]="showReplaceTimersContainer" class="uf-box accent col-12">
                <div expanderHeader class="uf-app-bar accent">
                    <div class="title">
                        Replace existing running timers
                    </div>
                </div>
                <div expanderBody class="pad">
                    <div class="grow">
                        <uf-checkbox [formControlName]="controlKeys.ReplacePreviousJob"
                            label="Replace previous timer (self replacement)" class="col-12" />
                        <uf-chips [options]="filteredWorkflowRelatedActivities"
                            [formControlName]="controlKeys.RelatedWorkflowActivities" [allowCustom]="false"
                            (searchChange)="filterRelatedActivities($event)" nameProperty="label" valueProperty="id"
                            label="Replace alternate timer" class="col-12 gap-top" />
                    </div>
                </div>
            </uf-expander>

        </div>
    </div>
</uf-panel>

<uc-error-message [error]="error" />