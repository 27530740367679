<div class="row">
    <uf-password *ngIf="!edit" [disabled]="true" [showReveal]="edit" [control]="control" [label]="label" [value]="value"
        class="grow">
        <uf-help *ngIf="help" [content]="help" />
    </uf-password>
    <uf-password *ngIf="edit" [showReveal]="true" [control]="control" [label]="label" [(value)]="value" class="grow">
        <uf-help *ngIf="help" [content]="help" />
    </uf-password>
    <div *ngIf="initialValue" class="edit">
        <button (click)="toggleEdit()" type="button" class="uf-button">
            <ng-container *ngIf="!edit">Edit</ng-container>
            <ng-container *ngIf="edit">Cancel Edit</ng-container>
        </button>
    </div>
</div>