import { Injectable } from '@angular/core';
import { DataDescriptor, DataDescriptorService } from '@unifii/library/common';

@Injectable({ providedIn: 'root' })
export class TableEditorService {

    constructor(
        private dataDescriptorService: DataDescriptorService,
    ) { }

    getCompanyDataDescriptor(): Promise<DataDescriptor | undefined> {
        return this.dataDescriptorService.getCompanyDataDescriptor();
    }

    getUserDataDescriptor(): Promise<DataDescriptor | undefined> {
        return this.dataDescriptorService.getUserDataDescriptor();
    }

    getBucketDataDescriptor(bucket: string): Promise<DataDescriptor | undefined> {
        return this.dataDescriptorService.getBucketDataDescriptor(bucket);
    }

}
