<uf-panel *ngIf="ready" class="content-pane container">
    <div class="uf-container-lg">
        <div class="uf-grid row-gap-lg gap-top">
            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Details
                    </div>
                </div>
                <div class="uc-group-content uf-grid pad">
                    <uf-data-display-list [items]="detailsKeyValue" class="small-label col-12" />

                    <uf-text [control]="cTitle" [(value)]="table.title" class="col-12" label="Title" />

                    <uf-text [control]="cIdentifier" [maxLength]="identifierMaxLength" [(value)]="table.identifier"
                        class="col-12" label="Identifier" />

                    <uf-textarea [control]="cDescription" [(value)]="table.description" class="col-12"
                        label="Description">
                        <uf-help
                            content="Description is displayed in the Console only. Add notes to remember table set up and purpose." />
                    </uf-textarea>

                    <uf-markdown-editor [control]="cHelpText" [(value)]="table.help" class="col-12" label="Help Text">
                        <uf-help content="Help Text is displayed to end users." />
                    </uf-markdown-editor>

                    <uf-checkbox *ngIf="showRecordCount" [control]="cShowCount" label="Show Count" class="col-12" />

                    <uf-checkbox *ngIf="table.sourceType !== sourceTypes.Company" [control]="cExport" [(value)]="export"
                        label="Export" class="col-12" />

                    <uf-chips [control]="cExportRoles" [options]="filteredRoles"
                        *ngIf="export && table.sourceType !== sourceTypes.Company" (searchChange)="filterRoles($event)"
                        class="col-12" label="Export roles" />
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Default Sort
                    </div>
                </div>
                <div class="uc-group-content uf-grid gap">
                    <uc-sort-property-input [properties]="sortableColumns" [(value)]="table.defaultSort"
                        (valueChange)="edited = true" class="col-12" />
                </div>
            </div>

            <uc-table-columns [availableColumns]="availableColumns" [parentControl]="cRoot" [(columns)]="table.columns"
                (columnsChange)="edited = true" class="col-12" />

            <uc-visible-filters [availableFilters]="availableVisibleFilters" [parentControl]="cRoot"
                [(filters)]="table.visibleFilters" (filtersChange)="edited = true" class="col-12" />

            <div class="uf-box col-12 gap-top-sm">
                <div class="uf-app-bar flat accent">
                    <ng-template [ngIf]="isAdvancedFilter" [ngIfElse]="basic">
                        <div class="title">
                            Advanced Hidden Filters
                        </div>
                        <a (click)="switchFilter()" title="Switch to standard" class="uf-action tertiary">
                            <uf-icon name="filter" />
                        </a>
                    </ng-template>
                    <ng-template #basic>
                        <div class="title">
                            Hidden Filters
                        </div>
                        <a (click)="switchFilter()" title="Switch to advanced" class="uf-action tertiary">
                            <uf-icon name="custom" />
                        </a>
                    </ng-template>
                </div>

                <div class="uc-group-content grid">
                    <uc-filter-editor *ngIf="!isAdvancedFilter" [dataProperties]="availableHiddenFilters"
                        [parentControl]="cFilter" [(filter)]="table.filter" class="col-1of1" />
                    <uf-textarea *ngIf="isAdvancedFilter" [control]="cAdvancedFilter" [(value)]="table.advancedFilter"
                        class="col-1of1" />
                </div>
            </div>
        </div>
    </div>
</uf-panel>