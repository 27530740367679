<div *ngIf="showAsCard; else showFlat" class="uf-box col-12">

    <div class="uf-app-bar flat accent">
        <div class="title">
            Visible Filters
        </div>
        <uc-expander-controls [container]="expandAllContainer" class="primary" />
    </div>

    <div #expandAllContainer class="uc-group-content pad-sides pad-bottom pad-sm-top">
        <ng-container *ngTemplateOutlet="filters" />
    </div>

</div>

<ng-template #showFlat>
    <h4>Visible Filters</h4>
    <ng-container *ngTemplateOutlet="filters" />
</ng-template>

<ng-template #filters>
    <uc-drag-list *ngIf="items.length" [items]="items" (moved)="emit()" canDrop="false" canReorder="true">
        <ng-template [ngForOf]="items" let-item ngFor let-i="index">
            <uf-expander [isExpanded]="false" dragItem class="uf-box draggable flat gap-sm-top">
                <div class="uf-app-bar" expanderHeader dragHandle>
                    <uf-icon [name]="item.icon" />
                    <div class="grow title primary">{{item.label}}</div>
                    <span *ngIf="!item.dataPropertyDescriptor" class="uf-lozenge error">Missing</span>
                    <button (click)="removeFilter(i)" type="button" title="Remove" class="uf-action tertiary">
                        <uf-icon name="delete" />
                    </button>
                </div>
                <ng-container expanderBody>
                    <uc-visible-filter [parentControl]="parentControl" [filterInfo]="item" (filterChange)="emit()" />
                </ng-container>
            </uf-expander>
        </ng-template>
    </uc-drag-list>
    <uf-search-result [options]="filterResults" (valueChange)="addFilter({identifier: $event.identifier})"
        (searchChange)="filterFilters($event)" placeholder="Search filter" nameProperty="display" class="gap-top" />
</ng-template>