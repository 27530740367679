import { Component, HostBinding, Inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';

import { EmailTemplate } from 'client';

interface EmailPreviewData extends EmailTemplate {
    emailColour: string;
}

@Component({
    selector: 'uc-system-email-template-preview-modal',
    templateUrl: 'system-email-template-preview-modal.html',
})
export class SystemEmailPreviewModalComponent implements Modal<EmailPreviewData, null> {

    @HostBinding('class.uf-form-card') cardClass = true;

    constructor(
        public runtime: ModalRuntime<EmailPreviewData, null>,
        @Inject(ModalData) public data: EmailPreviewData,
    ) { }

    close() {
        this.runtime.close();
    }

}
