<div class="header">
    <button (click)="close()" type="button" class="uf-action tertiary" title="Close">
        <uf-icon name="close" />
    </button>
    <h3>Image crop</h3>
</div>

<div #container class="uf-grid grow checkboard-bg">
    <uf-spinner *ngIf="!ready" class="col-12" type="dots" colour="#177e89" />
    <div class="col-12">
        <uc-image-cropper *ngIf="ready" [src]="image.url" [minWidth]="minWidth" [minHeight]="minHeight"
            [enabled]="enabled" [size]="canvasSize" [(value)]="value" class="col center-all justify-center" />
    </div>
</div>

<div class="row space-children pad">
    <div class="row grow center-all">
        <p *ngIf="!enabled" class="warning-text left">This image is too small for cropping</p>
        <span *ngIf="ready">Original size {{image.width}}x{{image.height}}</span>
        <span *ngIf="ready && value">
            Crop [ X:{{value.x}}, Y:{{value.y}}, W:{{value.width}}, H:{{value.height}}]
        </span>
    </div>
    <button *ngIf="enabled" (click)="close()" type="button" class="uf-button tertiary right">Cancel</button>
    <button *ngIf="ready && value" (click)="remove()" type="button" class="uf-button">Clear</button>
    <button *ngIf="enabled" (click)="save()" type="button" class="uf-button primary">Save</button>
</div>