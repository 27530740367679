import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { ModalService } from '@unifii/library/common';

export interface ItemPickerInfo<T> {
    id: string;
    label: string;
    icon: string;
    deletable?: boolean;
    marker?: string;
    data?: T;
}

export interface ItemPickerGroup<T> {
    title?: string;
    items: ItemPickerInfo<T>[];
}

@Component({
    selector: 'uc-item-picker',
    templateUrl: './item-picker.html',
    styleUrls: ['./item-picker.less'],
})
export class ItemPickerComponent<T> {

    @Input({ required: true }) groups: ItemPickerGroup<T>[];
    @Output() delete = new EventEmitter<ItemPickerInfo<T>>();

    private modalService = inject(ModalService);

    protected async onDelete(item: ItemPickerInfo<T>) {

        if (!await this.modalService.openConfirm({
            title: 'Delete Custom Field',
            message: `This deletion can't be undone.`,
            confirmLabel: 'Delete',
            cancelLabel: `Don't Delete`,
        })) {
            return;
        }

        this.delete.next(item);
    }

}
