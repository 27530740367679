import { Injectable, inject } from '@angular/core';
import { HierarchyUnitProvider } from '@unifii/library/common';
import { Dictionary, ErrorType, HierarchyUnitExtended, HierarchyUnitWithChildCount, HierarchyUnitWithPath, UfRequestError } from '@unifii/sdk';

import { UcHierarchy } from './hierarchy';

@Injectable()
export class UcHierarchyUnitProvider implements HierarchyUnitProvider {

    private hierarchy = inject(UcHierarchy);

    getUnit(id: string): Promise<HierarchyUnitExtended | undefined> {
        try {
            return this.hierarchy.getUnit(id);
        } catch (e) {
            if ((e as UfRequestError).type === ErrorType.NotFound) {
                return Promise.resolve(undefined);
            } else {
                throw e;
            }
        }
    }

    getUnits(ids: string[]): Promise<HierarchyUnitWithPath[]> {
        return this.hierarchy.getUnits(ids);
    }

    // TODO use API query parameter once UNIFII-6526 is resolved
    async getChildren(id: string, query?: string, params?: Dictionary<any>): Promise<HierarchyUnitWithChildCount[]> {
        try {
            const children = await this.hierarchy.getChildren(id, { params });

            if (!query || query.trim().length === 0) {
                return children;
            }

            return children.filter((u) => u.label.toLowerCase().includes((query as string).toLowerCase()));
        } catch (e) {
            if ((e as UfRequestError).type === ErrorType.NotFound) {
                return Promise.resolve([]);
            } else {
                throw e;
            }
        }
    }

    search(params: { q?: string; leaves?: boolean; ceiling?: string; active?: boolean }): Promise<HierarchyUnitWithPath[]> {
        return this.hierarchy.search(params);
    }

}
