/* eslint-disable @typescript-eslint/member-ordering */

import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Observable, lastValueFrom } from 'rxjs';

import { DragService } from './drag.service';

export interface DragListEvent<T> {
    item: T;
    target: DragListComponent;
    to: number;
    source?: DragListComponent;
    from?: number;
}

export enum DropPosition {
    Before = 'Before',
    After = 'After',
    Inside = 'Inside'
}

@Component({
    selector: 'uc-drag-list',
    templateUrl: './drag-list.html',
    styleUrls: ['drag-list.less'],
})
export class DragListComponent {

    private static counter = 0;

    @Input() parent: any;
    @Input() childrenProperty = 'children';

    // Config/Callback to decide if an item can de dropped (added, movedUp, movedDown, reordered)
    @Input() canDrop?: boolean | string | ((item: any, parent: any, dropIndex?: number) => boolean | Promise<boolean> | Observable<boolean>);
    // Config/Callback to decide if an item can be reordered withing its original list (this override the canDrop for the reordering case)
    @Input() canReorder?: boolean | string | ((item: any, parent: any, dropIndex?: number) => boolean | Promise<boolean> | Observable<boolean>);

    @Input() convertAdded?: (...args: any) => any | Promise<any>;
    @Input() drop?: (item: any, parent: any, position: DropPosition, index?: number) => void;

    @Output() insert = new EventEmitter<DragListEvent<any>>();
    @Output() moved = new EventEmitter<DragListEvent<any>>();

    id: number; // for diagnostic purposes
    hideIndicator = true;
    indicatorX = '0px';
    indicatorY = '0px';
    indicatorWidth = '100%';

    private enterCount = 0;
    private dropIndex: number | null;
    private dropPosition = DropPosition.Inside;
    private _items: any[];

    constructor(private service: DragService, public elementRef: ElementRef) {
        this.id = DragListComponent.counter++;
    }

    @Input() set items(v) {
        this._items = v;
    }

    get items() {
        return this._items || (this.parent?.[this.childrenProperty]);
    }

    findIndex(item: any) {
        return this.items.findIndex((i) => i === item);
    }

    end() {
        this.enterCount = 0;
        this.dropIndex = null;
        this.dropPosition = DropPosition.Inside;
        this.service.sourceList = null;
        this.service.dragIndex = null;
        this.hideIndicator = true;
    }

    // setPosition sets the indicator position and determines if it should be shown.
    setPosition(index: number, where: DropPosition, x: number, y: number, width: number): boolean {

        this.dropPosition = where;
        this.dropIndex = index;
        this.indicatorX = x + 'px';
        this.indicatorY = (y - 1) + 'px';
        this.indicatorWidth = width + 'px';
        const realDropIndex = this.getDropIndex();
        const canDrop = this.isDropLegal(realDropIndex || 0);

        // when inside we show outline
        this.hideIndicator = !canDrop || where === DropPosition.Inside;

        return canDrop;
    }

    private isChildOf(item: any) {

        if (item == null) {
            return false;
        }

        let children;

        if (item instanceof AbstractControl) {
            const childrenControl = (item as UntypedFormGroup).get(this.childrenProperty);

            if (childrenControl instanceof UntypedFormArray) {
                children = childrenControl.controls;
            }
        } else {
            children = item[this.childrenProperty];
        }

        if (!children) {
            return false;
        }

        if (children === this.items) {
            return true;
        }

        for (const ch of children) {
            if (this.isChildOf(ch)) {
                return true;
            }
        }

        return false;
    }

    private getDropIndex() {
        if (this.dropIndex == null) {
            return this.dropIndex;
        }

        let dropIndex = this.dropIndex;

        // placing after
        if (this.dropPosition === DropPosition.After) {
            dropIndex++;
        }

        // dragging down within same list
        if (this.service.sourceList === this && dropIndex > (this.service.dragIndex as number) && this.dropPosition !== DropPosition.Inside) {
            dropIndex--;
        }

        return dropIndex;
    }

    private isDropLegal(dropIndex: number) {
        if (this.service.sourceList == null) {
            return this.service.data != null;
        }
        if (this.service.sourceList === this && dropIndex === this.service.dragIndex) {
            return false;
        }
        if (this.isChildOf(this.service.sourceList.items[this.service.dragIndex as number])) {
            return false;
        }

        return true;
    }

    private async canDropInternal(dropIndex: number, item: any, parent: any): Promise<boolean> {

        // Can't drop
        if (!this.isDropLegal(dropIndex)) {
            return false;
        }

        const isReorderCase = this.service.sourceList === this;

        if (this.canReorder != null && isReorderCase) {
            return this.canReorderInternal(dropIndex, item, parent);
        }

        // canDrop not provided
        if (this.canDrop == null) {
            return true;
        }

        // canDrop as boolean
        if (typeof this.canDrop === 'boolean') {
            return this.canDrop;
        }

        // canDrop as string
        if (typeof this.canDrop === 'string') {
            return this.canDrop === 'true';
        }

        // CanDrop is a function
        if (typeof this.canDrop === 'function') {
            const result = this.canDrop(item, parent, dropIndex);

            if (typeof result === 'boolean' || result instanceof Promise) {
                return result;
            }

            return lastValueFrom(result);
        }

        return true;
    }

    private async canReorderInternal(dropIndex: number, item: any, parent: any): Promise<boolean> {
        if (this.canReorder == null) {
            return true;
        }

        if (typeof this.canReorder === 'boolean') {
            return this.canReorder;
        }

        if (typeof this.canReorder === 'string') {
            return this.canReorder === 'true';
        }

        if (typeof this.canReorder === 'function') {
            const result = this.canReorder(item, parent, dropIndex);

            if (typeof result === 'boolean' || result instanceof Promise) {
                return result;
            }

            return lastValueFrom(result);
        }

        return true;
    }

    private getItemToAdd() {
        if (this.service.sourceList != null) {
            return this.service.sourceList.items[this.service.dragIndex as number];
        } else {
            return this.service.data;
        }
    }

    @HostListener('drop', ['$event'])
    // eslint-disable-next-line complexity
    async dropInternal(event: DragEvent) {

        event.stopPropagation();

        const dropIndex = this.getDropIndex();
        const parent = this.dropPosition === DropPosition.Inside && this.items && dropIndex != null ? this.items[dropIndex] : this.parent;
        let item = this.getItemToAdd();

        // console.log('same list', this.service.sourceList === this);
        // console.log(`dropInternal dropPotision ${this.dropPosition} dropIndex ${dropIndex ?? 0} item`, item, 'parent', parent);

        const can = await this.canDropInternal(dropIndex || 0, item, parent);

        if (!can) {
            this.end();

            // console.warn('Cannot drop, aborting!');
            return;
        }

        // run conversion callback on new item
        if (this.service.sourceList == null && this.convertAdded) {
            const result = this.convertAdded(item, parent);

            if (result instanceof Promise) {
                item = await result;
            } else {
                item = result;
            }
        }

        // Detect if draglist is working with Control - Review: should it support a mix scenario?
        const useControls = item instanceof AbstractControl;

        // removed source item if it comes from sourceList
        if (this.service.sourceList != null) {

            if (useControls) {
                const parentControl = (this.service.sourceList.items[this.service.dragIndex as number] as AbstractControl).parent;

                if (parentControl instanceof UntypedFormArray) {
                    parentControl.removeAt(this.service.dragIndex as number);
                } else if (parentControl instanceof UntypedFormGroup) {
                    const name = Object.keys(parentControl.controls).find((c) => parentControl.controls[c] === item);

                    if (name) {
                        parentControl.removeControl(name);
                    }
                    console.warn('DragListComponent.drop - Remove dragged control from sourceList failed');
                }
            } else {
                this.service.sourceList.items.splice(this.service.dragIndex as number, 1);
            }
        }

        if (typeof this.drop === 'function') {
            this.drop(item, parent, this.dropPosition, dropIndex ?? undefined);
        } else {
            // insert item into the new position
            if (this.dropPosition === DropPosition.Inside && parent) {

                if (useControls) {
                    if (parent instanceof UntypedFormArray) {
                        (parent as UntypedFormArray).push(item);
                    } else if (parent instanceof UntypedFormGroup && this.childrenProperty) {
                        const childrenControl = parent.get(this.childrenProperty);

                        if (childrenControl instanceof UntypedFormArray) {
                            childrenControl.insert(0, item);
                        }
                    }

                } else {
                    if (parent[this.childrenProperty] == null) {
                        parent[this.childrenProperty] = [];
                    }
                    parent[this.childrenProperty].push(item);
                }
            } else {
                // Drop at the bottom if no dropIndex specified, at the index otherwise
                if (useControls) {
                    // TODO Fix the usage of 0 index
                    const parentControl = !this.items.length ? this.parent : (this.items[0] as AbstractControl).parent as UntypedFormArray;

                    parentControl.insert(dropIndex == null ? this.items.length : dropIndex, item);
                } else {
                    this.items.splice(dropIndex == null ? this.items.length : dropIndex, 0, item);
                }
            }
        }

        const dragEvent: DragListEvent<any> = {
            item,
            source: this.service.sourceList ?? undefined,
            from: this.service.sourceList ? this.service.dragIndex as number : undefined,
            target: this,
            to: dropIndex == null ? this.items.length : dropIndex,
        };

        if (this.service.sourceList == null) {
            // Fire insert for new item
            this.insert.emit(dragEvent);
        } else {
            // Fire drop for moved existing item
            this.moved.emit(dragEvent);
        }

        this.end();
    }

    @HostListener('dragover', ['$event'])
    dragover(event: DragEvent) {
        event.stopPropagation();
        // TODO don't show indicators when drop is impossible
        // this.hideIndicator = !this.isDropLegal(this.getDropIndex());
        event.preventDefault();
    }

    @HostListener('dragenter', ['$event'])
    dragenter(event: DragEvent) {
        event.stopPropagation();
        this.enterCount++;
    }

    @HostListener('dragleave', ['$event'])
    dragleave(event: DragEvent) {
        event.stopPropagation();
        this.enterCount--;
        if (this.enterCount === 0) {
            this.hideIndicator = true;
        }
    }

}
