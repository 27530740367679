<div *ngIf="item && !error" class="col grow">
    <div class="uf-app-bar transparent">
        <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large" />
        <a [routerLink]="['../']" class="uf-button tertiary right">Cancel</a>
        <button (click)="revoke()" type="button" class="uf-button secondary right">Revoke</button>
    </div>
    <div class="content-pane">
        <div class="uf-container-lg">
            <div class="uf-grid row-gap-lg pad-top">
                <div class="col-12 uf-box">
                    <div class="uf-app-bar accent">
                        <div class="title">Details</div>
                    </div>
                    <div class="uf-grid pad">
                        <div [class.center-all]="!!this.item.lastUsedAt" [class.align-flex-end]="!this.item.lastUsedAt"
                            class="col-12 row space-children">
                            <uf-data-display-list [items]="items" [class.gap-sm-bottom]="!this.item.lastUsedAt"
                                class="small-label" />
                            <button (click)="clipboard.setText(item.key)" type="button" title="Copy"
                                class="uf-action tertiary">
                                <uf-icon name="copy" />
                            </button>
                        </div>
                        <div *ngIf="item.enableOAuth" class="col-12">
                            <p class="small">
                                OAuth 2 client credentials flow enabled
                            </p>
                        </div>
                        <ng-template [ngIf]="item.secret">
                            <div class="col-12 row align-flex-end space-children">
                                <uf-data-display-list [items]="[{term: 'Secret', data: item.secret}]"
                                    class="small-label" />
                                <button (click)="clipboard.setText(item.secret)" type="button"
                                    class="uf-action tertiary" title="Copy">
                                    <uf-icon name="copy" />
                                </button>
                            </div>
                            <uf-blockquote icon="warningSolid" heading="Save your key and secret."
                                content="The secret won't be visible after leaving this page." class="col-12 warning" />
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <uf-panel class="grow pad-sides">
        <uc-permissions />
    </uf-panel>
</div>

<uc-error-message [error]="error" />