import { Component, HostBinding, Inject, ViewChild } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { Option } from '@unifii/sdk';

import { DragListComponent } from 'components/dragdrop/drag-list.component';

@Component({
    templateUrl: './data-source-mappings-reorder.html',
})
export class DataSourceMappingsReorderComponent implements Modal<Option[], string[]> {

    @HostBinding('class.uc-form-card') classes = true;
    @ViewChild(DragListComponent, { static: true }) dragListComponent: DragListComponent;

    protected items: Option[];

    constructor(
        @Inject(ModalData) data: Option[],
        public runtime: ModalRuntime<Option[], string[]>,
    ) {
        this.items = data;
    }

    close() {
        this.runtime.close();
    }

    protected save() {
        this.runtime.close(this.dragListComponent.items.map((item) => item.identifier));
    }

}

