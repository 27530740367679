<uf-expander class="uf-box flat">

    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Validators</div>
        <uf-icon *ngIf="validators.invalid" name="error" class="error" />
        <uc-expander-controls *ngIf="validators.length" [container]="validatorsWrap" />
    </div>

    <div #validatorsWrap expanderBody class="pad-sm">

        <uc-drag-list [items]="validators.controls" canDrop="false" canReorder="true">

            <ng-template [ngForOf]="validators.controls | asUfControlsGroups" ngFor let-validator let-i="index">
                <ng-container *ngIf="validatorsInfo[i]; let validatorInfo">
                    <uf-expander [dragDisabled]="validators.disabled" [isExpanded]="expandeds[i]" dragItem
                        class="uf-box flat draggable gap-sm-bottom">
                        <div expanderHeader dragHandle class="uf-app-bar flat">
                            <div class="title primary">{{validatorInfo.typeLabel}}</div>
                            <uf-icon *ngIf="validator.invalid" name="error" class="error" />
                            <button *ngIf="!validator.disabled" (click)="removeValidator(i)" type="button"
                                title="Delete" class="uf-action">
                                <uf-icon name="delete" />
                            </button>
                        </div>
                        <div [formGroup]="validator" expanderBody class="uf-grid pad">
                            <ng-container *ngIf="!validator.get(validatorKeys.Value)?.disabled">
                                <uf-text *ngIf="validatorInfo.type !== validatorTypes.Expression"
                                    [label]="validatorInfo.valueLabel" [placeholder]="validatorInfo.valuePlaceholder"
                                    [formControlName]="validatorKeys.Value" class="col-12" />
                                <uf-textarea *ngIf="validatorInfo.type === validatorTypes.Expression"
                                    [label]="validatorInfo.valueLabel" [placeholder]="validatorInfo.valuePlaceholder"
                                    [formControlName]="validatorKeys.Value" class="col-12" />
                            </ng-container>
                            <uf-text [placeholder]="validatorInfo.messagePlaceholder"
                                [formControlName]="validatorKeys.Message" label="Message" class="col-12" />
                        </div>
                    </uf-expander>
                </ng-container>
            </ng-template>

        </uc-drag-list>

        <button [options]="validatorOptions" (optionSelected)="addValidator($event)" type="button"
            class="uf-button x-small right gap-sm-top" listBox nameProperty="name">
            Add Validator
            <uf-icon name="more" class="gap-none" />
        </button>

    </div>
</uf-expander>