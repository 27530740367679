import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'bypassSanitizeUrl' })
export class BypassSanitizeUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }
  transform(value: string): any {
    if (value) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    }

    return '';
  }

}
