import { Component, Injector, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalService, ToastService, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { AstNode, Device, Q, RouteInfo, hasLengthAtLeast } from '@unifii/sdk';

import { PushNotification, UcApps } from 'client';

import { AppDetailsComponent } from './app-details.component';
import { SelectDevicesComponent } from './select-devices.component';
import { QueryBuilderData, UsersQueryBuilderComponent } from './users-query-builder.component';

@Component({
    selector: 'uc-app-send-notification',
    templateUrl: 'app-send-notification.html',
})
export class AppSendNotificationComponent implements OnInit, OnDestroy {

    @ViewChild('headerButtons', { static: true }) headerButtonsTemplate: TemplateRef<any>;

    form: UfControlGroup;
    messageControl: UfControlGroup;
    routingControl: UfControlGroup;

    selectedDevices: Device[] = [];
    userAstNode: AstNode;
    notification: PushNotification = { title: '', message: '', route: {}, devices: [] };

    constructor(
        private injector: Injector,
        private modalService: ModalService,
        private ucApps: UcApps,
        private toastService: ToastService,
        private parent: AppDetailsComponent,
    ) {

        this.messageControl = new UfControlGroup({
            title: new UfControl(ValidatorFunctions.required('Title is required')),
            message: new UfControl(ValidatorFunctions.required('Message is required')),
        });

        this.routingControl = new UfControlGroup({
            tenant: new UfControl(),
            projectId: new UfControl(),
            nodeId: new UfControl(),
            bucket: new UfControl(),
            id: new UfControl(),
        });

        this.form = new UfControlGroup({
            messageDetails: this.messageControl,
            routing: this.routingControl,
        });

        this.resetForm();
    }

    ngOnDestroy(): void {
        this.parent.buildHeaderConfig();
    }

    ngOnInit() {
        this.parent.templateRef = this.headerButtonsTemplate;
    }

    get id() {
        return this.parent.id;
    }

    get roles() {
        if (
            this.userAstNode.args &&
            hasLengthAtLeast(this.userAstNode.args, 1) &&
            this.userAstNode.args[0].args &&
            hasLengthAtLeast(this.userAstNode.args[0].args, 2)
        ) {
            return this.userAstNode.args[0].args[1].value;
        }
    }

    async openUserQueryBuilder() {

        const data: QueryBuilderData = {
            fields: [],
            // deep clone to avoid to modify the original data
            filter: JSON.parse(JSON.stringify(this.userAstNode)),
        };

        const result = await this.modalService.openMedium(
            UsersQueryBuilderComponent, data, undefined, undefined, this.injector);

        if (result?.edited) {
            this.userAstNode = result.filter;
        }
    }

    async addDevices() {
        const selected = await this.modalService.openMedium(
            SelectDevicesComponent,
            { appId: this.id, selected: this.selectedDevices },
        );

        if (!selected) {
            return;
        }

        for (const device of selected) {
            if (!this.selectedDevices.find((d) => d.id === device.id)) {
                this.selectedDevices.push(device);
            }
        }
    }

    resetForm() {

        this.resetUsers();
        this.selectedDevices = [];

        this.form.reset();
        this.form.setSubmitted(false);
    }

    resetUsers() {
        this.userAstNode = UsersQueryBuilderComponent.getEmptyAstNode();
    }

    resetDevices() {
        this.selectedDevices = [];
    }

    send() {

        this.form.setSubmitted();

        if (!this.form.valid) {
            return;
        }

        if (!this.selectedDevices.length && !this.roles.length && !this.claims.length) {
            this.toastService.error('At least one device or user must be targeted');

            return;
        }

        const info = this.cleanNotification();

        this.ucApps.sendNotifications(this.id, info).then(() =>
            this.toastService.success('Notifications sent'),
        );

    }

    private get claims(): AstNode[] {
        // Remove Roles
        const claims: AstNode[] = (this.userAstNode.args ?? []).slice(1);

        return claims ?? [];
    }

    private cleanNotification(): PushNotification {

        const info = Object.assign({}, this.notification);
        const userFilter: AstNode = JSON.parse(JSON.stringify(this.userAstNode));

        info.devices = this.selectedDevices.map((device) => device.id);

        // Remove empty devices list
        if (info.devices.length === 0) {
            delete info.devices;
        }

        // Remove route info if not needed
        if (info.route != null && Object.keys(info.route).find((k) => (info.route as RouteInfo)[k as keyof RouteInfo] !== '') == null) {
            delete info.route;
        }

        // Apply user query
        if (!UsersQueryBuilderComponent.isEmptyAstNode(userFilter)) {

            // Remove roles if empty
            if (!(userFilter as any).args[0].args[1].value.length) {
                (userFilter.args as AstNode[]).shift();
            }

            const filter = Q.fromAst(userFilter);

            if (filter) {
                info.userQuery = filter.stringify();
            }

        } else {
            delete info.userQuery;
        }

        return info;
    }

}
