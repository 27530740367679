<uc-icons-library />
<uc-icons-console />

<uf-panel class="container">
    <div class="grid--fixed">

        <div class="col-1of1 body-copy--small">
            <h2>Icons</h2>
        </div>

        <uf-search (searchChange)="search($event)" class="col-1of1" label="Search" />

        <uf-expander class="col-1of1 uc-group--shadow--no-border">
            <div expanderHeader class="uf-app-bar flat accent">
                <div class="title">Library</div>
            </div>
            <div expanderBody class="uc-group-content grid">
                <ng-template [ngForOf]="libraryIcons" ngFor let-item let-i="index">
                    <div #libs [ngClass]="{'selected': item.name === selected, 'duplicated': item.duplicated, 'overridden': item.overridden}"
                        [title]="item.name"
                        (click)="selectIcon(item.name)" class="wrapper">
                    </div>
                </ng-template>
            </div>
        </uf-expander>

        <uf-expander class="col-1of1 uc-group--shadow--no-border">
            <div expanderHeader class="uf-app-bar flat accent">
                <div class="title">Console</div>
            </div>
            <div expanderBody class="uc-group-content grid">
                <ng-template [ngForOf]="consoleIcons" ngFor let-item let-i="index">
                    <div #consoles [ngClass]="{'selected': item.name === selected, 'duplicated': item.duplicated, 'overridden': item.overridden}"
                        [title]="item.name"
                        (click)="selectIcon(item.name)" class="wrapper">
                    </div>
                </ng-template>
            </div>
        </uf-expander>

    </div>
</uf-panel>