import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { DataDescriptorAdapterCache, DataPropertyInfoService, ExpandersService, MessageLevel, ModalService, StorageWrapper, ToastService, UfControlArray, UfControlGroup, WindowWrapper } from '@unifii/library/common';
import { ErrorType, FieldType, UfError, ensureUfError, ensureUfRequestError, isArrayOfType, isDictionary, isString, isUfError } from '@unifii/sdk';
import { Observable, Subscription, interval } from 'rxjs';

import { DefinitionInfo, UcDefinition, UcProject } from 'client';
import { EditData, EditMode, SaveOption, SaveOptionType } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { TemplateConfigManager } from 'components/compound-builder/template-config-manager';
import { ConflictModalComponent } from 'components/conflict-modal';
import { PREVIEW_FORM_STORAGE_KEY, PROJECT_STORAGE_KEY } from 'constant';
import { flattenControls } from 'pages/structure/structure-form-ctrl';
import { reloadCurrentRoute } from 'pages/utils';
import { definitionResolver } from 'resolvers/definition-resolver';
import { ContextService } from 'services/context.service';
import { InfoTableManager } from 'services/table/info-table-manager';

import { FormEditorCache, FormEditorCacheService } from './form-editor-cache';
import { DefinitionControlKeys, FieldControlKeys } from './form-editor-control-keys';
import { FormEditorFieldScopeManager } from './form-editor-field-scope-manager';
import { FormEditorFormCtrl } from './form-editor-form-ctrl';
import { FormEditorFunctions } from './form-editor-functions';
import { FormEditorDefinition } from './form-editor-model';
import { FormEditorStatus } from './form-editor-status';
import { FormEditorService } from './form-editor.service';

@Component({
    selector: 'uc-form-editor',
    templateUrl: './form-editor.html',
    providers: [
        FormEditorStatus, FormEditorFormCtrl, FormEditorService, ExpandersService,
        TemplateConfigManager, FormEditorFieldScopeManager,
        { provide: FormEditorCache, useClass: FormEditorCacheService },
    ],
    styleUrls: ['./form-editor.less'],
})
export class FormEditorComponent implements OnInit, OnDestroy, EditData {

    protected error?: UfError;

    private previewWindow: Window | null;
    private subscriptions: Subscription = new Subscription();
    private revisionSubscription: Subscription | undefined;

    constructor(
        public status: FormEditorStatus,
        protected service: FormEditorService,
        @Inject(FormEditorCache) private cache: FormEditorCache,
        private ucProject: UcProject,
        private toast: ToastService,
        private context: ContextService,
        private router: Router,
        private dataDescriptorCache: DataDescriptorAdapterCache,
        private dataPropertyInfoService: DataPropertyInfoService,
        private modalService: ModalService,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        @Inject(StorageWrapper) private storage: StorageWrapper,
        @Inject(WindowWrapper) private window: Window,
        @Inject(ExpandersService) private expanders: ExpandersService,
        headerService: BuilderHeaderService,
        @Optional() @Inject(TableContainerManager) private tableManager: InfoTableManager | null,
    ) {
        this.status.headerService = headerService;
        this.status.route = route;
        this.status.containersByFieldType = FormEditorFunctions.getContainersByFieldType(this.context);
        this.status.editMode = FormEditorFunctions.detectEditMode(this.route.snapshot.params);
    }

    get edited(): boolean {
        return this.status.edited;
    }

    protected get form(): UfControlGroup {
        return this.status.root;
    }

    protected get fields(): UfControlArray {
        return this.form.get(FieldControlKeys.Fields) as UfControlArray;
    }

    protected get bucket(): string {
        return this.form.get(DefinitionControlKeys.Bucket)?.value as string;
    }

    private get definition(): FormEditorDefinition {
        return this.form.getRawValue() as FormEditorDefinition;
    }

    private get revision(): string | undefined {
        return this.status.definition.revision;
    }

    async ngOnInit() {
        this.dataDescriptorCache.reset();

        const resolverData = this.route.snapshot.data.definition as Exclude<Awaited<ReturnType<typeof definitionResolver>>, Observable<unknown>>;

        if (isUfError(resolverData)) {
            this.error = resolverData;

            return;
        }

        const definition = resolverData;

        // Cached data
        await this.service.loadItemPickerGroups();

        this.subscriptions.add(this.status.headerService.saveClicked.subscribe((saveOption) => { void this.onAction(saveOption); }));

        // Use definition set by definition resolver
        const formEditorDefinition = await FormEditorFunctions.mapDataToControlValue(definition, this.cache, this.dataPropertyInfoService);

        this.service.applyDefinition(formEditorDefinition);

        // New and duplicated forms start in edited status
        if (this.status.editMode !== EditMode.Existing) {
            this.status.edited = true;
        }

        this.initConflictDetection();
    }

    ngOnDestroy() {
        this.status.valueChangesSub?.unsubscribe();
        this.subscriptions.unsubscribe();
        this.revisionSubscription?.unsubscribe();
    }

    protected openPreview() {
        const openFn = this.window.open.bind(this.window);

        const definition = FormEditorFunctions.mapControlValueToData(this.definition);

        this.storage.setItem(PREVIEW_FORM_STORAGE_KEY, JSON.stringify(definition));
        this.storage.setItem(PROJECT_STORAGE_KEY, JSON.stringify(this.context.project));

        if (this.previewWindow && !this.previewWindow.closed) {
            this.previewWindow.focus();

            return;
        }

        const url = location.origin + '/form-preview';

        this.previewWindow = openFn(url, 'FormPreview');
    }

    protected selectField(control: UfControlGroup | null) {
        this.status.selected = control;
    }

    protected toggleExpanders(expand: boolean, list: HTMLElement) {

        if (expand) {
            this.expanders.expandAll(list);

            return;
        }

        this.expanders.collapseAll(list);
    }

    protected isNestable(type?: keyof typeof FieldType): boolean {
        return !!(type && this.status.containersByFieldType[type] === true);
    }

    protected filterFieldControls(control: UfControlGroup): boolean {
        return FormEditorFunctions.isControlAField(control);
    }

    private async onAction(option?: SaveOption) {

        try {
            // Guard
            if (!this.form.valid) {
                // DEBUG start
                const items = flattenControls(this.form);

                for (const entry of items) {
                    entry.control.markAsTouched();

                    if (entry.control.errors != null) {
                        console.log(entry.key, entry.control.errors);
                    }
                }

                this.toast.error('Unable to save. There are errors in your Form');

                // DEBUG end
                return;
            }

            // Disable save button so it won't be pressed twice mid save
            this.status.headerService.config.disabledSave = true;

            const saved = await this.save(FormEditorFunctions.mapControlValueToData(this.definition));

            if (!saved) {
                return;
            }

            const definitionInfo = Object.assign({}, saved, { name: saved?.label }) as any as DefinitionInfo;

            if (this.tableManager != null) {
                if (this.status.editMode === EditMode.Existing) {
                    this.tableManager.updateItem.next(definitionInfo);
                } else {
                    this.tableManager.reload.next();
                }
            }

            this.status.edited = false;

            if (!option) {
                const formEditorDefinition = await FormEditorFunctions.mapDataToControlValue(
                    saved,
                    this.cache,
                    this.dataPropertyInfoService,
                );

                // force refresh in template of root
                // needed for detecting changes to root once is re-built in applyDefinition
                this.status.root = undefined as any;
                this.cdr.detectChanges();

                this.service.applyDefinition(formEditorDefinition);

                return;
            }

            switch (option.id) {
                case SaveOptionType.Approve: {
                    const data = await this.service.approve(this.form.get('id')?.value);

                    this.tableManager?.updateItem.next(data as DefinitionInfo);
                    this.back();
                    break;
                }
                case SaveOptionType.Close:
                    this.back();
                    break;
                case SaveOptionType.New:
                    if (this.router.url.endsWith('/new')) {
                        reloadCurrentRoute(this.router);
                    } else {
                        void this.router.navigate(['..', 'new'], { relativeTo: this.route });
                    }
                    break;
                case SaveOptionType.Next: {
                    const nextId = this.tableManager?.getNextItem(saved?.id)?.id;

                    if (nextId != null) {
                        void this.router.navigate(['..', nextId], { relativeTo: this.route });
                    }
                    break;
                }
            }
        } catch (error) {
            const ufError = ensureUfError(error, 'Oops... something went wrong with saving your form');

            this.status.headerService.notify.next({
                title: 'Error',
                level: MessageLevel.Error,
                message: ufError.message,
            });
        } finally {
            this.status.headerService.config.disabledSave = false;
        }
    }

    private async save(toSave: UcDefinition, force?: boolean): Promise<UcDefinition | null> {
        try {
            const saved = await this.ucProject.saveForm(toSave, force);

            this.cache.reset();
            this.dataDescriptorCache.reset();
            this.toast.success('Form saved');

            return saved;
        } catch (err) {

            const error = ensureUfRequestError(err, 'Oops... something went wrong with saving your form');

            if (error.type === ErrorType.Conflict) {
                void this.onConflictDetected();

                return null;
            }

            if (error.type === ErrorType.Validation && isDictionary(error.data)) {
                if (isArrayOfType(error.data.warnings, isString)) {
                    const confirmed = await this.modalService.openConfirm({
                        title: 'Warning - Incompatible Fields',
                        message: error.data.warnings.join(`\n\n`),
                        confirmLabel: 'Save',
                    });

                    if (confirmed) {
                        void this.save(toSave, true);
                    }
                } else {
                    await this.modalService.openAlert({
                        title: 'Error - Incompatible Fields',
                        message: isString(error.data.message) ? error.data.message : error.message,
                        closeButtonLabel: 'Close',
                    });
                }
            } else {
                const message = isDictionary(error.data) && isString(error.data.message) ? error.data.message : error.message;

                this.toast.error(message);
            }

            return null;
        }
    }

    private back() {
        void this.router.navigate(['..'], { relativeTo: this.route });
    }

    private initConflictDetection() {
        if (this.status.editMode !== EditMode.Existing) {
            return;
        }

        this.revisionSubscription?.unsubscribe();
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.revisionSubscription = interval(15000).subscribe(async() => {
            if (!this.definition.id) {
                return;
            }

            const revision = await this.ucProject.getFormRevision(this.definition.id.toString());

            if (revision !== this.revision) {
                void this.onConflictDetected();
            }
        });
    }

    private async onConflictDetected() {
        this.revisionSubscription?.unsubscribe();

        const result = await this.modalService.openMedium(ConflictModalComponent, undefined, { guard: true });

        if (!result) {
            return;
        }

        switch (result) {
            case 'Discard':
                this.status.edited = false;
                reloadCurrentRoute(this.router);
                break;
            case 'OpenNewTab': {
                const openFn = this.window.open.bind(this.window);

                openFn(location.href);
                break;
            }
        }
    }

}
