<div *ngIf="form && !error" class="header-pane uf-app-bar transparent">
    <uc-builder-header />
</div>

<uf-panel *ngIf="form && !error" class="content-pane container">
    <div class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">

            <ng-container *ngIf="dataSource">

                <uc-data-feature-selector [integrations]="integrations"
                    [features]="features" [isNew]="isNew" [control]="form"
                    (integrationChanged)="integrationChanged($event)" (featureChanged)="featureChanged($event)" class="col-12" />

                <uc-data-feature-configuration *ngIf="feature" [control]="form" [feature]="feature"
                    [hideDataSource]="isDataTransaction" [(dataSource)]="dataSource" class="col-12" />

            </ng-container>

            <div *ngIf="features && !features.length" class="col-12">
                <uf-blockquote icon="warningSolid" content="No features available" class="warning" />
            </div>

            <div *ngIf="!dataSource" class="col-12 gap-lg-top">
                <uf-spinner type="dots" />
            </div>
        </div>
    </div>
</uf-panel>

<uc-error-message [error]="error" />