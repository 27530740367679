import { Injectable, OnDestroy } from '@angular/core';
import { UfControl, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { SchemaTransition, hasLengthAtLeast } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { APIEvent, ActivityType, FormSubmittedCondition, Integration as IntegrationData, IntegrationFeature, NewWorkflowRule, RoleAddedCondition, WorkflowActivityTimer as TimerData, TimerEvent, UcClient, UcFormBucketClient, UcIntegrations, UcWorkflow, WorkflowActivityInfo, WorkflowCondition, WorkflowEvent, WorkflowEventType, WorkflowRule, WorkflowRuleActivity, WorkflowState } from 'client';

import { reduceExpressionToSingleLine, sortByActivityType } from './workflow-functions';

interface ChangeEventTypeData {
    type: WorkflowEventType;
    bucket: UfControl;
    transitions: UfControl;
    timer: UfControl;
    integration: UfControl;
    forms: UfControl;
    roles: UfControl;
    feature: UfControl;
    includeExternal: UfControl;
    includeNewUser: UfControl;
}

export type SchemaTransitionDescription = SchemaTransition & { description: string };

export enum ControlKeys {
    Id = 'id',
    State = 'state',
    Label = 'label',
    EventType = 'eventType',
    Condition = 'condition',
    Bucket = 'bucket',
    Transitions = 'transitions',
    Forms = 'forms',
    Expression = 'expression',
    Activities = 'activities',
    Type = 'type',
    Activity = 'activity',
    Timer = 'timer',
    Integration = 'integration',
    Feature = 'feature',
    Roles = 'roles',
    IncludeNewUser = 'includeNewUser',
    IncludeExternal = 'includeExternal',
    TransitionOptions = 'transitionOptions'
}

export interface WorkflowRuleFormModel {
    [ControlKeys.Id]: string;
    [ControlKeys.State]: WorkflowState;
    [ControlKeys.Label]: string;
    [ControlKeys.EventType]: WorkflowEventType;
    [ControlKeys.Timer]?: TimerData;
    [ControlKeys.Integration]?: IntegrationData;
    [ControlKeys.Feature]?: IntegrationFeature;
    [ControlKeys.Bucket]?: string;
    [ControlKeys.Transitions]?: SchemaTransition[];
    [ControlKeys.Condition]?: WorkflowCondition;
    [ControlKeys.Activities]: { id: string; label: string; type: ActivityType; activity?: WorkflowActivityInfo }[];
    [ControlKeys.TransitionOptions]?: SchemaTransitionDescription[];
}

@Injectable()
export class WorkflowRuleFormController implements OnDestroy {

    private subscriptions: Subscription = new Subscription();

    constructor(
        private ufb: UfFormBuilder,
        private ucClient: UcClient,
        private ucWorkflow: UcWorkflow,
        private ucIntegrations: UcIntegrations,
        private ucFormBucketClient: UcFormBucketClient,
    ) { }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    buildRoot(data?: WorkflowRuleFormModel | null): UfControlGroup {

        const eventType = this.ufb.control({ value: data?.eventType, disabled: !!data?.id }, ValidatorFunctions.required('This field is mandatory'));
        const timer = this.ufb.control({ value: data?.timer, disabled: data?.eventType !== WorkflowEventType.Timer }, ValidatorFunctions.required('This field is mandatory'));
        const bucket = this.ufb.control({ value: (data?.condition as FormSubmittedCondition)?.bucket, disabled: data?.eventType !== WorkflowEventType.FormSubmitted || !!data?.id }, ValidatorFunctions.required('This field is mandatory'));
        const forms = this.ufb.control({ value: (data?.condition as FormSubmittedCondition)?.forms, disabled: data?.eventType !== WorkflowEventType.FormSubmitted });
        const transitions = this.ufb.control({ value: (data?.condition as FormSubmittedCondition)?.transitions, disabled: data?.eventType !== WorkflowEventType.FormSubmitted }, ValidatorFunctions.required('This field is mandatory'));
        const integration = this.ufb.control({ value: data?.integration, disabled: data?.eventType !== WorkflowEventType.ApiEvent }, ValidatorFunctions.required('This field is mandatory'));
        const feature = this.ufb.control({ value: data?.feature, disabled: data?.eventType !== WorkflowEventType.ApiEvent }, ValidatorFunctions.required('This field is mandatory'));
        const roles = this.ufb.control({ value: (data?.condition as RoleAddedCondition)?.roles, disabled: data?.eventType !== WorkflowEventType.RoleAdded });
        const includeExternal = this.ufb.control({ value: (data?.condition as RoleAddedCondition)?.includeExternal, disabled: data?.eventType !== WorkflowEventType.RoleAdded });
        const includeNewUser = this.ufb.control({ value: (data?.condition as RoleAddedCondition)?.includeNewUser, disabled: data?.eventType !== WorkflowEventType.RoleAdded });

        const condition = this.ufb.group({
            [ControlKeys.Expression]: data?.condition?.expression,
            [ControlKeys.Forms]: forms,
            [ControlKeys.Bucket]: bucket,
            [ControlKeys.Transitions]: transitions,
            [ControlKeys.Roles]: roles,
            [ControlKeys.IncludeExternal]: includeExternal,
            [ControlKeys.IncludeNewUser]: includeNewUser,
        });

        const root = this.ufb.group({
            [ControlKeys.Id]: data?.id,
            [ControlKeys.State]: data?.state,
            [ControlKeys.Label]: [data?.label, ValidatorFunctions.required('This field is mandatory')],
            [ControlKeys.EventType]: eventType,
            [ControlKeys.Timer]: timer,
            [ControlKeys.Integration]: integration,
            [ControlKeys.Feature]: feature,
            [ControlKeys.Condition]: condition,
            [ControlKeys.TransitionOptions]: [data?.transitionOptions ?? []],
            [ControlKeys.Activities]: this.ufb.array(
                (data?.activities ?? []).map((a) => this.buildActivityControl(a)),
                ValidatorFunctions.custom((v) => (v.length > 0), 'At least one activity is required'),
            ),
        });

        this.subscriptions.add(integration.valueChanges.subscribe(() => feature.enable()));

        return root;
    }

    buildActivityControl(activity?: WorkflowRuleActivity): UfControlGroup {
        const activityControl = this.ufb.control(activity?.label, ValidatorFunctions.required('This field is mandatory'));
        const activityLabelControl = this.ufb.control(activity?.label);

        this.subscriptions.add(activityControl.valueChanges.subscribe((activityInfo: WorkflowActivityInfo) => {
            activityLabelControl.setValue(activityInfo?.label);
        }));

        return this.ufb.group({
            [ControlKeys.Id]: activity?.id,
            [ControlKeys.Label]: activityLabelControl,
            [ControlKeys.Activity]: activityControl,
            [ControlKeys.Type]: activity?.type,
        });

    }

    async toFormModel(workflowRule: WorkflowRule): Promise<WorkflowRuleFormModel> {
        const formValue: WorkflowRuleFormModel = {
            id: workflowRule.id,
            state: workflowRule.state,
            label: workflowRule.label,
            eventType: workflowRule.event?.type,
            condition: workflowRule.condition,
            activities: (workflowRule.activities ?? []).sort(sortByActivityType),
        };

        let event;

        switch (workflowRule.event.type) {
            case WorkflowEventType.Timer:
                {
                    const timerId = (workflowRule.event as TimerEvent).timerId as string;

                    event = await this.ucWorkflow.getActivity<TimerData>(timerId);
                    formValue.timer = event as TimerData;
                    break;
                }

            case WorkflowEventType.ApiEvent:
                {
                    const integrationId = (workflowRule.event as APIEvent).integrationId as string;
                    const featureId = (workflowRule.event as APIEvent).featureId as string;

                    event = await this.ucIntegrations.get(integrationId);
                    formValue.integration = event as IntegrationData;
                    const { features } = await this.ucClient.getAvailableIntegration(event.provider.id);

                    formValue.feature = features.find((f) => f.id === featureId);
                    break;
                }

            case WorkflowEventType.FormSubmitted:
                {
                    const { bucket, transitions } = (formValue.condition as FormSubmittedCondition);

                    if (!bucket) {
                        break;
                    }

                    const { transitions: schemaTransitions } = await this.ucFormBucketClient.get(bucket as string);

                    formValue.transitionOptions = schemaTransitions.map((transition) => this.mapSchemaTransitionDescription(transition));
                    if (transitions) {
                        for (let index = 0; index < transitions.length; index++) {
                            const transitionAtIndex = transitions[index];

                            if (transitionAtIndex) {
                                const transitionOption = formValue.transitionOptions.find((to) => to.action === transitionAtIndex.action && to.source === transitionAtIndex.source);

                                if (transitionOption) {
                                    transitions[index] = transitionOption;
                                }
                            }
                        }
                    }
                }
        }

        return formValue;
    }

    toDataModel(value: WorkflowRuleFormModel): WorkflowRule | NewWorkflowRule | null {
        const event = this.getWorkflowEvent(value);
        const workflowRule: NewWorkflowRule = {
            event,
            label: value.label,
            activities: value[ControlKeys.Activities].map((a) => ({ id: a.id, label: a.label, type: a.type })),
        };

        const condition = this.reduceWorkflowCondition(value[ControlKeys.Condition]);

        if (condition != null) {
            workflowRule.condition = condition;
        }

        if (value.id == null) {
            return workflowRule;
        }

        return {
            id: value.id,
            state: value.state,
            ...workflowRule,
        };
    }

    resetEventForm({ timer, bucket, transitions, forms, type: v, integration, roles, feature, includeNewUser, includeExternal }: ChangeEventTypeData) {
        if (v === WorkflowEventType.Timer) {
            timer.enable();
            // Reset controls
            bucket.reset({ disabled: true, value: null });
            // TODO look at chips component as reset requires an empty array otherwise it will kill the stack
            forms.reset({ disabled: true, value: [] });
            transitions.reset({ disabled: true, value: [] });
            integration.reset({ disabled: true, value: null });
            feature.reset({ disabled: true, value: null });
            // role added
            roles.reset({ disabled: true, value: [] });
            includeExternal.reset({ disabled: true, value: null });
            includeNewUser.reset({ disabled: true, value: null });
        } else if (v === WorkflowEventType.ApiEvent) {
            integration?.enable();

            feature.reset({ disabled: true, value: null });
            timer.reset({ disabled: true, value: null });
            bucket.reset({ disabled: true, value: null });
            // TODO look at chips component as reset requires an empty array otherwise it will kill the stack
            forms.reset({ disabled: true, value: [] });
            transitions.reset({ disabled: true, value: [] });
            roles.reset({ disabled: true, value: [] });
            includeExternal.reset({ disabled: true, value: null });
            includeNewUser.reset({ disabled: true, value: null });
        } else if (v === WorkflowEventType.FormSubmitted) {
            bucket.enable();
            forms.enable();

            // TODO look at chips component as reset requires an empty array otherwise it will kill the stack
            transitions.reset({ disabled: true, value: [] });
            integration.reset({ disabled: true, value: null });
            feature.reset({ disabled: true, value: null });
            timer.reset({ disabled: true, value: null });
            roles.reset({ disabled: true, value: [] });
            includeExternal.reset({ disabled: true, value: null });
            includeNewUser.reset({ disabled: true, value: null });
        } else if (v === WorkflowEventType.RoleAdded) {
            roles.enable();
            includeExternal.reset({ disabled: false, value: false });
            includeNewUser.reset({ disabled: false, value: false });

            bucket.reset({ disabled: true, value: null });
            timer.reset({ disabled: true, value: null });
            forms.reset({ disabled: true, value: [] });
            transitions.reset({ disabled: true, value: [] });
            integration.reset({ disabled: true, value: null });
            feature.reset({ disabled: true, value: null });
        }
    }

    mapSchemaTransitionDescription({ source, action, target }: SchemaTransition): SchemaTransitionDescription {
        return {
            source, action, target,
            description: target ? `${source} > ${target} (${action})` : `${source} (${action})`,
        };
    }

    private getWorkflowEvent(formValue: WorkflowRuleFormModel): WorkflowEvent {
        const timer = formValue[ControlKeys.Timer]?.id;

        if (timer != null) {
            return {
                timerId: '' + timer,
                type: WorkflowEventType.Timer,
            };
        }

        const integrationId = formValue[ControlKeys.Integration]?.id;
        const featureId = formValue[ControlKeys.Feature]?.id;

        if (integrationId != null && featureId != null) {
            return {
                type: WorkflowEventType.ApiEvent,
                featureId, integrationId,
            };
        }

        return { type: formValue[ControlKeys.EventType] };
    }

    private reduceWorkflowCondition(inputCondition?: WorkflowCondition): WorkflowCondition | null {
        if (inputCondition == null) {
            return null;
        }

        if (inputCondition.expression) {
            inputCondition.expression = reduceExpressionToSingleLine(inputCondition.expression);
            inputCondition.expression = inputCondition.expression ? inputCondition.expression : undefined;
        }

        let condition: WorkflowCondition | null = null;

        for (const key of Object.keys(inputCondition)) {
            const value = inputCondition[key as keyof WorkflowCondition];

            if ((!Array.isArray(value) && !ValidatorFunctions.isEmpty(value)) || (Array.isArray(value) && hasLengthAtLeast(value, 1))) {
                if (condition == null) {
                    condition = {};
                }
                condition[key as keyof WorkflowCondition] = value;
            }
        }

        return condition;
    }

}
