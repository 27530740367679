<ng-template [ngIf]="isInput">
    <uf-radio [value]="value ?? entry?.emptyOption?.identifier" [options]="options" (valueChange)="valueChanged($event)"
        valueProperty="identifier" columns="1" />
</ng-template>

<div *ngIf="isDisplay && !isEmpty" class="uf-chip x-small">
    {{label}}:{{ displayValue }}
    <button (click)="remove()" type="button" class="uf-action">
        <uf-icon name="delete" />
    </button>
</div>