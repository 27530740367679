import { Component, HostBinding } from '@angular/core';
import { Modal, ModalRuntime, UfControl, ValidatorFunctions } from '@unifii/library/common';

@Component({
    selector: 'uc-system-smtp-test-modal',
    templateUrl: './system-smtp-test-modal.html',
})
export class SystemSmtpTestModalComponent implements Modal<null, string> {

    @HostBinding('class.uf-form-card') cardClass = true;

    email: UfControl;

    constructor(
        public runtime: ModalRuntime<null, string>,
    ) {
        this.email = new UfControl(ValidatorFunctions.compose([
            ValidatorFunctions.email('Invalid email'),
            ValidatorFunctions.required('System Email is required'),
        ]));
    }

    sendTest() {
        this.email.markAllAsTouched();

        if (!this.email.valid) {
            return;
        }

        this.runtime.close(this.email.value);
    }

}
