import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { TableContainerManager } from '@unifii/components';
import { Subscription } from 'rxjs';

import { UcApps } from 'client';

import { AppsTableManager } from './apps-table-manager';

@Component({
    selector: 'uc-apps-table',
    providers: [
        { provide: TableContainerManager, useClass: AppsTableManager },
    ],
    template: `
    <div ufMasterDetail>
        <uf-table-container (addItem)="addItem()" ufSyncRoute class="accent list-md pad-none" />
    </div>
    <router-outlet />
`,
})
export class AppsComponent implements OnInit, OnDestroy {

    @HostBinding('class.stretch-component') protected stretchComponentClass = true;

    private subscriptions: Subscription = new Subscription();
    private ucApps = inject(UcApps);
    private manager = inject<AppsTableManager>(TableContainerManager);

    ngOnInit() {
        this.subscriptions.add(this.ucApps.appDeleted.subscribe(() => this.manager.reload.next()));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    addItem() {
        this.manager.addItem();
    }

}
