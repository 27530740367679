<div class="uf-box col-12">
    <div class="uf-app-bar flat accent">
        <div class="title">
            Details
        </div>
    </div>
    <div class="pad uf-grid">
        <uf-select [disabled]="!isNew" [control]="integrationControl" [options]="integrations"
            (valueChange)="changeIntegration($event)" label="Integration" placeholder="Select an integration" class="col-12" />
        <ng-container *ngIf="features?.length">
            <div *ngIf="integration?.provider?.logo as logo" class="col-2">
                <div class="uc-image--fixed-ratio">
                    <img [src]="logo | bypassSanitizeUrl">
                </div>
            </div>
            <uf-select [disabled]="!isNew" [control]="featureControl" [options]="features"
                (valueChange)="featureChanged.emit($event)" label="Feature" placeholder="Select a feature" class="col-12" />
        </ng-container>
    </div>
</div>