import { Injectable } from '@angular/core';
import { TableContainerManager } from '@unifii/components';
import { FilterEntry, FilterValue, TableConfig } from '@unifii/library/common';
import { Subject } from 'rxjs';

import { AuthProviderDetails, UcAuthProviders } from 'client';

import { getAuthProviderImagePath, getAuthProviderLabel, getAuthProviderUrlSegment } from './auth-provider-functions';
import { AuthProvidersDatasource } from './auth-providers-datasource';

@Injectable()
export class AuthProvidersTableManager implements TableContainerManager<AuthProviderDetails, FilterValue, FilterEntry> {

    tableConfig: TableConfig<AuthProviderDetails>;
    addActionConfig = true;
    reload = new Subject<void>();

    constructor(private ucAuthProviders: UcAuthProviders) {
        this.tableConfig = {
            columns: [{
                name: 'providerLoginLabel',
                label: 'Provider',
                value: (item) => item.providerLoginLabel ?? item.type,
            }, {
                name: 'tenant',
                label: 'Tenant',
            }, {
                name: 'type',
                label: 'Type',
                value: (item) => item.manual ? 'Manual' : 'Automatic',
            }, {
                name: 'status',
                label: 'Status',
                value: (item) => item.isActive ? 'Active' : 'Inactive',
            }],
            row: {
                image: (item) => getAuthProviderImagePath(item.type),
                label: (item) => getAuthProviderLabel(item.type),
                link: (item) => [getAuthProviderUrlSegment(item.type), item.id],
            },
            pageSize: -1,
        };
    }

    createDataSource() {
        return new AuthProvidersDatasource(this.ucAuthProviders);
    }

}
