import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, ToastService, UfControl, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { Option, Schema, UfError } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { DataForwarder, IntegrationFeature, IntegrationInfo, IntegrationProviderFeatureType, SchemaInfo, UcFormBucketClient, UcIntegrations, UcWorkflow, WorkflowEventType } from 'client';
import { EditData, SaveOption, SaveOptionType, useDefaultErrorMessage } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { MappableField } from 'models';
import { reloadCurrentRoute } from 'pages/utils';
import { BreadcrumbService } from 'services/breadcrumb.service';

import { WorkflowSourceTypeLabel } from './constants';
import { FieldMappingService } from './field-mapping.service';
import { WorkflowActivityTableManager } from './workflow-activity-table-manager';
import { ControlKeys, WorkflowDataForwardersFormController } from './workflow-data-forwarders-form.controller';
import { WorkflowDataForwardersModel } from './workflow-types';
import { buildHeaderConfig } from './workflow-utils';

@Component({
    templateUrl: 'workflow-data-forwarders-form.html',
})
export class WorkflowDataForwardersFormComponent implements EditData, OnInit, OnDestroy {

    protected readonly controlKeys = ControlKeys;
    protected readonly sourceTypes: Option[] = [
        { name: WorkflowSourceTypeLabel[WorkflowEventType.FormSubmitted], identifier: WorkflowEventType.FormSubmitted },
        // TODO - Add Support for User & Integration Source
        // { name:  WorkflowSourceTypeLabel[WorkflowEventType.ApiEvent], identifier: WorkflowEventType.ApiEvent },
        // { name:  WorkflowSourceTypeLabel[WorkflowEventType.RoleAdded], identifier: WorkflowEventType.RoleAdded },
    ];
    protected readonly dataMappingOptions = [
        { name: 'Forward all Data', value: true },
        { name: 'Use Data Mapping', value: false },
    ];

    protected error?: UfError;
    protected form: UfControlGroup;
    protected buckets: SchemaInfo[];
    protected integrations: IntegrationInfo[];
    protected breadcrumbs: Breadcrumb[];
    protected filteredFeatures: IntegrationFeature[];
    protected targetFields?: MappableField[];
    protected sourceFields?: MappableField[];

    private schema?: Schema;
    private features: IntegrationFeature[];
    private subscriptions = new Subscription();
    private hasSaveAndNextButton: boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private ucFormBucketClient: UcFormBucketClient,
        private ucWorkflow: UcWorkflow,
        private toastService: ToastService,
        private ucIntegrations: UcIntegrations,
        private formController: WorkflowDataForwardersFormController,
        private fieldMappingService: FieldMappingService,
        private breadcrumbService: BreadcrumbService,
        private builderHeaderService: BuilderHeaderService,
        @Inject(TableContainerManager) private tableManager: WorkflowActivityTableManager,
    ) { }

    get edited() {
        return !!this.builderHeaderService.config?.edited;
    }

    set edited(v: boolean) {
        this.builderHeaderService.config.edited = v;
    }

    async ngOnInit() {
        this.builderHeaderService.init();

        const { id, duplicate } = this.route.snapshot.params;

        this.hasSaveAndNextButton = id !== 'new' && !duplicate;

        try {
            const dataForwarder = await this.getDataForwarder(id);
            const model = await this.formController.toModel(dataForwarder);

            if (model != null) {
                if (duplicate) {
                    model.id = null as any as string;
                    model.label += ' (copy)';
                }

                if (model.bucket?.id) {
                    this.schema = await this.ucFormBucketClient.get(model.bucket.id);
                    await this.loadSourceFields();
                }

                this.targetFields = this.fieldMappingService.getIntegrationFeatureMappableFields(model.feature);
            }

            this.form = this.formController.buildRoot(model);
            this.subscriptions.add(this.form.valueChanges.subscribe(() => { this.edited = true; }));
            // Set breadcrumbs
            this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => this.save(saveOption)));
            this.buildHeaderConfig(dataForwarder);
        } catch (e) {
            this.error = useDefaultErrorMessage(e);

            return;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    protected async searchBuckets(q: string) {
        try {
            this.buckets = await this.ucFormBucketClient.list({ params: { q } });
        } catch (e) {
            this.toastService.error(`Bucket search failed`);
        }
    }

    protected async searchIntegration(q: string) {
        try {
            this.integrations = await this.ucIntegrations.list({ params: { q } });
        } catch (e) {
            this.toastService.error(`Integrations search failed`);
        }
    }

    protected async save(saveOption?: SaveOption) {
        this.form.setSubmitted();

        if (!this.form.valid) {
            return;
        }

        try {
            const model = this.form.getRawValue() as WorkflowDataForwardersModel;
            const dataForwarder = this.formController.toData(model);

            let updatedDataForwarder;

            if (!dataForwarder.id) {
                updatedDataForwarder = await this.ucWorkflow.addActivity<DataForwarder>(dataForwarder);
                this.toastService.success('Data Forwarder saved successfully');
                this.tableManager.reload.next();
            } else {
                updatedDataForwarder = await this.ucWorkflow.updateActivity<DataForwarder>(dataForwarder);
                this.toastService.success('Data Forwarder updated successfully');
                this.tableManager.updateItem.next(updatedDataForwarder);
            }

            this.edited = false;

            if (!saveOption) {
                if (!dataForwarder.id) {
                    this.router.navigate(['..', updatedDataForwarder.id], { relativeTo: this.route });
                } else {
                    this.builderHeaderService.updateConfig({ 
                        breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [updatedDataForwarder.label]),
                        lastModifiedAt: updatedDataForwarder.lastModifiedAt,
                        lastModifiedBy: updatedDataForwarder.lastModifiedBy,
                    });
                }

                return;
            }

            switch (saveOption.id) {
                case SaveOptionType.New:
                    if (this.router.url.endsWith('/new')) {
                        reloadCurrentRoute(this.router);

                        return;
                    } else {
                        this.router.navigate(['../', 'new'], { relativeTo: this.route });

                        return;
                    }
                case SaveOptionType.Next:
                    {
                        const nextId = this.tableManager.getNextItem(updatedDataForwarder.id)?.id;

                        if (nextId) {
                            this.router.navigate(['..', nextId], { relativeTo: this.route });

                            return;
                        }
                        break;
                    }

            }

            this.router.navigate(['..'], { relativeTo: this.route });
        } catch (e) {
            this.toastService.error('Unable to save. There are errors in your Data Forwarder');
        }
    }

    protected async searchFeature(query: string) {
        if (!this.features) {
            const integration = this.integrationControl?.value as IntegrationInfo | undefined;

            await this.loadFeaturesByIntegration(integration);
        }
        this.filteredFeatures = (this.features ?? []).filter((v) => !query || v.name.toLowerCase().includes(query.toLowerCase()));
    }

    protected async bucketChange(bucket?: SchemaInfo) {

        if (bucket?.id === this.schema?.bucket) {
            this.schema = undefined;

            return;
        }

        try {
            this.schema = bucket ? await this.ucFormBucketClient.get(bucket.id) : undefined;
        } catch (e) {
            this.toastService.error(`Unable to load Bucket by id ${bucket?.id}`);
            this.form.get(ControlKeys.Bucket)?.reset();
            this.schema = undefined;
        }

        if (this.featureControl?.value) {
            this.featureChange(this.featureControl.value);
        }
        await this.loadSourceFields();
    }

    protected async integrationChange(integration?: IntegrationInfo) {

        await this.loadFeaturesByIntegration(integration);
        this.form.get(ControlKeys.Feature)?.reset();
    }

    protected featureChange(feature?: IntegrationFeature) {

        this.form.removeControl(ControlKeys.InputMap);
        this.form.get(ControlKeys.ForwardAllData)?.setValue(true);

        if (this.schema && feature) {
            this.targetFields = this.fieldMappingService.getIntegrationFeatureMappableFields(feature);

            return;
        }

        this.targetFields = undefined;
    }

    protected get inputMapControl() {
        return this.form.get(ControlKeys.InputMap) as UfControlArray | null ?? undefined;
    }

    private get featureControl(): UfControl {
        return this.form.get(ControlKeys.Feature) as UfControl;
    }

    private get integrationControl(): UfControl {
        return this.form.get(ControlKeys.Integration) as UfControl;
    }

    private async loadSourceFields() {
        if (!this.schema) {
            this.sourceFields = [];

            return;
        }

        try {
            this.sourceFields = await this.loadBucketSourceFields(this.schema.bucket);
        } catch (e) {
            this.toastService.error(`Unable to get source bucket: ${this.schema.bucket}`);
            this.sourceFields = undefined;
        }

    }

    private getDataForwarder(id: string) {
        if (id === 'new') {
            return {
                label: 'New',
            } as any as DataForwarder;
        }

        return this.ucWorkflow.getActivity<DataForwarder>(id);
    }

    private async loadFeaturesByIntegration(integrationInfo?: IntegrationInfo) {

        if (!integrationInfo?.id) {
            return;
        }

        try {
            const integration = await this.ucIntegrations.get(integrationInfo.id);

            this.features = (integration.provider.features ?? []).filter((f) => f.type === IntegrationProviderFeatureType.FeatureTypeSink);
        } catch (e) {
            this.toastService.error('Unable to get Integration');
            this.features = [];
        }

    }

    private loadBucketSourceFields(bucketId?: string): Promise<MappableField[]> {
        if (!bucketId) {
            return Promise.resolve([]);
        }

        return this.fieldMappingService.getSchemaMappableFields(bucketId);
    }

    private buildHeaderConfig(dataForwarder: DataForwarder) {
        const headerConfig = buildHeaderConfig(dataForwarder, this.hasSaveAndNextButton);

        headerConfig.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [headerConfig.title]);
        this.builderHeaderService.buildConfig(headerConfig);
    }

}
