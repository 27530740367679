import { Injectable, inject } from '@angular/core';
import { Compound, ContentType, Definition, Dictionary, ImageProfile, NodeType, Page, PublishedContent, Query, Schema, Table, hasLengthAtLeast } from '@unifii/sdk';

import { StructureService, UcFormBucketClient, UcProject } from 'client';

@Injectable()
export class PreviewContentService implements PublishedContent {

    private ucProject = inject(UcProject);
    private ucFormBucketClient = inject(UcFormBucketClient);
    private structureService = inject(StructureService);

    getView(identifier: string): Promise<Compound> {
        return this.ucProject.getView(+identifier);
    }

    getViewDefinition(identifier: string): Promise<Definition> {
        return this.ucProject.getViewDefinition(identifier) as Promise<Definition>;
    }

    getPage(identifier: string): Promise<Page> {
        return this.ucProject.getPage(+identifier);
    }

    getCollectionDefinition(identifier: string): Promise<Definition> {
        return this.ucProject.collection(identifier).getDefinition() as Promise<Definition>;
    }

    getCollections(): Promise<Definition[]> {
        throw new Error('not implemented');
    }

    queryCollection(identifier: string, query?: Query): Promise<Compound[]> {
        const ids = this.getIds(query);

        return this.ucProject.collection(identifier).get({ limit: 200, full: true, ids: ids?.join(',') });
    }

    getCollectionItem(identifier: string, id: string): Promise<Compound> {
        return this.ucProject.collection(identifier).getItem(+id);
    }

    getBucket(identifier: string): Promise<Schema> {
        return this.ucFormBucketClient.get(identifier);
    }

    queryForms(): Promise<Definition[]> {
        throw new Error('not implemented');
    }

    getForm(identifier: string): Promise<Definition> {
        return this.ucProject.getForm(identifier) as Promise<Definition>;
    }

    queryTables(): Promise<Table[]> {
        throw new Error('not implemented');
    }

    queryPages(): Promise<Page[]> {
        throw new Error('not implemented.');
    }

    getTable(id: string): Promise<Table> {
        return this.ucProject.getTable(id);
    }

    getStructure() {
        return this.structureService.get();
    }

    getIdentifiers(): Promise<Dictionary<{ type: ContentType }>> {
        throw new Error('not implemented');
    }

    getAssetUrl(): Promise<string> {
        throw new Error('Method not implemented.');
    }

    // TODO remove and instead use SDK buildImageUrl functions
    buildImageUrl(imageProfile: ImageProfile, options?: any): string {

        const url = new URL(imageProfile.url as string);

        // set crop
        if (imageProfile.crop != null) {
            url.searchParams.set('crop', `(${imageProfile.crop.x}, ${imageProfile.crop.y}, ${imageProfile.crop.x + imageProfile.crop.width}, ${imageProfile.crop.y + imageProfile.crop.height})`);
        }

        if (options) {
            if (options.width) {
                url.searchParams.set('w', options.width.toString());
            }
        }

        return url.toString();
    }

    private getIds(query: Query | undefined): string[] | undefined {

        if (query && query.args.length === 1 && hasLengthAtLeast(query.args, 1)) {
            const n = query.args[0];

            if (n.op === 'in' && n.args[0]?.type === NodeType.Identifier && n.args[0].value === 'id') {
                return n.args[1]?.value as string[] | undefined;
            }
        }

        return;
    }

}

