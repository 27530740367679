import { Component, HostBinding, OnDestroy } from '@angular/core';
import { UfControlArray, UfControlGroup, UfFormBuilder } from '@unifii/library/common';
import { Subscription } from 'rxjs';

interface Item {
    title: string;
    children?: Item[];
}

@Component({
    selector: 'uc-lab-drag-list',
    templateUrl: './lab-drag-list.html',
})
export class LabDragListComponent implements OnDestroy {

    @HostBinding('class.stretch-component') class = true;

    flatItems: Item[];
    nestedItems: Item[];

    flatControl: UfControlArray;
    flatEdited: boolean;

    nestedControl: UfControlArray;
    nestedEdited: boolean;

    private subscriptions = new Subscription();

    constructor(private fb: UfFormBuilder) {

        this.flatItems = [{ title: 'A' }, { title: 'B' }, { title: 'C' }];

        this.nestedItems = [
            { title: 'A' },
            { title: 'B', children: [{ title: 'B.1' }, { title: 'B.2' }] },
            { title: 'C' },
        ];

        this.flatControl = this.fb.array(this.flatItems.map((i) => this.buildItemControl(i)));
        this.subscriptions.add(this.flatControl.valueChanges.subscribe((_) => this.flatEdited = true));

        this.nestedControl = this.fb.array(this.nestedItems.map((i) => this.buildItemControl(i)));
        this.subscriptions.add(this.nestedControl.valueChanges.subscribe((_) => this.nestedEdited = true));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    buildItemControl(item: Item): UfControlGroup {
        return this.fb.group({
            title: item.title + ' - control',
            children: this.fb.array((item.children?.map((c) => this.buildItemControl(c)) || [])),
        });
    }

}
