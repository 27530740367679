import { Component, HostBinding, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { FilterComponentRegistry, FilterEntries } from '@unifii/library/common';

import { UcProject } from 'client';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { InfoFilterFactory } from 'services/table/info-filter-factory';
import { InfoFilterRegistry } from 'services/table/info-filter-registry';
import { TableInfoLoader } from 'services/table/info-loaders';
import { InfoTableManager } from 'services/table/info-table-manager';
import { InfoLoader, InfoType } from 'services/table/models';

const createLoader = (ucProject: UcProject) => new TableInfoLoader(ucProject);

const createFilters = (filterFactory: InfoFilterFactory) => filterFactory.create(InfoType.Table);

@Component({
    providers: [
        { provide: FilterComponentRegistry, useClass: InfoFilterRegistry },
        { provide: FilterEntries, useFactory: createFilters, deps: [InfoFilterFactory] },
        { provide: InfoLoader, useFactory: createLoader, deps: [UcProject] },
        { provide: TableContainerManager, useClass: InfoTableManager },
    ],
    template: `
        <div ufMasterDetail>
            <uf-table-container (addItem)="addItem()" ufSyncRoute class="accent list-md">
                <uf-breadcrumbs [breadcrumbs]="breadcrumbs" title class="large" />
            </uf-table-container>
        </div>
        <router-outlet />
    `,
})
export class TablesComponent {

    @HostBinding('class.stretch-component') class = true;

    breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);

    constructor(
        @Inject(TableContainerManager) private manager: InfoTableManager,
        private breadcrumbService: BreadcrumbService,
        private route: ActivatedRoute,
    ) { }

    addItem() {
        this.manager.addActionCallback();
    }

}
