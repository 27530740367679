import { Inject, Injectable } from '@angular/core';
import { FileUploader } from '@unifii/library/common';
import { Client, DOMExceptionAbortErrorName, Progress, TenantClient, generateUUID } from '@unifii/sdk';

import { UcMedia } from 'client';

import { Config } from '../app-config';

@Injectable()
export class FileUploaderService implements FileUploader {

    constructor(
        @Inject(Config) private config: Config,
        private client: Client,
        private tenantClient: TenantClient,
        private ucMedia: UcMedia,
    ) { }

    upload(file: File, progressCallback?: (progress: Progress) => void, signal?: AbortSignal): Promise<Progress> {

        if (signal && signal.aborted) {
            return Promise.reject(new DOMException('Upload aborted', DOMExceptionAbortErrorName));
        }

        const id = generateUUID();
        const size = file.size;

        return new Promise<Progress>(async(resolve) => {

            const start = performance.now();

            if (progressCallback) {
                progressCallback({ done: Math.trunc(file.size * 0.2), total: size });
            }
            console.log(`Attachment saved in ${performance.now() - start}ms`);

            if (!navigator.onLine) {
                resolve({ total: size, done: size, id });

                return;
            }

            const uploadProgress = await this.client.upload(file, this.config.baseUrl + '/api/assets', { id, onProgress: progressCallback, signal });

            resolve({
                id: uploadProgress.id,
                done: size,
                total: size,
            });
        });
    }

    async getUrl(id: string): Promise<string> {
        const asset = await this.tenantClient.getAsset(id);

        return asset.url;
    }

    async remove(id: string): Promise<void> {
        await this.ucMedia.delete(+id);
    }

}
