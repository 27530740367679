export enum DataSourceControlKeys {
    Integration = 'integration',
    Feature = 'feature',
    Name = 'name',
    Description = 'description',
    InputArgs = 'inputArgs',
    OutputArgs = 'outputArgs',
    DataSeedOutputArgs = 'dataSeedOutputArgs',
}

export enum DataSourceArgControlKeys {
    Source = 'source',
    Key = 'key',
    Value = 'value',
    Type = 'type',
    IsRequired = 'isRequired'
}

export enum DataSeedIdentifiers {
    Id = 'idArg',
    Display = 'displayArg'
}
