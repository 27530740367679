import { Component, HostBinding, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@unifii/library/common';
import { UfError, isUfError } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { Integration } from 'client';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { BreadcrumbService } from 'services/breadcrumb.service';

import { SystemIntegrationsComponent } from '../system-integrations.component';

@Component({
    templateUrl: './integration.html',
    selector: 'uc-integration',
    styleUrls: ['./integration.less'],
})
export class IntegrationComponent {

    @HostBinding('class.stretch-component') protected stretchComponentClass = true;

    // it needs to be public - as it's used by children components
    integration: Integration;

    protected breadcrumbs: Breadcrumb[];
    protected error?: UfError;
    protected showFeaturesTab: boolean;
    
    private route = inject(ActivatedRoute);
    private breadcrumbService = inject(BreadcrumbService);
    private builderHeaderService = inject(BuilderHeaderService);
    private parent = inject(SystemIntegrationsComponent);

    private subscriptions = new Subscription();

    ngOnInit() {
        this.subscriptions.add(this.route.data.subscribe((data) => {
            
            if (isUfError(data.integration)) {
                this.error = data.integration;
    
                return;
            }

            this.integration = data.integration;
            this.showFeaturesTab = !!this.integration?.id;
        }));

        if (this.error) { return; }
        this.buildHeaderConfig();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();

        this.parent.updateHeaderConfig();
    }

    buildHeaderConfig() {
        this.builderHeaderService.buildConfig({
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route),
            hideSaveButton: true,
            cancelRoute: ['../'],
        });
    }

}
