import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DataDescriptor, DataDescriptorService } from '@unifii/library/common';
import { UfError } from '@unifii/sdk';

import { SchemaSetting, UcFormBucketClient, UcSchema } from 'client';
import { useDefaultErrorMessage } from 'components';
import { ContextService } from 'services/context.service';

export interface BucketConfig {
    schema: UcSchema; 
    publishedSchema?: UcSchema;
    schemaSetting: SchemaSetting;
    dataDescriptor?: DataDescriptor;
}

export const bucketConfigResolver = async(route: ActivatedRouteSnapshot): Promise<BucketConfig | UfError> => {
    const dataDescriptorService = inject(DataDescriptorService);
    const ucFormBucketClient = inject(UcFormBucketClient);
    const context = inject(ContextService);

    context.bucketId = route.params.id as string;

    try {
        return {
            schemaSetting: await ucFormBucketClient.getSettings(context.bucketId),
            schema: await ucFormBucketClient.get(context.bucketId),
            publishedSchema: await ucFormBucketClient.get(context.bucketId, { params: { published: true } }),
            dataDescriptor: await dataDescriptorService.getBucketDataDescriptor(context.bucketId),
        };
    } catch (e) {
        return useDefaultErrorMessage(e);
    }
};
