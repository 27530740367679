import { Directive, ElementRef, HostBinding, HostListener, Input, Optional } from '@angular/core';

import { DragListComponent, DropPosition } from './drag-list.component';
import { DragService } from './drag.service';

@Directive({
    selector: '[dragItem]',
})
export class DragItemDirective {

    @HostBinding('class.hover') hover: boolean | null | undefined;
    @HostBinding('class.disabled') @Input() dragDisabled: boolean | null | undefined;
    @Input() nestable: boolean;
    @Input() dragItem: any;

    hasHandle: boolean;
    handleUsed: boolean;
    index: number;

    private el: HTMLElement;

    constructor(
        elementRef: ElementRef,
        private service: DragService,
        @Optional() private parent?: DragListComponent,
    ) {
        this.el = elementRef.nativeElement as HTMLElement;
    }

    @HostBinding('draggable') get isDraggable() {
        return !this.dragDisabled;
    }

    @HostListener('dragstart', ['$event'])
    dragStart(event: DragEvent) {
        (event.dataTransfer as DataTransfer).setData('text/plain', ''); // needed for Firefox to work with draggable items
        event.stopPropagation();

        if (this.dragDisabled) {
            return;
        }

        if (this.hasHandle && !this.handleUsed) {
            // console.warn('Canceling drag, use handle!');
            event.preventDefault();

            return;
        }

        this.handleUsed = false;
        this.service.data = this.dragItem;

        const { target } = event;

        if (target instanceof HTMLElement) {
            target.style.opacity = '.2';
        }

        if (this.parent) {
            this.service.dragIndex = this.getIndex();
            this.service.sourceList = this.parent;
        } else {
            delete (this.service as any).dragIndex;
            delete (this.service as any).sourceList;
        }
    }

    @HostListener('dragend', ['$event'])
    dragEnd(event: DragEvent) {
        event.stopPropagation();

        const { target } = event;

        if (target instanceof HTMLElement) {
            target.style.opacity = 'unset';
        }
    }

    @HostListener('dragenter', ['$event'])
    dragEnter() {
        this.index = this.getIndex();
    }

    @HostListener('dragleave', ['$event'])
    dragLeave() {
        this.hover = false;
    }

    @HostListener('drop', ['$event'])
    drop() {
        this.hover = false;
    }

    @HostListener('dragover', ['$event'])
    dragOver(event: DragEvent) {

        if (!this.parent) {
            return;
        }

        // TODO canDrop
        const rect = this.el.getBoundingClientRect();

        const threshold = this.nestable ? Math.min(30, (rect.height / 4)) : rect.height / 2;
        const dropPosition = this.getDropPosition(threshold, event.clientY - rect.top);

        const coords = {
            x: this.el.offsetLeft,
            y: this.calculateTop(dropPosition),
        };

        const success = this.parent.setPosition(this.index, dropPosition, coords.x, coords.y, rect.width);

        this.hover = success && dropPosition === DropPosition.Inside;
    }

    private getIndex() {
        if (this.dragItem && this.parent) {
            return this.parent.findIndex(this.dragItem);
        }

        // we fall back to getting index using DOM position
        // this assumes no other elements on parent
        return Array.prototype.indexOf.call((this.el.parentElement as HTMLElement).children, this.el);
    }

    private getDropPosition(threshold: number, yPos: number) {
        const itemHeight = this.el.offsetHeight;
        let dropPosition = DropPosition.Inside;

        if (yPos <= threshold) {
            dropPosition = DropPosition.Before;
        } else if (yPos > itemHeight - threshold) {
            dropPosition = DropPosition.After;
        }

        return dropPosition;
    }

    private calculateMiddle(a: HTMLElement, b: HTMLElement): number {
        const bottom = a.offsetTop + a.offsetHeight;

        return bottom + (b.offsetTop - bottom) / 2;
    }

    // search for siblings and move the indidcater between the items
    private calculateTop(dropPosition: DropPosition): number {
        let nextEl = this.el.nextElementSibling;

        if (nextEl && nextEl.classList.contains('drop-indicator')) {
            nextEl = null;
        }

        const previousEl = this.el.previousElementSibling as HTMLElement;

        if (dropPosition === DropPosition.After) {
            if (nextEl) {
                return this.calculateMiddle(this.el, nextEl as HTMLElement);
            }

            return this.el.offsetTop + this.el.offsetHeight;
        }

        if (dropPosition === DropPosition.Before && previousEl) {
            return this.calculateMiddle(previousEl, this.el);
        }

        return this.el.offsetTop;
    }

}
