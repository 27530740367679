<div *ngIf="ready && notifyEnabled" class="grid padded--vert">
    <uf-checkbox *ngIf="field.type === fieldType.Address" [(value)]="autocompleteEnabled"
        (valueChange)="autocompleteEnabledChange($event)" label="Enable search" class="col-1of1--no-margin" />
    <div *ngFor="let name of $any(fields)[field.type]" class="col-1of1--no-margin">
        <ng-container *ngIf="name !== 'lat' && name !== 'lng'">

            <div class="fieldset-item--x-small">
                <span>{{ getLabel(name) | toDisplayName }}</span>
            </div>
            <div class="row space-children">
                <uf-checkbox [(value)]="values.visible[name]" (valueChange)="updateValues(name)" label="Visible" />
                <uf-checkbox [(value)]="values.readOnly[name]" (valueChange)="updateValues(name)" label="Read Only" />
                <uf-checkbox [(value)]="values.required[name]" (valueChange)="updateValues(name)" label="Required" />
            </div>
            <uf-error *ngIf="form.controls[name] let namedControl" [control]="namedControl | asUfControl" />
        </ng-container>
    </div>
</div>