import { Component, HostBinding, Inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';

import { Media } from 'client';

@Component({
    selector: 'uc-image-preview',
    templateUrl: './image-preview.html',
})
export class ImagePreviewComponent implements Modal<Media, void> {

    @HostBinding('class.uc-form-card') class = true;

    constructor(
        public runtime: ModalRuntime<Media, void>,
        @Inject(ModalData) public data: Media,
    ) { }

    close() {
        this.runtime.close();
    }

}
