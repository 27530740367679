import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ToastService, UfControlArray, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { Subscription } from 'rxjs';

// CC.A - Defined ControlKeys enums representing the ControlData attributes and ControlTree controls
enum AddressControlKeys { Street = 'street', Suburb = 'suburb' }
enum UserControlKeys { Name = 'name', Surname = 'surname', Addresses = 'addresses' }

// ModelData and ControlData interfaces in this example match
interface Address { street: string; suburb: string }
interface User { name: string; surname?: string; addresses?: Address[] }

@Component({
    templateUrl: './reactive-forms.html',
})
export class ReactiveFormsComponent implements OnInit, OnDestroy {

    @HostBinding('class.stretch-component') class = true;

    // CC.A - Expose enums for template access
    readonly userControlKeys = UserControlKeys;
    readonly addressControlKeys = AddressControlKeys;

    // The ControlTree
    form: UfControlGroup;

    valueChangesSub: Subscription | null;
    edited: boolean;

    constructor(
        private ufb: UfFormBuilder, // UfFormBuilder used to build the ControlTree
        private toast: ToastService,
    ) { }

    // CC.B - Access data via control value
    get user(): User {
        return this.form.value;
    }

    // CC.D - getter provide easy typed reference to a control within the ControlTree
    get addresses(): UfControlArray {
        // CC.C - Avoid 'literals' when navigating the ControlTree
        return this.form.get(UserControlKeys.Addresses) as UfControlArray;
    }

    ngOnInit() {
        const userData: User = { name: 'John' }; // LC.1&2

        // LC.3 - Use UfFormBuilder to create the ControlTree with values and validators
        this.form = this.ufb.group({
            name: [userData.name, ValidatorFunctions.required('Name is required')],
            surname: userData.surname ?? null, // undefined is not a valid control value
            addresses: this.ufb.array(
                (userData.addresses ?? []).map(this.getAddressControl),
                ValidatorFunctions.custom((v) => (v as Address[]).length > 0, 'At least one address'),
            ),
        });

        // LC.6 - Subscribe to the ControlTree events
        this.valueChangesSub = this.form.valueChanges.subscribe(() => { this.edited = true; });
    }

    ngOnDestroy() {
        // LC.9 - Unsubscribe from ControlTree events
        this.valueChangesSub?.unsubscribe();
    }

    save() {
        // Validity is determined by the ControlTree
        if (!this.form.valid) {
            this.toast.error('User details contains errors');
        } else {
            // LC.8 - Retrieve ControlData and optionally convert it to ModelData
            /* Usage of getRawValue() to guarantee retrive of the whole data
            * (disabled controls value are not reflected to their attribute control.value) */
            const dataToSave = this.form.getRawValue();

            this.toast.success('User saved', dataToSave);
        }
    }

    addAddress() {
        // LC.5 - Data modification are operated against the ControlTree
        // CC.D - Use getter reference for lookup major controls within the ControlTree
        this.addresses.push(this.getAddressControl({ street: '', suburb: '' }));
    }

    removeAddress(index: number) {
        this.addresses.removeAt(index);
    }

    private getAddressControl(address: Address): UfControlGroup {
        return this.ufb.group({
            street: [address.street, ValidatorFunctions.required('Street is required')],
            suburb: address.suburb,
        });
    }

}
