import { ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, DataPropertyInfoService, FieldTypeIcon, FormDefinitionMetadataIdentifiers, ToastService, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { WorkflowStartState } from '@unifii/library/smart-forms';
import { Option, OriginIdentifiers, Transition, UfError } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { FormDataActivity, IntegrationFeature, IntegrationInfo, SchemaInfo, UcDefinition, UcFormBucketClient, UcProject, WorkflowEventType } from 'client';
import { UcWorkflow } from 'client/workflow';
import { EditData, SaveOption, SaveOptionType, useDefaultErrorMessage } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { transitionName } from 'helpers/transition-helper-functions';
import { MappableField } from 'models';
import { reloadCurrentRoute } from 'pages/utils';
import { BreadcrumbService } from 'services/breadcrumb.service';

import { WorkflowSourceTypeLabel } from './constants';
import { FieldMappingService } from './field-mapping.service';
import { WorkflowActivityTableManager } from './workflow-activity-table-manager';
import { FormDataControlKeys, WorkflowFormDataFormController } from './workflow-form-data-form.controller';
import { WorkflowFormDataModel } from './workflow-types';
import { buildHeaderConfig } from './workflow-utils';

interface MappableFormMetafield {
    targetField: FormDefinitionMetadataIdentifiers;
    sourceField?: FormDefinitionMetadataIdentifiers;
    sourceExpression?: string;
    sourceLabel?: string;
}

interface MappableMetaField {
    source: MetaTableField;
    target: MetaTableField;
}

interface MetaTableField {
    identifier?: string;
    expression?: string;
    label?: string;
    icon?: string;
}

@Component({
    templateUrl: 'workflow-form-data-form.html',
    styleUrls: ['workflow-form-data-form.less'],
})
export class WorkflowFormDataFormComponent implements EditData, OnInit, OnDestroy {

    protected readonly transitionName = transitionName;
    protected readonly controlKeys = FormDataControlKeys;
    protected readonly sourceTypes: Option[] = [
        { name: WorkflowSourceTypeLabel[WorkflowEventType.FormSubmitted], identifier: WorkflowEventType.FormSubmitted },
        // TODO - Add Support for User & Integration Source
        // { name: WorkflowSourceTypeLabel[WorkflowEventType.ApiEvent], identifier: WorkflowEventType.ApiEvent },
        // { name: WorkflowSourceTypeLabel[WorkflowEventType.RoleAdded], identifier: WorkflowEventType.RoleAdded },
    ];

    protected error?: UfError;
    protected form: UfControlGroup;
    protected buckets: SchemaInfo[];
    protected transitions: Transition[];
    protected formOptions: Option[];
    protected integrations: IntegrationInfo[];
    protected breadcrumbs: Breadcrumb[];
    protected filteredFeatures: IntegrationFeature[];
    protected targetFields?: MappableField[];
    protected sourceFields?: MappableField[];
    protected metaFields?: MappableMetaField[];
    protected isExpanded = true;

    get edited() {
        return !!this.builderHeaderService.config.edited;
    }

    private set edited(v: boolean) {
        this.builderHeaderService.config.edited = v;
    }

    private sourceBucketId?: string;
    private targetFormTransitions: Transition[];
    private targetDefinition?: UcDefinition;
    private targetTransition?: Transition;
    private subscriptions = new Subscription();
    private hasSaveAndNextButton: boolean;

    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private ucProject = inject(UcProject);
    private ucFormBucketClient = inject(UcFormBucketClient);
    private ucWorkflow = inject(UcWorkflow);
    private toastService = inject(ToastService);
    private formController = inject(WorkflowFormDataFormController);
    private fieldMappingService = inject(FieldMappingService);
    private breadcrumbService = inject(BreadcrumbService);
    private builderHeaderService = inject(BuilderHeaderService);
    private cdr = inject(ChangeDetectorRef);
    private dataPropertyInfoService = inject(DataPropertyInfoService);
    private tableManager = inject(TableContainerManager) as WorkflowActivityTableManager;

    async ngOnInit() {
        const { id, duplicate } = this.route.snapshot.params;

        this.builderHeaderService.init();
        this.hasSaveAndNextButton = id !== 'new' && !duplicate;

        try {
            const formData = await this.getFormData(id);

            const model = await this.formController.toModel(formData);

            if (id !== 'new' && model) {
                if (duplicate) {
                    model.id = null as any as string;
                    model.label += ' (copy)';
                }

                this.sourceBucketId = model.bucket?.id;
                this.sourceFields = await this.loadBucketSourceFields(this.sourceBucketId);
                this.targetDefinition = model.targetFormDefinition;
                this.targetTransition = model.targetTransition;
                this.targetFormTransitions = this.formController.getFieldTransitions(this.targetDefinition, WorkflowStartState);
                this.targetFields = await this.fieldMappingService.mapDefinitionToMappableFields(this.targetDefinition, this.targetTransition);
                this.metaFields = this.getMappableMetaFields(this.targetDefinition, this.targetTransition, this.sourceBucketId);
            }

            this.form = await this.formController.buildRoot(model);
            this.subscriptions.add(this.form.valueChanges.subscribe(() => { this.edited = true; }));
            this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [model?.label ?? 'New']);
            // Set breadcrumbs
            this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => void this.save(saveOption)));
            this.buildHeaderConfig(formData);
        } catch (e) {
            this.error = useDefaultErrorMessage(e);

            return;
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    protected async searchForms(q: string) {
        try {
            const forms = await this.ucProject.getForms({ params: { q } });

            this.formOptions = forms.map(({ name, id }) => ({ name, identifier: id }));
        } catch (e) {
            this.toastService.error(`Form search failed`);
        }
    }

    protected async searchBuckets(q: string) {
        try {
            this.buckets = await this.ucFormBucketClient.list({ params: { q } });
        } catch (e) {
            this.toastService.error(`Bucket search failed`);
        }
    }

    protected searchTransitions(q: string) {
        this.transitions = this.targetFormTransitions.filter((v) => transitionName(v).toLowerCase().includes(q.toLowerCase()));
    }

    protected async save(saveOption?: SaveOption) {
        this.form.setSubmitted();

        if (!this.form.valid || !this.targetDefinition) {
            return;
        }

        try {
            const model = this.form.getRawValue() as WorkflowFormDataModel;

            model.targetFormDefinition = this.targetDefinition;
            const formData = this.formController.toData(model);

            let updatedFormData;

            if (!formData.id) {
                updatedFormData = await this.ucWorkflow.addActivity<FormDataActivity>(formData);
                this.toastService.success('Form Data saved successfully');
                this.tableManager.reload.next();
            } else {
                updatedFormData = await this.ucWorkflow.updateActivity<FormDataActivity>(formData);
                this.toastService.success('Form Data updated successfully');
                this.tableManager.updateItem.next(updatedFormData);
            }

            this.edited = false;

            if (!saveOption) {
                if (!formData.id) {
                    void this.router.navigate(['..', updatedFormData.id], { relativeTo: this.route });
                } else {
                    this.builderHeaderService.updateConfig({
                        breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [updatedFormData.label]),
                        lastModifiedAt: updatedFormData.lastModifiedAt,
                        lastModifiedBy: updatedFormData.lastModifiedBy,
                    });
                }

                return;
            }

            switch (saveOption.id) {
                case SaveOptionType.New:
                    if (this.router.url.endsWith('/new')) {
                        reloadCurrentRoute(this.router);

                        return;
                    } else {
                        void this.router.navigate(['../', 'new'], { relativeTo: this.route });

                        return;
                    }
                case SaveOptionType.Next: {
                    const nextId = this.tableManager.getNextItem(updatedFormData.id)?.id;

                    if (nextId) {
                        void this.router.navigate(['..', nextId], { relativeTo: this.route });

                        return;
                    }
                    break;
                }
            }

            void this.router.navigate(['../'], { relativeTo: this.route });
        } catch (e) {
            this.toastService.error('Unable to save. There are errors in your Form Data.');
        }
    }

    protected get inputMapControl() {
        return this.form.get(FormDataControlKeys.InputMap) as UfControlArray | null ?? undefined;
    }

    protected toggle() {
        this.isExpanded = !this.isExpanded;
    }

    protected async bucketChange(bucket?: SchemaInfo) {
        this.sourceBucketId = bucket?.id;

        try {
            this.sourceFields = await this.loadBucketSourceFields(this.sourceBucketId);
        } catch (e) {
            this.toastService.error(`Unable to get source bucket: ${this.sourceBucketId}`);
            this.sourceBucketId = undefined;
            this.sourceFields = undefined;
        }

        await this.checkFieldMap();
    }

    protected async targetFormChange(formDefinition?: Option) {
        try {
            this.targetDefinition = formDefinition ? await this.ucProject.getForm(formDefinition.identifier) : undefined;
            this.targetFormTransitions = this.formController.getFieldTransitions(this.targetDefinition, WorkflowStartState);
        } catch (e) {
            this.toastService.error(`Unable to get target form: ${formDefinition?.identifier}`);
            this.targetDefinition = undefined;
            this.targetFormTransitions = [];
        }

        await this.checkFieldMap();
    }

    protected async transitionChange(targetTransition?: Transition) {
        this.targetTransition = targetTransition;

        await this.checkFieldMap();
    }

    private async checkFieldMap() {

        this.form.removeControl(FormDataControlKeys.InputMap);
        this.cdr.detectChanges();

        if (!this.sourceBucketId || !this.targetTransition || !this.targetDefinition) {
            this.targetFields = undefined;
            this.metaFields = undefined;

            return;
        }

        try {
            this.targetFields = await this.fieldMappingService.mapDefinitionToMappableFields(this.targetDefinition, this.targetTransition);
            this.metaFields = this.getMappableMetaFields(this.targetDefinition, this.targetTransition, this.sourceBucketId);
            this.form.addControl(FormDataControlKeys.InputMap, this.fieldMappingService.buildInputMapControl(this.targetFields));
        } catch (e) {
            this.toastService.error(`Unable to get transition form fields: ${this.targetDefinition.bucket}`);
            this.form.get(FormDataControlKeys.TargetTransition)?.reset();
        }
    }

    private getFormData(id: string): Promise<FormDataActivity> | FormDataActivity {
        if (id === 'new') {
            return {
                label: 'New',
            } as any as FormDataActivity;
        }

        return this.ucWorkflow.getActivity<FormDataActivity>(id);
    }

    private loadBucketSourceFields(bucketId?: string): Promise<MappableField[]> {
        if (!bucketId) {
            return Promise.resolve([]);
        }

        return this.fieldMappingService.getSchemaMappableFields(bucketId);
    }

    private buildHeaderConfig(formData: FormDataActivity) {
        const headerConfig = buildHeaderConfig(formData, this.hasSaveAndNextButton);

        headerConfig.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [headerConfig.title]);
        this.builderHeaderService.buildConfig(headerConfig);
    }

    private getMappableMetaFields(targetDefinition: UcDefinition, transition?: Transition, sourceBucketId?: string): MappableMetaField[] {

        const { identifier } = targetDefinition;

        const mappableFormMetafields: MappableFormMetafield[] = [
            {
                sourceExpression: `'${identifier}'`,
                targetField: FormDefinitionMetadataIdentifiers.DefinitionIdentifier,
            },
            {
                sourceLabel: 'Date & Time when run',
                targetField: FormDefinitionMetadataIdentifiers.CreatedAt,
            },
            {
                sourceField: FormDefinitionMetadataIdentifiers.LastModifiedBy,
                targetField: FormDefinitionMetadataIdentifiers.CreatedBy,
            },
            {
                sourceLabel: 'Date & Time when run',
                targetField: FormDefinitionMetadataIdentifiers.LastModifiedAt,
            },
            {
                sourceField: FormDefinitionMetadataIdentifiers.LastModifiedBy,
                targetField: FormDefinitionMetadataIdentifiers.LastModifiedBy,
            },
            {
                sourceExpression: transition?.target ? `'${transition.target}'` : undefined,
                targetField: FormDefinitionMetadataIdentifiers.State,
            },
            {
                sourceExpression: transition?.result ? `'${transition.result}'`: undefined,
                targetField: FormDefinitionMetadataIdentifiers.Result,
            },
            {
                sourceField: FormDefinitionMetadataIdentifiers.DefinitionIdentifier,
                targetField: FormDefinitionMetadataIdentifiers.ParentDefinitionIdentifier,
            },
            {
                sourceExpression: `'${sourceBucketId}'`,
                targetField: FormDefinitionMetadataIdentifiers.ParentBucket,
            },
            {
                sourceField: FormDefinitionMetadataIdentifiers.Id,
                targetField: FormDefinitionMetadataIdentifiers.ParentId,
            },
            {
                sourceField: FormDefinitionMetadataIdentifiers.SeqId,
                targetField: FormDefinitionMetadataIdentifiers.ParentSeqId,
            },
            {
                sourceExpression: `'${OriginIdentifiers.Unifii}'`,
                targetField: FormDefinitionMetadataIdentifiers.Origin,
            },

        ];

        const formDataMetadataReferences = this.dataPropertyInfoService.formDefinitionReferences;

        return mappableFormMetafields.flatMap((f) => {

            if (!f.sourceField && !f.sourceLabel && !f.sourceExpression) {
                return [];
            }

            const sourceFieldInfo = f.sourceField ? formDataMetadataReferences[f.sourceField] : undefined;
            const targetFieldInfo = formDataMetadataReferences[f.targetField];

            return {
                source: {
                    identifier: f.sourceField,
                    expression: f.sourceExpression,
                    label: sourceFieldInfo?.label ?? f.sourceLabel,
                    // TODO - Replace with Library Pipe
                    icon: sourceFieldInfo?.type ? FieldTypeIcon.get(sourceFieldInfo.type): undefined,
                },
                target: {
                    identifier: targetFieldInfo.identifier,
                    label: targetFieldInfo.label,
                    // TODO - Replace with Library Pipe
                    icon: FieldTypeIcon.get(targetFieldInfo.type),
                },
            };
        });
    }

}
