<div *ngIf="!error" class="header-pane uf-app-bar transparent">
    <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large" />
    <a [routerLink]="['../']" class="uf-button tertiary right">Cancel</a>
</div>

<uf-panel *ngIf="!error" class="content-pane container">
    <div class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">
            <div *ngIf="!device && !loading" class="col-12">
                <uf-blockquote class="error-text" icon="errorSolid">
                    <p>No device found</p>
                </uf-blockquote>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Details
                    </div>
                </div>

                <div class="uf-grid pad">
                    <uf-data-display-list *ngIf="device" class="col-12">
                        <dt>Name</dt>
                        <dd>{{device.name}}</dd>
                        <dt>Id</dt>
                        <dd>{{device.id}}</dd>
                        <dt>OS</dt>
                        <dd>{{device.os}}</dd>
                        <dt>OS Version</dt>
                        <dd>{{device.osVersion}}</dd>
                        <dt>Manufacturer</dt>
                        <dd>{{device.manufacturer}}</dd>
                        <dt>Model</dt>
                        <dd>{{device.model}}</dd>
                        <dt>Notification Token</dt>
                        <dd>{{device.notificationToken}}</dd>
                    </uf-data-display-list>
                </div>
            </div>
        </div>
    </div>
</uf-panel>

<uc-error-message [error]="error" />