<div *ngIf="isNew && !error" class="header-pane uf-app-bar transparent">
    <uf-breadcrumbs [breadcrumbs]="parent.breadcrumbs" class="large">
        <span *ngIf="edited">*</span>
    </uf-breadcrumbs>
    <div class="row space-children right">
        <a [routerLink]=" ['../']" type="button" class="uf-button tertiary">
            Cancel
        </a>
        <uc-save-options [options]="saveOptions" (onSave)="save($event)" />
    </div>
</div>

<uf-panel *ngIf="app && form && !error" class="content-pane container">
    <div class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">
            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Details
                    </div>
                </div>

                <div class="uf-grid pad">
                    <uf-text *ngIf="form.controls.id" [control]="form.controls.id | asUfControl" [disabled]="!isNew"
                        [(value)]="app.id" label="Id" class="col-6" />
                    <uf-text *ngIf="form.controls.name" [control]="form.controls.name | asUfControl"
                        [(value)]="app.name" label="Name" class="col-6" />
                    <uf-data-display-list class="col-12">
                        <ng-container *ngIf="app.createdBy?.username">
                            <dt>Created by</dt>
                            <dd>{{app.createdBy?.username}}</dd>
                        </ng-container>
                        <ng-container *ngIf="app.createdAt">
                            <dt>Created at</dt>
                            <dd>{{app.createdAt | dataDisplay}}</dd>
                        </ng-container>
                        <ng-container *ngIf="app?.lastModifiedBy?.username">
                            <dt>Last modified by</dt>
                            <dd>{{app.lastModifiedBy?.username}}</dd>
                        </ng-container>
                        <ng-container *ngIf="app?.lastModifiedAt">
                            <dt>Last modified at</dt>
                            <dd>{{app.lastModifiedAt | dataDisplay}}</dd>
                        </ng-container>
                    </uf-data-display-list>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">Authentication</div>
                </div>
                <div class="uf-grid pad">
                    <uc-secret-input [(value)]="app.secret" (valueChange)="changeSecret($event)" label="API Key"
                        class="col-12" />
                </div>
            </div>
            <ng-template [ngIf]="arePushNotificationsEnabled">
                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">Android Notification Settings</div>
                        <button *ngIf="app.googleKey" (click)="downloadGoogleKey()" type="button" title="Download"
                            class="uf-action">
                            <uf-icon name="import" />
                        </button>
                        <button *ngIf="app.googleKey" (click)="deleteGoogleKey()" type="button" title="Delete"
                            class="uf-action">
                            <uf-icon name="delete" />
                        </button>
                    </div>
                    <div class="uf-grid pad">
                        <div class="col-12">
                            <uf-blockquote *ngIf="app.googleKey" content="Key Uploaded" class="success"
                                icon="successSolid" />
                            <uf-file [label]="app.googleKey ? 'Select a new key' : 'Select a key'"
                                (valueChange)="updateGoogleKey($event)" class="padded--vertical" />
                        </div>
                    </div>
                </div>

                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            iOS Notification Settings
                        </div>
                        <button *ngIf="showAppleCertDetails" (click)="downloadAppleCertificate()" type="button"
                            title="Download" class="uf-action">
                            <uf-icon name="import" />
                        </button>
                        <button *ngIf="app.appleCert" (click)="deleteAppleCert()" type="button" title="Delete"
                            class="uf-action">
                            <uf-icon name="delete" />
                        </button>
                    </div>
                    <div class="uf-grid pad">
                        <uf-text *ngIf="showAppleCertDetails" [value]="app.appleCertName" disabled="true" label="Name"
                            class="col-12" />
                        <uf-checkbox *ngIf="showAppleCertDetails" [(value)]="app.appleIsSandbox" label="Sandbox"
                            class="col-12" />
                        <div *ngIf="showAppleCertDetails && isAppleCertExpired" class="col-12">
                            <uf-blockquote
                                content="Your certificate is expired on {{app.appleCertNotAfter | date:'medium'}}"
                                class="error" icon="errorSolid" />
                        </div>
                        <div *ngIf="showAppleCertDetails && !isAppleCertExpired" class="col-12">
                            <uf-blockquote
                                content="Your certificate is valid until {{app.appleCertNotAfter | date:'medium'}}"
                                class="success" icon="successSolid" />
                        </div>
                        <div class="col-12">
                            <uf-file [label]="app.appleCertName ? 'Select a new certificate' : 'Select a certificate'"
                                (valueChange)="updateAppleCertificate($event)" class="padded--vertical" />
                        </div>
                        <div *ngIf="showAppleCertPreview" class="col-12">
                            <uf-blockquote icon="warning" content="Certificate selected, please provide its password."
                                class="warning" />
                        </div>
                        <uf-password *ngIf="uploadedAppleCert" [showReveal]="true" [(value)]="app.applePassword"
                            label="Apple password" class="col-12" />
                        <uf-checkbox *ngIf="uploadedAppleCert" [(value)]="app.appleIsSandbox" label="Sandbox"
                            class="col-12" />
                    </div>
                </div>
            </ng-template>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">Cross-Origin Resource Sharing (CORS)</div>
                </div>
                <div *ngIf="form.controls.corsOrigins" class="uf-grid pad">
                    <div *ngFor="let control of (form.controls.corsOrigins | asUfControlArray).controls; let i = index"
                        class="row col-12">
                        <uf-text [label]="'Origin ' + (i + 1)" [control]="control | asUfControl"
                            [(value)]="$any(app.corsOrigins)[i]" class="grow" />
                        <button (click)="deleteCorsOrigin(i)" title="Delete" type="button" class="uf-action tertiary">
                            <uf-icon name="delete" />
                        </button>
                    </div>
                    <div class="col-12 row">
                        <button (click)="addCorsOrigin()" type="button" class="uf-button right">Add</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</uf-panel>

<uc-error-message [error]="error" />