import { Injectable, inject } from '@angular/core';
import { ClaimConfig, ClientGetOptions, ClientHeadOptions, Company, Role, UserInfo } from '@unifii/sdk';
import { UserProvisioningProvider } from '@unifii/user-provisioning';

import { UcClient, UcRoles, UcUserClaims, UcUsers } from '../../client';

@Injectable()
export class UcUserProvisioningProvider implements UserProvisioningProvider {

    private ucClient = inject(UcClient);
    private ucUsers = inject(UcUsers);
    private ucRoles = inject(UcRoles);
    private ucUserClaims = inject(UcUserClaims);

    queryRoles(q?: string, sort?: string, options?: ClientGetOptions): Promise<Role[]> {
        // TODO find a solution to override UcRoles.get DefaultPaginationParams with no limit, no offset
        return this.ucRoles.get(q, sort, { ...options, params: { limit: 1000 } });
    }

    queryCompanies(q?: string): Promise<Company[]> {
        return this.ucClient.getCompanies(q);
    }

    getUserClaimConfig(options?: ClientGetOptions): Promise<ClaimConfig[]> {
        return this.ucUserClaims.list(options);
    }

    getUserByUsername(username: string, options?: ClientGetOptions): Promise<UserInfo> {
        return this.ucUsers.getByUsername(username, options);
    }

    checkEmail(email: string, options?: ClientHeadOptions): Promise<boolean> {
        return this.ucUsers.checkEmail(email, options);
    }

}
