<form *ngIf="show" class="wrap">
    <uc-drag-list [items]="elements" (moved)="moved()" canDrop="false" canReorder="true" class="uc-list">
        <uf-expander *ngFor="let e of elements, let i = index" [isExpanded]="expanded['' + i]" dragItem
            class="col-1of1--no-margin--no-padding uc-list-item--small--draggable--no-shrink">
            <div expanderHeader dragHandle class="uf-app-bar flat">
                <div class="title primary">
                    <span *ngIf="e.type">{{e.type}}</span>
                </div>
                <uf-icon *ngIf="$any(form.controls.list).at(i).invalid" name="warning" class="icon-warning" />
                <button (click)="remove(i)" type="button" title="Delete" class="uf-action tertiary">
                    <uf-icon name="delete" />
                </button>
            </div>
            <div expanderBody class="uc-group-content grid">
                <uf-select *ngIf="configs[i]?.type?.show" [control]="$any(form.controls.list).at(i).controls.type" [options]="validatorOptions"
                    [(value)]="e.type" (valueChange)="changeType($event, i)" label="Type"
                    placeholder="Select type" nameProperty="label" valueProperty="type" class="col-1of1" />

                <uf-text *ngIf="configs[i]?.expression?.show" [control]="$any(form.controls.list).at(i).controls.expression"
                    [placeholder]="validatorDictionary[e.type]?.placeholders?.value"
                    [(value)]="e.value" label="Expression"
                    class="col-1of1" />

                <uf-text *ngIf="configs[i]?.itemExpression?.show" [control]="$any(form.controls.list).at(i).controls.itemExpression"
                    [placeholder]="validatorDictionary[e.type]?.placeholders?.value"
                    [(value)]="e.value" label="Item Expression"
                    class="col-1of1" />

                <uf-text *ngIf="configs[i]?.pattern?.show" [control]="$any(form.controls.list).at(i).controls.pattern"
                    [placeholder]="validatorDictionary[e.type]?.placeholders?.value"
                    [(value)]="e.value" label="Pattern" class="col-1of1" />

                <uf-number *ngIf="configs[i]?.minLength?.show" [control]="$any(form.controls.list).at(i).controls.minLength"
                    [placeholder]="validatorDictionary[e.type]?.placeholders?.value"
                    [(value)]="e.value" label="Min Length"
                    class="col-1of1" />

                <uf-number *ngIf="configs[i]?.min?.show" [control]="$any(form.controls.list).at(i).controls.min"
                    [placeholder]="validatorDictionary[e.type]?.placeholders?.value"
                    [(value)]="e.value" label="Min" class="col-1of1" />

                <uf-number *ngIf="configs[i]?.max?.show" [control]="$any(form.controls.list).at(i).controls.max"
                    [placeholder]="validatorDictionary[e.type]?.placeholders?.value"
                    [(value)]="e.value" label="Max" class="col-1of1" />

                <uf-text *ngIf="configs[i]?.message?.show" [control]="$any(form.controls.list).at(i).controls.message"
                    [placeholder]="validatorDictionary[e.type]?.placeholders?.message"
                    [(value)]="e.message" label="Message"
                    class="col-1of1" />
            </div>
        </uf-expander>
    </uc-drag-list>
</form>
<uf-error *ngIf="form.controls.list" [control]="form.controls.list | asUfControl"
    style="display: block; padding-left: 8px;" />