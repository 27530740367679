import { EventEmitter, Injectable } from '@angular/core';
import { ModalService, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { CompoundType, StructureNode, StructureNodeType } from '@unifii/sdk';

import { CompoundInfo, DefinitionInfo, Media, TableInfo, UcFormBucketClient, UcProject, UcStructure } from 'client';
import { TypeSelectComponent } from 'components';
import { ContentSelectComponent, ContentSelectType, getContentSelectConfig } from 'components/content/modals/content-select.component';
import { DropPosition } from 'components/dragdrop/drag-list.component';
import { FieldReferenceHelper } from 'helpers/field-reference-helper';

import { StructureNodeControlKeys } from './structure-control-keys';
import { StructureFormCtrl } from './structure-form-ctrl';
import { StructureFunctions } from './structure-functions';
import { ConsoleStructureNode } from './structure-model';
import { StructureStatus } from './structure-status';

@Injectable()
export class StructureEditorService {

    fieldDeselected = new EventEmitter<void>();
    fieldSelected = new EventEmitter<void>();

    constructor(
        private modalService: ModalService,
        private ucProject: UcProject,
        private ucFormBucketClient: UcFormBucketClient,
        private sfb: StructureFormCtrl,
        private status: StructureStatus,
    ) { }

    async selectNode(control?: UfControlGroup) {

        if (!control) {
            this.status.selected = null;
            this.fieldDeselected.next();

            return;
        }

        if (control === this.status.selected) {
            return;
        }

        const node = control.getRawValue() as StructureNode;
        const isHome = StructureFunctions.isNodeRoot(node);
        const isHomeEmpty = isHome && node.type === StructureNodeType.Empty;

        if (!isHomeEmpty) {
            this.status.selected = null;
            this.fieldDeselected.next();
            this.status.selected = control;
            this.fieldSelected.next();

            return;
        }

        // Select new HomePage
        const homeNode = await this.chooseHomePage();

        if (!homeNode) {
            this.status.selected = null;
            this.fieldDeselected.next();

            return;
        }

        homeNode.name = 'Home Page';
        delete node.children;

        control.patchValue(homeNode, { emitEvent: true });
        this.status.selected = null;
        this.fieldDeselected.next();
        this.status.selected = control;
        this.fieldSelected.next();
    }

    async chooseHomePage(): Promise<StructureNode | null> {

        const nodeType = await this.chooseHomeNodeType();

        if (!nodeType) {
            return null;
        }

        const node = await this.chooseNodeContent(nodeType);

        if (node) {
            return node;
        }

        return null;
    }

    async chooseNodeContent(type: StructureNodeType): Promise<StructureNode | null> {

        const node: StructureNode = { type, children: [] };

        const contentSelectType = this.mapNodeTypeToContentType(type);

        if (!contentSelectType) {
            node.name = type;

            return node;
        }

        const content = await this.modalService.openMedium(
            ContentSelectComponent, getContentSelectConfig(contentSelectType, this.ucProject, this.ucFormBucketClient),
        );

        if (!content) {
            return null;
        }

        switch (type) {
            case StructureNodeType.Collection:
                {
                    const collection = content as DefinitionInfo;

                    node.id = collection.id as unknown as number;
                    node.definitionIdentifier = collection.identifier;
                    node.publishState = collection.publishState;
                    node.name = collection.name;
                    break;
                }

            case StructureNodeType.CollectionItem:
                {
                    const collectionItem = content as CompoundInfo;

                    node.id = collectionItem.id as unknown as number;
                    node.definitionIdentifier = collectionItem.definitionIdentifier;
                    node.definitionLabel = collectionItem.definitionLabel;
                    node.publishState = collectionItem.publishState;
                    node.name = collectionItem.name;
                    break;
                }

            case StructureNodeType.Form:
               {
                    const form = content as DefinitionInfo;

                    node.id = form.id as unknown as number;
                    node.definitionIdentifier = form.identifier;
                    node.publishState = form.publishState;
                    node.definitionLabel = form.name;
                    node.name = form.name;
                    break;
               }
            case StructureNodeType.Page:
                {
                    const page = content as CompoundInfo;

                    node.id = page.id as unknown as number;
                    node.definitionIdentifier = page.definitionIdentifier;
                    node.definitionLabel = page.definitionLabel;
                    node.publishState = page.publishState;
                    node.name = page.name;
                    break;
                }
          
            case StructureNodeType.PdfViewer:
                {
                    const pdf = content as Media;

                    node.id = pdf.id as unknown as number;
                    node.name = pdf.title;
                    break;
                }

            case StructureNodeType.View:
                {
                    const view = content as CompoundInfo;

                    node.id = view.id as unknown as number;
                    node.definitionIdentifier = view.definitionIdentifier;
                    node.definitionLabel = view.definitionLabel;
                    node.publishState = view.publishState;
                    node.name = view.name;
                    break;
                }

            case StructureNodeType.FormBucket:
                {
                    const table = content as TableInfo;

                    node.id = table.id as any as number;
                    node.definitionIdentifier = table.identifier;
                    node.definitionLabel = table.title;
                    node.name = table.title;
                    break;
                }
                
            default:
                return null;
        }

        return node;
    }

    canDrop = async(element: any): Promise<boolean> => {

        if (element instanceof UfControlGroup && element.get(StructureNodeControlKeys.NodeId)?.value) {
            // An existing node control (excluded the HomePage with .nodeId == '0') => allow move action
            return true;
        }

        if (!element.id) {
            // Not an ItemPicker => block move action
            return false;
        }

        // User pick a new node to be added
        const node = await this.chooseNodeContent(element.id);

        if (!node) {
            // New node not selected => block move action
            return false;
        }

        /* The node need to be inserted in the element,
        *  that will be chained as input to the next draglist callback 'addConverter'
        *  allow move action */
        element._node = node;

        return true;
    };

    canCopy(node: StructureNode, control: UfControlGroup): boolean {
        return !StructureFunctions.isNodeRoot(node)
            && node.type !== StructureNodeType.Custom
            && !node.children?.length
            && !control.disabled;
    }

    addConverter = (element: { _node?: StructureNode }): UfControlGroup | null => {

        if (element._node) {
            const node = element._node;

            delete element._node; // clean up the field reference object

            node.nodeId = this.getStructureNextId(true);

            return this.sfb.buildNodeControl(node as ConsoleStructureNode);
        }

        return null;
    };

    drop = (element: any, parent: any, _position: DropPosition, index?: number): void => {

        let childrenControl: UfControlArray | null;

        if (parent instanceof UfControlGroup) {
            childrenControl = parent.get(StructureNodeControlKeys.Children) as UfControlArray;
            if (childrenControl == null) {
                childrenControl = this.sfb.buildNodeChildrenControl();
                parent.addControl(StructureNodeControlKeys.Children, childrenControl);
            }
        } else {
            childrenControl = parent;
        }

        childrenControl?.insert(index == null ? parent.controls.length : index, element);
        this.selectNode(element);
    };

    getStructureNextId(updateLastNodeId?: boolean): string {

        const structure = this.status.root.value as UcStructure;
        const nextNodeId = StructureFunctions.getNextAvailableNodeId(structure);

        if (updateLastNodeId) {
            this.status.root.patchValue({ lastNodeId: nextNodeId });
        }

        return `${nextNodeId}`;
    }

    copyAndInsert(node: StructureNode, nodeControl: UfControlGroup) {
        const parent = nodeControl.parent as UfControlArray | UfControlGroup;

        if (parent instanceof UfControlGroup) {
            return;
        }

        node = Object.assign({}, node);
        node.nodeId = this.getStructureNextId(true);
        node.name = `${node.name} - Copy`;

        const control = this.sfb.buildNodeControl(node as ConsoleStructureNode);
        const index = parent.controls.findIndex((c) => c === nodeControl);

        if (index >= 0) {
            parent.insert(index, control);
        } else {
            parent.push(control);
        }
    }

    private mapNodeTypeToContentType(nodeType: StructureNodeType): ContentSelectType | null {
        switch (nodeType) {
            case StructureNodeType.CollectionItem:
                return ContentSelectType.CollectionItem;
            case StructureNodeType.Collection:
                return ContentSelectType.Collection;
            case StructureNodeType.PdfViewer:
                return ContentSelectType.PdfViewer;
            case StructureNodeType.View:
                return ContentSelectType.View;
            case StructureNodeType.Page:
                return ContentSelectType.Page;
            case StructureNodeType.Form:
                return ContentSelectType.Form;
            case StructureNodeType.FormBucket:
                return ContentSelectType.Table;
            default:
                return null;
        }
    }

    private async chooseHomeNodeType(): Promise<StructureNodeType | undefined> {

        return (await this.modalService.openMedium(
            TypeSelectComponent,
            {
                title: 'Select Type', types: [
                    StructureNodeType.View,
                    StructureNodeType.PdfViewer,
                    StructureNodeType.Page,
                    StructureNodeType.Dashboard,
                    StructureNodeType.Custom,
                ].map((type) => FieldReferenceHelper.getFieldReference({ type }, CompoundType.Structure)),
            },
        ))?.type as StructureNodeType | undefined;
    }

}
