<uf-expander *ngIf="control" class="uf-box flat gap-sm-bottom">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">
            Parameters
        </div>
        <uf-icon *ngIf="control.invalid" name="error" class="error" />
        <uc-expander-controls *ngIf="control.length" [container]="argsWrap" />
    </div>
    <div #argsWrap expanderBody class="uc-group-content grid">
        <ul *ngIf="control.length" class="uc-list--joined col-1of1">
            <ng-template [ngForOf]="control.controls | asUfControlsGroups" let-arg ngFor let-i="index">
                <uf-expander [formGroup]="arg" class="uc-group margin--vertical">
                    <div expanderHeader class="uf-app-bar flat">
                        <div class="title primary">
                            {{ arg.get(formKeys.Key)?.value }}
                        </div>
                        <uf-icon *ngIf="arg.invalid" name="error" class="error" />
                        <button *ngIf="!arg.disabled" (click)="removeArg(i)" type="button" title="Delete"
                            class="uf-action tertiary">
                            <uf-icon name="delete" />
                        </button>
                    </div>
                    <div expanderBody class="uc-group-content grid">
                        <uf-text [formControlName]="formKeys.Key" label="Key" class="col-1of1" />
                        <uf-expression [formControlName]="formKeys.Value" label="Value" class="col-1of1" />
                        <!-- <div class="col-1of1">{{arg.value | json}}</div> -->
                    </div>
                </uf-expander>
            </ng-template>
        </ul>
        <div class="col-1of1">
            <button *ngIf="!control.disabled" (click)="addArg()" type="button" class="uf-button x-small right">
                Add Parameter
            </button>
        </div>
    </div>
</uf-expander>