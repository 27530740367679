import { Injectable, Type } from '@angular/core';
import { ComponentRegistryType, FormComponentRegistryOptions, FormField } from '@unifii/library/smart-forms';
import { UfFormComponentRegistry } from '@unifii/library/smart-forms/input';
import { FieldType } from '@unifii/sdk';

import { SecretFieldComponent } from '../secret-input-field.component';

@Injectable()
export class SystemIntegrationRegistry extends UfFormComponentRegistry {

    override type = ComponentRegistryType.Input;

    override get(type: FieldType, options?: FormComponentRegistryOptions): Type<FormField> {

        if (options?.tags?.includes('secret')) {
            return SecretFieldComponent;
        }

        return super.get(type, options);
    }

}
