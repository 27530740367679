import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataPropertyDescriptor, UfControlValueAccessor } from '@unifii/library/common';

@Component({
    selector: 'uc-find-by-property-input',
    templateUrl: 'find-by-property-input.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR, useExisting: FindByPropertyInputComponent, multi: true,
    }],
})
export class FindByPropertyInputComponent extends UfControlValueAccessor<string> implements OnInit {

    @Input() label: string;
    @Input() properties: DataPropertyDescriptor[];
    @Output() override valueChange = new EventEmitter<string>();

    protected findByProperty: DataPropertyDescriptor | null;
    protected findByResults: DataPropertyDescriptor[];

    ngOnInit() {
        this.findByProperty = this.value ? this.properties.find((c) => c.identifier === this.value) ?? null : null;
    }

    protected encodeFindBy() {
        this.value = this.findByProperty?.identifier;
        this.control.markAsTouched();
        this.valueChange.emit(this.value);
    }

    protected reset() {
        this.value = undefined;
        this.findByProperty = null;
        this.control.reset();
        this.valueChange.emit(this.value);
    }

    protected search(query: string) {
        this.findByResults = this.properties.filter((dp) => {
            if (query.trim().length) {
                return (dp.display as string).toLowerCase().includes(query.toLowerCase());
            }

            return true;
        });
    }

}
