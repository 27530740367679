import { Option } from '@unifii/sdk';

import { WorkflowActivityInfo, WorkflowRule } from 'client';
import { SaveAndClose, SaveAndNew, SaveAndNext } from 'components';
import { HeaderConfig } from 'components/common/builder-header/builder-header.component';

export const buildHeaderConfig = (workflowActivity: WorkflowRule | WorkflowActivityInfo, hasSaveAndNextButton: boolean): HeaderConfig => {
    const saveOptions = [SaveAndClose];

    if (hasSaveAndNextButton) {
        saveOptions.push(SaveAndNext);
    }
    saveOptions.push(SaveAndNew);

    return {
        title: workflowActivity.label,
        saveOptions,
        lastModifiedAt: workflowActivity.lastModifiedAt,
        lastModifiedBy: workflowActivity.lastModifiedBy,
        cancelRoute: ['../'],
    };
};

export const buildOptionsFromEnum = (enumObject: any): Option[] => Object.keys(enumObject).map((identifier) => ({
    identifier,
    name: enumObject[identifier] ? enumToName(enumObject[identifier]) : identifier,
}));

export const enumToName = (enumName: string): string => enumName.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
