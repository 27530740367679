import { UcPermission, UcPermissionTableEntry } from 'client';

export const toUcPermissionTableEntry = (permission: UcPermission) => 
    Object.assign(
        {},
        permission,
        { hasConditionalActions: permission.actions.some((action) => !!action.condition) },
        { hasConfiguredFields:
            !!permission.fields?.length ||
            !!permission.lockedFields?.length ||
            !!permission.readFields?.length ||
            !!permission.deniedFields?.length },
    ) as UcPermissionTableEntry;

export const toUcPermission = (permission: UcPermissionTableEntry) => {

    const result = Object.assign({}, permission) as UcPermissionTableEntry;
    
    delete result.hasConditionalActions;
    delete result.hasConfiguredFields;
    
    return result as UcPermission;
};
