import { Component, HostBinding, Inject, OnDestroy } from '@angular/core';
import { Modal, ModalData, ModalRuntime, ModalService, UfControl, ValidatorFunctions } from '@unifii/library/common';
import { LengthAtLeast, Option } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { Media, MediaType } from 'client';
import { ImageCropComponent } from 'components/content/modals/image-crop.component';

enum Alignment {
    Left = 'left',
    Center = 'center',
    Right = 'right',
    Stretch = 'stretch',
    FloatLeft = 'floatLeft',
    FloatRight = 'floatRight'
}

enum Orientation {
    Portrait = 'Portrait',
    Landscape = 'Landscape'
}

interface RadioOption {
    name: string;
    orientation: Orientation;
    w: number;
    h: number;
}

export interface AssetOptions {
    type?: string;
    showAlignmentOptions?: boolean;
}

const ImageProportions: RadioOption[] & LengthAtLeast<RadioOption[], 10> = [
    { name: 'Actual Size', orientation: Orientation.Landscape, w: 0, h: 0 },
    { name: '1 x 1', orientation: Orientation.Landscape, w: 1, h: 1 },
    { name: '2 x 1', orientation: Orientation.Landscape, w: 2, h: 1 },
    { name: '3 x 2', orientation: Orientation.Landscape, w: 3, h: 2 },
    { name: '16 x 9', orientation: Orientation.Landscape, w: 16, h: 9 },
    { name: '4 x 3', orientation: Orientation.Landscape, w: 4, h: 3 },
    { name: '1 x 2', orientation: Orientation.Portrait, w: 1, h: 2 },
    { name: '2 x 3', orientation: Orientation.Portrait, w: 2, h: 3 },
    { name: '9 x 16', orientation: Orientation.Portrait, w: 9, h: 16 },
    { name: '3 x 4', orientation: Orientation.Portrait, w: 3, h: 4 },
];

const ImageSizes = [
    { name: 'Full Width', identifier: '' },
    { name: '1 / 3', identifier: '33%' },
    { name: '1 / 2', identifier: '50%' },
];

@Component({
    templateUrl: './uc-markdown-tokenizer.html',
    styleUrls: ['./uc-markdown-tokenizer.less'],
})
export class UcMarkdownTokenizerComponent implements Modal<AssetOptions | null, string>, OnDestroy {

    @HostBinding('class.uf-form-card') classes = true;

    readonly mediaType = MediaType;
    readonly alignmentOptions: Option[] = [
        { name: 'Left', identifier: Alignment.Left },
        { name: 'Center', identifier: Alignment.Center },
        { name: 'Right', identifier: Alignment.Right },
        { name: 'Stretch', identifier: Alignment.Stretch },
        { name: 'Float Left', identifier: Alignment.FloatLeft },
        { name: 'Float Right', identifier: Alignment.FloatRight },
    ];
    readonly allRatioOptions: RadioOption[] = ImageProportions.map((p, i) => ({
        ...p,
        value: i,
    }));
    readonly loremIpsum: string = `Lorem ipsum dolor sit, consectetur adipiscing elit. Pellentesque varius quis augue ut elementum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec sagittis non erat vel imperdiet. Suspendisse non felis vulputate nisi scelerisque dignissim. In sed erat est. Nullam sit amet hendrerit ex. Etiam eget urna dapibus, tincidunt arcu ac, pulvinar erat. Nam sed rhoncus sapien, ac iaculis diam. Nulla sed tortor lectus.`;

    tag: string;
    type: MediaType;
    asset: Media;
    hasData = false;
    imageSize: string;
    directive: string;
    ratio = 0;
    cropperSize: { w: number; h: number };
    alignment: Alignment;
    imageSizeOptions: Option[];
    data?: AssetOptions | null | undefined;
    orientation = Orientation.Landscape;
    radioOptions = this.allRatioOptions;
    crop: { x: number; y: number; height: number; width: number } | undefined;
    assetControl = new UfControl(ValidatorFunctions.required('This field is mandatory'));

    private subscription: Subscription;

    constructor(
        public runtime: ModalRuntime<AssetOptions | null, string>,
        @Inject(ModalData) data: AssetOptions | null,
        private modalService: ModalService,
    ) {

        this.type = data ? data.type as MediaType || MediaType.Image : MediaType.Image as MediaType;
        this.tag = data && data.type === MediaType.Image ? '!img' : '!vid';

        if (data && !data.showAlignmentOptions) {
            this.alignmentOptions = this.alignmentOptions.filter((alignmentOption) => alignmentOption.identifier === Alignment.Stretch);
        }

        this.alignment = Alignment.Stretch;
        this.updateAlignment();

        this.subscription = this.assetControl.valueChanges.subscribe((asset) => {
            this.directive = asset ? this.updateDirective(true, asset) : '';
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    async cropImage() {
        const { w, h } = this.allRatioOptions[this.ratio] ?? ImageProportions[0];

        this.crop = await this.modalService.openLarge(ImageCropComponent, { image: { ...this.asset, crop: this.crop }, options: { minWidth: w * 10, minHeight: h * 10 } });

        this.directive = this.updateDirective(true);
    }

    add() {

        if (this.assetControl.invalid) {
            this.assetControl.setSubmitted();

            return;
        }

        this.directive = this.updateDirective();

        this.runtime.close(this.directive);
    }

    updateDirective(addsLorem?: boolean, asset?: Media): string {
        let directive;
        const { w, h } = this.allRatioOptions[+this.ratio] ?? ImageProportions[0];

        directive = `${this.tag}(${asset ? asset.id : this.asset.id}`;
        if (this.type === MediaType.Video) {
            directive += ` title=${asset ? asset.title : this.asset.title}`;
        }
        if (this.alignment) {
            directive += ` position=${this.alignment}`;
        }
        if (this.stringifiedCrop) {
            directive += ` crop=${this.stringifiedCrop}`;
        }
        if (this.imageSize) {
            directive += ` width=${this.imageSize}`;
        }
        if (this.ratio) {
            directive += ` aspectRatio=${w}x${h}`;
        }
        directive += ')';
        if (addsLorem) {
            directive += this.loremIpsum;
        }

        return directive;
    }

    updateAlignment() {

        this.imageSizeOptions = ImageSizes.filter((v) => this.alignment !== Alignment.Stretch ? v.identifier !== '' : v.identifier === '');
        if (this.alignment !== Alignment.Stretch && !this.imageSize) {
            this.imageSize = '33%';
        } else if (this.alignment === Alignment.Stretch) {
            this.imageSize = '';
        }

        if (this.asset) {
            this.directive = this.updateDirective(true);
        }
    }

    private get stringifiedCrop(): string | undefined {

        if (this.crop) {
            return `${this.crop.x},${this.crop.y},${this.crop.x + this.crop.width},${this.crop.y + this.crop.height}`;
        }

        return;
    }

}
