import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Breadcrumb } from '@unifii/library/common';
import { UfError, isUfError } from '@unifii/sdk';

import { FormBucketService } from 'pages/form-data/bucket-service';
import { TabsPage } from 'pages/tabs-page';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

import { bucketConfigResolver } from './bucket-config-resolver';

/** Route non-reusable component, see ConsoleRouteReuseStrategy */
@Component({
    selector: 'uc-bucket',
    templateUrl: 'bucket.html',
    providers: [FormBucketService],
})
export class BucketComponent extends TabsPage implements OnInit, OnDestroy {
    
    override edited: boolean;
    
    protected error?: UfError;
    protected breadcrumbs: Breadcrumb[];
    protected context = inject(ContextService);

    private bucketService = inject(FormBucketService);
    private breadcrumbService = inject(BreadcrumbService);

    override ngOnInit() {
        const resolverData = this.route.snapshot.data.bucketConfig as Awaited<ReturnType<typeof bucketConfigResolver>>;

        if (isUfError(resolverData)) {
            this.error = resolverData;

            return;
        }

        const { schema, schemaSetting, dataDescriptor, publishedSchema } = resolverData;

        this.bucketService.schema = schema;
        this.bucketService.publishedSchema = publishedSchema;
        this.bucketService.schemaSetting = schemaSetting;
        this.bucketService.dataDescriptor = dataDescriptor;
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [schema.bucket]);

        super.ngOnInit();
    }

    override ngOnDestroy() {
        this.context.bucketId = undefined;
        super.ngOnDestroy();
    }

}
