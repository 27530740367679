import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { SaveOption } from 'components/common/save-options/save-options.component';

import { BuilderNotificationInfo } from '../../compound-builder/builder.service';

import { HeaderConfig } from './builder-header.component';

@Injectable({ providedIn: 'root' })
export class BuilderHeaderService {

    saveClicked = new EventEmitter<SaveOption | undefined>();

    notify: Subject<BuilderNotificationInfo> = new Subject<BuilderNotificationInfo>();

    config: HeaderConfig = {};

    busy: boolean;

    init() {
        this.config = {};
    }

    buildConfig<T extends HeaderConfig>(config: T): void {
        this.config = config;

        this.busy = false;
    }

    updateConfig(config: HeaderConfig) {
        this.config = Object.assign({}, this.config, config);
    }

}
