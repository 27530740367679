<div class="header-pane uf-app-bar transparent">
    <uc-builder-header />
</div>
<uf-panel class="content-pane">
    <div class="uf-container-lg">
        <div class="uf-grid col-12 pad-top">
            <uf-skeleton *ngIf="!error && !root" class="gap-none col-12" />

            <uf-message *ngIf="error" [content]="error.message" icon="error" class="error gap-bottom col-12" />

            <ng-container *ngIf="root" [formGroup]="root">
                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Details
                        </div>
                    </div>
                    <div class="uf-grid pad">
                        <uf-text [formControlName]="controlKeys.Label" label="Title" class="col-12">
                            <span class="suffix">*</span>
                        </uf-text>
                        <uf-autocomplete *ngIf="root.get(controlKeys.Bucket)?.enabled"
                            [formControlName]="controlKeys.Bucket" [options]="bucketsResult"
                            (valueChange)="bucketChange($event)" (searchChange)="searchBuckets($event)"
                            label="Form Data Repository" placeholder="Search form data repository" nameProperty="name"
                            class="col-12" />
                    </div>
                </div>

                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Recipients
                        </div>
                    </div>

                    <div *ngFor="let recipient of recipients.controls | asUfControlsGroups; let i = index"
                        class="uf-box">
                        <uf-expander [dragDisabled]="true" [isExpanded]="true" dragItem>
                            <div expanderHeader class="uf-app-bar flat">
                                <div class="title">
                                    {{recipientLabel(i)}}
                                </div>
                                <button (click)="removeRecipient(i)" title="Delete" class="uf-action" type="button">
                                    <uf-icon name="delete" />
                                </button>
                            </div>
                            <div expanderBody class="uf-grid pad">

                                <!-- User Hierarchy -->
                                <uc-workflow-notification-hierarchy-field
                                    *ngIf="recipient.get(controlKeys.Type)?.value === workflowNotificationRecipientType.UserHierarchy"
                                    [fields]="hierarchyFields" [controlGroup]="recipient" class="col-12" />

                                <!-- Form Data -->
                                <ng-template
                                    [ngIf]="recipient.get(controlKeys.Type)?.value === workflowNotificationRecipientType.FormData">

                                    <!-- Form Data User / User Manager - DataSources -->
                                    <ng-container *ngIf="isFormDataUserDataSource(recipient)">
                                        <uf-autocomplete
                                            *ngIf="recipient.get(controlKeys.FormData)?.get(controlKeys.Value); let formDataControl"
                                            [options]="filteredUserDataSources"
                                            [control]="formDataControl | asUfControl"
                                            (searchChange)="searchUserDataSource($event)" label="User DataSource"
                                            placeholder="Select a DataSource" class="col-12" />
                                    </ng-container>

                                    <!-- Form Data Email Field -->
                                    <ng-container
                                        *ngIf="recipient.get(controlKeys.FormData)?.get(controlKeys.Type)?.value === workflowNotificationRecipientFormDataType.Email">
                                        <uf-autocomplete
                                            *ngIf="recipient.get(controlKeys.FormData)?.get(controlKeys.Value); let emailFieldControl"
                                            [options]="filteredEmailFields" [control]="emailFieldControl | asUfControl"
                                            (searchChange)="searchEmailFields($event)" label="Email Fields"
                                            nameProperty="name" placeholder="Select a Field" class="col-12" />
                                    </ng-container>

                                </ng-template>

                                <!-- Email -->
                                <ng-container
                                    *ngIf="recipient.get(controlKeys.Type)?.value === workflowNotificationRecipientType.Email">
                                    <uf-email *ngIf="recipient.get(controlKeys.Email); let emailControl"
                                        [control]="emailControl | asUfControl" label="Email" class="col-12" />
                                </ng-container>

                                <!-- Role -->
                                <ng-container
                                    *ngIf="recipient.get(controlKeys.Type)?.value === workflowNotificationRecipientType.Role">
                                    <uf-autocomplete [options]="filteredRoles"
                                        *ngIf="recipient.get(controlKeys.Role); let roleControl"
                                        [control]="roleControl | asUfControl" (searchChange)="searchRole($event)"
                                        label="Role" placeholder="Select a role" class="col-12" />
                                </ng-container>

                                <!-- User -->
                                <ng-container
                                    *ngIf="recipient.get(controlKeys.Type)?.value === workflowNotificationRecipientType.User">
                                    <uf-autocomplete *ngIf="recipient.get(controlKeys.User); let userControl"
                                        [options]="users" [control]="userControl | asUfControl"
                                        (searchChange)="searchUsers($event)" label="User" nameProperty="username"
                                        valueProperty="username" class="col-12" />
                                </ng-container>

                                <!-- Claim -->
                                <ng-template
                                    [ngIf]="recipient.get(controlKeys.Type)?.value === workflowNotificationRecipientType.Claim">

                                    <uf-autocomplete [options]="filteredUserClaims"
                                        *ngIf="recipient.get(controlKeys.Claim)?.get(controlKeys.Type); let claimControl;"
                                        [control]="claimControl | asUfControl" [allowCustom]="false"
                                        (valueChange)="changeClaim($event, recipientClaimControl(i))"
                                        (searchChange)="searchUserClaims($event)" label="Claim"
                                        placeholder="Search Claims" class="col-6" />

                                    <uf-select
                                        *ngIf="recipient.get(controlKeys.Claim)?.get(controlKeys.MatchType); let matchTypeControl"
                                        [options]="claimMatchOptions" [control]="matchTypeControl | asUfControl"
                                        label="Match Type" valueProperty="identifier" nameProperty="name"
                                        placeholder="Select a match type" class="col-6" />

                                    <!-- Match type = Value - Claim Editor -->
                                    <ng-container
                                        *ngIf="!!recipient.get(controlKeys.Claim)?.get(controlKeys.ClaimConfig)?.value && isValueClaim(recipient)">
                                        <uf-claim-field
                                            *ngIf="recipient.get(controlKeys.Claim)?.get(controlKeys.ClaimConfig); let claimEditorControl"
                                            [control]="claimEditorControl | asUfControl"
                                            [claimConfig]="recipient.get(controlKeys.Claim)?.get(controlKeys.ClaimConfig)?.value"
                                            class="col-6" />
                                    </ng-container>

                                    <!-- Match type = User -->
                                    <ng-container
                                        *ngIf="recipient.get(controlKeys.Claim)?.get(controlKeys.MatchAgainst); let matchAgainstControl">
                                        <uf-autocomplete *ngIf="isMatchAgainst(recipient)" [allowCustom]="true"
                                            [options]="filteredUserClaims" [control]="matchAgainstControl | asUfControl"
                                            (searchChange)="searchUserClaims($event)" label="User Claim"
                                            placeholder="Search Claims" class="col-6" />
                                    </ng-container>

                                    <!-- Match type = Form Data -->
                                    <ng-container
                                        *ngIf="recipient.get(controlKeys.Claim)?.get(controlKeys.MatchType)?.value === workflowNotificationRecipientClaimMatchType.FormData">
                                        <uf-autocomplete
                                            *ngIf="recipient.get(controlKeys.Claim)?.get(controlKeys.Value); let claimValueControl"
                                            [allowCustom]="false" [options]="filteredClaimDataSources"
                                            [control]="claimValueControl | asUfControl"
                                            (searchChange)="searchClaim($event)" label="Claim DataSource"
                                            placeholder="Search Claims" class="col-6" />
                                    </ng-container>

                                </ng-template>

                                <!-- Combo -->
                                <div *ngIf="recipient.get(controlKeys.Type)?.value === workflowNotificationRecipientType.Combo"
                                    class="col-12 uf-grid">
                                    <ng-container
                                        *ngIf="recipient.get(controlKeys.Conditions); let recipientConditions">
                                        <div *ngFor="let conditionGroup of (recipientConditions | asUfControlArray).controls; let ci = index"
                                            class="uf-box flat col-12 pad uf-grid">

                                            <!-- Condition Role -->
                                            <uf-autocomplete
                                                *ngIf="$any(conditionGroup).get(controlKeys.Type)?.value === workflowNotificationConditionType.Role"
                                                [options]="filteredRoles"
                                                [control]="$any(conditionGroup).get(controlKeys.Role)"
                                                (searchChange)="searchRole($event)" label="Role"
                                                placeholder="Select a role" class="col-11" />

                                            <!-- Condition Company -->
                                            <uf-autocomplete
                                                *ngIf="$any(conditionGroup).get(controlKeys.Type)?.value === workflowNotificationConditionType.Company && !isUserManagementNotification"
                                                [options]="filteredCompanyDataSources"
                                                [control]="$any(conditionGroup).get(controlKeys.Company)"
                                                (searchChange)="searchCompanyDataSource($event)" label="Company"
                                                placeholder="Select a company" class="col-11" />

                                            <!-- Condition Hierarchy -->
                                            <ng-template
                                                [ngIf]="$any(conditionGroup).get(controlKeys.Type)?.value === workflowNotificationConditionType.Hierarchy">
                                                <uf-autocomplete *ngIf="!isUserManagementNotification"
                                                    [options]="filteredHierarchyFields"
                                                    [control]="$any(conditionGroup).get(controlKeys.FormData)"
                                                    (searchChange)="searchHierarchyField($event)"
                                                    valueProperty="identifier" nameProperty="name"
                                                    label="Hierarchy Field" placeholder="Select a Field"
                                                    class="col-12" />

                                                <uf-hierarchy-unit
                                                    [control]="$any(conditionGroup).get(controlKeys.HierarchyUnit)"
                                                    [selectLeafsOnly]="false" label="Hierarchy Unit" placeholder="Root"
                                                    class="col-11" />
                                            </ng-template>

                                            <!-- User Hierarchy -->
                                            <uc-workflow-notification-hierarchy-field
                                                *ngIf="$any(conditionGroup).get(controlKeys.Type)?.value === workflowNotificationConditionType.UserHierarchy"
                                                [fields]="hierarchyFields"
                                                [controlGroup]="conditionGroup | asUfControlGroup" class="col-11" />

                                            <!-- Condition Claim -->
                                            <ng-template
                                                [ngIf]="$any(conditionGroup).get(controlKeys.Type)?.value === workflowNotificationConditionType.Claim">
                                                <div class="col-11 uf-grid">
                                                    <uf-autocomplete
                                                        [control]="$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.Type)"
                                                        [allowCustom]="true" [options]="filteredUserClaims"
                                                        (searchChange)="searchUserClaims($event)"
                                                        (valueChange)="changeClaim($event, $any(conditionGroup).get(controlKeys.Claim))"
                                                        label="Claim" placeholder="Search Claims" class="col-5" />

                                                    <uf-select [options]="claimMatchOptions"
                                                        *ngIf="!!$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.MatchType)"
                                                        [control]="$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.MatchType)"
                                                        valueProperty="identifier" nameProperty="name"
                                                        placeholder="Select a match type" label="Match Type"
                                                        class="col-6" />

                                                    <!-- Match type = Form Data -->
                                                    <uf-autocomplete
                                                        *ngIf="$any(conditionGroup).get(controlKeys.Claim)?.get(controlKeys.MatchType)?.value === workflowNotificationRecipientClaimMatchType.FormData"
                                                        [allowCustom]="false"
                                                        [options]="filteredFormDataClaimDataSources"
                                                        [control]="$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.Value)"
                                                        (searchChange)="searchFormDataClaim($event)"
                                                        label="Claim DataSource" placeholder="Search Claims"
                                                        class="col-6" />

                                                    <!-- Match type = Value - Claim Editor -->
                                                    <uf-claim-field *ngIf="isValueClaim($any(conditionGroup))"
                                                        [control]="$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.Value)"
                                                        [claimConfig]="$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.ClaimConfig).value"
                                                        class="col-5" />

                                                    <!-- Match Type = CreatedBy/LastModifiedBy -->
                                                    <uf-autocomplete *ngIf="isMatchAgainst($any(conditionGroup))"
                                                        [allowCustom]="true" [options]="filteredUserClaims"
                                                        [control]="$any(conditionGroup).get(controlKeys.Claim).get(controlKeys.MatchAgainst)"
                                                        (searchChange)="searchUserClaims($event)" label=" User Claim"
                                                        placeholder="Search Claims" class="col-5" />
                                                </div>

                                            </ng-template>

                                            <button (click)="recipientConditionsControls(i).removeAt(ci)"
                                                title="Remove Condition"
                                                class="col-1 uf-action tertiary center grow right" type="button">
                                                <uf-icon name="delete" />
                                            </button>
                                        </div>
                                    </ng-container>

                                    <div *ngIf="recipientTypeControl(i).value === workflowNotificationRecipientType.Combo"
                                        class="col-12 right row">
                                        <uf-error
                                            *ngIf="recipient.get(controlKeys.TwoConditions); let twoConditionsControl"
                                            [control]="twoConditionsControl | asUfControl" class="gap-right" />
                                        <ng-container
                                            *ngIf="recipient.get(controlKeys.Conditions); let recipientConditions">
                                            <button [options]="conditionTypes"
                                                (optionSelected)="addCondition($event, $any(recipientConditions))"
                                                type="button" listBox nameProperty="name"
                                                class="uf-button x-small right gap-sm-top">
                                                Add Condition
                                                <uf-icon name="more" class="gap-none" />
                                            </button>
                                        </ng-container>
                                    </div>
                                </div>

                                <!-- End of Combo -->

                                <uf-checkbox *ngIf="recipient.get(controlKeys.LiveOnly); let liveOnlyControl"
                                    [control]="liveOnlyControl | asUfControl" label="LiveOnly" class="col-12" />
                            </div>
                        </uf-expander>
                    </div>
                    <div class="pad border-top">
                        <button [options]="recipientTypes" [disabled]="!canAddRecipient"
                            (optionSelected)="addRecipient($event)" listBox type="button" nameProperty="name"
                            class="uf-button x-small right gap-sm-top">
                            Add Recipient
                            <uf-icon name="more" class="gap-none" />
                        </button>
                    </div>
                </div>

                <!-- Messages -->
                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">

                        <div class="title">
                            Email Notification
                        </div>

                        <button *ngIf="hasEmail?.value" (click)="showGlossary(workflowNotificationDeliveryMethod.Email)"
                            type="button" title="Glossary" class="uf-action tertiary">
                            <uf-icon name="info" />
                        </button>
                        <button *ngIf="hasEmail?.value"
                            (click)="previewMessage(workflowNotificationDeliveryMethod.Email)" type="button"
                            title="Preview" class="uf-action tertiary">
                            <uf-icon name="view" />
                        </button>
                        <button *ngIf="arePushNotificationsEnabled"
                            [title]="hasEmail.value ? 'Disable Email Notifications' : 'Enable Email Notifications'"
                            (click)="hasEmail.setValue(!hasEmail.value);" type="button" class="uf-action tertiary">
                            <uf-icon *ngIf="!hasEmail.value" name="checkbox" />
                            <uf-icon *ngIf="hasEmail.value" name="checkboxTick" />
                        </button>

                    </div>

                    <div *ngIf="hasEmail.value" class="uf-grid notification-type pad">
                        <uf-text [value]="email.title.value" [control]="email.title" label="Title" class="col-12" />
                        <uf-markdown-editor #emailMarkdownEditor [control]="email.body" label="Message"
                            class="col-12" />
                        <ng-template *ngIf="!isUserManagementNotification" ufFeatureFlag="pdfRendering">
                            <uf-checkbox [control]="email.attachFormAsPdf" label="Attach form as PDF" class="col-12" />
                        </ng-template>
                        <uf-text [control]="email.replyTo" label="Reply to" class="col-12">
                            <uf-help content="Comma separate multiple email addresses." />
                        </uf-text>
                    </div>

                </div>

                <ng-template [ngIf]="arePushNotificationsEnabled">
                    <div class="uf-box col-12">
                        <div class="uf-app-bar flat accent">
                            <div class="title">
                                Push Notification
                            </div>

                            <button *ngIf="hasPushNotification?.value"
                                (click)="showGlossary(workflowNotificationDeliveryMethod.Push)" type="button"
                                class="uf-action tertiary" title="Glossary">
                                <uf-icon name="info" />
                            </button>
                            <button *ngIf="hasPushNotification?.value"
                                (click)="previewMessage(workflowNotificationDeliveryMethod.Push)" type="button"
                                title="Preview" class="uf-action tertiary">
                                <uf-icon name="view" />
                            </button>
                            <button
                                [title]="hasPushNotification.value ? 'Disable Push Notification Notifications' : 'Enable Push Notification Notifications'"
                                (click)="hasPushNotification.setValue(!hasPushNotification.value);" type="button"
                                class="uf-action tertiary">
                                <uf-icon *ngIf="!hasPushNotification.value" name="checkbox" />
                                <uf-icon *ngIf="hasPushNotification.value" name="checkboxTick" />
                            </button>

                        </div>
                        <div *ngIf="hasPushNotification.value" class="uf-grid notification-type pad">
                            <uf-text [control]="pushNotification.title" label="Title" class="col-12" />
                            <uf-textarea #pushTextArea [control]="pushNotification.body" label="Message"
                                class="col-12" />
                        </div>
                    </div>
                </ng-template>

                <uf-error *ngIf="root.get(controlKeys.AtLeastOneNotification); let atLeastOneNotification;"
                    [control]="atLeastOneNotification | asUfControl" class="col-12" />
            </ng-container>
        </div>
    </div>

</uf-panel>