import { ContentFieldComponent } from './content-field.component';
import { FileListComponent } from './file-list.component';
import { GroupInputComponent } from './group-input.component';
import { ImageListComponent } from './image-list.component';
import { LinkListComponent } from './link-list.component';
import { LinkComponent } from './link.component';
import { COMPONENTS_CONTENT_MODALS } from './modals';
import { SoundListComponent } from './sound-list.component';
import { VideoListComponent } from './video-list.component';

export const COMPONENTS_CONTENT = [
    COMPONENTS_CONTENT_MODALS,
    ContentFieldComponent,
    FileListComponent,
    GroupInputComponent,
    ImageListComponent,
    LinkListComponent,
    LinkComponent,
    SoundListComponent,
    VideoListComponent,
];

export * from './modals';
export * from './content-field.component';
export * from './content-list';
export * from './content-model';
export * from './content-value';
export * from './content';
export * from './file-list.component';
export * from './group-input.component';
export * from './image-list.component';
export * from './link-list.component';
export * from './link.component';
export * from './sound-list.component';
export * from './video-list.component';
