<uf-expander class="uf-box flat">

    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Variations</div>
        <uf-icon *ngIf="variations.invalid" name="error" class="error" />
        <uc-expander-controls *ngIf="variations.length" [container]="variationsWrap" />
    </div>

    <div #variationsWrap expanderBody class="pad-sm">

        <uc-drag-list [items]="variations.controls" canDrop="false" canReorder="true">

            <ng-template [ngForOf]="variations.controls | asUfControlsGroups" ngFor let-variation let-i="index">
                <uf-expander [dragDisabled]="variations.disabled" [isExpanded]="expandeds[i]" dragItem
                    class="uf-box flat draggable gap-sm-bottom">
                    <div expanderHeader dragHandle class="uf-app-bar flat">
                        <div class="title primary">
                            {{ variation.get(variationKeys.Name)?.value }}
                        </div>
                        <uf-icon *ngIf="variation.invalid" name="error" class="error" />
                        <button *ngIf="!variation.disabled" (click)="removeVariation(i)" type="button" class="uf-action"
                            title="Delete">
                            <uf-icon name="delete" />
                        </button>
                    </div>
                    <div [formGroup]="variation" expanderBody class="uf-grid pad">
                        <uf-message *ngIf="showVariationError(variation)" icon="errorSolid"
                            class="x-small col-12 error">
                            <p>This variation has an error - please select it for more information.</p>
                        </uf-message>
                        <uf-text [formControlName]="variationKeys.Name" label="Name" class="col-12" />
                        <uf-textarea [formControlName]="variationKeys.Condition" label="Condition" class="col-12" />
                    </div>
                </uf-expander>
            </ng-template>

        </uc-drag-list>

        <button *ngIf="!control.disabled" (click)="addVariation()" type="button"
            class="uf-button x-small right gap-sm-top">
            Add Variation
        </button>

    </div>
</uf-expander>