import { SourceConfig, SourceConfigBucket, SourceConfigCollection, SourceConfigExternal, SourceConfigUser, SourceConfigUserClaims } from '@unifii/library/common';
import { DataSourceType } from '@unifii/sdk';

export type FilterEditorSupportedSourceConfig =
    SourceConfigUser |
    SourceConfigCollection |
    SourceConfigBucket |
    SourceConfigUserClaims |
    SourceConfigExternal;

export const filterEditorSupportSourceConfig = (sourceConfig: SourceConfig | null | undefined): sourceConfig is FilterEditorSupportedSourceConfig =>
    !!sourceConfig &&
    (sourceConfig.type === DataSourceType.Users ||
    sourceConfig.type === DataSourceType.Collection ||
    sourceConfig.type === DataSourceType.Bucket ||
    sourceConfig.type === DataSourceType.UserClaims ||
    sourceConfig.type === DataSourceType.External);
