import { Inject, Injectable } from '@angular/core';
import { TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import { DataDisplayService, FilterEntries, FilterEntry, FilterValue, HierarchyUnitProvider, TableConfig, TableConfigColumn, getDefaultTableConfig } from '@unifii/library/common';
import { Subject } from 'rxjs';

import { AuditEntry, UcAuditLog } from 'client';
import { TABLE_SEARCH_MIN_LENGTH } from 'constant';
import { AuditLogDataSource } from 'pages/audit-log/audit-log-datasource';

@Injectable()
export class AuditLogTableManager implements TableContainerManager<AuditEntry, FilterValue, FilterEntry> {

    tableConfig: TableConfig<AuditEntry>;
    showSearch = true;
    searchMinLength = TABLE_SEARCH_MIN_LENGTH;
    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    inputManager: TableInputManager<FilterValue, FilterEntry>;

    constructor(
        @Inject(FilterEntries) entries: FilterEntry[],
        @Inject(HierarchyUnitProvider) hierarchyUnitProvider: HierarchyUnitProvider,
        @Inject(DataDisplayService) private dataDisplayService: DataDisplayService,
        private uCAuditLog: UcAuditLog,
    ) {
        this.inputManager = new TableInputManager(entries, hierarchyUnitProvider, null, null);

        const columns = this.getColumns();

        this.tableConfig = getDefaultTableConfig(columns, 'audit-log');
    }

    createDataSource(inputs: TableInputs<FilterValue> = {}) {
        const { q, when, ...query } = this.inputManager.serializeInputs(inputs);

        if (q) {
            query.login = q;
        }

        if (when) {
            const [from, to] = (when as string).split(',');

            if (from) {
                query.from = from;
            }
            if (to) {
                query.to = to;
            }
        }

        return new AuditLogDataSource(this.uCAuditLog, query ?? {});
    }

    private getColumns(): TableConfigColumn<AuditEntry>[] {
        return [{
            name: 'at',
            label: 'When',
            value: (v) => this.dataDisplayService.displayAsString(v.at),
        }, {
            name: 'login',
            label: 'Login',
        }, {
            name: 'sourceIp',
            label: 'Source IP',
        }, {
            name: 'resource',
            label: 'Resource',
        }, {
            name: 'action',
            label: 'Action',
        }];
    }

}

