import { PROJECT_SETTINGS_EXTERNAL_CALL_COMPONENTS } from './external-call';
import { SettingsGeneralComponent } from './general';
import { SettingsComponent } from './settings.component';
import { SettingsThemingComponent } from './theming';

export * from './external-call';
export * from './general';
export * from './theming';
export * from './model';
export * from './settings.component';

export const PROJECT_SETTINGS_COMPONENTS = [
    ...PROJECT_SETTINGS_EXTERNAL_CALL_COMPONENTS,
    SettingsGeneralComponent,
    SettingsThemingComponent,
    SettingsComponent,
];
