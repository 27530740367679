<uf-expander *ngIf="ready" class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Nested Fields</div>
        <uf-icon *ngIf="isInvalid" name="error" class="error" />
    </div>

    <div [formGroup]="control" expanderBody class="uc-group-content grid">

        <!-- Address autocomplete -->
        <ng-template [ngIf]="field.type === fieldTypes.Address">
            <uf-checkbox [formControlName]="fieldKeys.AddressAutocomplete" label="Enable search"
                class="col-1of1--no-margin" />
        </ng-template>

        <!-- Nested Fields -->
        <div [formGroup]="nestedControlGroup">
            <div *ngFor="let controlName of nestedControlNames" [formGroupName]="controlName"
                class="col-1of1--no-margin">
                <div class="fieldset-item--x-small">
                    <span>{{ getLabel(controlName) | toDisplayName }}</span>
                </div>
                <div class="row space-children">
                    <uf-checkbox [formControlName]="nestedKeys.Visible" label="Visible" />
                    <uf-checkbox [formControlName]="nestedKeys.ReadOnly" label="Read Only" />
                    <uf-checkbox [formControlName]="nestedKeys.Required" label="Required" class="hideError" />
                </div>
                <uf-error [formControlName]="nestedKeys.Required" />
            </div>
        </div>

    </div>

</uf-expander>