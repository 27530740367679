import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ToastService, UfControl, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { Dictionary, FormStyle, Option, Theme } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { UcProject, UcProjectInfo } from 'client';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { EditData } from 'components/common/edit-data';
import { ContextService } from 'services/context.service';

import { SettingsComponent } from '../settings.component';

const DEFAULT_BORDER_STYLE = '1px solid #cccccc';

const PRESET_THEMES: Dictionary<Theme> = {
    green: {
        brand: '#262626',
        brandAccent: '#177E89',
        group: '#336d79',
        groupChild: '#ABBFC3',
        groupSecondLevel: '#3b7e8c',
        groupThirdLevel: '#71a0aa',
        groupFourthLevel: '#dbe7ea',
        primaryAction: '#177E89',
        primaryActionDisabled: '#8BBEC4',
        primaryActionHover: '#39919A',
        primaryActionPressed: '#115E66',
        secondaryAction: '#FFFFFF',
        secondaryActionDisabled: '#FFFFFF',
        secondaryActionHover: '#EEF6F6',
        secondaryActionPressed: '#C4D8DC',
        info: '#325786',
        success: '#017E3C',
        warning: '#FBA600',
        error: '#AF1211',
        borderRadius: '4px',
        borderRadiusButton: '4px',
        inputLabel: '#177E89',
    },
    blue: {
        brand: '#262626',
        brandAccent: '#2957A3',
        group: '#2957A3',
        groupChild: '#EAEEF6',
        groupSecondLevel: '#5479B5',
        groupThirdLevel: '#7F9AC8',
        groupFourthLevel: '#BFCDE3',
        primaryAction: '#2957A3',
        primaryActionDisabled: '#EAEEF6',
        primaryActionHover: '#234A8A',
        primaryActionPressed: '#17315B',
        secondaryAction: '#FFFFFF',
        secondaryActionDisabled: '#FFFFFF',
        secondaryActionHover: '#EAEEf7',
        secondaryActionPressed: '#BFCDE3',
        info: '#3257C7',
        success: '#017E3C',
        warning: '#FBA600',
        error: '#AF1211',
        borderRadius: '4px',
        borderRadiusButton: '4px',
        inputLabel: '#2957A3',
    },
};

const DefaultInputStyle = {
    inputBackground: 'transparent',
    inputBorderRadius: '0px',
    inputPaddingRight: '0',
    inputPaddingLeft: '0',
    inputBorderTop: 'none',
    inputBorderRight: 'none',
    inputBorderLeft: 'none',
    inputIconMarginRight: '0',
    labelPaddingBottom: '0',
    inputIconColour: '#737373',
};

const OutlinedInputStyle = {
    inputBackground: 'white',
    inputBorderRadius: '4px',
    inputPaddingLeft: '8px',
    inputBorderTop: DEFAULT_BORDER_STYLE,
    inputBorderRight: DEFAULT_BORDER_STYLE,
    inputBorderLeft: DEFAULT_BORDER_STYLE,
    inputIconMarginRight: '8px',
    labelPaddingBottom: '6px',
    inputIconColour: '#888888',
};

const INPUT_STYLES: Option[] = [
    { name: 'Default', identifier: 'default' },
    { name: 'Outlined', identifier: 'outlined' },
];

const FORM_STYLES: Option[] = [
    { name: 'Summary', identifier: FormStyle.Summary },
    { name: 'Full Form', identifier: FormStyle.FullForm },
];

@Component({
    selector: 'uc-settings-theming',
    templateUrl: './settings-theming.html',
    styleUrls: ['./settings-theming.less'],
})
export class SettingsThemingComponent implements OnInit, OnDestroy, EditData {

    protected readonly inputStyleOptions: Option[] = INPUT_STYLES;
    protected readonly formStyleOptions = FORM_STYLES;
    protected project: UcProjectInfo;
    protected form: UfControlGroup;
    protected inputStyle: string;

    private toastService = inject(ToastService);
    private context = inject(ContextService);
    private ucProject = inject(UcProject);
    private parent = inject(SettingsComponent);
    private builderHeaderService = inject(BuilderHeaderService);
    private ufb = inject(UfFormBuilder);
    private subscriptions = new Subscription();

    set edited(v: boolean) {
        this.parent.edited = v;
    }

    get edited() {
        return this.parent.edited;
    }

    ngOnInit() {
        const requiredValidator = ValidatorFunctions.required('A value is required');

        this.form = new UfControlGroup({
            // App Theme
            brand: new UfControl(requiredValidator),
            brandAccent: new UfControl(requiredValidator),
            primaryAction: new UfControl(requiredValidator),
            primaryActionDisabled: new UfControl(requiredValidator),
            primaryActionHover: new UfControl(requiredValidator),
            primaryActionPressed: new UfControl(requiredValidator),
            secondaryAction: new UfControl(requiredValidator),
            secondaryActionDisabled: new UfControl(requiredValidator),
            secondaryActionHover: new UfControl(requiredValidator),
            secondaryActionPressed: new UfControl(requiredValidator),
            success: new UfControl(requiredValidator),
            warning: new UfControl(requiredValidator),
            error: new UfControl(requiredValidator),
            info: new UfControl(requiredValidator),
            borderRadius: new UfControl(requiredValidator),
            // Form Theme
            group: new UfControl(requiredValidator),
            groupSecondLevel: new UfControl(requiredValidator),
            groupThirdLevel: new UfControl(requiredValidator),
            groupFourthLevel: new UfControl(requiredValidator),
            groupChild: new UfControl(requiredValidator),
            inputLabel: new UfControl(requiredValidator),
            formStyle: this.ufb.control(FormStyle.Summary, requiredValidator),
        });

        this.inputStyle = Object.assign({}, this.inputStyleOptions[0]?.identifier);
        this.load();

        this.subscriptions.add(this.form.statusChanges.subscribe(() => { this.edited = true; }));
        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe(() => this.save()));

        this.builderHeaderService.buildConfig({
            cancelRoute: ['../'],
            breadcrumbs: this.parent.breadcrumbs,
            lastModifiedAt: this.context.project?.lastModifiedAt,
            lastModifiedBy: this.context.project?.lastModifiedBy,
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.parent.buildHeaderConfig();
    }

    protected applyTheme(identifier: string) {
        this.setTheme(Object.assign({}, PRESET_THEMES[identifier]));
    }

    private setTheme(theme: Theme) {

        for (const key of Object.keys(this.form.controls) as (keyof Theme)[]) {
            const value = theme[key];

            if (value) {
                this.form.controls[key]?.setValue(value);
            }
        }
        // Update form theme
        this.inputStyle = theme.inputBackground === 'white' ? 'outlined' : 'default';
    }

    private save() {

        this.form.setSubmitted(true);

        if (this.form.invalid) {
            return;
        }

        const inputStyles = this.inputStyle === 'outlined' ? OutlinedInputStyle : DefaultInputStyle;

        this.project.theme = Object.assign(inputStyles, this.form.value);

        void this.ucProject.save(this.project).then((p) => {
            this.context.project = p;
            this.project = JSON.parse(JSON.stringify(p));
            this.toastService.success('Changes saved!');
            this.edited = false;

            this.builderHeaderService.updateConfig({
                lastModifiedAt: p.lastModifiedAt,
                lastModifiedBy: p.lastModifiedBy,
            });
        });
    }

    private load() {

        this.project = JSON.parse(JSON.stringify(this.context.project));
        const theme = Object.assign({}, PRESET_THEMES.green, this.normalizeTheme(this.context.project?.theme));

        this.setTheme(theme);
        this.edited = false;
    }

    private normalizeTheme(theme: Theme | undefined): Theme {

        theme = theme ?? {};
        /**
         * // TODO remove!!! once all themes have been re-saved (date:16/4/2019)
         * new color input label has been added to suit specific projects if it doesn't exist
         * use primary action so styles will still look correct
         */
        if (theme.primaryAction != null && theme.inputLabel == null) {
            theme.inputLabel = theme.primaryAction;
        }

        return theme;
    }

}
