import { inject } from '@angular/core';
import { CanDeactivateFn, Params } from '@angular/router';

import { Config } from 'app-config';
import { ContextService } from 'services/context.service';
import { DialogsService } from 'services/dialogs.service';

export interface EditData {
    edited: boolean;
    onLeavePage?: () => Promise<void>;
}

export enum EditMode {
    New, Existing, Duplicate
}

export const getEditMode = (state: Params): EditMode => {

    if (state.id && state.duplicate) {
        return EditMode.Duplicate;
    }

    if (!!state.id && state.id !== 'new') {
        return EditMode.Existing;
    }

    return EditMode.New;
};

export const canDeactivateEditDataComponent: CanDeactivateFn<EditData> = async(component) => {
    /**
         * strange behaviour component is null
         * on 401 the user is redirected to the login which must trigger canDeactive before the
         * component is set
         */
    const config = inject(Config);
    const context = inject(ContextService);

    if (!context.account || config.debug || !component?.edited) {
        return true;
    }

    const confirmLeave = await inject(DialogsService).confirmLeaveWithoutSaving();

    if (confirmLeave && component.onLeavePage) {
        await component.onLeavePage();
    }

    return confirmLeave;
};
