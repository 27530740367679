import { AfterViewInit, Component } from '@angular/core';

import { ContentList, ModalSearchType } from 'components/content/content-list';
import { MediaSearchComponent } from 'components/content/modals/media-search.component';

import { LinkSearchConfig, LinkSearchType } from './modals/link-search.component';

@Component({
    selector: 'uc-sound-list',
    templateUrl: './sound-list.html',
    styleUrls: ['./group-input.less'],
    providers: [{ provide: ModalSearchType, useValue: MediaSearchComponent }],
})
export class SoundListComponent extends ContentList implements AfterViewInit {

    protected getSearchConfig(): LinkSearchConfig {
        return {
            type: LinkSearchType.Audio,
            title: 'Add Sound(s)',
            multiSelect: true,
        };
    }

}
