import { Component, Input, OnInit, inject } from '@angular/core';
import { FieldTypeIcon, ModalService, UfControl, UfControlArray, UfFormBuilder } from '@unifii/library/common';
import { Dictionary } from '@unifii/sdk';

import { MappableField } from 'models';
import { FieldMapperComponent, FieldMappingData } from 'pages/form-editor/field-configuration/field-mapper.component';

import { reduceExpressionToSingleLine } from './workflow-functions';

const sourceKey = 'source';
const targetKey = 'target';
const sourceExpressionKey = 'sourceExpression';

@Component({
    selector: 'uc-field-mapping-table',
    templateUrl: 'field-mapping-table.html',
})
export class FieldMappingTableComponent implements OnInit {

    @Input({ required: true }) control: UfControlArray;
    @Input({ required: true }) targetFields: MappableField[];
    @Input({ required: true }) sourceFields?: MappableField[];

    private sourceFieldsMap?: Dictionary<MappableField | undefined>;
    private targetFieldsMap: Dictionary<MappableField | undefined>;
    private modalService = inject(ModalService);
    private ufb = inject(UfFormBuilder);

    ngOnInit() {
        if (this.sourceFields) {
            this.sourceFieldsMap = Object.fromEntries(this.sourceFields.map((f) => [f.identifier, f]));
        }

        this.targetFieldsMap = Object.fromEntries(this.targetFields.map((f) => [f.identifier, f]));
    }

    protected async inputArgClick(control: UfControl) {

        const { identifier, expression: sourceExpression, field: sourceField } = control.value;
        const target = this.targetFieldsMap[identifier];

        if (!target || !this.sourceFieldsMap) {
            return;
        }

        const data: FieldMappingData = {
            sourceFields: this.sourceFields,
            mappingGroup: {
                control: this.ufb.group({
                    sourceExpression,
                    source: this.sourceFieldsMap[sourceField],
                    target,
                }),
                sourceKey,
                targetKey,
                sourceExpressionKey,
            },
            targetFieldLabel: 'Target Data',
            sourceFieldLabel: 'Source Data Field',
            sourceExpressionLabel: 'Source Expression Field',
        };

        const result = await this.modalService.openMedium(FieldMapperComponent, data);

        control.markAsTouched();

        if (!result) {
            return;
        }

        const rawExpression = result.get(sourceExpressionKey)?.value;
        const expression = typeof rawExpression === 'string' ? reduceExpressionToSingleLine(rawExpression) : undefined;
        const field = result.get(sourceKey)?.value?.identifier;

        control.setValue({
            ...control.value,
            field,
            expression,
        });
    }

    protected getSourceFieldLabel(identifier?: string): string {
        if (!this.sourceFieldsMap || !identifier) {
            return '';
        }

        return this.sourceFieldsMap[identifier]?.label ?? '';
    }

    protected getTargetFieldLabel(identifier: string): string {
        return this.targetFieldsMap[identifier]?.label ?? '';
    }

    protected getSourceFieldIcon(identifier?: string): string | undefined {

        if (!this.sourceFieldsMap || !identifier) {
            return;
        }

        const fieldType = this.sourceFieldsMap[identifier]?.type;

        if (!fieldType) {
            return;
        }

        // TODO - Replace with Library Pipe
        return FieldTypeIcon.get(fieldType);
    }

    protected getTargetFieldIcon(identifier: string): string | undefined {
        const fieldType = this.targetFieldsMap[identifier]?.type;

        if (!fieldType) {
            return;
        }

        // TODO - Replace with Library Pipe
        return FieldTypeIcon.get(fieldType);
    }

}
