<div class="finder-box row">
    <div class="uf-app-bar flat small">
        <div class="title">
            {{label}}
        </div>
        <div class="right row center-all">
            <button *ngIf="showEditColumnButton" (click)="updateGroupLabel.emit()" type="button"
                class="uf-action small tertiary">
                <uf-icon name="edit" />
            </button>
        </div>
    </div>
    <div class="finder-column column">
        <ul class="uf-list col small grow">
            <ng-container *ngIf="units?.length">
                <li *ngFor="let unit of units; let i = index" [class.selected]="selected?.id === unit.id"
                    class="uf-list-button">
                    <div class="list-item row">
                        <div (click)="selectUnit.emit(unit)" class="row grow center-all">
                            {{ mapLabel(unit) }}
                            <div class="right row">
                                <div *ngIf="isInactive(unit)" [tooltipPosition]="tooltipPosition" tooltip="Inactive"
                                    class="center-all right">
                                    <uf-icon class="x-small" name="warningSolid" />
                                </div>
                                <div class="center-all right">
                                    <uf-icon *ngIf="unit.childCount > 0" name="arrowForwardSolid" />
                                </div>
                            </div>
                        </div>
                        <uf-drop-menu [options]="contextOptions" [context]="unit"
                            (selected)="executeContextOption(unit, $event)" class="center-all" />
                    </div>
                </li>
            </ng-container>
        </ul>
        <div *ngIf="!disabled" class="row small pad-sm actions">
            <div class="x-small right">
                <button (click)="add.emit();" type="button" class="uf-action small primary">
                    <uf-icon name="add" />
                </button>
            </div>
        </div>
    </div>