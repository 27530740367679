import { Component, Input, inject } from '@angular/core';
import { ClipboardService, DataDisplayListItem, UfControlGroup } from '@unifii/library/common';
import { StructureNode, StructureNodeType, isString } from '@unifii/sdk';

import { TablesContainerTemplateOptions } from '../structure-constants';
import { StructureNodeControlKeys } from '../structure-control-keys';
import { StructureEditorService } from '../structure-editor.service';
import { StructureFunctions } from '../structure-functions';

@Component({
    selector: 'uc-node-settings',
    templateUrl: './node-settings.html',
})
export class NodeSettingsComponent {

    protected readonly formKeys = StructureNodeControlKeys;
    protected readonly templateOptions = TablesContainerTemplateOptions;
    protected showTemplate: boolean;
    protected ready: boolean;
    protected appUrl: DataDisplayListItem | null;
    protected canCopy = false;
    protected showTags: boolean;
    protected showDefinitionIdentifier: boolean;
    protected showURL: boolean;
    protected tagOptions: string[] = [];

    private _control: UfControlGroup;
    private clipboard = inject(ClipboardService);
    private service = inject(StructureEditorService);

    @Input({ required: true }) set control(v: UfControlGroup) {

        this._control = v;

        // Reset
        this.ready = false;

        this.canCopy = this.service.canCopy(this.node, v) && [StructureNodeType.IFrame].includes(this.node.type);
        this.showTags = [StructureNodeType.View, StructureNodeType.Empty, StructureNodeType.Custom].includes(this.node.type);
        this.showDefinitionIdentifier = this.node.type === StructureNodeType.Custom;
        this.showURL = [StructureNodeType.IFrame, StructureNodeType.Link].includes(this.node.type);
        this.showTemplate = this.node.type === StructureNodeType.Dashboard;

        const appUrl = this.getNodeUrlParts(this.node)?.join('/');

        if (appUrl) {
            this.appUrl = { term: 'App URL', data: appUrl };
        }

        this.ready = true;
    }

    get control() {
        return this._control;
    }

    protected get node(): StructureNode {
        return this.control.getRawValue() as StructureNode;
    }

    protected get isInvalid(): boolean {
        const invalidName = this.control.get(StructureNodeControlKeys.Name)?.invalid ?? false;
        const invalidUrl = this.showURL && (this.control.get(StructureNodeControlKeys.Url)?.invalid ?? false);
        const invalidIdentifier = this.showDefinitionIdentifier && (this.control.get(StructureNodeControlKeys.DefinitionIdentifier)?.invalid ?? false);

        return invalidName || invalidUrl || invalidIdentifier;
    }

    protected copy() {
        this.service.copyAndInsert(this.node, this.control);
    }

    protected copyAppURL() {
        if (this.appUrl?.data && isString(this.appUrl.data)) {
            void this.clipboard.setText(this.appUrl.data);
        }
    }

    protected searchTags() {
        this.tagOptions = [];
    }

    private getNodeUrlParts(node: StructureNode): string[] | undefined {

        if (!node.nodeId || StructureFunctions.isNodeRoot(node) || [StructureNodeType.Empty, StructureNodeType.Link].includes(node.type)) {
            return;
        }

        const prefix = ['/n', node.nodeId];

        switch (node.type) {
            case StructureNodeType.Page:
            case StructureNodeType.View:
            case StructureNodeType.Collection:
            case StructureNodeType.Form:
            case StructureNodeType.FormBucket:
                return node.definitionIdentifier ? [...prefix, node.definitionIdentifier] : undefined;
            case StructureNodeType.CollectionItem:
                return node.definitionIdentifier && node.id != null ? [...prefix, node.definitionIdentifier, `${node.id}`] : undefined;
            case StructureNodeType.Dashboard:
                return [...prefix, 'dashboard'];
            case StructureNodeType.Custom:
                return node.definitionIdentifier ? [...prefix, 'custom', node.definitionIdentifier] : undefined;
            case StructureNodeType.IFrame:
                return [...prefix, 'iframe'];
            case StructureNodeType.PdfViewer:
                return node.id ? [...prefix, 'pdf-viewer', `${node.id}`] : undefined;
            default:
                return [...prefix];
        }
    }

}
