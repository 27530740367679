import { ClientDeleteOptions, ClientGetOptions, amendOptionsParams, mergeParams } from '@unifii/sdk';

import { PermissionPrincipalType, UcPermission } from './models';
import { UcClient } from './uc-client';

export class UcPermissionsClient {

    constructor(
        private client: UcClient,
        public principalType: PermissionPrincipalType,
        public principalId: string,
        public inherited?: boolean,
    ) { }

    get(options?: ClientGetOptions): Promise<UcPermission[]> {
        const params = mergeParams(options?.params);

        if (this.principalType === PermissionPrincipalType.User) {
            params.source = this.inherited ? 'inherited' : 'user';
        }

        return this.client.get(this.url(), amendOptionsParams(params, options)) as Promise<UcPermission[]>;
    }

    save(permission: UcPermission): Promise<UcPermission> {
        return (permission.id ?
            this.client.put(this.url(permission.id), permission) :
            this.client.post(this.url(), { body: permission })) as Promise<UcPermission>;
    }

    delete(id: string, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url(id), options) as Promise<void>;
    }

    private url(...extra: string[]): string {
        return this.client.buildUrl([this.principalType, this.principalId, 'permissions', ...extra]);
    }

}
