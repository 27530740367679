import { Component, Inject } from '@angular/core';
import { DataDisplayListItem, Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { DefinitionPublishState } from '@unifii/sdk';

export interface PublishStateModal {
    title?: string;
    publishState?: DefinitionPublishState;
    lastModified?: string | null;
    lastPublished?: string | null;
}

@Component({
    selector: 'uc-publish-info',
    templateUrl: './publish-info.html',
})
export class PublishInfoComponent implements Modal<PublishStateModal, void> {

    modifiedPublishedDescriptionList: DataDisplayListItem[] = [];

    constructor(
        public runtime: ModalRuntime<PublishStateModal, void>,
        @Inject(ModalData) public data: PublishStateModal,
    ) {
        this.modifiedPublishedDescriptionList = [];
        if (data.lastModified) {
            this.modifiedPublishedDescriptionList.push({ term: 'Last Modified', data: data.lastModified });
        }

        if (data.lastPublished) {
            this.modifiedPublishedDescriptionList.push({ term: 'Last Published', data: data.lastPublished });
        }
    }

}
