<uf-panel class="container">
    <div *ngIf="!loading" class="header-pane uf-app-bar transparent">
        <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large">
            <span *ngIf="edited"> *</span>
        </uf-breadcrumbs>
        <div class="row header-buttons right">
            <a [routerLink]="['../']" class="uf-button tertiary">Cancel</a>
            <button (click)="delete()" restrict="AssetManager" type="button" class="uf-button">Delete</button>
            <uc-save-options [options]="saveOptions" (onSave)="save($event)" restrict="AssetManager" />
        </div>
    </div>

    <uf-panel class="content-pane">
        <div *ngIf="!loading" [formGroup]="detailsForm" class="uf-container-lg">
            <div class="uf-grid row-gap-lg pad-top">
                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">
                            Details
                        </div>
                    </div>
                    <div class="uf-grid pad">
                        <div *ngIf="error" class="col-12">
                            <uf-blockquote [content]="error.message || 'Unknown Error'" icon="errorSolid"
                                class="error-text" />
                        </div>
                        <div [class.checkboard-bg]="media.type === mediaType.Image" class="col-12 media-detail-preview">
                            <img *ngIf="media.type === mediaType.Image" src="{{getUrl(640, 320)}}"
                                (click)="viewFullSizeImage()" />
                            <video *ngIf="media.type === mediaType.Video" poster="{{getUrl(640, 320)}}" width="640"
                                controls>
                                <source src="{{media.url}}" type="video/mp4"> Your browser doesn't support HTML5
                                video.
                            </video>
                            <audio *ngIf="media.type === 'Audio'" controls="controls">
                                Your browser does not support the
                                <code>audio</code> element.
                                <source src="{{media.url}}" type="audio/mp3">
                            </audio>
                        </div>

                        <uf-data-display-list class="col-12">
                            <dt>Filename</dt>
                            <dd>{{media.filename}}</dd>
                            <dt>Actual Image size</dt>
                            <dd>{{media.width}} x {{media.height}} px</dd>
                            <dt>Upload Date</dt>
                            <dd>{{media.uploadedAt | dataDisplay}}</dd>
                        </uf-data-display-list>

                        <div class="col-12">
                            <button (click)="download()" type="button" class="uf-button primary">Download</button>
                        </div>
                        <uf-text *ngIf="detailsForm.controls.titleControl"
                            [control]="detailsForm.controls.titleControl | asUfControl" [(value)]="media.title"
                            label="Title" name="title" class="col-12" />

                        <uf-textarea *ngIf="detailsForm.controls.descriptionControl"
                            [control]="detailsForm.controls.descriptionControl | asUfControl"
                            [(value)]="media.description" label="Description" name="description" class="col-12" />

                        <uf-text *ngIf="detailsForm.controls.authorControl"
                            [control]="detailsForm.controls.authorControl | asUfControl" [(value)]="media.author"
                            label="Author" name="author" class="col-12" />

                        <uf-text *ngIf="detailsForm.controls.creditsControl"
                            [control]="detailsForm.controls.creditsControl | asUfControl" [(value)]="media.credits"
                            name="credits" label="Credits" class="col-12" />
                    </div>
                </div>
            </div>
        </div>
    </uf-panel>
</uf-panel>

<uc-error-message [error]="error" />