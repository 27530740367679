<uf-expander *ngIf="ready" class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Display</div>
        <uf-icon *ngIf="isInvalid" name="error" class="error" />
    </div>
    <div [formGroup]="control" expanderBody class="uf-grid pad-sides pad-bottom pad-sm-top">

        <uc-markdown *ngIf="showHelpText" [formControlName]="fieldKeys.Help" [showAlignmentOptions]="true"
            label="Help text" class="col-12" />

        <uf-text *ngIf="meta.placeholder && showPlaceholder" [formControlName]="fieldKeys.Placeholder"
            label="Placeholder" class="col-12" />

        <uf-text *ngIf="meta.itemLabel && showItemLabel" [formControlName]="fieldKeys.ItemLabel" label="Item label"
            class="col-12" />

        <uf-text *ngIf="meta.addButtonLabel" [formControlName]="fieldKeys.AddButtonLabel" label="Add button label"
            class="col-12" />

        <div *ngIf="meta.columnVisibility && showColumnVisibility" class="col-12">
            <label class="x-small">Column visibility</label>
            <div class="pad-sm-top">
                <ul class="uf-list uf-box flat column-toggles-list">
                    <ng-template [ngForOf]="fields" ngFor let-child>
                        <ng-container *ngIf="child.get(fieldKeys.Identifier)?.value as childIdentifier">
                            <li class="row center-all space-children pad-sm-sides">
                                <div class="grow">
                                    <p class="small">{{child.get(fieldKeys.Label)?.value}}</p>
                                </div>
                                <uf-checkbox [value]="!$any(field.columnVisibility?.hideOnMobile)[childIdentifier]"
                                    (valueChange)="updateShowFlag(true, childIdentifier)"
                                    label="Mobile" />
                                <uf-checkbox [value]="!$any(field.columnVisibility?.hideOnDesktop)[childIdentifier]"
                                    (valueChange)="updateShowFlag(false, childIdentifier)"
                                    label="Desktop" />
                            </li>
                        </ng-container>
                    </ng-template>
                </ul>
            </div>
        </div>

        <!-- TODO [disabled]="field.template === fieldTemplate.RadioWithContent" -->
        <uf-select *ngIf="meta.width" [formControlName]="fieldKeys.Width" [options]="widthOptions" label="Field width"
            valueProperty="identifier" nameProperty="name" class="col-12" />

        <!-- TODO [disabled]="!field.width || field.width === fieldWidth.Stretch" -->
        <uf-checkbox [formControlName]="fieldKeys.BreakAfter"
            *ngIf="meta.breakAfter && field.width !== fieldWidths.Stretch" label="Break after" class="col-12" />

        <uf-select *ngIf="meta.columnCount && showColumnCount" [formControlName]="fieldKeys.ColumnCount"
            [options]="columnCountOptions" label="Columns" valueProperty="identifier" nameProperty="name"
            class="col-12" />

        <uf-select *ngIf="meta.layoutDirection && showLayoutDirection" [formControlName]="fieldKeys.LayoutDirection"
            [options]="layoutDirectionOptions" label="Direction" valueProperty="identifier" nameProperty="name"
            class="col-12" />

        <uf-chips *ngIf="meta.visibleTo" [options]="rolesResult" [formControlName]="fieldKeys.VisibleTo"
            (searchChange)="findRoles($event)" label="Visible to" class="col-12" />

    </div>
</uf-expander>