<uf-expander [class.uc-group--draggable--no-shrink]="canDragField && !isSelected"
    [class.uc-group--draggable--no-shrink--accent]="canDragField && isSelected" class="uc-group">
    <div [class.accent]="isSelected" (click)="selectField($event)" expanderHeader dragHandle expanderHeader
        class="uf-app-bar flat">
        <uf-icon name="fileList" />
        <div class="title primary">
            <span>File List</span>
            <span *ngIf="configuredField.label"> | {{configuredField.label}}</span>
        </div>
        <uf-help *ngIf="configuredField.help" [content]="configuredField.help" class="uf-action tertiary" />
        <a *ngIf="canRemoveField" (click)="removeField($event)" title="Delete" class="uf-action tertiary">
            <uf-icon name="delete" />
        </a>
    </div>
    <div [class.content--accent]="isSelected" expanderBody class="uc-group-content">
        <uc-drag-list *ngIf="content" [canReorder]="canEditField" [items]="content" [parent]="content" (moved)="moved()"
            canDrop="false">
            <ul class="uc-list--gap">
                <li [class.uc-list-item--small--no-shrink--bordered]="disabled"
                    [class.uc-list-item--draggable--small--no-shrink]="!disabled" [dragDisabled]="disabled"
                    *ngFor="let file of content, let i = index" class="row center-all" dragItem>
                    <div class="list-content">
                        <div class="list-context">
                            <a [routerLink]="['/assets', 'media',file.id]" target="_blank" title="Open"
                                class="uf-action tertiary">
                                <uf-icon name="open" />
                            </a>
                            <button *ngIf="canEditField" (click)="delete(i)" type="button" tabindex="-1" title="Delete"
                                class="uf-action tertiary">
                                <uf-icon name="delete" />
                            </button>
                        </div>
                        <div>{{file.title}}</div>
                        <div class="fieldset-item--x-small">
                            <span>File name: </span>
                            <span>{{file.filename}}</span>
                        </div>
                        <div class="fieldset-item--x-small">
                            <span>Description: </span>
                            <span>{{file.description}}</span>
                        </div>
                        <div class="fieldset-item--x-small">
                            <span>Uploaded at: </span>
                            <span>{{ file.uploadedAt | date:'short' }}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </uc-drag-list>
        <div class="row padded">
            <button *ngIf="canEditField" (click)="add()" type="button" class="uf-button x-small right">Add File</button>
        </div>
    </div>
</uf-expander>
<uf-error [control]="control" />