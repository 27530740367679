<ng-container *ngIf="inputArgsControl.length">
    <div class="uf-app-bar flat">
        <div class="title">
            Parameters
        </div>
    </div>

    <div class="uf-grid pad">
        <!-- TODO Restore once the wording has been decided
            <uf-blockquote icon="infoSolid"
            content="These values must be set before the Data Source will function. They can be “Constant” ie. configured in Project Settings and set automatically for each individual use, “Default” ie. configured in Project Settings but can be manually changed when used, “Form” ie. always changed manually for each use, or “Unset” ie. when a value will never be set."
            class="info col-12" /> -->

        <div class="col-12">
            <table class="uf-table inputs accent">
                <thead>
                    <tr>
                        <th>Integration Parameter</th>
                        <th>Value Source</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let inputArg of inputArgsControl.controls; let i = index"
                        [formGroup]="inputArg | asUfControlGroup">
                        <ng-container *ngTemplateOutlet="row; context: {
                            isRequired: inputArg.get(argControlKeys.IsRequired)?.value,
                            source: inputArg.get(argControlKeys.Source)?.value,
                            type: inputArg.get(argControlKeys.Type)?.value,
                            control: inputArg
                        }" />
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>

<ng-template #row let-control="control" let-isRequired="isRequired" let-source="source" let-type="type">
    <ng-container [formGroup]="control">
        <td class="input-cell">
            <div class="row center-all">
                <div *ngIf="isRequired" class="suffix pad-left">*
                </div>
                <uf-text [disabled]="true" [formControlName]="argControlKeys.Key" class="table-cell grow" />
            </div>
        </td>
        <td class="input-cell">
            <uf-select *ngIf="!isRequired" [options]="sourceTypes" [formControlName]="argControlKeys.Source"
                class="table-cell" />

            <uf-select *ngIf="isRequired" [options]="filteredSourceTypes" [formControlName]="argControlKeys.Source"
                class="table-cell" />
        </td>
        <td class="input-cell">
            <uf-text *ngIf="asSourceTypeInfo(source)" value="{{sourceTypesInfo[source]}}" disabled="true"
                class="table-cell grow" />
            <ng-container *ngIf="source === 'Constant' || source === 'Default'">
                <uf-text *ngIf="type === integrationFeatureTypes.String" [formControlName]="argControlKeys.Value"
                    class="table-cell grow" />
                <uf-number *ngIf="type === integrationFeatureTypes.Number" [formControlName]="argControlKeys.Value"
                    class="table-cell grow" />
            </ng-container>
        </td>
    </ng-container>
</ng-template>