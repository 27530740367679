<div [formGroup]="control" class="grid--no-padding">

    <!-- <pre class="col-1of1">{{filterEntry | json}}</pre> -->

    <!-- Field -->
    <uf-autocomplete [options]="fieldOptions" [formControlName]="controlKeys.Field" [ngClass]="cssClass"
        (searchChange)="search($event)" label="Field" placeholder="Search..." nameProperty="display" class="col-1of2" />

    <!-- Operator -->
    <uf-select *ngIf="filterEntry.field" [options]="operatorOptions" [formControlName]="controlKeys.Operator"
        [ngClass]="cssClass" label="Operator" valueProperty="identifier" class="col-1of2" />

    <!-- ValueType -->
    <uf-select *ngIf="filterEntry.operator" [options]="valueTypeOptions" [formControlName]="controlKeys.ValueType"
        [ngClass]="cssClass" label="Value Type" valueProperty="identifier" class="col-1of2" />

    <ng-template [ngIf]="filterEntry.field && filterEntry.operator">
        <!-- Value -->
        <uc-filter-value *ngIf="filterEntry.valueType === nodeTypes.Value" [field]="filterEntry.field"
            [operator]="filterEntry.operator" [control]="valueControl" cssClass="small" class="col-1of2 small" />
        <!-- Expression -->
        <uf-text *ngIf="filterEntry.valueType === nodeTypes.Expression" [formControlName]="controlKeys.Value"
            [ngClass]="cssClass" label="Expression" class="col-1of2" />
    </ng-template>

    <uf-error [control]="control" class="col-1of1" />

</div>