<div *ngIf="ready" class="pad uf-grid">

    <uf-text *ngIf="form.get(controlKeys.Label) as control" [control]="control | asUfControl" label="Label"
        placeholder="Override default label" class="col-12" />

    <uf-select *ngIf="form.get(controlKeys.Format) as control" [control]="control | asUfControl"
        [options]="formatOptions" label="Format" placeholder="Override default format" valueProperty="_id"
        nameProperty="_display" class="col-12" />

    <uf-select *ngIf="form.get(controlKeys.InputType) as control" [control]="control | asUfControl"
        [placeholder]="dateTimePlaceholder" [options]="dataTimeOptions" label="Input type" valueProperty="_id"
        nameProperty="_display" class="col-12" />

    <uc-sort-property-input *ngIf="form.get(controlKeys.Sort) as control" [control]="control | asUfControl"
        [properties]="sortProperties" label="Sort" class="col-12" />

    <uf-chips *ngIf="form.get(controlKeys.Roles) as control" [control]="control | asUfControl"
        [options]="roleResults" (searchChange)="findRoles($event)" label="Restrict to" placeholder="Search roles"
        class="col-12" />
</div>