<div class="header">
    <button (click)="close()" title="Close" type="button" class="uf-action tertiary">
        <uf-icon name="close" />
    </button>
    <h3>Metadata - Revision {{data.formData._rev}}</h3>
</div>

<div class="container pad">
    <uf-data-display-list [items]="items" class="columns" />
</div>

<div class="row space-children pad">
    <button (click)="close()" type="button" class="uf-button tertiary right">Close</button>
</div>