<ng-container *ngIf="ready && definition && definition.fields">
    <uc-builder-header />

    <div class="builder-content scrollable">
        <uc-field-reference-picker />

        <div class="builder-growing-section">
            <div class="field-list uf-box flat">
                <uc-builder-fields-header />

                <div [entries]="definition.fields" [current]="selected"
                    (next)="selectField({ subject: $event, atomic: false })" keySelect class="nodes-container scrollable grow">

                    <uc-drag-list [items]="definition.fields" [canDrop]="canDrop"
                        [convertAdded]="convertFieldRef" (insert)="inserted($event.item)" (moved)="moved($event.item)"
                        class="scrollable">
                        <ul class="uc-list">
                            <ng-template [ngForOf]="definition.fields" let-field ngFor let-i="index">
                                <uc-collection-field [field]="field" dragItem />
                            </ng-template>
                            <div *ngIf="!definition.fields.length" class="empty">
                                <div>
                                    <img src="/assets/svg/icon-projects.svg">
                                    <p>Drag your items here!</p>
                                </div>
                            </div>
                        </ul>
                    </uc-drag-list>
                </div>
            </div>

            <div class="field-settings">
                <uf-tabs class="scrollable">
                    <uf-tab label="Details">
                        <div class="tab-content scrollable uf-box flat">
                            <uc-collection-settings *ngIf="!selected" [definition]="definition" />
                            <uc-field-builder *ngIf="selected" [field]="selected" />
                        </div>
                    </uf-tab>
                    <uf-tab *ngIf="!selected && previewDefinition" label="Preview">
                        <div class="tab-content scrollable uf-box flat">
                            <uf-field *ngFor="let field of previewDefinition.fields" [scope]="previewScope"
                                [field]="field" />
                        </div>
                    </uf-tab>
                </uf-tabs>
            </div>
        </div>
    </div>
</ng-container>