import { DataSourceLoader, SourceConfig } from '@unifii/library/common';
import { DataSeed } from '@unifii/sdk';

import { IntegrationInfo, UcIntegrations } from 'client';

export class IntegrationLoader implements DataSourceLoader {

    // TODO: Remove this after UNIFII-7371 - as this is not the ideal solution
    sourceConfig: SourceConfig = {} as unknown as SourceConfig;

    constructor(private ucIntegrations: UcIntegrations) { }

    async search(q?: string): Promise<DataSeed[]> {
        return (await this.ucIntegrations.list({ params: { q } })).map(this.mapToSeed);
    }

    findAllBy(match: string): Promise<DataSeed[]> {
        console.warn('datasource does not support findByAll, falling back on search');

        return this.search(match);
    }

    getOptions(): Promise<any> {
        throw new Error('method not implemented');
    }

    async get(id: string): Promise<DataSeed> {
        const { id: integrationId, name } = await this.ucIntegrations.get(id);

        return { _id: integrationId ?? '', _display: name };
    }

    mapToSeed = ({ id, name }: IntegrationInfo): DataSeed => ({ _id: id ?? '', _display: name });

}
