import { TableDataSource } from '@unifii/library/common';

import { UcProjectInfo } from 'client';


export class ProjectsDataSource extends TableDataSource<UcProjectInfo> {

    private _search: string | null = null;
    private projects: UcProjectInfo[];

    constructor(
        private getProjects: () => Promise<UcProjectInfo[]>,
        search?: string
    ) {
        super();

        if (search && search.trim().length) {
            this._search = search.trim().toLowerCase();
        }
    }

    get filtered(): boolean {
        return this._search != null;
    }

    get sorted(): boolean {
        return false;
    }

    async load() {
        try {
            if (!this.projects) {
                this.projects = await this.getProjects();

                if (this._search != null) {
                    this.projects = this.projects.filter(p => p.name.toLowerCase().includes(this._search as string));
                }
            }

            this.stream.next({ data: this.projects });
        } catch (error) {
            this.stream.error({ error });
        }
    }

}
