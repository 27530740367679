<ng-container *ngIf="ready && definition && !error">
    <uc-builder-header />

    <div class="builder-content scrollable">

        <uc-field-reference-picker *ngIf="contentSettings.canEditDefinition" />

        <div class="builder-growing-section">
            <div #fieldlist class="field-list uf-box flat">

                <uc-builder-fields-header>
                    <uc-expander-controls *ngIf="definition.fields?.length" [container]="fieldlist"
                        [class.primary]="!selectedField" />
                </uc-builder-fields-header>

                <div [entries]="definition.fields" [current]="selectedField" (next)="selectField({ subject: $event })"
                    keySelect class="nodes-container scrollable grow">

                    <uc-drag-list [items]="definition.fields" [canDrop]="canDrop"
                        [convertAdded]="convertFieldRef" (insert)="inserted($event.item)" (moved)="moved($event.item)"
                        class="scrollable">
                        <ng-template [ngForOf]="definition.fields" let-field ngFor let-i="index">
                            <uc-content-field [field]="field" [scope]="compound"
                                [(content)]="compound[field.identifier ?? '']" dragItem class="col-1of1--no-margin--no-padding" />
                        </ng-template>
                        <div *ngIf="!definition.fields.length" class="empty">
                            <div>
                                <img src="/assets/svg/icon-projects.svg">
                                <p>Drag your items here!</p>
                            </div>
                        </div>
                    </uc-drag-list>

                </div>

            </div>

            <div class="field-settings">
                <uf-tabs class="scrollable">
                    <uf-tab label="Details">
                        <div class="tab-content scrollable uf-box flat">
                            <uc-view-settings *ngIf="!contentSettings.canEditDefinition || !selectedField" />
                            <uc-field-builder *ngIf="selectedField && contentSettings.canEditDefinition"
                                [field]="selectedField" (valueChange)="fieldBuilderChange($event)" />
                        </div>
                    </uf-tab>
                    <uf-tab *ngIf="!selectedField" label="Preview">
                        <div class="tab-content scrollable uf-box flat">
                            <uf-view *ngIf="displayCompound && displayDefinition" [definition]="displayDefinition"
                                [compound]="displayCompound" [classList]="['grid', 'body-copy']" />
                        </div>
                    </uf-tab>
                </uf-tabs>
            </div>
        </div>

    </div>

</ng-container>

<uc-error-message [error]="error" />