import { Injectable, inject } from '@angular/core';
import { ClientDeleteOptions, ClientGetOptions, ClientPostOptions, amendOptionsParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { ApiKey, PermissionPrincipalType } from './models';
import { UcPermissionsClient } from './permissions-client';
import { UcClient } from './uc-client';

@Injectable({ providedIn: 'root' })
export class UcAPIKeys {

    private client = inject(UcClient);

    get(options?: ClientGetOptions): Promise<ApiKey[]> {
        return this.client.get(this.url(), amendOptionsParams(DefaultPaginationParams, options));
    }

    getDetails(key: string, options?: ClientGetOptions): Promise<ApiKey> {
        return this.client.get(this.url(key), options);
    }

    save(item: ApiKey, options?: ClientPostOptions): Promise<ApiKey> {
        return this.client.post(this.url(), { ...options, body: item });
    }

    delete(id: string, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url(id), options);
    }

    permissions(id: string): UcPermissionsClient {
        return new UcPermissionsClient(this.client, PermissionPrincipalType.ApiKey, id);
    }

    private url(...extra: string[]): string {
        const urlParts = ['api-keys', ...extra];

        return this.client.buildUrl(urlParts);
    }

}
