import { CompanyFormProvider, ProvisioningFormProviderPermissionInfo } from '@unifii/user-provisioning';

/** Implementation that grant unrestricted permissions access */
export class UcCompanyFormProvider implements CompanyFormProvider {
    
    getReadPermissionInfo(): ProvisioningFormProviderPermissionInfo {
        return { granted: true };
    }
    
    canReadField(): boolean {
        return true;
    }
    
    getEditPermissionInfo(): ProvisioningFormProviderPermissionInfo {
        return { granted: true };
    }
    
    canEditField(): boolean {
        return true;
    }
    
    isEditFieldRequired(): boolean {
        return true;
    }
    
    canListClaims(): boolean {
        return true;
    }

}
