import { Injectable } from '@angular/core';
import { DataDescriptor } from '@unifii/library/common';
import { FormDataClient, PublishedContent } from '@unifii/sdk';

import { SchemaSetting, UcSchema } from 'client';

/**
 * Provided at BucketComponent, WorkflowComponent and TableComponent levels
 * Centralize and share the formBucket status across sub-routes components
 */
@Injectable()
export class FormBucketService {

    /** Current bucket Schema */
    schema: UcSchema;

    /** Published bucket Schema */
    publishedSchema?: UcSchema;

    /** Current bucket Data Descriptor */
    dataDescriptor?: DataDescriptor;

    /** Current bucket SchemaSetting */
    schemaSetting: SchemaSetting;

    /**
     * Configured Bucket in Live or Preview
     * Use SDK v0 endpoint that is ACL exceptions mitigated for Console users
     */
    formDataClient: FormDataClient;

    /** Configured Content in Live vs Preview */
    content: PublishedContent;

}
