<uf-panel class="container">
    <div class="header-pane uf-app-bar transparent">
        <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large" />
    </div>
    <div class="content-pane">
        <uf-tabs>
            <uf-tab label="Workflow Rules" path="rules" />
            <uf-tab label="Notifications" path="notifications" />
            <uf-tab label="Timers" path="timers" />
            <uf-tab label="Form Data" path="form-data" />
            <uf-tab label="Data Forwarders" path="data-forwarders" />
        </uf-tabs>
    </div>
</uf-panel>