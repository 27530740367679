import { Component, OnInit, inject } from '@angular/core';
import { Breadcrumb } from '@unifii/library/common';

import { SystemRole } from 'client';
import { TabConfig } from 'pages/page-models';
import { TabsPage } from 'pages/tabs-page';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

@Component({
    selector: 'uc-user-management',
    templateUrl: './user-management.html',
})
export class UserManagementComponent extends TabsPage implements OnInit {

    override edited: boolean;
    breadcrumbs: Breadcrumb[];
    tabs: TabConfig[] = [];

    private breadcrumbService = inject(BreadcrumbService);
    private context = inject(ContextService);

    override ngOnInit() {
        
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);

        if (this.context.checkRoles(SystemRole.UserManager)) {
            this.tabs.push(
                { label: 'Users', path: 'users' },
                { label: 'Roles', path: 'roles' },
                { label: 'User Claims', path: 'user-claims' },
                { label: 'Hierarchy', path: 'hierarchy' },
                { label: 'API Keys', path: 'api-keys' },
            );
        }

        if (this.context.checkRoles(SystemRole.Auditor)) {
            this.tabs.push(
                { label: 'AuditLog', path: 'audit-log' });
        }

        super.ngOnInit();
    }

}
