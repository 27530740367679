import { Injectable, OnDestroy, inject } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SharedTermsTranslationKey, UfControlArray, UfControlGroup, UfFormBuilder, UfFormControl, ValidatorFunctions, controlIterator } from '@unifii/library/common';
import { StructureNode, StructureNodeArg, StructureNodeBucketOptions, StructureNodeType, StructureNodeVariation, TableDetailTemplate, hasLengthAtLeast, isNotNull } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { UcStructure } from 'client';

import { StructureControlKeys, StructureNodeArgControlKeys, StructureNodeBucketOptionsControlKeys, StructureNodeControlKeys, StructureNodeVariationControlKeys, UserReferenceControlKeys } from './structure-control-keys';
import { StructureEditorCache } from './structure-editor-cache';
import { StructureFunctions } from './structure-functions';
import { ConsoleStructure, ConsoleStructureNode, StructureNodeArgControlValue } from './structure-model';

// TODO Adopt ControlAccessor
interface DependantInfo {
    dependant: string; // control key
    dependencies: string[]; // list of control keys
}

@Injectable()
export class StructureFormCtrl implements OnDestroy {

    private dependantConfig: DependantInfo[] = [{
        dependant: StructureNodeControlKeys.Name,
        dependencies: [StructureNodeControlKeys.Type, StructureNodeControlKeys.NodeId],
    }];

    private subscriptions = new Subscription();

    private formBuilder = inject(UfFormBuilder);
    private cache = inject(StructureEditorCache);
    private translateService = inject(TranslateService);

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    mapDataToControlValue(structure: UcStructure): ConsoleStructure {

        const copy: UcStructure = JSON.parse(JSON.stringify(structure));
        const iFrames = StructureFunctions.getAllNodesOfType(copy, StructureNodeType.IFrame);
        const dashboards = StructureFunctions.getAllNodesOfType(copy, StructureNodeType.Dashboard);

        for (const dashboard of dashboards) {
            if (dashboard.tags && hasLengthAtLeast(dashboard.tags, 1)) {
                (dashboard as ConsoleStructureNode).template = dashboard.tags[0] as TableDetailTemplate;
            } else {
                (dashboard as ConsoleStructureNode).template = TableDetailTemplate.PageView;
                if ((dashboard as ConsoleStructureNode).template !== TableDetailTemplate.PageViewHideEmptyTables ) {
                    dashboard.emptyMessage = undefined;
                }
            }
        }

        for (const iframe of iFrames) {
            if (iframe.args) {
                (iframe as ConsoleStructureNode).args = iframe.args.map((arg: StructureNodeArg): StructureNodeArgControlValue => ({
                    [StructureNodeArgControlKeys.Key]: arg.key,
                    [StructureNodeArgControlKeys.Value]: { value: arg.value ?? null, isExpression: !!arg.isExpression },
                }));
            }
        }

        return copy as ConsoleStructure;
    }

    mapControlValueToData(structure: ConsoleStructure): UcStructure {

        const copy: ConsoleStructure = JSON.parse(JSON.stringify(structure));
        const iFrames = StructureFunctions.getAllNodesOfType(copy as UcStructure, StructureNodeType.IFrame) as ConsoleStructureNode[];
        const dashboards = StructureFunctions.getAllNodesOfType(copy as UcStructure, StructureNodeType.Dashboard);

        for (const dashboard of dashboards) {
            const template = (dashboard as ConsoleStructureNode).template;

            dashboard.tags = template ? [template] : undefined;
        }

        for (const iframe of iFrames) {

            if (iframe.args) {
                (iframe as StructureNode).args = iframe.args.map((arg: StructureNodeArgControlValue): StructureNodeArg => ({
                    [StructureNodeArgControlKeys.Key]: arg.key,
                    [StructureNodeArgControlKeys.Value]: arg.value.value ?? undefined,
                    isExpression: arg.value.isExpression,
                }));
            }
        }

        return copy as UcStructure;
    }

    buildRoot(structure: UcStructure): UfControlGroup {
        const controlStructureValue = this.mapDataToControlValue(structure);
        const group = this.buildNodeControl(controlStructureValue as ConsoleStructureNode);

        group.addControl(StructureControlKeys.Rev, this.formBuilder.control(controlStructureValue.rev));
        group.addControl(StructureControlKeys.LastNodeId, this.formBuilder.control(controlStructureValue.lastNodeId));
        group.addControl(StructureControlKeys.Variations, this.formBuilder.array(controlStructureValue.variations?.map((vr) => this.buildNodeVariationControl(vr)) ?? []));
        group.addControl(StructureControlKeys.StructurePublishState, this.formBuilder.control(controlStructureValue.structurePublishState));
        group.addControl(StructureControlKeys.Revision, this.formBuilder.control(controlStructureValue.revision));

        return group;
    }

    buildNodeControl(node: ConsoleStructureNode): UfControlGroup {

        const group = this.formBuilder.group({
            [StructureNodeControlKeys.Type]: [node.type, ValidatorFunctions.required('Type is required')],
            [StructureNodeControlKeys.Id]: node.id,
            [StructureNodeControlKeys.NodeId]: [node.nodeId, ValidatorFunctions.required('NodeId is required')],
            [StructureNodeControlKeys.Name]: [node.name, ValidatorFunctions.custom((v, c) => {
                const type = c?.parent?.get(StructureNodeControlKeys.Type)?.value as StructureNodeType;
                const nodeId = c?.parent?.get(StructureNodeControlKeys.NodeId)?.value as string;

                let result: boolean;

                if (nodeId === '0' && type === StructureNodeType.Empty) {
                    result = true;
                } else {
                    result = !ValidatorFunctions.isEmpty(v);
                }

                return result;

            }, 'Menu name is required')],
            [StructureNodeControlKeys.DefinitionIdentifier]: [node.definitionIdentifier, ValidatorFunctions.custom((v, c) => {
                const type = c?.parent?.get(StructureNodeControlKeys.Type)?.value as StructureNodeType;

                return type !== StructureNodeType.Custom || !ValidatorFunctions.isEmpty(v);
            }, 'Identifier is required')],
            [StructureNodeControlKeys.DefinitionLabel]: node.definitionLabel,
            [StructureNodeControlKeys.PublishState]: node.publishState,
            [StructureNodeControlKeys.LastPublishedAt]: node.lastPublishedAt,
            [StructureNodeControlKeys.LastModifiedAt]: node.lastModifiedAt,
            [StructureNodeControlKeys.LastModifiedBy]: this.formBuilder.group({
                [UserReferenceControlKeys.Id]: node.lastModifiedBy?.id,
                [UserReferenceControlKeys.Username]: node.lastModifiedBy?.username,
            }),
            [StructureNodeControlKeys.LastPublishedBy]: this.formBuilder.group({
                [UserReferenceControlKeys.Id]: node.lastPublishedBy?.id,
                [UserReferenceControlKeys.Username]: node.lastPublishedBy?.username,
            }),
            [StructureNodeControlKeys.Url]: [node.url, ValidatorFunctions.custom((v, c) => {
                const type = c?.parent?.get(StructureNodeControlKeys.Type)?.value as StructureNodeType;

                return ![StructureNodeType.IFrame, StructureNodeType.Link].includes(type)
                    || !ValidatorFunctions.isEmpty(v);
            }, 'Url is required')],
            [StructureNodeControlKeys.Tags]: [node.tags],
            [StructureNodeControlKeys.Roles]: [node.roles],
            [StructureNodeControlKeys.Hidden]: node.hidden,
            [StructureNodeControlKeys.BucketOptions]: this.formBuilder.array(node.bucketOptions?.map((bo) => this.buildBucketOptionControl(bo)) ?? []),
            [StructureNodeControlKeys.Args]: this.formBuilder.array(node.args?.map((arg) => this.buildArgControl(arg)) ?? []),
            [StructureNodeControlKeys.Children]: this.buildNodeChildrenControl(node.children),
        });

        if (node.type === StructureNodeType.Dashboard) {
            const nodeTemplateValue = node.template ?? TableDetailTemplate.PageView;
            const templateControl = this.formBuilder.control(nodeTemplateValue);
            const emptyMessageControl = this.formBuilder.control({ value: node.emptyMessage, disabled: nodeTemplateValue !== TableDetailTemplate.PageViewHideEmptyTables }, ValidatorFunctions.required('Empty message is required'));

            group.addControl(StructureNodeControlKeys.Template, templateControl);
            group.addControl(StructureNodeControlKeys.EmptyMessage, emptyMessageControl);
            this.subscriptions.add(templateControl.valueChanges.subscribe((value: TableDetailTemplate) => {
                const emptyMessageValue = value !== TableDetailTemplate.PageViewHideEmptyTables ? undefined : this.translateService.instant(SharedTermsTranslationKey.PageViewEmptyTablesMessage);

                emptyMessageControl.setValue(emptyMessageValue);
                if (value === TableDetailTemplate.PageViewHideEmptyTables) {
                    emptyMessageControl.enable();
                } else {
                    emptyMessageControl.disable();
                }
            }));
        }

        this.setDependencies(this.dependantConfig, group);

        return group;
    }

    buildNodeChildrenControl(children?: ConsoleStructureNode[]): UfControlArray {
        return this.formBuilder.array(children?.map((child) => this.buildNodeControl(child)) ?? []);
    }

    buildNodeVariationControl(variation: StructureNodeVariation): UfControlGroup {
        return this.formBuilder.group({
            [StructureNodeVariationControlKeys.Type]: [variation.type, ValidatorFunctions.required('Variation type is required')],
            [StructureNodeVariationControlKeys.Name]: [variation.name, ValidatorFunctions.required('Variation name is required')],
            [StructureNodeVariationControlKeys.Id]: variation.id,
            [StructureNodeVariationControlKeys.PublishState]: variation.publishState,
            [StructureNodeVariationControlKeys.DefinitionIdentifier]: [variation.definitionIdentifier, ValidatorFunctions.custom((v, c) => {
                const type = c?.parent?.get(StructureNodeControlKeys.Type)?.value as StructureNodeType;

                return type !== StructureNodeType.Custom || !ValidatorFunctions.isEmpty(v);
            }, 'Variation identifier is required')],
            [StructureNodeVariationControlKeys.DefinitionLabel]: variation.definitionLabel,
            [StructureNodeVariationControlKeys.Roles]: [variation.roles],
            [StructureNodeVariationControlKeys.Tags]: [variation.tags],
            [StructureNodeVariationControlKeys.BucketOptions]: this.formBuilder.array(variation.bucketOptions?.map((bo) => this.buildBucketOptionControl(bo)) ?? []),
        });
    }

    buildBucketOptionControl(bucketOption: StructureNodeBucketOptions): UfControlGroup {
        return this.formBuilder.group({
            [StructureNodeBucketOptionsControlKeys.Identifier]: bucketOption.identifier,
            [StructureNodeBucketOptionsControlKeys.PageSize]: [bucketOption.pageSize, ValidatorFunctions.custom((v) => v >= 1 && v <= 100, 'Between 1 and 100 rows')],
            [StructureNodeBucketOptionsControlKeys.Roles]: [bucketOption.roles],
        }, { asyncValidators: this.createBucketOptionAsyncValidator() });
    }

    buildArgControl(nodeArg: StructureNodeArgControlValue): UfControlGroup {
        return this.formBuilder.group({
            [StructureNodeArgControlKeys.Key]: [nodeArg.key, ValidatorFunctions.required('A key is required')],
            [StructureNodeArgControlKeys.Value]: nodeArg.value,
        });
    }

    private createBucketOptionAsyncValidator(): AsyncValidatorFn {
        return async(control: AbstractControl): Promise<ValidationErrors | null> => {

            const bucketOptions = control.value as StructureNodeBucketOptions;

            if (!bucketOptions.identifier) {
                return null;
            }

            const table = await this.cache.getTable(bucketOptions.identifier);

            if (!table) {
                return { table: 'Table not found' };
            }

            return null;
        };
    }

    private setDependencies(info: DependantInfo[], rootControl: UfControlGroup) {
        /**
         * limited to scope
         */
        for (const groupControl of controlIterator(rootControl)) {
            if (!(groupControl instanceof UfControlGroup)) {
                continue;
            }
            for (const { dependant, dependencies } of info) {
                const control = groupControl.get(dependant) as UfFormControl | undefined;

                if (control) {
                    const deps = dependencies.map((n) => groupControl.get(n) as UfFormControl | null).filter(isNotNull);

                    control.addDependencies(deps);
                    // Refresh validators now all controls are initialized
                    control.updateValueAndValidity();
                }
            }
        }

    }

}

// TODO replace for iterable and move it into the library
export const flattenControls = (control: AbstractControl, key = 'root'): { key: string; control: AbstractControl }[] => {

    let extracted: { key: string; control: AbstractControl }[] = [{ key, control }];

    if (control instanceof FormArray) {
        const children = control.controls.map((c, i) => flattenControls(c, `${key}[${i}]`));

        extracted = extracted.concat(...children);
    }

    if (control instanceof FormGroup) {
        const children = Object.keys(control.controls).map((k) => {
            const c = control.get(k);

            return c ? flattenControls(c, `${key}.${k}`) : [];
        });

        extracted = extracted.concat(...children);
    }

    return extracted;
};
