import { UfControlGroup } from '@unifii/library/common';
import { Dictionary, FieldOption, FieldType, HierarchyConfiguration, HierarchyUnitFormData, LinkContentType, ValidatorType, Variation } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { UcDefinition, UcField, UcTransition } from 'client';
import { MappableField } from 'models';

export enum AttributeParentType {
    FieldOptionType, VariationOptionType
}

export enum FieldWidth {
    Stretch = 'Stretch',
    Half = 'Half',
    Third = 'Third',
    TwoThirds = 'TwoThirds',
    Quarter = 'Quarter'
}

export interface IdentifiersMaxLength {
    definition: number;
    bucket: number;
    field: number;
    option: number;
}

export interface FormEditorOption extends FieldOption {
    id?: string;
    uuid: string;
}

export interface FormEditorFieldMapping {
    parentField?: MappableField;
    childField?: MappableField;
}

export interface FormEditorTransition extends Omit<UcTransition, 'role'> {
    roles: string[];
    isNew: boolean;
}

export interface FormEditorVariation extends Omit<Variation, 'options'> {
    options?: FormEditorOption[];
}

export interface FormEditorHierarchyConfiguration extends Omit<HierarchyConfiguration, 'ceiling'> {
    ceiling?: HierarchyUnitFormData; // TODO - UNIFII-6328 is this correct?
}

export interface FormEditorColumnVisibility {
    hideOnDesktop: Dictionary<boolean>;
    hideOnMobile: Dictionary<boolean>;
}

export interface FormEditorField extends Omit<UcField, 'fields' | 'role' | 'visibleTo' | 'visibleFields' | 'requiredFields' | 'readOnlyFields' | 'options' | 'transitions' | 'variations' | 'hierarchyConfig' | 'templateConfig' | 'step' | 'dataCapture'> {
    fields: FormEditorField[];
    roles: string[];
    dataCaptures: string[];
    visibleTo: string[];
    addressAutocomplete: boolean;
    addressNested?: FormAddressNestedFields;
    geoLocationNested?: FormGeoLocationNestedFields;
    options?: FormEditorOption[];
    allowedTypes?: LinkContentType[];
    transitions?: FormEditorTransition[];
    variations?: FormEditorVariation[];
    columnVisibility?: FormEditorColumnVisibility;
    hierarchyConfig?: FormEditorHierarchyConfiguration;
    step?: string;
    scrollTime: number | '';
    activeBackgroundTinted?: boolean;
    alwaysExpanded?: boolean;
    expandWhenInactive?: boolean;
    hideWhenInactive?: boolean;
    // Runtime support attributes (not part of the data)
    uuid: string; // also the children fields scopeUuid for FieldType.Repeat
    scopeUuid: string;
    path?: string[]; // updated when scopeUUID change or Identifier changes
}

export interface FormEditorDefinition extends Omit<UcDefinition, 'fields' | 'bucket'> {
    fields: FormEditorField[];
    bucket: string;
}

export interface FormFieldMetadata {
    // Hidden
    uuid: string;
    scopeUuid: string;
    type: FieldType;
    isContainer: boolean;
    // Details
    label: boolean;
    shortLabel: boolean;
    identifier: boolean;
    template: boolean;
    role: boolean; // TODO rename roles
    customFields: boolean; // save action
    description: boolean;
    // Workflow
    transitions: boolean;
    // Settings
    isReadOnly: boolean;
    isRequired: boolean;
    autoDetect: boolean;
    currency: boolean;
    autofill: boolean;
    bindTo: boolean;
    showIf: boolean;
    showOn: boolean;
    precision: boolean;
    maxLength: boolean;
    step: boolean; // interval
    format: boolean;
    hierarchy: boolean; // Hierarchy
    sort: boolean;
    // Options
    options: boolean;
    dataSourceConfig: boolean;
    avoidDuplicates: boolean;
    // Nested fields
    addressAutocomplete: boolean;
    addressNested: boolean;
    geoLocationNested: boolean;
    // Display
    help: boolean;
    placeholder: boolean;
    itemLabel: boolean;
    addButtonLabel: boolean;
    columnVisibility: boolean;
    width: boolean;
    breakAfter: boolean;
    columnCount: boolean;
    layoutDirection: boolean;
    visibleTo: boolean;
    scrollTime: boolean;
    activeBackgroundTinted: boolean;
    alwaysExpanded: boolean;
    expandWhenInactive: boolean;
    hideWhenInactive: boolean;
    // Variations
    variations: boolean;
    // Validators
    validators: boolean;
    // Advanced
    isReportable: boolean;
    isTranslatable: boolean;
    dataCaptures: boolean;
    allowedTypes: boolean;
    tags: boolean;
}

export interface FormFieldDetailSections {
    details: boolean;
    settings: boolean;
    options: boolean;
    nestedFields: boolean;
    display: boolean;
    variations: boolean;
    validators: boolean;
    transitions: boolean;
    advanced: boolean;
}

export interface FormNestedField {
    visible: boolean;
    required: boolean;
    readOnly: boolean;
}

export interface FormAddressNestedFields {
    address1: FormNestedField;
    address2: FormNestedField;
    suburb: FormNestedField;
    state: FormNestedField;
    postcode: FormNestedField;
    country: FormNestedField;
    map: FormNestedField;
}

export interface FormGeoLocationNestedFields {
    map: FormNestedField;
    latlng: FormNestedField;
}

export interface ValidatorInfo {
    typeLabel?: string;
    valueLabel?: string;
    valuePlaceholder?: string;
    messagePlaceholder?: string;
    type: ValidatorType;
}

export interface FormFieldScopedInfo {
    control: UfControlGroup;
    uuid: string;
    identifier?: string;
    identifierValueChangesSub?: Subscription;
}
