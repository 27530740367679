import { TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { RequestAnalyticsOrigin, mergeParams } from '@unifii/sdk';

import { AuditEntry, AuditQuery, UcAuditLog } from 'client';

export class AuditLogDataSource extends TableDataSource<AuditEntry> {

    constructor(
        private ucAuditLog: UcAuditLog,
        private auditQuery: AuditQuery,
    ) {
        super();
    }

    get filtered(): boolean {
        return this.auditQuery.action != null ||
            this.auditQuery.from != null ||
            this.auditQuery.to != null ||
            this.auditQuery.resource != null ||
            this.auditQuery.login != null;
    }

    get sorted(): boolean {
        return false;
    }

    load(options?: TableDataSourceQueryOptions) {

        const params = mergeParams(this.auditQuery as Record<string, unknown>, { offset: options?.offset, limit: options?.limit });

        this.ucAuditLog.query({ params, analytics: { origin: RequestAnalyticsOrigin.Table } }).then(
            (audits) => this.stream.next({ data: audits }),
            (error) => this.stream.next({ error }),
        );
    }

}
