<div class="row">
    <uf-icon *ngIf="control.invalid" [class.error]="!selected" name="error"
        title="This field configuration is invalid" />
    <uf-icon *ngIf="hasShowIf" [ngClass]="{white: selected, primary: !selected}" name="showIf" title="show if"
        class="gap-sm-sides" />
    <uf-icon *ngIf="isRequired" [ngClass]="{white: selected, primary: !selected}" name="required" title="required"
        class="gap-sm-sides" />
    <uf-icon *ngIf="isHidden" [ngClass]="{white: selected, primary: !selected}" name="viewHidden" title="hidden"
        class="gap-sm-sides" />
    <uf-icon *ngIf="hasTags" [ngClass]="{white: selected, primary: !selected}" name="tagged" title="tags"
        class="gap-sm-sides" />
    <uf-icon *ngIf="hasAutofill" [ngClass]="{white: selected, primary: !selected}" name="autofill" title="autofill"
        class="gap-sm-sides" />
    <uf-icon *ngIf="hasBindTo" [ngClass]="{white: selected, primary: !selected}" name="link" title="bind to"
        class="gap-sm-sides" />
    <uf-icon *ngIf="hasVariations" [ngClass]="{white: selected, primary: !selected}" name="variation" title="variations"
        class="gap-sm-sides" />
    <uf-icon *ngIf="isReportable" [ngClass]="{white: selected, primary: !selected}" name="reportable" title="reportable"
        class="gap-sm-sides" />
    <uf-icon *ngIf="isSearchable" [ngClass]="{white: selected, primary: !selected}" name="searchable" title="searchable"
        class="gap-sm-sides" />
    <uf-icon *ngIf="hasDescription" [ngClass]="{white: selected, primary: !selected}" name="notes" title="description"
        class="gap-sm-sides" />
</div>