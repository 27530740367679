import { ChangeDetectorRef, Component, ElementRef, Optional, ViewChild } from '@angular/core';
import { ToastService, UfControl, ValidatorFunctions } from '@unifii/library/common';
import { CompoundType, Field, FieldTemplate, FieldType, FieldWidth, HorizontalTableTemplate, LayoutDirection, Option } from '@unifii/sdk';

import { BuilderField, UcProject } from 'client';
import { BuilderService } from 'components/compound-builder/builder.service';
import { TemplateConfigManager } from 'components/compound-builder/template-config-manager';
import { FieldDetailHelper, FieldDetailMetadata, OptionItem } from 'helpers/helpers';
import { DialogsService } from 'services/dialogs.service';

import { FieldAttributeConfig, FieldDetailBasic } from './field-detail-basic';

interface FieldDisplayConfig {
    itemLabel: FieldAttributeConfig;
    addButtonLabel: FieldAttributeConfig;
    width: FieldAttributeConfig;
    template: FieldAttributeConfig;
    position: FieldAttributeConfig;
    columnCount: FieldAttributeConfig;
    layoutDirection: FieldAttributeConfig;
    minHeight: FieldAttributeConfig;
    minWidth: FieldAttributeConfig;
    breakAfter: FieldAttributeConfig;
    columns: FieldAttributeConfig;
    columnVisibility: FieldAttributeConfig;
}

enum ScreenSize {
    Mobile = 'mobile',
    Desktop = 'desktop'
}

/**
 * // TODO improve this, this is extremely hacky way of rendering labels
 * in bool templates when it gets to the renderer the options are thrown
 * out and replaced with boolean values
 */
const BoolOptions: Option[] = [
    { identifier: 'true', name: 'Yes' },
    { identifier: 'false', name: 'No' },
];

const MaxColumnsForImageChoice = 4;

const PositionOptions = [
    { value: 'left', name: 'Left' },
    { value: 'stretch', name: 'Stretch' },
    { value: 'right', name: 'Right' },
    { value: 'banner', name: 'Banner' },
];

@Component({
    selector: 'uc-field-display',
    templateUrl: './field-display.html',
})
export class FieldDisplayComponent extends FieldDetailBasic {

    // Element Ref needed to steal focus from autocomplete input
    @ViewChild('focus', { static: true }) focus: ElementRef;

    // template enum references
    readonly fieldTemplate = FieldTemplate;
    readonly fieldWidth = FieldWidth;
    readonly displayType = ScreenSize;

    // Static Options
    readonly widthOptions: OptionItem[] = [
        { value: FieldWidth.Stretch, name: 'Full' },
        { value: FieldWidth.Half, name: 'Half' },
        { value: FieldWidth.Third, name: 'One Third' },
        { value: FieldWidth.TwoThirds, name: 'Two Thirds' },
        { value: FieldWidth.Quarter, name: 'Quarter' },
    ];
    readonly directionOptions: OptionItem[] = [
        { value: 'Row', name: 'Row' },
        { value: 'Column', name: 'Column' },
    ];

    protected columnCountOptions = FieldDetailHelper.getColumnOptions();
    protected positionOptions: OptionItem[] = PositionOptions;

    // Dynamic Options
    protected templateOptions: OptionItem[] = [];

    // Values
    protected config: FieldDisplayConfig;
    protected fieldColumns: BuilderField[] = [];
    protected columnVisibility?: HorizontalTableTemplate;

    private _previousTemplate: string;

    constructor(
        builderService: BuilderService,
        ref: ChangeDetectorRef,
        private toastService: ToastService,
        private ucProject: UcProject,
        private dialogs: DialogsService,
        @Optional() private templateConfigManager?: TemplateConfigManager,
    ) {
        super(builderService, 'display', ref);
        this.generateControls();

        if (this.config.columnVisibility.show) {
            this.columnVisibility = this.field.templateConfig as HorizontalTableTemplate;
        }
    }

    get isBreakAfterDisabled(): boolean {

        const { width } = this.field;

        return (!width || width === FieldWidth.Stretch);
    }

    protected update() {

        const parent = this.builderService.builder.getFieldPosition(this.field)?.parent as Field | undefined;
        let metadata = FieldDetailHelper.getMetadata(this.field, this.builderService.builder.type, parent);

        // Update Template options based on field type
        this.templateOptions = FieldDetailHelper.getTemplateOptions(this.field.type) ?? [];
        this.columnCountOptions = this.getColumnOptions();

        metadata = this.additionalSetup(this.field, metadata);

        if (this.field.type === FieldType.Content || !this.config.template.show) {
            this.config.template.control = new UfControl();
        } else {
            this.config.template.control = new UfControl(ValidatorFunctions.required('Template is required'));
        }

        this.config.width.show = metadata.width;
        this.config.breakAfter.show = metadata.breakAfter;
        this.config.template.show = metadata.template;
        this.config.columns.show = metadata.columns;
        this.config.position.show = metadata.position;
        this.config.columnCount.show = metadata.columnCount;
        this.config.layoutDirection.show = metadata.layoutDirection;
        this.config.minHeight.show = metadata.minHeight;
        this.config.minWidth.show = metadata.minWidth;
        this.config.itemLabel.show = metadata.itemLabel;
        this.config.addButtonLabel.show = metadata.addButtonLabel;
        this.config.columnVisibility.show = metadata.columnVisibility;

        this.normalizeData(this.field);
        this.addRemoveControls();
        this.notifyRefresh();
    }

    protected setup() {

        this.update();

        if (this.field.type === FieldType.Bool || this.field.type === FieldType.Repeat) {
            /**
             * Ensure all Bool fields have options set
             * even checkboxes required labels for reporting purposes
             */
            this.templateChange(this.field.template);
        }

        this.positionOptions = this.field.type !== FieldType.ImageList ? PositionOptions : PositionOptions.filter((a) => a.value === 'stretch');
    }

    protected templateChange(template?: string) {

        if ([FieldType.Choice, FieldType.MultiChoice].includes(this.field.type)) {
            if (this.field.options?.length && template) {
                if (this.shouldDeleteContent(this._previousTemplate, template)) {
                    this.field.options.forEach((option) => {
                        delete option.content;
                    });
                }
            }
        }

        if (this.field.type === FieldType.Repeat && this._previousTemplate === FieldTemplate.Form && template !== FieldTemplate.Form && this.field.fields) {
            this.field.fields.forEach((field) => {
                delete field.width;
            });
        }

        this._previousTemplate = template ?? '';

        // only Bool field types need to be changed
        if (this.field.type !== FieldType.Bool) {
            return;
        }
        // Add options for DropDown, Radio, TickCross
        if (!this.field.options?.length) {
            this.field.options = [...BoolOptions.map((o) => Object.assign({}, o))];
        }

        if (this.templateConfigManager != null) {
            this.templateConfigManager.set(this.field);
        }

    }

    protected filterColumns(query?: string) {

        this.ucProject.collection(this.field.definitionIdentifier as string).getDefinition().then((definition: any) => {
            /** Check that field is not already in list and is allow for table cells */
            const existingColumnMap = this.field.columns?.length ? this.field.columns.map((field) => field.identifier) : [];

            const fields: BuilderField[] = definition.fields.filter((field: BuilderField) =>
                /** Filter on allowed cells and existing cells */
                !existingColumnMap.includes(field.identifier as string),
            );

            if (!query || query.trim() === '') {
                this.fieldColumns = [...fields];

                return;
            }

            this.fieldColumns = fields.filter((field) => field.label ? field.label.toLowerCase().includes(query.toLowerCase()) : false);
        });
    }

    protected addColumn(value?: BuilderField) {

        if (!value) {
            return;
        }

        if (!this.field.columns) {
            this.field.columns = [];
        }

        this.field.columns.push({ label: value.label as string, identifier: value.identifier as string });

    }

    protected async removeColumn(column: { identifier: string; label: string }) {

        if (!this.field.columns) {
            return;
        }

        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        const index = this.field.columns.findIndex((item) => item === column);

        this.field.columns.splice(index, 1);
        this.notifyEdited();
    }

    protected columnVisibilityChange(visible: boolean, identifier: string, type: ScreenSize) {

        if (this.field.templateConfig == null) {
            this.field.templateConfig = {
                hideFromColumnsOnDesktop: [],
                hideFromColumnsOnMobile: [],
            };
        }

        const horizontalTableTemplate = this.field.templateConfig as HorizontalTableTemplate;

        const desktopList: string[] = horizontalTableTemplate.hideFromColumnsOnDesktop as string[];
        const mobileList: string[] = horizontalTableTemplate.hideFromColumnsOnMobile as string[];
        // Reassign list to new variable to avoid duplicating code
        const hiddenList = type === ScreenSize.Desktop ? desktopList : mobileList;

        if (!visible && !hiddenList.includes(identifier)) {
            hiddenList.push(identifier);
        }

        if (visible && hiddenList.includes(identifier)) {
            const index = hiddenList.findIndex((i) => i === identifier);

            hiddenList.splice(index, 1);
        }

        this.templateConfigManager?.set(this.field);
    }

    private additionalSetup(field: BuilderField, fm: FieldDetailMetadata) {

        switch (field.type) {
            case FieldType.Choice:
                if (!field.template || field.template === FieldTemplate.DropDown) {
                    fm.columnCount = false;
                    fm.layoutDirection = false;
                }
                if (field.template === FieldTemplate.Radio || field.template === FieldTemplate.RadioWithContent) {
                    fm.layoutDirection = field.columnCount ? field.columnCount > 1 : false;
                }
                break;
            case FieldType.Bool:
                if (!field.template || field.template === FieldTemplate.DropDown || field.template === FieldTemplate.Checkbox) {
                    fm.columnCount = false;
                }
                break;
            case FieldType.MultiChoice:
                if (!field.columnCount) {
                    field.columnCount = 1;
                }
                fm.layoutDirection = field.columnCount > 1;
                break;
            case FieldType.Repeat:
                if (!field.template || ![FieldTemplate.HorizontalTable, FieldTemplate.HorizontalTableMobile].includes(field.template as FieldTemplate)) {
                    fm.columnVisibility = false;
                }
                break;
        }

        return fm;
    }

    private normalizeData(field: BuilderField) {

        this._previousTemplate = field.template ?? '';

        // Field width
        if ((!field.width && this.config.width.show) || this.field.template === FieldTemplate.RadioWithContent) {
            field.width = FieldWidth.Stretch;
        }

        if (field.width === FieldWidth.Stretch) {
            field.breakAfter = false;
        }

        if (this.isPageMultiText(field) || this.isFormContent(field) || this.isFileList(field)) {
            /**
             * Set template to an empty string so the 'Content' option is selected in the dropdown
             * when the form is saved the empty string considered empty and not saved
             */
            field.template = '' as FieldTemplate;
        }

        if (field.type === FieldType.ImageList) {
            field.template = field.template ?? FieldTemplate.Stretch;
        }

        // Remove unneeded values from field
        for (let key of Object.keys(this.config)) {
            // Special case added for template and position
            // as template is reused for position
            key = key === 'template' ? 'position' : key;

            if (!this.config[key as keyof FieldDisplayConfig].show) {
                delete field[key as keyof BuilderField];
            }

            if (key === 'width' && this.field.width == null) {
                this.field.width = FieldWidth.Default;
            }
        }

        switch (field.type) {
            case FieldType.Choice:
                field.template = field.template ?? FieldTemplate.DropDown;
                field.placeholder = field.placeholder ?? 'Please choose';
                break;
            case FieldType.Repeat:
                field.template = field.template ?? FieldTemplate.Form;
                break;
            case FieldType.LinkList:
                field.template = field.template ?? FieldTemplate.List;
                break;
            case FieldType.MultiChoice:
            case FieldType.Bool:
                field.template = field.template ?? FieldTemplate.Checkbox;
                break;
        }

        if (this.config.columnCount.show) {
            if (this.isOptionWithContent(field)) {
                field.columnCount = field.columnCount ? field.columnCount <= MaxColumnsForImageChoice ? field.columnCount : 1 : 1;
            } else {
                field.columnCount = field.columnCount || 1;
            }
        }

        if (this.config.layoutDirection.show) {
            field.layoutDirection = field.layoutDirection ?? LayoutDirection.Row;
        }

        if (this.config.columnVisibility && this.templateConfigManager != null) {
            this.templateConfigManager.set(this.field);
        }

    }

    private addRemoveControls() {

        // Add/Remove controls from the form
        Object.keys(this.config).forEach((key) => {

            if (this.config[key as keyof FieldDisplayConfig].show === true && this.form.controls[key] == null) {
                // Add control
                const control: UfControl = this.config[key as keyof FieldDisplayConfig].control;

                this.form.addControl(key, control);
                this.addControlListener(key as keyof FieldDisplayConfig, control);
            }

            if (this.config[key as keyof FieldDisplayConfig].show === false && this.form.controls[key] != null) {
                this.form.removeControl(key);
            }
        });
    }

    private generateControls() {

        this.config = {
            template: { show: false, control: new UfControl() },
            itemLabel: { show: false, control: new UfControl() },
            addButtonLabel: { show: false, control: new UfControl() },
            width: { show: false, control: new UfControl() },
            columns: { show: false, control: new UfControl() },
            position: { show: false, control: new UfControl() },
            columnCount: { show: false, control: new UfControl() },
            layoutDirection: { show: false, control: new UfControl() },
            minHeight: { show: false, control: new UfControl(ValidatorFunctions.custom((v) => v > 0, 'Minimum height can\'t be negative')) },
            minWidth: { show: false, control: new UfControl(ValidatorFunctions.custom((v) => v > 0, 'Minimum width can\'t be negative')) },
            breakAfter: { show: false, control: new UfControl() },
            columnVisibility: { show: false, control: new UfControl() },
        };
    }

    private getColumnOptions(): OptionItem[] {

        if (this.field.type === FieldType.Bool && (this.field.template === FieldTemplate.BoolTickCross || this.field.template === FieldTemplate.Radio)) {
            return FieldDetailHelper.getColumnOptions().filter((o) => o.value === 2 || o.value === 1);
        }

        if (this.isOptionWithContent(this.field)) {
            return FieldDetailHelper.getColumnOptions().filter((o) => o.value <= MaxColumnsForImageChoice);
        }

        return FieldDetailHelper.getColumnOptions();
    }

    private isOptionWithContent(field: BuilderField): boolean {
        return (field.type === FieldType.Choice || field.type === FieldType.MultiChoice)
            && field.template !== undefined && [FieldTemplate.CheckboxWithContent, FieldTemplate.RadioWithContent, FieldTemplate.OptionWithContent].includes(field.template as FieldTemplate);
    }

    private shouldDeleteContent(previousTemplate: string, currentTemplate: string) {
        if ((previousTemplate as FieldTemplate === FieldTemplate.Checkbox && currentTemplate as FieldTemplate !== FieldTemplate.Checkbox) || (previousTemplate as FieldTemplate !== FieldTemplate.Checkbox && currentTemplate as FieldTemplate === FieldTemplate.Checkbox)) {
            return true;
        } else if ((previousTemplate as FieldTemplate === FieldTemplate.Radio && currentTemplate as FieldTemplate !== FieldTemplate.Radio) || (previousTemplate as FieldTemplate !== FieldTemplate.Radio && currentTemplate as FieldTemplate === FieldTemplate.Radio)) {
            return true;
        } else if ((previousTemplate as FieldTemplate === FieldTemplate.DropDown && currentTemplate as FieldTemplate !== FieldTemplate.DropDown) || (previousTemplate as FieldTemplate !== FieldTemplate.DropDown && currentTemplate as FieldTemplate === FieldTemplate.DropDown)) {
            return true;
        }

        return false;
    }

    private addControlListener(controlKey: keyof FieldDisplayConfig, control: UfControl) {

        if (controlKey === 'width') {
            this.subscriptions.add(control.valueChanges.subscribe((v) => {

                if (v === 'Quarter') {
                    this.toastService.warning('All inputs require a minimum width and may not display as a full quarter');

                    return;
                }

                if ((v !== 'Fixed' && v !== 'Stretch') && this.field.columnCount && this.field.columnCount > 1 && (this.field.type === FieldType.Choice || this.field.type === FieldType.MultiChoice)) {
                    this.toastService.warning('Inputs with columns should be set to full width for better readability');
                }

                if (v === 'Fixed' || v === 'Stretch') {
                    this.field.breakAfter = false;
                }
            }));

            return;
        }

        if (controlKey === 'template') {
            this.subscriptions.add(control.valueChanges.subscribe((v) => {
                if (this.field.type === FieldType.Repeat && (v === 'Table' || v === 'Grid')) {
                    this.toastService.warning('This template will restrict how its fields are displayed');
                }
                if (this.field.type === FieldType.LinkList && v !== 'Table') {
                    delete this.field.columns;
                }
            }));

            return;
        }

    }

    private isFileList(field: Field): boolean {
        return field.type === FieldType.FileList && field.template == null;
    }

    private isPageMultiText(field: Field): boolean {
        return field.type === FieldType.MultiText && field.template == null && this.builderService.builder.type === CompoundType.Page;
    }

    private isFormContent(field: Field): boolean {
        return field.type === FieldType.Content && field.template == null && this.builderService.builder.type === CompoundType.Form;
    }

}
