<!-- Application root component -->
<router-outlet />

<!-- new library modals -->
<uf-modal-container />
<uf-toast-container />

<!-- Order is important cause we want new icons to override old icons -->
<uc-icons />
<uf-icons />

<div class="tooltip"></div>

<uf-progress [inProgress]="progressCount > 0" class="app-progress" />