<div *ngIf="form" class="col grow">
    <div class="uf-app-bar transparent pad-sides">
        <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large" />
    </div>
    <div class="content-pane">
        <div class="uf-container">
            <div class="uf-grid row-gap-lg pad-top">
                <div class="col-12 uf-box">
                    <div class="uf-app-bar accent">
                        <div class="title">{{role.name}}</div>
                        <button (click)="edit()" title="Edit" type="button" class="uf-action tertiary">
                            <uf-icon name="edit" />
                        </button>
                    </div>
                    <div class="uf-grid pad-sm">
                        <uf-data-display-list [items]="roleInfo" class="small-label col-12" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <uf-panel class="grow pad-sides">
        <uc-permissions />
    </uf-panel>
</div>

<uc-error-message [error]="error" />