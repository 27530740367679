import { Component, Input, OnInit } from '@angular/core';
import { UfControlArray, UfControlGroup } from '@unifii/library/common';
import { LockedConfig, UserKeys } from '@unifii/user-provisioning';

@Component({
    selector: 'uc-user-roles',
    templateUrl: 'user-roles.html',
})

export class UserRolesComponent implements OnInit {

    @Input({ required: true }) name: string;
    @Input({ required: true }) form: UfControlGroup;
    @Input({ required: true }) lockedConfig: LockedConfig | undefined;
    @Input() cssClasses: string | string[] | undefined;

    protected readonly controlKeys = UserKeys;
    protected rolesControl: UfControlArray;

    ngOnInit() {
        this.rolesControl = this.form.get(UserKeys.Roles) as UfControlArray;
    }

}
