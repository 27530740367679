<div class="header-pane uf-app-bar transparent">
    <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large" />
</div>

<uf-panel class="content-pane">
    <div class="uf-card-list">
        <ng-template [ngForOf]="items" let-item ngFor>
            <a [routerLink]="item.route" [restrict]="item.roles" class="uf-card">
                <div class="uf-img-3x2">
                    <img [attr.src]="item.image" alt="" />
                </div>
                <div class="uf-card-title">
                    <h4>{{item.label}}</h4>
                </div>
            </a>
        </ng-template>
    </div>
    <div class="pad-lg gap-top">
    </div>
</uf-panel>