import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, UfControl, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { Subscription } from 'rxjs';

import { IntegrationFeatureArgType } from 'client';
import { FieldIdentifierValidCharactersValidator, IdentifierNoEmptySpacesValidator } from 'helpers/field-identifier-helper';

import { IntegrationFeatureArgData, IntegrationFeatureControlKeys } from './models';

export interface IntegrationFeatureArgModalData {
    isInput: boolean;
    arg?: IntegrationFeatureArgData;
}

@Component({
    selector: 'uc-integration-feature-arg-modal',
    templateUrl: './integration-feature-arg-modal.html',
})
export class IntegrationFeatureArgModalComponent implements Modal<IntegrationFeatureArgModalData, IntegrationFeatureArgData | undefined>, OnInit, OnDestroy {

    @HostBinding('class.uc-form-card') classes = true;

    data = inject<IntegrationFeatureArgModalData>(ModalData);
    runtime = inject<ModalRuntime<IntegrationFeatureArgModalData, IntegrationFeatureArgData | undefined>>(ModalRuntime);

    protected readonly controlKeys = IntegrationFeatureControlKeys;
    protected readonly integrationFeatureArgTypes = Object.values(IntegrationFeatureArgType);

    // TODO Required field will be added later on, when expressions are updated to accept it
    // protected readonly isRequiredOptions = [
    //     { identifier: true, name: 'Yes' },
    //     { identifier: false, name: 'No' },
    // ];

    protected form: UfControlGroup;
    protected modalTitle: string;
    protected typesControl: UfControl;

    private readonly requiredField = ValidatorFunctions.required('Required, please complete.');

    private subscriptions = new Subscription();

    private ufb = inject(UfFormBuilder);

    ngOnInit() {
        this.buildTypesControl();

        this.modalTitle = `${this.data.arg ? 'Edit' : 'Add'} ${this.data.isInput ? 'Input' : 'Output'} arg`;

        this.form = this.buildArg(this.data.arg);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    protected typeTrackBy(index: number, value: IntegrationFeatureArgType) {
        return `${index}-${value}`;
    }

    protected updateValue(v: IntegrationFeatureArgType, index: number) {
        let types = this.typesControl.getRawValue() as (IntegrationFeatureArgType | undefined)[];

        types[index] = v;

        if (types.every((type) => type === IntegrationFeatureArgType.List)) {
            types.push(undefined);
        } else {
            const lastNonListTypeIndex = types.findIndex((type) => type !== IntegrationFeatureArgType.List);

            types = types.slice(0, lastNonListTypeIndex + 1);
        }

        this.typesControl.setValue(types);
    }

    protected save() {
        this.form.setSubmitted();
        this.typesControl.setSubmitted();

        if (this.form.invalid || this.typesControl.invalid) {
            return;
        }

        const featureArgument: IntegrationFeatureArgData = this.form.getRawValue();

        const types = this.typesControl.getRawValue() as (IntegrationFeatureArgType | undefined)[];

        const kind = types[types.length - 1];

        this.runtime.close({
            ...featureArgument,
            kind: kind ?? featureArgument.kind,
            listCount: types.length - 1,
        });
    }

    private buildArg(arg?: IntegrationFeatureArgData) {
        return this.ufb.group({
            [IntegrationFeatureControlKeys.Identifier]: [arg?.identifier, ValidatorFunctions.compose([
                this.requiredField,
                FieldIdentifierValidCharactersValidator,
                IdentifierNoEmptySpacesValidator,
            ])],
            // TODO Required field will be added later on, when expressions are updated to accept it
            // [IntegrationFeatureControlKeys.IsRequired]: [arg?.isRequired, this.requiredField],
        });
    }

    private buildTypesControl() {
        const types = [] as (IntegrationFeatureArgType | undefined)[];

        // if it has a listCount it means it's a list argument
        if (this.data.arg?.listCount) {
            types.push(...Array.from({ length: this.data.arg.listCount }, () => IntegrationFeatureArgType.List));
        }

        types.push(this.data.arg?.kind);

        this.typesControl = this.ufb.control(types, this.buildTypesCustomValidation());
    }

    private buildTypesCustomValidation() {
        return ValidatorFunctions.custom(
            (types: IntegrationFeatureArgType[]) => !!types[types.length - 1] && types[types.length - 1] !== IntegrationFeatureArgType.List,
            'Feature argument invalid');
    }

}
