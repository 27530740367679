import { Media } from 'client';

export class MediaHelper {

    static getImageUrl(media: Media, width: number, height?: number, mode = 'max'): string | null {
        if (!media) {
            return null;
        }

        const url = media.thumbUrl || media.url;

        if (!url) {
            return null;
        }

        // Return full URL
        if (width && height) {
            return [url, '?w=', width, '&h=', height, '&mode=', mode].join('');
        }

        if (!width && height) {
            return [url, '?h=', height, '&mode=', mode].join('');
        }

        if (width && !height) {
            return [url, '?w=', width, '&mode=', mode].join('');
        }

        return url;
    }

}
