import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UfControl } from '@unifii/library/common';

import { Media, MediaQueryParams, UcMedia } from 'client';

@Component({
    selector: 'uc-asset',
    templateUrl: './uc-asset.html',
    styleUrls: ['./uc-asset.less'],
})
export class UcAssetComponent {

    @Input() control = new UfControl();
    @Input() value: Media;
    @Input() multipleValues: boolean; /** flag to switch to chips component */
    @Input() type: string;
    @Input() autofocus: boolean;
    @Output() valueChange = new EventEmitter<Media>(); /** Notify the selected value has changed */

    protected options: Media[] = [];

    constructor(private ucMedia: UcMedia) { }

    protected async filter(q: string) {
        const params: MediaQueryParams = {
            q,
            types: this.type,
            limit: 80,
            offset: 0,
        };

        this.options = await this.ucMedia.get(params);
    }

}
