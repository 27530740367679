import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'uc-icons',
    templateUrl: './icons.html',
})
export class ConsoleIconsComponent {

    @HostBinding('style.display') hostDisplay = 'none';

}
