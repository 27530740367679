import { Injectable } from '@angular/core';
import { CollectionDataDescriptorAdapterLoader } from '@unifii/library/common';
import { Definition } from '@unifii/sdk';

import { UcProject } from 'client';

@Injectable()
export class UcCollectionDataDescriptorAdapterLoader implements CollectionDataDescriptorAdapterLoader {

    constructor(
        private ucProject: UcProject,
    ) { }

    loadCollectionDefinition(identifier: string): Promise<Definition> {
        return this.ucProject.collection(identifier).getDefinition() as Promise<Definition>;
    }

}
