<uf-panel class="content-pane container">
    <div *ngIf="project" class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        App Theme
                    </div>
                    <button (click)="applyTheme('green')" type="button" class="uf-button x-small primary">
                        Apply Green Theme
                    </button>
                    <button (click)="applyTheme('blue')" type="button" class="uf-button x-small primary">
                        Apply Blue Theme
                    </button>
                </div>
                <uf-expander>
                    <div expanderHeader class="uf-app-bar flat">
                        <div class="title">
                            Brand Colours
                        </div>
                    </div>
                    <div expanderBody class="grid">
                        <uc-color *ngIf="form.controls.brand" [control]="form.controls.brand | asUfControl"
                            label="Brand" class="col-1of2" />
                        <uc-color *ngIf="form.controls.brandAccent" [control]="form.controls.brandAccent | asUfControl"
                            label="Brand Accent" class="col-1of2" />
                    </div>
                </uf-expander>
                <uf-expander>
                    <div expanderHeader class="uf-app-bar flat">
                        <div class="title">
                            Button/Action Colours
                        </div>
                    </div>
                    <div expanderBody class="grid">
                        <uc-color *ngIf="form.controls.primaryAction"
                            [control]="form.controls.primaryAction | asUfControl" label="Primary Action"
                            class="col-1of2" />

                        <uc-color *ngIf="form.controls.primaryActionDisabled"
                            [control]="form.controls.primaryActionDisabled | asUfControl"
                            label="Primary Action Disabled" class="col-1of2" />

                        <uc-color *ngIf="form.controls.primaryActionHover"
                            [control]="form.controls.primaryActionHover | asUfControl" label="Primary Action Hover"
                            class="col-1of2" />

                        <uc-color *ngIf="form.controls.primaryActionPressed"
                            [control]="form.controls.primaryActionPressed | asUfControl" label="Primary Action Active"
                            class="col-1of2" />

                        <uc-color *ngIf="form.controls.secondaryAction"
                            [control]="form.controls.secondaryAction | asUfControl" label="Secondary Action"
                            class="col-1of2" />

                        <uc-color *ngIf="form.controls.secondaryActionDisabled"
                            [control]="form.controls.secondaryActionDisabled | asUfControl"
                            label="Secondary Action Disabled" class="col-1of2" />

                        <uc-color *ngIf="form.controls.secondaryActionHover"
                            [control]="form.controls.secondaryActionHover | asUfControl" label="Secondary Action Hover"
                            class="col-1of2" />

                        <uc-color *ngIf="form.controls.secondaryActionPressed"
                            [control]="form.controls.secondaryActionPressed | asUfControl"
                            label="Secondary Action Active" class="col-1of2" />

                    </div>
                </uf-expander>
                <uf-expander>
                    <div expanderHeader class="uf-app-bar flat">
                        <div class="title">
                            Form
                        </div>
                    </div>
                    <div expanderBody class="grid">
                        <uf-select *ngIf="form.controls.formStyle" [control]="form.controls.formStyle | asUfControl"
                            [options]="formStyleOptions" label="Form Style" nameProperty="name"
                            valueProperty="identifier" class="col-1of1" />

                        <div class="col-1of1">
                            <h3>Colours</h3>
                        </div>
                        <uc-color *ngIf="form.controls.group" [control]="form.controls.group | asUfControl"
                            label="Group first level colour" class="col-1of2" />

                        <uc-color *ngIf="form.controls.groupSecondLevel"
                            [control]="form.controls.groupSecondLevel | asUfControl" label="Group second level colour"
                            class="col-1of2" />

                        <uc-color *ngIf="form.controls.groupThirdLevel"
                            [control]="form.controls.groupThirdLevel | asUfControl" label="Group third level colour"
                            class="col-1of2" />

                        <uc-color *ngIf="form.controls.groupFourthLevel"
                            [control]="form.controls.groupFourthLevel | asUfControl" label="Group fourth level colour"
                            class="col-1of2" />

                        <uc-color *ngIf="form.controls.groupChild" [control]="form.controls.groupChild | asUfControl"
                            label="Group children" class="col-1of2" />

                        <uc-color *ngIf="form.controls.inputLabel" [control]="form.controls.inputLabel | asUfControl"
                            label="Input label colour" class="col-1of2" />

                    </div>
                </uf-expander>
                <uf-expander>
                    <div expanderHeader class="uf-app-bar flat">
                        <div class="title">
                            Messaging Colours
                        </div>
                    </div>
                    <div expanderBody class="grid">
                        <uc-color *ngIf="form.controls.info" [control]="form.controls.info | asUfControl" label="Info"
                            class="col-1of2" />

                        <uc-color *ngIf="form.controls.success" [control]="form.controls.success | asUfControl"
                            label="Success" class="col-1of2" />

                        <uc-color *ngIf="form.controls.warning" [control]="form.controls.warning | asUfControl"
                            label="Warning" class="col-1of2" />

                        <uc-color *ngIf="form.controls.error" [control]="form.controls.error | asUfControl"
                            label="Error" class="col-1of2" />

                    </div>
                </uf-expander>
                <uf-expander>
                    <div expanderHeader class="uf-app-bar flat">
                        <div class="title">
                            Other
                        </div>
                    </div>
                    <div expanderBody class="grid">
                        <uf-select [options]="inputStyleOptions" [(value)]="inputStyle" label="Input style"
                            nameProperty="name" valueProperty="identifier" class="col-1of2" />

                        <uf-text *ngIf="form.controls.borderRadius" [control]="form.controls.borderRadius | asUfControl"
                            label="Border Radius" class="col-1of2" />
                    </div>
                </uf-expander>
            </div>
        </div>
    </div>
</uf-panel>