import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, inject } from '@angular/core';
import { UfControlGroup } from '@unifii/library/common';
import { Field, FieldOption, FieldTemplate, FieldType, Option } from '@unifii/sdk';

import { IdentifierFunctions } from 'helpers/helpers';
import { DialogsService } from 'services/dialogs.service';

@Component({
    selector: 'uc-field-option',
    templateUrl: './field-option.html',
    styles: [`:host { display: block; }`],
})
export class FieldOptionComponent implements OnChanges {

    @Input({ required: true }) control: UfControlGroup;
    @Input({ required: true }) field: Field;
    @Input({ required: true }) option: FieldOption;
    @Input() isExpanded = false;
    @Output() remove = new EventEmitter<FieldOption>();
    // TODO remove asap should not be required when controls are attached to the same tree structure */
    @Output() notifyRefresh = new EventEmitter<void>();

    protected readonly fieldType = FieldType;
    protected labelPrefix: string;
    protected identifierMaxLength = IdentifierFunctions.IDENTIFIER_MAX_LENGTH;
    protected showAlignmentOptions: boolean;
    protected showContentField: boolean;

    private dialogs = inject(DialogsService);

    ngOnChanges(changes: SimpleChanges) {
        if (changes.option && this.field.type === FieldType.Bool) {
            const { identifier } = changes.option.currentValue as Option;

            this.labelPrefix = `${identifier.charAt(0).toUpperCase() + identifier.substring(1, identifier.length)} - `;
        }

        this.showAlignmentOptions = !([FieldType.Choice, FieldType.MultiChoice].includes(this.field.type) &&
            (this.field.template && [
                FieldTemplate.OptionWithContent,
                FieldTemplate.RadioWithContent,
                FieldTemplate.CheckboxWithContent,
            ].includes(this.field.template)));

        this.showContentField = !!this.field.template
            && ([FieldType.Choice, FieldType.MultiChoice].includes(this.field.type)
            && [FieldTemplate.CheckboxWithContent, FieldTemplate.RadioWithContent, FieldTemplate.OptionWithContent].includes(this.field.template));
    }

    protected nameChange(value: string) {

        // For boolean field identifiers set to booleans
        if (this.field.type === FieldType.Bool) {
            return;
        }

        // Generate identifier
        const identifier = IdentifierFunctions.safeOptionIdentifier(this.option, this.field.options ?? [], value);

        this.control.get('identifier')?.setValue(identifier, { onlySelf: false, emitValue: true });

        // TODO remove asap should not be required when controls are attached to the same tree structure
        setTimeout(() => {
            this.notifyRefresh.emit();
        }, 0);
    }

    protected async removeOption(event: Event) {

        event.stopPropagation();

        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.remove.emit(this.option);
    }

}
