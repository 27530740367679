<div class="grid">
    <uf-text *ngIf="control.controls.title" [control]="control.controls.title | asUfControl"
        [(value)]="builderService.compound._title" label="Title" class="col-1of1" />

    <uf-text *ngIf="control.controls.recordName" [control]="control.controls.recordName | asUfControl"
        [(value)]="builderService.compound.recordName" label="Record Name" class="col-1of1" />

    <uf-text *ngIf="control.controls.identifier" [control]="control.controls.identifier | asUfControl"
        [maxLength]="identifierMaxLength" [(value)]="builderService.definition.identifier" label="identifier"
        class="col-1of1" />
</div>