import { WORKFLOW_NOTIFICATION_FIELDS } from './fields';
import { WorkflowNotificationComponent } from './workflow-notification.component';
import { WorkflowNotificationsComponent } from './workflow-notifications.component';

export * from './fields';
export * from './workflow-notification-constants';
export * from './workflow-notification.component';
export * from './workflow-notifications.component';

export const WORKFLOW_NOTIFICATION_COMPONENTS = [
    WorkflowNotificationComponent,
    WorkflowNotificationsComponent,
    WORKFLOW_NOTIFICATION_FIELDS,
];
