import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'uc-template-form',
    templateUrl: './template-form.html',
})
export class TemplateFormComponent {

    @HostBinding('class.stretch-component') stretchComponentClass = true;

}

