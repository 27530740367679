import { Component, HostBinding, Inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { Device, Dictionary } from '@unifii/sdk';

import { UcApps } from 'client';

export interface SelectDevicesData {
    appId: string;
    selected?: Device[];
}

@Component({
    selector: 'uc-select-devices',
    templateUrl: './select-devices.html',
    styleUrls: ['./select-devices.less'],
})
export class SelectDevicesComponent implements Modal<SelectDevicesData, Device[]> {

    @HostBinding('class.uc-form-card') classes = true;

    filteredDevices: Device[] = [];
    selectedMap: Dictionary<boolean> = {};

    constructor(
        private ucApps: UcApps,
        public runtime: ModalRuntime<SelectDevicesData, Device[]>,
        @Inject(ModalData) public data: SelectDevicesData,
    ) {
        void this.filter();
    }

    async filter(q?: string | null) {
        const devices = await this.ucApps.getDevices(this.data.appId, { params: { q } });

        this.filteredDevices = this.filterSelected(devices, this.data.selected ?? []);
    }

    select(id: string) {

        if (this.selectedMap[id]) {
            delete this.selectedMap[id];

            return;
        }

        this.selectedMap[id] = true;
    }

    save() {
        const selected = this.filteredDevices.filter((device) => this.selectedMap[device.id]);

        this.runtime.close(selected);
    }

    close() {
        this.runtime.close();
    }

    private filterSelected(devices: Device[], selected: Device[]): Device[] {

        const selectedIds = selected.map((d) => d.id);

        return devices.filter((d) => !selectedIds.includes(d.id));
    }

}
