<uf-panel class="container">
    <div class="header-pane uf-app-bar transparent">
        <uf-breadcrumbs
            [breadcrumbs]="[{ urlSegments: ['/style-guide'], name: 'Style Guide' }, { name: 'Error Handling'}]"
            class="large" />
    </div>
    <div class="content-pane">
        <div class="row padded">
            <button (click)="error()" type="button" class="uf-button">
                <uf-icon name="error" />
                <span>Create Error</span>
            </button>
        </div>
    </div>
</uf-panel>