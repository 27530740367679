import { Inject } from '@angular/core';
import { DataPropertyInfoService, DataSourceMappingDisplayAllowedDataTypes } from '@unifii/library/common';
import { AstNode, DataSource, DataSourceOutputMap, DataSourceType, Dictionary, FieldType, NodeType, OutputField, SchemaField } from '@unifii/sdk';

import { FormEditorCache } from './form-editor-cache';
import { FormEditorFunctions } from './form-editor-functions';

interface DataSourceErrors {
    notFound: string[];
    incompatible: string[];
}

interface DataSourceMappingInfo {
    target: string;
    source: string;
    valueType: FieldType | null;
}

export class DataSourceValidator {

    constructor(
        @Inject(FormEditorCache) private cache: FormEditorCache,
        private dataPropertyInfoService: DataPropertyInfoService,
    ) { }

    validate(dataSource: DataSource): Promise<string | null> {

        if ([DataSourceType.Company, DataSourceType.Named].includes(dataSource.type)) {
            return Promise.resolve(null);
        }

        if (DataSourceType.External === dataSource.type) {
            return this.validateExternal(dataSource.id as string);
        }

        // Other DS types shared the same structure
        const { id, outputs, type, outputFields, filter } = dataSource;

        if (outputs == null || !Object.keys(outputFields ?? {}).length) {
            return Promise.resolve('Data source misconfigured');
        }

        switch (type) {
            case DataSourceType.Collection: return this.validateCollection(id as string, outputs, outputFields);
            case DataSourceType.Bucket: return this.validateBucket(id as string, outputs, outputFields);
            case DataSourceType.Users: return this.validateUser(outputs, outputFields, filter);
        }

        return Promise.resolve(null);
    }

    private async validateExternal(id: string): Promise<string | null> {
        const externalDataSource = await this.cache.getExternalDataSource(id);

        return externalDataSource ? null : 'External data source not found';
    }

    private async validateUser(outputs: DataSourceOutputMap, outputFields?: Dictionary<OutputField>, filter?: AstNode): Promise<string | null> {

        if (filter?.args && filter.args.length !== 0) {
            const parentNode = filter.args.find((arg) => arg.args?.find((arg2) => arg2.type === NodeType.Identifier && arg2.value === 'roles'));
            const userNode = parentNode?.args?.find((arg) => arg.type === NodeType.Value);

            if (userNode) {

                const errorMessage = await FormEditorFunctions.missingRoleError(this.cache, userNode.value);

                // console.log(userNode, errorMessage);
                if (errorMessage) {
                    return errorMessage;
                }
            }
        }

        const mappingInfo = this.getMappingInfo(outputs, outputFields)
            .filter((o) => /^claims\..*/.test(o.source))
            .map((info) => {
                info.source = info.source.replace('claims.', '');

                return info;
            });

        if (!mappingInfo.length) {
            return null;
        }

        try {
            const claimConfig = await this.cache.listUserClaimConfig() ?? [];
            const schemaFields = claimConfig.map((c) => ({ identifier: c.type, type: c.valueType, label: '' }));

            const { notFound, incompatible } = await this.errorReducer(mappingInfo, schemaFields);

            return this.createError(notFound, incompatible);
        } catch (e) {
            return 'Failed to load claim configuration';
        }
    }

    private async validateBucket(id: string, outputs: DataSourceOutputMap, outputFields?: Dictionary<OutputField>): Promise<string | null> {
        const schema = await this.cache.getSchema(id);

        if (schema == null) {
            return 'Form Data Repository not found';
        }

        const mappingInfo = this.getMappingInfo(outputs, outputFields, Object.keys(this.dataPropertyInfoService.formDefinitionReferences));

        const { notFound, incompatible } = await this.errorReducer(mappingInfo, schema.fields);

        return this.createError(notFound, incompatible);
    }

    private async validateCollection(id: string, outputs: DataSourceOutputMap, outputFields?: Dictionary<OutputField>): Promise<string | null> {
        const definition = await this.cache.getCollectionDefinition(id);

        if (definition == null) {
            return 'Collection not found';
        }

        const schemaField = (definition.fields?.filter((f) => f.identifier != null) ?? []) as SchemaField[];
        const mappingInfo = this.getMappingInfo(outputs, outputFields, Object.keys(this.dataPropertyInfoService.collectionItemReferences));

        const { notFound, incompatible } = await this.errorReducer(mappingInfo, schemaField);

        return this.createError(notFound, incompatible);
    }

    private async errorReducer(mappingInfo: DataSourceMappingInfo[], fields: SchemaField[]): Promise<DataSourceErrors> {
        const errors: DataSourceErrors = { notFound: [], incompatible: [] };

        for (const { target, source, valueType } of mappingInfo) {
            const isExpression = /{{.*?}}/g.test(source);

            if (isExpression) {
                return errors;
            }

            const found = await this.matchSource(source, fields, valueType);

            if (found == null) {
                const valueTypeError = valueType != null ? ` (${valueType})` : '';

                errors.notFound.push(`${source}${valueTypeError}`);        
            } else if (valueType != null && found?.type !== valueType) {
                errors.incompatible.push(`${source} (${valueType}, ${found?.type})`);
            } else if (target === '_display' && found?.type && !DataSourceMappingDisplayAllowedDataTypes.includes(found?.type)) {
                errors.incompatible.push(`${target} data type ${found?.type} not allowed`);
            }
        }

        return errors;
    }

    private createError(notFound: string[], incompatible: string[]): string | null {
        if (!notFound.length && !incompatible.length) {
            return null;
        }

        let message = '';

        if (notFound.length) {
            message += 'Data source missing mapped fields: ' + notFound.join(', ') + '. ';
        }
        if (incompatible.length) {
            message += 'Data source mapped fields have incompatible data types: ' + incompatible.join(', ') + '. ';
        }

        return message;
    }

    private getMappingInfo(outputs: Dictionary<string>, outputFields?: Dictionary<OutputField>, exclude: string[] = []): DataSourceMappingInfo[] {
        return Array.from(this.outputIterator(outputs, outputFields, exclude));
    }

    private *outputIterator(outputs: Dictionary<string>, outputFields?: Dictionary<OutputField>, exclude: string[] = []): Iterable<DataSourceMappingInfo> {
        for (const target of Object.keys(outputs)) {
            const source = outputs[target];
            let valueType: FieldType | null = null;

            if (!source) {
                continue;
            }

            if (outputFields) {
                valueType = outputFields[target]?.type ?? null;
            }

            if (!exclude.includes(source)) {
                yield { target, source, valueType };
            }
        }
    }

    private async matchSource(source: string, fields: SchemaField[], sourceValueType: FieldType | null): Promise<{ type: FieldType } | null> {
        for (const { identifier, type, dataSourceConfig } of fields) {
            if (source === identifier) {
                return { type };
            }

            if (!source.includes('.')) {
                continue;
            }

            const sourceParts = source.split('.');

            const [parent, child] = sourceParts;

            if (child && sourceParts.length > 2 && dataSourceConfig?.outputFields && dataSourceConfig.outputFields[child]?.type === FieldType.Lookup) {
                const childSchema = await this.cache.getSchema(dataSourceConfig.id);

                if (!childSchema) {
                    return null;
                }

                const childSource = sourceParts.slice(1).join('.');

                return this.matchSource(childSource, childSchema.fields, sourceValueType);
            }

            if (parent !== identifier) {
                continue;
            }

            // Trust that children of Hierarchy fields are
            if (type === FieldType.Hierarchy) {
                return { type: sourceValueType ?? FieldType.Text };
            }

            if (dataSourceConfig != null) {
                const { outputFields } = dataSourceConfig;

                if (outputFields == null) {
                    return null;
                }

                return child ? (outputFields[child] ?? null) : null;
            }

        }

        return null;
    }

}
