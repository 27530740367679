<uf-panel class="container">
    <ng-template [ngForOf]="groups" let-group ngFor>
        <ng-template [ngIf]="group.title">
            <uf-expander class="uc-group gap--bottom-small">
                <div expanderHeader class="uf-app-bar flat">
                    <span class="title primary">{{ group.title }}</span>
                </div>
                <div expanderBody class="uc-group-content padded--small">
                    <ul class="uc-list">
                        <!-- TODO replace with library list -->
                        <ng-template [ngForOf]="group.entries" let-field ngFor>
                            <li *ngIf="!field.disabled || config.debug" [dragItem]="field"
                                [dragDisabled]="field.disabled" class="uc-list-item--draggable--small row center-all">
                                <uf-icon [name]="field.icon" />
                                <span class="grow gap-left">{{ field.label }}</span>
                                <a *ngIf="field.customId" (click)="deleteCustomField(field)" class="uf-action tertiary"
                                    title="Delete">
                                    <uf-icon name="delete" />
                                </a>
                            </li>
                        </ng-template>
                    </ul>
                </div>
            </uf-expander>
        </ng-template>
        <ng-template [ngIf]="!group.title">
            <div>
                <ul class="uc-list drag-blocks-no-heading">
                    <ng-template [ngForOf]="group.entries" let-field ngFor>
                        <li [dragItem]="field" *ngIf="!field.disabled || config.debug" [dragDisabled]="field.disabled"
                            class="uc-list-item--draggable--small row center-all">
                            <uf-icon [name]="field.icon" />
                            <span class="grow gap-left">{{ field.label }}</span>
                            <a *ngIf="field.customId" (click)="deleteCustomField(field)" class="uf-action tertiary"
                                title="Delete">
                                <uf-icon name="delete" />
                            </a>
                        </li>
                    </ng-template>
                </ul>
            </div>
        </ng-template>
    </ng-template>
</uf-panel>