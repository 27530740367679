<div *ngIf="systemRolesControl?.enabled || superUserControl?.enabled" [ngClass]="cssClasses" class="uf-box">
    <div class="uf-app-bar flat accent">
        <div class="title">
            System Roles
        </div>
    </div>
    <div class="uc-group-content uf-grid pad">
        <uf-checkbox *ngIf="superUserControl && superUserControl.enabled" [control]="superUserControl"
            (valueChange)="toggleSuperUser($event)" label="SuperUser" class="col-12" />
        <uf-multi-choice *ngIf="systemRolesControl && systemRoleOptions.length" [control]="systemRolesControl"
            [options]="systemRoleOptions" label="System Roles" columns="4" class="col-12" />
    </div>
</div>