import { Inject, Injectable } from '@angular/core';
import { TableContainerManager, TableInputs } from '@unifii/components';
import { DataDisplayService, FilterEntry, FilterValue, TableAction, TableConfig, TableConfigColumn, ToastService } from '@unifii/library/common';
import { DataType } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { ApiKey, UcAPIKeys } from 'client';

import { APIKeysDataSource } from './api-keys-datasource';

@Injectable()
export class APIKeysTableManager implements TableContainerManager<ApiKey, FilterValue, FilterEntry> {

    tableConfig: TableConfig<ApiKey>;
    addActionConfig = true;
    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();

    constructor(
        private ucAPIKeys: UcAPIKeys,
        private toastService: ToastService,
        @Inject(DataDisplayService) private dataDisplayService: DataDisplayService,
    ) {
        this.tableConfig = {
            id: 'api-keys',
            columns: this.columns,
            actions: this.actions,
            pageSize: 50,
            selectable: true,
            columnToggles: true,
            rowLink: (role) => role.key ?? [],
        };
    }

    createDataSource() {
        return new APIKeysDataSource(this.ucAPIKeys);
    }

    private get columns(): TableConfigColumn<ApiKey>[] {
        return [{
            name: 'name',
            label: 'Name',
        }, {
            name: 'key',
            label: 'Key',
        }, {
            name: 'createdBy',
            label: 'Created By',
            value: (item) => item.createdBy.username,
        }, {
            name: 'createdAt',
            label: 'Created At',
            value: (item) => this.dataDisplayService.displayAsString(item.createdAt, { type: DataType.OffsetDateTime }),
        }];
    }

    private get actions(): TableAction<ApiKey>[] {
        return [{
            label: 'Revoke',
            icon: 'delete',
            action: (rows) => this.delete(rows.map((row) => row.$implicit)),
        }];
    }

    private async delete(apiKeys: ApiKey[]) {
        // Create new reference so array can be modified for printing a meaningful error message
        apiKeys = [...apiKeys];

        try {
            for (let i = (apiKeys.length - 1); i >= 0; i--) {
                const apiKey = apiKeys[i];

                if (!apiKey?.key) {
                    continue;
                }

                await this.ucAPIKeys.delete(apiKey.key);
                apiKeys.splice(i, 1);
            }
            this.toastService.success('API Keys revoke');
        } catch (e) {
            this.toastService.error(`Failed to revoke API Keys: ${apiKeys.map((k) => k.name).join(', ')}`);
        } finally {
            this.reload.next();
        }
    }

}
