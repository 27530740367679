import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { ClipboardService, DataPropertyDescriptor, DateAndTimeDisplayFormat, DateAndTimeFormat, DateDisplayFormat, DateFormat, DateWeekDayAndTimeDisplayFormat, DateWeekDayDisplayFormat, ExpressionParser, HierarchyLeafFormat, ToastService, UfControl, UfControlArray, UfControlGroup, ValidatorFunctions, isIdentifiersPathExpression } from '@unifii/library/common';
import { CellTemplate, CellTemplateType, CellVariation, ColumnDescriptor, DataSeed, FieldType, IconCell, LozengeCell, MessageColour, objectKeys } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { DialogsService } from 'services/dialogs.service';

interface ColumnInputValue {
    showOnDesktop: boolean;
    showOnMobile: boolean;
    heading: string | null;
    defaultTemplate: CellTemplateType | null;
    defaultFormat: string | null;
    defaultCellValue: string | null;
    colour: MessageColour | null;
    variations: VariationInputValue[];
    itemTemplate: string | null;
}

interface VariationInputValue {
    condition: string;
    value: string | null;
    template: CellTemplateType | null;
    colour: MessageColour | null;
}

@Component({
    selector: 'uc-column-descriptor-editor',
    templateUrl: 'column-descriptor-editor.html',
})
export class ColumnDescriptorEditorComponent implements OnInit, OnDestroy {

    @Input() parentControl: UfControlGroup;
    @Input() column: ColumnDescriptor;
    @Input() index: number;
    @Input() descriptor?: DataPropertyDescriptor;
    @Output() columnChange = new EventEmitter<ColumnDescriptor>();

    protected readonly templateOptions: DataSeed[] = [
        { _display: 'Default', _id: '' },
        { _display: 'Lozenge', _id: CellTemplateType.Lozenge },
    ];

    protected readonly colourOptions: DataSeed[] = [
        { _display: 'Accent', _id: MessageColour.Accent },
        { _display: 'Information', _id: MessageColour.Info },
        { _display: 'Warning', _id: MessageColour.Warning },
        { _display: 'Error', _id: MessageColour.Error },
        { _display: 'Success', _id: MessageColour.Success },
    ];

    protected readonly formatDatePlaceholder = DateDisplayFormat;
    protected readonly formatDateOptions = [
        DateFormat,
        DateWeekDayDisplayFormat,
        `E${DateWeekDayDisplayFormat}`,
    ];

    protected readonly formatDateTimePlaceholder = DateAndTimeDisplayFormat;
    protected readonly formatDateTimeOptions = [
        DateAndTimeFormat,
        DateWeekDayAndTimeDisplayFormat,
        `E${DateWeekDayAndTimeDisplayFormat}`,
    ];

    protected headingControl: UfControl;
    protected defaultCellValueControl: UfControl;
    protected itemTemplateControl: UfControl;
    protected control: UfControlGroup;
    protected formatOptions: string[] | null;
    protected formatPlaceholder: string | null;
    protected isCustomColumn: boolean;

    private subscriptions = new Subscription();

    private dialogsService = inject(DialogsService);
    private expressionParser = inject(ExpressionParser);
    private clipboardService = inject(ClipboardService);
    private toastService = inject(ToastService);

    ngOnInit() {
        this.isCustomColumn = !isIdentifiersPathExpression(this.column.identifier);

        this.defaultCellValueControl = new UfControl();

        this.headingControl = new UfControl(ValidatorFunctions.custom((v) =>
            !this.isCustomColumn ? true : !ValidatorFunctions.isEmpty(v),
            'Heading is required'),
        );

        this.itemTemplateControl = new UfControl();

        if (this.descriptor?.type === FieldType.Repeat) {
            this.itemTemplateControl.enable();
        } else {
            this.itemTemplateControl.disable();
        }

        this.control = new UfControlGroup({
            showOnDesktop: new UfControl(),
            showOnMobile: new UfControl(),
            heading: this.headingControl,
            defaultTemplate: new UfControl(),
            defaultFormat: new UfControl(),
            defaultCellValue: this.defaultCellValueControl,
            colour: new UfControl(),
            variations: new UfControlArray([]),
            itemTemplate: this.itemTemplateControl,
        });

        if (!this.isCustomColumn) {
            this.defaultCellValueControl.disable();
        }

        switch (this.descriptor?.type) {
            case FieldType.Date:
                this.formatPlaceholder = this.formatDatePlaceholder;
                this.formatOptions = [this.formatDatePlaceholder, ...this.formatDateOptions];
                break;
            case FieldType.DateTime:
            case FieldType.ZonedDateTime:
                this.formatPlaceholder = this.formatDateTimePlaceholder;
                this.formatOptions = [
                    this.formatDateTimePlaceholder,
                    ...this.formatDateTimeOptions,
                    this.formatDatePlaceholder,
                    ...this.formatDateOptions,
                ];
                break;
            case FieldType.Hierarchy:
                this.formatPlaceholder = 'full path';
                this.formatOptions = [HierarchyLeafFormat];
                break;
        }

        const inputValue = this.toInputValue(this.column);

        // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
        for (const _variation of inputValue.variations) {
            this.addVariation();
        }

        this.control.setValue(inputValue);
        this.subscriptions.add(this.control.valueChanges.subscribe((v: ColumnInputValue) => { this.onValueChanges(v); }));

        this.parentControl.setControl(`${this.index}`, this.control);
    }

    ngOnDestroy() {
        this.parentControl.removeControl(`${this.index}`);
        this.subscriptions.unsubscribe();
    }

    protected addVariation() {
        (this.control.get('variations') as UfControlArray).push(this.getNewVariation());
    }

    protected async removeVariation(index: number) {
        if (!await this.dialogsService.confirmDelete()) {
            return;
        }
        (this.control.get('variations') as UfControlArray).removeAt(index);
    }

    protected copyVariation(index: number) {
        const variation = ((this.control.get('variations') as UfControlArray).at(index) as UfControlGroup).getRawValue() as VariationInputValue;

        void this.clipboardService.setText(JSON.stringify(variation));
    }

    protected async pasteVariation() {
        try {
            const text = await this.clipboardService.getText();
            const variation = JSON.parse(text ?? '') as VariationInputValue;
            const variationControl = this.getNewVariation();

            variationControl.setValue(variation);
            (this.control.get('variations') as UfControlArray).push(variationControl);
            this.toastService.success('Pasted from clipboard');
        } catch (err) {
            console.error('Failed to read clipboard contents: ', err);
            this.toastService.error('Failed to paste');
        }
    }

    protected emit() {
        this.control.get('variations')?.updateValueAndValidity();
    }

    protected copyIdentifier() {
        void this.clipboardService.setText(this.column.identifier);
    }

    private toColumnDescriptor(v: ColumnInputValue): ColumnDescriptor {

        const { showOnDesktop, showOnMobile, heading, defaultTemplate, defaultFormat, defaultCellValue, colour, variations, itemTemplate } = v;

        const descriptor: ColumnDescriptor = {
            identifier: this.column.identifier,
            hideOnDesktop: !showOnDesktop,
            hideOnMobile: !showOnMobile,
            heading: heading ?? undefined,
            defaultTemplate: defaultTemplate ? this.getTemplate(defaultTemplate, colour ?? undefined) : undefined,
            defaultFormat: defaultFormat ?? undefined,
            defaultCellValue: defaultCellValue ?? undefined,
            variations: variations.map((variation) => this.toColumnVariation(variation)),
            itemTemplate: itemTemplate ?? undefined,
        };

        this.purgeEmptyValuesFromColumnDescriptor(descriptor);

        return descriptor;
    }

    private toColumnVariation(v: VariationInputValue): CellVariation {

        const { value, condition, template, colour } = v;
        const variation: CellVariation = { value: value ?? undefined, condition };

        if (template) {
            variation.template = this.getTemplate(template, colour ?? undefined);
        }
        this.purgeEmptyValuesFromCellVariation(variation);

        return variation;
    }

    private toInputValue(column: ColumnDescriptor): ColumnInputValue {
        const { hideOnMobile, hideOnDesktop, heading, defaultTemplate, defaultFormat, defaultCellValue, variations, itemTemplate } = column;

        return {
            showOnDesktop: !hideOnDesktop,
            showOnMobile: !hideOnMobile,
            heading: heading ?? null,
            defaultTemplate: defaultTemplate?.type ?? null,
            defaultFormat: defaultFormat ?? null,
            defaultCellValue: defaultCellValue ?? null,
            colour: (defaultTemplate as LozengeCell | IconCell | undefined)?.colour ?? null,
            variations: (variations ?? []).map((v) => this.toInputVariation(v)),
            itemTemplate: itemTemplate ?? null,
        };
    }

    private toInputVariation(variation: CellVariation): VariationInputValue {

        const { value, condition, template } = variation;

        return {
            value: value ?? null,
            condition,
            template: template?.type ?? null,
            colour: (template as LozengeCell | IconCell | undefined)?.colour ?? null,
        };
    }

    private getNewVariation(): UfControlGroup {
        const template = new UfControl();

        template.setValue('');

        return new UfControlGroup({
            value: new UfControl(),
            condition: new UfControl(ValidatorFunctions.compose([
                ValidatorFunctions.required('A condition is required'),
                ValidatorFunctions.isValidExpression(this.expressionParser, 'Provide a valid expression'),
            ])),
            template,
            colour: new UfControl(),
        });
    }

    private getTemplate(type: CellTemplateType, colour?: MessageColour): CellTemplate {
        const template: CellTemplate = { type };

        if (colour) {
            (template as LozengeCell | IconCell).colour = colour;
        }

        return template;
    }

    private onValueChanges(v: ColumnInputValue) {
        const descriptor = this.toColumnDescriptor(v);

        this.columnChange.emit(descriptor);
    }

    private purgeEmptyValuesFromColumnDescriptor(descriptor: ColumnDescriptor) {
        for (const key of objectKeys(descriptor)) {
            const value = descriptor[key];

            if (!value || (Array.isArray(value) && !value.length)) {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete descriptor[key];
            }

            if (Array.isArray(value)) {
                for (const variation of value) {
                    this.purgeEmptyValuesFromCellVariation(variation);
                }
            }
        }
    }

    private purgeEmptyValuesFromCellVariation(variation: CellVariation) {

        for (const key of objectKeys(variation)) {
            const value = variation[key];

            if (!value) {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete variation[key];
            }
        }
    }

}
