<ng-template [ngIf]="ready">
    <div class="uf-box flat pad-sm-sides uf-grid row-gap-sm pad-sm-top pad-sm-bottom">

        <ng-template [ngIf]="control.showError && control.errors?.message">
            <uf-message [content]="control.errors?.message" class="error col-12" icon="error" />
        </ng-template>

        <!-- <span class="col-12">Meta: <uf-help [content]="meta | json"></uf-help></span> -->
        <uf-select *ngIf="meta.variations && variations.length" [options]="variationOptions"
            [(value)]="selectedVariationOption" (valueChange)="selectVariation($event)" label="Variation" placeholder="Default"
            class="col-12 pad-sm-sides" />

        <ng-template [ngIf]="!variation">
            <uc-form-field-details *ngIf="visibles.details" [control]="control" class="col-12" />
            <uc-form-field-transitions *ngIf="visibles.transitions" [control]="control" [transitions]="transitions"
                [meta]="meta" class="col-12" />
            <uc-form-field-settings *ngIf="visibles.settings" [control]="control" class="col-12" />
            <uc-form-field-options *ngIf="visibles.options" [type]="field.type" [meta]="meta" [options]="options"
                [dataSourceConfig]="dataSourceConfig" [template]="template" [id]="id" [identifier]="identifier"
                [isReportable]="isReportable" [avoidDuplicates]="avoidDuplicates" [dataCaptures]="dataCaptures"
                class="col-12" />
            <uc-form-field-nested-fields *ngIf="visibles.nestedFields" [control]="control" class="col-12" />
            <uc-form-field-display *ngIf="visibles.display" [control]="control" class="col-12" />
            <uc-form-field-variations *ngIf="visibles.variations" [control]="control" [meta]="meta" class="col-12" />
            <uc-form-field-validators *ngIf="visibles.validators" [validators]="validators" [type]="field.type"
                class="col-12" />
            <uc-form-field-advanced *ngIf="visibles.advanced" [control]="control" class="col-12" />
        </ng-template>

        <uc-form-field-variation *ngIf="variation" [control]="variation" [type]="field.type" [template]="template"
            [id]="id" [identifier]="identifier" [meta]="meta" [isReportable]="isReportable" class="col-12" />
    </div>
</ng-template>