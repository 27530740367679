import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ErrorType, UfError, isStringNotEmpty } from '@unifii/sdk';
import { EMPTY, lastValueFrom } from 'rxjs';

import { UcProjectInfo } from 'client';
import { useDefaultErrorMessage } from 'components';
import { ProjectService } from 'services/project.service';

export const projectResolver = async(route: ActivatedRouteSnapshot): Promise<UcProjectInfo | UfError> => {

    const projectService = inject(ProjectService);

    const { projectId } = route.params;

    if (!isStringNotEmpty(projectId)) {
        return lastValueFrom(EMPTY);
    }

    try {
        const project = await projectService.setProject(projectId);

        if (!project) {
            return useDefaultErrorMessage(new UfError('Project not found', ErrorType.NotFound));
        }

        return project;

    } catch (err) {
        return useDefaultErrorMessage(err);
    }
};
