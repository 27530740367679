<uf-panel class="container scrollable">
    <div class="uf-container">
        <div class="uf-grid">
            <div class="col-12 gap-top">Flat (items)</div>
            <uc-drag-list [items]="flatItems" class="col-12">
                <ng-template [ngForOf]="flatItems" ngFor let-item>
                    <div dragItem class="uf-app-bar  uf-box flat draggable gap-sm-bottom">
                        <div class="title primary">{{item.title}}</div>
                    </div>
                </ng-template>
            </uc-drag-list>

            <div class="col-12">Nested (items)</div>
            <uc-drag-list [items]="nestedItems" class="col-12">
                <ng-template [ngForOf]="nestedItems" ngFor let-item>

                    <div [nestable]="true" dragItem>
                        <!-- Parent -->
                        <ng-template [ngIf]="item.children?.length" [ngIfElse]="leaf">
                            <uf-expander>
                                <div expanderHeader class="uf-app-bar  uf-box flat draggable gap-sm-bottom">
                                    <div class="title primary">{{item.title}}</div>
                                </div>
                                <uc-drag-list *ngIf="item.children" [items]="item.children" expanderBody
                                    class="gap-left">
                                    <ng-template [ngForOf]="item.children" ngFor let-child>
                                        <div dragItem class="uf-app-bar  uf-box flat draggable gap-sm-bottom">
                                            <div class="title primary">{{child.title}}</div>
                                        </div>
                                    </ng-template>
                                </uc-drag-list>
                            </uf-expander>
                        </ng-template>
                        <!-- Leaf -->
                        <ng-template #leaf>
                            <div [nestable]="true" dragItem class="uf-app-bar  uf-box flat draggable gap-sm-bottom">
                                <div class="title primary">{{item.title}}</div>
                            </div>
                        </ng-template>
                    </div>

                </ng-template>
            </uc-drag-list>

            <div class="col-12">Flat (controls) <span *ngIf="flatEdited">*</span></div>
            <uc-drag-list [items]="flatControl.controls" class="col-12">
                <ng-template [ngForOf]="flatControl.controls" ngFor let-control>
                    <div dragItem class="uf-app-bar  uf-box flat draggable gap-sm-bottom">
                        <div class="title primary">{{control.value.title}}</div>
                    </div>
                </ng-template>
            </uc-drag-list>

            <div class="col-12">Nested (controls) <span *ngIf="nestedEdited">*</span></div>
            <uc-drag-list [items]="nestedControl.controls" class="col-12">
                <ng-template [ngForOf]="nestedControl.controls" ngFor let-control>

                    <div [nestable]="true" dragItem>
                        <!-- Parent -->
                        <ng-template [ngIf]="$any(control.get('children')).length" [ngIfElse]="leaf">
                            <uf-expander>
                                <div expanderHeader class="uf-app-bar  uf-box flat draggable gap-sm-bottom">
                                    <div class="title primary">{{control.value.title}}</div>
                                </div>
                                <uc-drag-list [items]="$any(control.get('children'))?.controls" expanderBody
                                    class="gap-left">
                                    <ng-template [ngForOf]="$any(control.get('children'))?.controls" ngFor let-child>
                                        <div dragItem class="uf-app-bar  uf-box flat draggable gap-sm-bottom">
                                            <div class="title primary">{{child.value.title}}</div>
                                        </div>
                                    </ng-template>
                                </uc-drag-list>
                            </uf-expander>
                        </ng-template>
                        <!-- Leaf -->
                        <ng-template #leaf>
                            <div [nestable]="true" dragItem class="uf-app-bar  uf-box flat draggable gap-sm-bottom">
                                <div class="title primary">{{control.value.title}}</div>
                            </div>
                        </ng-template>
                    </div>

                </ng-template>
            </uc-drag-list>
        </div>
    </div>
</uf-panel>