<ng-template [ngIf]="ready">

  <div class="header">
    <button (click)="close()" type="button" class="uf-action tertiary" title="Close">
      <uf-icon name="close" />
    </button>
    <h3>Users Filter Builder</h3>
  </div>

  <div class="content grid">
    <!-- Roles and Claims -->
    <uf-chips [control]="rolesControl" [options]="roleResults" [(value)]="roles" (searchChange)="findRoles($event)"
      class="col-1of1" label="Add Role(s)" placeholder="Search roles" />
    <div class="col-1of1">
      <button (click)="addClaim()" type="button" class="uf-button x-small right">Add Claim</button>
    </div>
  </div>

  <div class="content grow scrollable">
    <div class="grid">
      <ng-template [ngForOf]="claims" let-claim ngFor let-i="index">
        <div class="field-row col-1of1--no-padding">
          <div class="grid grow">
            <uf-autocomplete [control]="$any(claimsControl.at(i)).controls.type" [allowCustom]="true"
              [options]="claimOptions" [(value)]="claim.type" (searchChange)="searchClaims($event)"
              class="col-1of2" label="Type" placeholder="Search Claims" />
            <uf-text [control]="$any(claimsControl.at(i)).controls.value" [(value)]="claim.value" class="col-1of2"
              label="Value" />
          </div>

          <!-- Remove entry -->
          <div class="delete">
            <a (click)="removeClaim(i)">
              <uf-icon name="delete" />
            </a>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="row space-children pad">
    <button (click)="close()" type="button" class="uf-button tertiary right">Cancel</button>
    <button (click)="save()" type="button" class="uf-button primary">Confirm</button>
  </div>

</ng-template>