import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { FormDefinitionMetadataIdentifiers, ModalService } from '@unifii/library/common';
import { CompoundType, UfError } from '@unifii/sdk';
import { Observable } from 'rxjs';

import { UcDefinition, UcProject } from 'client';
import { useDefaultErrorMessage } from 'components';
import { FormMetadataModalComponent } from 'pages/form-editor';
import { cleanDefinitionToBeDuplicated } from 'pages/utils';

/**
 * @description
 * Ensures that minimum settings are provided to FormEditor component to initialize
 */
export const definitionResolver: ResolveFn<UcDefinition | UfError> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
    new DefinitionResolver(
        inject(UcProject),
        inject(ModalService),
        inject(Router),
    ).resolve(route, state);

class DefinitionResolver {

    constructor(
        private ucProject: UcProject,
        private modalService: ModalService,
        private router: Router,
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UcDefinition | UfError> {
        const prevUrl = state.url.replace('/new', '');
        const type = route.data.type;
        const { id, duplicate } = route.params;

        return this.resolvePromise(this.getDefinition(id, type, duplicate), prevUrl);
    }

    private resolvePromise(promise: Promise<UcDefinition | undefined>, prevUrl: string): Observable<UcDefinition | UfError> {
        // Wraps promises with an observable that completes which will cancel navigation
        return new Observable((subscriber) => {
            promise.then((d) => {
                if (d) {
                    subscriber.next(d);
                } else {
                    void this.router.navigateByUrl(prevUrl);
                }
                subscriber.complete();
            }).catch((err) => {
               const error = useDefaultErrorMessage(err);

                subscriber.next( error );
                subscriber.complete();
            });
        });
    }

    private async getDefinition(id: string, type: CompoundType, duplicate = false): Promise<UcDefinition | undefined> {
        if (id === 'new') {
            return this.createNewDefinition(type);
        }

        const definition = await this.getDefinitionProvider(id, type);

        if (!duplicate) {
            return definition;
        }

        Object.values(FormDefinitionMetadataIdentifiers).forEach((attribute) => {
            delete definition[attribute as keyof UcDefinition];
        });

        const duplicatedDefinition = cleanDefinitionToBeDuplicated(definition) as UcDefinition;

        delete duplicatedDefinition.state;
        delete duplicatedDefinition.version;

        duplicatedDefinition.identifier += '-copy';
        duplicatedDefinition.label += ' - COPY';

        return duplicatedDefinition;
    }

    private createNewDefinition(type: CompoundType): Promise<UcDefinition | undefined> {
        switch (type) {
            case CompoundType.Form:
                return this.modalService.openMedium(FormMetadataModalComponent);
            // Implement more modals as needed
            default:
                throw new Error('type not available');
        }
    }

    private getDefinitionProvider(id: string, type: CompoundType): Promise<UcDefinition> {
        switch (type) {
            case CompoundType.Form: return this.ucProject.getForm(id);
            // Implement more requests as needed
            default: throw new Error('type not available');
        }
    }

}
