import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService, StorageWrapper } from '@unifii/library/common';
import { ErrorType, Interceptor, ensureUfError } from '@unifii/sdk';

import { PREVIEW_FORM_STORAGE_KEY } from 'constant';
import { ContextService } from 'services/context.service';

import { TokenService } from './token.service';

@Injectable()
export class SdkInterceptor implements Interceptor {

    private _disabled = false;
    private router = inject(Router);
    private modalService = inject(ModalService);
    private location = inject(Location);
    private context = inject(ContextService);
    private storage = inject(StorageWrapper);
    private tokenService = inject(TokenService);

    async intercept(source: Promise<any>): Promise<any> {
        try {
            return await source as Promise<any>;
        } catch (error) {
            const sdkError = ensureUfError(error);

            if (this.requiresRedirect(sdkError.type)) {
                // Redirect to the most appropriate route
                await this.redirectUser(sdkError.type);
                // The error may have happened in a modal dialog, close all of them here
                this.modalService.closeAll();
            }
            throw error;
        }
    }

    set disabled(v: boolean) {
        this._disabled = v;
    }

    get disabled(): boolean {
        return this._disabled;
    }

    private requiresRedirect(errorType: ErrorType): boolean {
        if (this.disabled) {
            return false;
        }

        return errorType === ErrorType.Unauthorized || errorType === ErrorType.Forbidden;
    }

    private redirectUser(errorType: ErrorType): Promise<boolean> {
        const path = errorType === ErrorType.Forbidden ? '/unauthorized' : '/login';
        const currentUrl = this.location.path();

        // Already redirected
        if (currentUrl.startsWith(path)) {
            return Promise.resolve(true);
        }

        this.context.clear();
        this.tokenService.clear();
        this.storage.removeItem(PREVIEW_FORM_STORAGE_KEY);

        return this.router.navigate([`/${path}`, { return: currentUrl }]);
    }

}
