import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { TokenService } from 'client';

/**
 * Ensure no user is currently logged in
 */
export const anonymousGuard: CanActivateFn = (): boolean => {

    const tokenService = inject(TokenService);
    const router = inject(Router);

    if (!tokenService.token) {
        return true;
    }

    void router.navigate(['/']);

    return false;
};
