<div class="header">
    <button (click)="close()" title="Close" type="button" class="uf-action tertiary">
        <uf-icon name="close" />
    </button>
    <h3>Form Data</h3>
</div>
<uf-panel>
    <pre class="gap"><code>{{this.formData}}</code></pre>
</uf-panel>
<div class="row space-children pad">
    <button (click)="close()" class="uf-button tertiary right" type="button">Cancel</button>
    <button (click)="copy()" class="uf-button primary" type="button">
        Copy
    </button>
</div>