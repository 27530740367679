import { Injectable, OnDestroy, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Config } from 'app-config';

import { ContextService } from './context.service';

// TODO is this class needed??
@Injectable({ providedIn: 'root' })
export class TitleService implements OnDestroy {

    private subscriptions = new Subscription();
    private context = inject(ContextService);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private title = inject(Title);
    private config = inject(Config);

    init() {
        this.subscriptions.add(this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
        ).subscribe(() => this.updateName()));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private updateName() {

        let result = this.context.tenantSettings?.name ?? this.config.title;

        let title = '';
        let child = this.route.firstChild;

        while (child) {
            // Store title
            if (child.snapshot.data?.title) {
                title = child.snapshot.data.title;
            }
            // Look for deeper route title (more specific)
            if (child.firstChild) {
                child = child.firstChild;
            } else {
                child = null;
            }
        }

        if (title) {
            result += ' | ' + title;
        }

        this.title.setTitle(result);
    }

}
