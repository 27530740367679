<uf-expander [class.uc-group--draggable--no-shrink]="canDragField && !isSelected"
    [class.uc-group--draggable--no-shrink--accent]="canDragField && isSelected" class="uc-group">
    <div [class.accent]="isSelected" (click)="selectField($event)" expanderHeader dragHandle class="uf-app-bar flat">
        <uf-icon [name]="icon" />
        <div class="title primary">
            {{ title }}
            <span *ngIf="configuredField.label"> | {{ configuredField.label }}</span>
        </div>
        <uf-icon *ngIf="!isValid" name="warning" class="icon-warning" />
        <uf-help *ngIf="configuredField.help" [content]="configuredField.help" class="uf-action tertiary" />
        <a *ngIf="canRemoveField" (click)="removeField($event)" title="Delete" class="uf-action tertiary">
            <uf-icon name="delete" />
        </a>
    </div>
    <div [class.content--accent]="isSelected" expanderBody class="uc-group-content">
        <ul *ngIf="content" class="uc-list">
            <li class="uc-list-item--small--no-shrink row center-all">
                <div class="list-content">
                    <div class="list-context">
                        <a [routerLink]="getLink(content)" target="_blank" title="Open" class="uf-action tertiary">
                            <uf-icon name="open" />
                        </a>
                        <a *ngIf="canEditField && content" (click)="delete(0)" title="Delete"
                            class="uf-action tertiary">
                            <uf-icon name="delete" />
                        </a>
                    </div>
                    <div>{{ $any(content).name || $any(content).recordName }}</div>
                </div>
            </li>
        </ul>
        <div *ngIf="canEditField && !content" class="row padded">
            <button (click)="add()" type="button" class="uf-button x-small right">Select {{ title }}</button>
        </div>
    </div>
</uf-expander>
<uf-error [control]="control" />