<div #wrapper class="column grow container">
    <div *ngIf="!errorMessage" class="row wrapper">
        <uc-hierarchy-finder-column *ngFor="let column of columns; let i = index;" [label]="column.label"
            [units]="column.units" [selected]="column.selected" [parentId]="column.parentId"
            [hideEditButton]="i === 0" (selectUnit)="select($event, i)" (updateGroupLabel)="editColumnLabel(i)" (finderUnitChange)="finderUnitChange($event, i)"
            (add)="add(i)" />
    </div>
    <uf-message *ngIf="errorMessage" [showExpander]="false" [content]="errorMessage" class="error small" icon="alert">
        <button (click)="retry()" class="uf-button x-small right" type="button">
            Retry
        </button>
    </uf-message>
</div>