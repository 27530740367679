<div class="uf-grid condensed">
    <uf-blockquote class="col-12 info gap-bottom" icon="infoSolid"
        content="You can add and reorder multiple email templates on top of the default template. The email will use the top matching template based on order and conditions, or fall back to default." />
    <uc-drag-list *ngIf="templatesControl.controls.length" [items]="templatesControl.controls" canDrop="false"
        canReorder="true" class="col-12">
        <ng-container *ngFor="let control of (templatesControl.controls | asUfControlsGroups); let i = index">
            <div dragItem>
                <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{i, control}" />
            </div>
        </ng-container>
    </uc-drag-list>
    <div class="col-12">
        <ng-container [ngTemplateOutlet]="template"
            [ngTemplateOutletContext]="{control: defaultTemplateControl, isDefault: true}" />
    </div>
    <div class="col-12 row gap-top">
        <button (click)="addTemplate()" type="button" class="uf-button secondary right" title="Add Template">
            Add Template
        </button>
    </div>
    <uf-error *ngIf="templatesControl.invalid" [control]="templatesControl | asUfControlArray" class="col-12 gap-top" />
</div>


<ng-template #template let-i="i" let-control="control" let-isDefault="isDefault">
    <uf-expander [isExpanded]="isDefault ? false : expanderStates[i]" class="uf-box flat gap-sm-top">
        <div class="uf-app-bar pad-sm-left" expanderHeader dragHandle>
            <div class="grow title primary">
                {{control.get(templateControlKey.Title)?.value}}
            </div>
            <uf-icon *ngIf="control.invalid && control.submitted" name="error" class="error" />
            <button (click)="preview(i, isDefault)" type="button" class="uf-action tertiary" title="Preview">
                <uf-icon name="view" />
            </button>
            <button *ngIf="!isDefault" (click)="removeTemplate(i)" type="button" title="Delete Template"
                class="uf-action tertiary">
                <uf-icon name="delete" />
            </button>
        </div>
        <ng-container expanderBody>
            <div [formGroup]="control" class="uf-grid pad">
                <uf-text [formControlName]="templateControlKey.Title" class="col-12" label="Title" />
                <uf-text [formControlName]="templateControlKey.Subject" class="col-12" label="Email Subject" />
                <uf-markdown-editor [formControlName]="templateControlKey.IntroMessage" class="col-12"
                    label="Intro Message" />
                <uf-text [formControlName]="templateControlKey.ButtonLabel" class="col-12" label="Button Label" />
                <uf-markdown-editor [formControlName]="templateControlKey.BodyMessage" class="col-12"
                    label="Body Message" />
                <uf-text [formControlName]="templateControlKey.BaseUrl" class="col-12" label="Base URL">
                    <uf-help content="Base URL from General Settings will be used if left blank." />
                </uf-text>
                <uf-text [formControlName]="templateControlKey.ReplyTo" class="col-12" label="Reply to">
                    <uf-help content="Comma separate multiple email addresses." />
                </uf-text>
                <ul class="uf-list uf-box col-12">
                    <ng-container
                        *ngIf="!isDefault && control.get(templateControlKey.Conditions); let conditionControls">
                        <li class="uf-list-fieldset"
                            *ngFor="let conditionControl of (conditionControls | asUfControlArray).controls | asUfControlsGroups; let j = index">
                            <div [formGroup]="conditionControl" class="uf-grid grow pad gaps">
                                <uf-select [formControlName]="conditionControlKey.ConditionType"
                                    [options]="conditionOptions" (valueChange)="conditionTypeChanged($event, i, j)"
                                    label="Condition Type" class="col-12" valueProperty="identifier"
                                    nameProperty="name" />
                                <div *ngIf="conditionControl.get(conditionControlKey.Claim)?.enabled"
                                    [formGroupName]="conditionControlKey.Claim" class="col-12 uf-grid">
                                    <uf-autocomplete [options]="claimOptions" [formControlName]="conditionClaimControlKey.Type"
                                        (searchChange)="searchClaims($event)" label="Claim Type"
                                        class="col-6" nameProperty="type" />

                                    <uf-claim-field
                                        *ngIf="conditionControl.get(conditionControlKey.Claim)?.get(conditionClaimControlKey.Type)?.value; let claimConfig"
                                        [formControlName]="conditionClaimControlKey.Value" [claimConfig]="claimConfig"
                                        class="col-6" />
                                </div>
                                <uf-autocomplete [options]="roleOptions" [formControlName]="conditionControlKey.Role"
                                    (searchChange)="searchRoles($event)"
                                    *ngIf="conditionControl.get(conditionControlKey.Role)?.enabled" label="Role"
                                    class="col-12" />
                            </div>
                            <div class="pad-sm">
                                <button *ngIf="!isDefault" (click)="removeCondition(i, j)" type="button"
                                    class="uf-action tertiary" title="Delete Condition">
                                    <uf-icon name="delete" />
                                </button>
                            </div>
                        </li>
                    </ng-container>
                </ul>
                <div *ngIf="!isDefault" class="col-12 row">
                    <button (click)="addCondition(i)" type="button" class="uf-button secondary right"
                        title="Add Condition">
                        Add Condition
                    </button>
                </div>
                <uf-error [control]="control.get(templateControlKey.Conditions)" />
            </div>
        </ng-container>
    </uf-expander>
</ng-template>

<ng-template #header>

</ng-template>