<uf-expander [class.uc-group--draggable--no-shrink]="canDragField && !isSelected"
    [class.uc-group--draggable--no-shrink--accent]="canDragField && isSelected" class="uc-group">
    <div [class.accent]="isSelected" (click)="selectField($event)" expanderHeader dragHandle class="uf-app-bar flat">
        <uf-icon name="audio" class="inset-icon small" />
        <div class="title primary">
            <span>Sound List</span>
            <span *ngIf="configuredField.label"> | {{ configuredField.label }}</span>
        </div>
        <uf-help *ngIf="configuredField.help" [content]="configuredField.help" class="uf-action tertiary" />
        <a *ngIf="canRemoveField" (click)="removeField($event)" title="Delete" class="uf-action tertiary">
            <uf-icon name="delete" />
        </a>
    </div>
    <div [class.content--accent]="isSelected" expanderBody class="uc-group-content">
        <uc-drag-list *ngIf="content" [canReorder]="canEditField" [items]="content" [parent]="content" (moved)="moved()"
            canDrop="false">
            <ul class="uc-list--gap">
                <li *ngFor="let sound of content, let i = index"
                    [class.uc-list-item--small--no-shrink--bordered]="canEditField"
                    [class.uc-list-item--draggable--small--no-shrink]="!canEditField" [dragDisabled]="canEditField"
                    dragItem class="row center-all">
                    <div class="list-content">
                        <div class="list-context">
                            <a [routerLink]="['/assets', 'media', sound.id]" target="_blank" title="Open"
                                class="uf-action tertiary">
                                <uf-icon name="open" />
                            </a>
                            <button *ngIf="canEditField" (click)="delete(i)" type="button" tabindex="-1" title="Delete"
                                class="uf-action tertiary">
                                <uf-icon name="delete" />
                            </button>
                        </div>
                        <div>{{sound.title}}</div>
                        <div class="fieldset-item--x-small">
                            <span>File name: </span>
                            <span>{{sound.filename}}</span>
                        </div>
                        <div class="fieldset-item--x-small">
                            <span>Description: </span>
                            <span>{{sound.description}}</span>
                        </div>
                        <div class="fieldset-item--x-small">
                            <span>Uploaded at: </span>
                            <span>{{ sound.uploadedAt | date:'short' }}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </uc-drag-list>
        <div class="row padded">
            <button *ngIf="canEditField" (click)="add()" type="button" class="uf-button x-small right">Add
                Sound</button>
        </div>
    </div>
</uf-expander>
<uf-error [control]="control" />