import { Injectable, inject } from '@angular/core';
import { BucketDataDescriptorAdapter, UfFormBuilder, ValidatorFunctions, areSourceConfigsCompatible, fieldIterator } from '@unifii/library/common';
import { DataSourceInputType, FieldType, Transition } from '@unifii/sdk';

import { IntegrationFeature, IntegrationFeatureArg, IntegrationFeatureArgType, UcDefinition } from 'client';
import { mapArgType } from 'components/field-builder/data-source-editor/data-source-editor-functions';
import { MappableField } from 'models';

import { WorkflowInputMap } from './workflow-types';

export const compatibleIntegrationFields: Record<DataSourceInputType, FieldType> = {
    [DataSourceInputType.Number]: FieldType.Number,
    [DataSourceInputType.Text]: FieldType.Text,
};

// list of dataSourceCompatibleFields
const dataSourceFieldTypes = [FieldType.Repeat, FieldType.Choice, FieldType.Lookup];

// list of compatible fields matrix
// [Source]: [Target]
const compatibleSchemaFields = new Map<FieldType, FieldType[]>([
    [FieldType.Address, [FieldType.Address]],
    [FieldType.Bool, [FieldType.Bool]],
    [FieldType.Choice, [FieldType.Choice]],
    [FieldType.Content, [FieldType.Content]],
    [FieldType.Cost, [FieldType.Cost]],
    [FieldType.Date, [FieldType.Date]],
    [FieldType.DateTime, [FieldType.DateTime]],
    [FieldType.ZonedDateTime, [FieldType.ZonedDateTime]],
    [FieldType.Email, [FieldType.Email]],
    [FieldType.FileList, [FieldType.FileList]],
    [FieldType.GeoLocation, [FieldType.GeoLocation]],
    [FieldType.Hierarchy, [FieldType.Hierarchy]],
    [FieldType.ImageList, [FieldType.ImageList, FieldType.FileList]],
    [FieldType.MultiChoice, [FieldType.MultiChoice]],
    [FieldType.MultiText, [FieldType.MultiText]],
    [FieldType.Number, [FieldType.Number, FieldType.Lookup]],
    [FieldType.Phone, [FieldType.Phone]],
    [FieldType.Repeat, [FieldType.Repeat]],
    [FieldType.Section, [FieldType.Section]],
    [FieldType.Separator, [FieldType.Separator]],
    [FieldType.Signature, [FieldType.Signature]],
    [FieldType.SoundList, [FieldType.SoundList]],
    [FieldType.Stepper, [FieldType.Stepper]],
    [FieldType.Survey, [FieldType.Survey]],
    [FieldType.Text, [FieldType.Text, FieldType.MultiText, FieldType.Lookup]],
    [FieldType.Time, [FieldType.Time]],
    [FieldType.Website, [FieldType.Website]],
    [FieldType.Link, [FieldType.Link]],
]);

// list of compatible datasource fields matrix
// [Source]: [Target]
const dataSourceCompatibleFields = new Map<FieldType, FieldType[]>([
    [FieldType.Choice, [FieldType.Choice, FieldType.Lookup]],
    [FieldType.Lookup, [FieldType.Choice, FieldType.Lookup]],
    [FieldType.Repeat, [FieldType.Repeat]],
]);

// list of compatible fields with target Datasource matrix
// [Source]: [Target]
const targetDataSourceCompatibleFields = new Map<FieldType, FieldType[]>([
    [FieldType.Text, [FieldType.Choice, FieldType.Lookup]],
    [FieldType.Number, [FieldType.Choice, FieldType.Lookup]],
]);

@Injectable({ providedIn: 'root' })
export class FieldMappingService {

    private readonly requiredMessage = 'Field is Required';
    private ufb = inject(UfFormBuilder);
    private bucketDataDescriptorAdapter = inject(BucketDataDescriptorAdapter);

    async mapDefinitionToMappableFields(form: UcDefinition, targetTransition?: Transition): Promise<MappableField[]> {

        if (!form.bucket) {
            return [];
        }

        const definitionFields: MappableField[] = [...fieldIterator(form.fields, undefined, {
            canDive: (field) => {
                switch (field.type) {
                    case FieldType.Repeat: return false;
                    case FieldType.Section: return !!field.transitions?.some((t) => t.source === targetTransition?.source);
                    case FieldType.ActionGroup: return field.showOn === targetTransition?.action;
                    default: return true;
                }
            },
            canIterate: (field) => !!field.identifier,
        })].map((entry) => {
            const { label, identifier, isRequired, type } = entry.field;

            return {
                label: label ?? '',
                identifier: identifier ?? '',
                isRequired: !!isRequired && !!targetTransition?.validate,
                type,
            };
        });

        return (await this.bucketDataDescriptorAdapter.getDataDescriptor(form.bucket))?.propertyDescriptors.flatMap((descriptor) => {
            const { label, identifier, type, sourceConfig } = descriptor;
            const definition = definitionFields.find((field) => field.identifier === identifier);

            if (!definition) {
                return [];
            }

            return {
                label,
                identifier,
                type,
                isRequired: !!definition.isRequired && !!targetTransition?.validate,
                sourceConfig,
            };
        }) ?? [];
    }

    getSchemaMappableFields(bucketId: string) {
        return this.bucketDataDescriptorAdapter.getDataDescriptor(bucketId).then((dataDescriptor) => dataDescriptor?.propertyDescriptors.map((dataPropertyDescriptor) => {
            const { label, identifier, type, sourceConfig } = dataPropertyDescriptor;

            return { label, identifier, type, sourceConfig } as MappableField;
        }) ?? []);
    }

    getIntegrationFeatureMappableFields(feature: IntegrationFeature): MappableField[] {
        const attributes = feature.input?.attributes;

        if (!attributes) {
            return [];
        }

        return Object.keys(attributes).reduce<MappableField[]>((mappableFields, key) => {
            const inputArgument = attributes[key] as IntegrationFeatureArg | undefined;

            if (inputArgument && ![IntegrationFeatureArgType.List, IntegrationFeatureArgType.Object].includes(inputArgument.kind)) {
                const dataSourceType = mapArgType(inputArgument.kind);

                if (!dataSourceType) {
                    return mappableFields;
                }

                const type = compatibleIntegrationFields[dataSourceType];

                mappableFields.push({
                    type,
                    label: key,
                    identifier: key,
                    isRequired: false,
                });
            }

            return mappableFields;
        }, []);
    }

    areTransitionMappingFieldsCompatible(source: MappableField, target: MappableField): boolean {

        // not a field compatible as mappable DataSource
        if (!dataSourceFieldTypes.includes(target.type) && !dataSourceFieldTypes.includes(source.type)) {
            return compatibleSchemaFields.get(source.type)?.includes(target.type) ?? false;
        }

        if (!target.sourceConfig && !source.sourceConfig) {
            return compatibleSchemaFields.get(source.type)?.includes(target.type) ?? false;
        }

        if (target.sourceConfig && source.sourceConfig && areSourceConfigsCompatible(target.sourceConfig, source.sourceConfig)) {
            return dataSourceCompatibleFields.get(source.type)?.includes(target.type) ?? false;
        }

        if (target.sourceConfig) {
            return targetDataSourceCompatibleFields.get(source.type)?.includes(target.type) ?? false;
        }

        // Not compatible
        return false;
    }

    buildInputMapControl(targetFields: MappableField[], inputMap?: WorkflowInputMap[]) {
        const arrayControl = this.ufb.array([]);
        const customValidator = ValidatorFunctions.custom((value: WorkflowInputMap) => !!value.field || !!value.expression, this.requiredMessage);

        for (const targetField of targetFields) {
            let field: string | undefined;
            let expression: string | undefined;

            if (inputMap) {
                const map = inputMap.find((item) => item.identifier === targetField.identifier);

                field = map?.field;
                expression = map?.expression;
            }

            const ctrl = this.ufb.control({ field, expression, identifier: targetField.identifier, isRequired: targetField.isRequired });

            if (targetField.isRequired) {
                ctrl.addValidators(customValidator);
            }

            arrayControl.push(ctrl);
        }

        return arrayControl;
    }

}
