import { PermissionEditorComponent } from './editor';
import { PermissionsClonerComponent } from './permissions-cloner.component';
import { PermissionsComponent } from './permissions.component';

export * from './editor';
export * from './permissions-cloner.component';
export * from './permissions-datasource';
export * from './permissions-functions';
export * from './permissions-manager.service';
export * from './permissions-table-manager';
export * from './permissions.component';
export * from './resource-cache-service';
export * from './resource-functions';

export const PERMISSIONS_COMPONENTS = [
    PermissionsComponent,
    PermissionsClonerComponent,
    PermissionEditorComponent,
];
