import { Injectable, NgModuleRef, inject } from '@angular/core';
import { DateAndTimeDisplayFormat } from '@unifii/library/common';
import { format } from 'date-fns';
import { printSubscribers, track } from 'observable-profiler';

import { Config } from 'app-config';
import { ReportingExceptionHandler } from 'reporting-exception-handler';

import { ContextService } from './context.service';

@Injectable({ providedIn: 'root' })
export class SubscriptionsTrackerService {

    // Store AppModuleRef to manage Observable subscribers tracking
    appModuleRef?: NgModuleRef<any>;

    private contextService = inject(ContextService);
    private config = inject(Config);
    
    /**
     * Destroy the AppModule in order to track the unhandled Observable subscriptions
     * @param throwError throw an error to allow Sentry notification
     */
    destroyAppModuleAndTrackObservableUnhandledSubscriptions(throwError = false) {
        // appModuleRef is not available in production, see main.ts
        if (!this.appModuleRef) {
            return;
        }

        // new instance as the DI one won't be available after AppModule destroy
        const errorHandler = new ReportingExceptionHandler(this.config, this.contextService);

        // need to destroy the module in order to force the destroy lifecycle of trackers
        this.appModuleRef.destroy();
        // no more referenced to the DI past this point

        const subscribers = track(false);
        
        // Display on console (useful for manual tracking, see _trackSubs() in main.ts)
        void printSubscribers({
            subscribers,
            reportInnerSubscriptions: true,
            rewriteStack: true,
        });
        
        const infos = (subscribers?.current() ?? []).map((subscriber) => ({
            id: subscriber.id,
            name: subscriber.name,
            message: subscriber.message,
            stack: subscriber.stack ?? '',
        }));

        // No pending subscription to notify
        if (!infos.length) {
            return;
        }

        const infoString = infos.length ? JSON.stringify(infos) : undefined;
        
        if (!infoString) {
            // No pending subscription to notify
            return;
        }

        if (throwError) {
            errorHandler.handleError(new Error(infoString));
        } else {
            // TODO see https://github.com/jakearchibald/idb#typescript to use IndexDB instead
            // LocalStorage as fallback of Sentry notification
            window?.localStorage.setItem(`UfUnsubscribes-${format(new Date(), DateAndTimeDisplayFormat)}`, JSON.stringify(infos));
        }
    }

}
