/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectorRef, Directive } from '@angular/core';
import { UfControlArray } from '@unifii/library/common';

import { BuilderField } from 'client';
import { BuilderService } from 'components/compound-builder/builder.service';

import { DialogsService } from 'services/dialogs.service';

import { FieldDetailBasic } from './field-detail-basic';

@Directive()
export abstract class FieldDetailListBasic<A, B> extends FieldDetailBasic {

    protected formList = new UfControlArray([]);

    protected abstract get elements(): A[];
    protected abstract set elements(v: A[]);
    protected abstract addElementGroupControl(element: A, i: number): void;

    protected afterAddElementGroupControl(_element: A, _i: number) { return; }
    protected beforeSetup(_field: BuilderField): boolean { return true; }
    protected afterSetup(): void { return; }

    configs: B[] = [];

    constructor(
        builderService: BuilderService,
        elementName: string,
        ref: ChangeDetectorRef,
        private dialogs: DialogsService,
    ) {
        super(builderService, elementName, ref);
        this.form.addControl('list', this.formList);
    }

    get show(): boolean {
        return this.ready === true && this.configured === true;
    }

    protected setup(field: BuilderField) {
        // Execute optional setup requirements
        if (this.beforeSetup(field) === false) {
            return;
        }

        // Insure that elements are initialized
        if (!field[this.elementName as keyof BuilderField]) {
            ((field[this.elementName as keyof BuilderField]) as any[]) = [];
        }
        // Clean up form from previous values
        while (this.formList.length) {
            this.formList.removeAt(0);
        }
        this.configs = [];
        // Initialize controls for each validator
        ((field[this.elementName as keyof BuilderField]) as any[] as A[]).forEach((e, i) => {
            this.addElementGroupControl(e, i);
            this.afterAddElementGroupControl(e, i);
        });

        this.afterSetup();
    }

    canDrop = (element: any): boolean => {
        /**
         * JIRA ISSUE: UNIFII-707
         * Looks like this check was put here to prevent dragging between
         * other similar drag lists
         */
        const found = this.elements.find((e: any) => {
            const keys = Object.keys(e);

            if (keys.length !== Object.keys(element).length) {
                return false;
            }
            let matchingKeys = true;

            keys.forEach((k) => {
                matchingKeys = element[k] && element[k] === e[k];
            });

            return matchingKeys;
        }) != null;

        if (!found) {
            this.form.setSubmitted(true);
        }

        return found;
    };

    moved() {
        // Force hide form and refresh component
        this.configured = false;
        this.ref.detectChanges();

        this.setup(this.field);

        // Force show form and refresh component
        this.configured = true;
        this.ref.detectChanges();

        setTimeout(() => {
            // Form is slow to update values after set of new field values
            this.notifyRefresh();
        }, 0);
    }

    async remove(i: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.elements.splice(i, 1);
        this.configs.splice(i, 1);
        this.formList.removeAt(i);
        this.formList.markAsTouched();
    }

}
