<div class="stretch-component col">
    <div [ngClass]="config.env" class="tenant-nav row space-children center-all pad-sm-sides">
        <a [routerLink]="['/']" class="logo">
            <img src="/assets/svg/menu-logo.svg" alt="Unifii" class="unifii-logo" />
        </a>

        <div restrict="ProjectManager,ContentEditor,FormDesigner,Publisher,FormDataViewer,WorkflowDesigner"
            class="row center-all">
            <div>
                <a [routerLink]="['/projects']"
                    restrict="ProjectManager,ContentEditor,FormDesigner,Publisher,FormDataViewer,WorkflowDesigner"
                    class="uf-button tertiary">
                    Projects
                </a>
            </div>
            <a *ngIf="(projectMenuConfig.contextMenuConfig?.length || 0) > 1"
                (click)="openContextMenu($event, projectMenuConfig)" label="Select Project" class="uf-action tertiary">
                <uf-icon name="arrowDownSolid" />
            </a>
        </div>

        <div class="grow"></div>

        <div class="row">
            <ng-template [ngForOf]="menuConfigs" let-config ngFor>
                <ng-template [ngIf]="config.contextMenuConfig?.length" [ngIfElse]="directLink">
                    <uc-menu-item [restrict]="config.restrict" [label]="config.label" [icon]="config.icon"
                        (click)="openContextMenu($event, config)" />
                </ng-template>
                <ng-template #directLink>
                    <uc-menu-item [restrict]="config.restrict" [label]="config.label" [icon]="config.icon"
                        [routerLink]="config.link" />
                </ng-template>

            </ng-template>

        </div>
    </div>
    <div class="row grow">
        <uc-project-nav />
        <div class="grow console-content">
            <router-outlet />
        </div>
    </div>
</div>