import { Component, Injector, Input, inject } from '@angular/core';
import { ClipboardService, ModalService, ToastService, UfControl, UfControlArray, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { FieldType } from '@unifii/sdk';

import { DialogsService } from 'services/dialogs.service';

import { FormEditorCache } from '../form-editor-cache';
import { DefinitionControlKeys, TransitionControlKeys } from '../form-editor-control-keys';
import { FormEditorFormCtrl } from '../form-editor-form-ctrl';
import { FormEditorField, FormEditorTransition, FormFieldMetadata } from '../form-editor-model';
import { FormEditorStatus } from '../form-editor-status';
import { FormEditorService } from '../form-editor.service';

import { FormFieldTransitionEditorComponent, FormFieldTransitionEditorData } from './form-field-transition-editor.component';

@Component({
    selector: 'uc-form-field-transitions',
    templateUrl: './form-field-transitions.html',
})
export class FormFieldTransitionsComponent {

    @Input({ required: true }) control: UfControlGroup;
    @Input({ required: true }) transitions: UfControlArray;
    @Input({ required: true }) meta: FormFieldMetadata;

    protected readonly fieldTypes = FieldType;
    protected readonly transitionKeys = TransitionControlKeys;
    protected ready: boolean;

    private fb = inject(FormEditorFormCtrl);
    private service = inject(FormEditorService);
    private status = inject(FormEditorStatus);
    private modalService = inject(ModalService);
    private cache = inject(FormEditorCache);
    private injector = inject(Injector);
    private dialogs = inject(DialogsService);
    private toast = inject(ToastService);
    private clipboard = inject(ClipboardService);

    get field(): FormEditorField {
        return this.control.getRawValue() as FormEditorField;
    }

    get hasBucket(): boolean {
        return !ValidatorFunctions.isEmpty(this.bucket);
    }

    protected hasDescription(control: UfControlGroup): boolean {
        return !!(control.get(TransitionControlKeys.Description) as UfControlArray).value;
    }

    protected hasTags(control: UfControlGroup): boolean {
        return ((control.get(TransitionControlKeys.Tags)?.value as string[] | null) ?? []).length > 0;
    }

    protected hasValidate(control: UfControlGroup): boolean {
        return !!(control.get(TransitionControlKeys.Validate) as UfControl).value;
    }

    protected hasShowIf(control: UfControlGroup): boolean {
        return !!(control.get(TransitionControlKeys.ShowIf) as UfControl).value;
    }

    protected async addTransition() {
        const editedStatus = this.status.edited;
        const transition: FormEditorTransition = {
            source: null as any,
            action: null as any,
            target: null as any,
            roles: [],
            actionLabel: null as any,
            validate: true,
            isNew: true,
        };

        const control = this.fb.buildTransitionControl(this.meta, transition, true);

        this.transitions.push(control);
        control.updateDependencies();
        this.transitions.updateDependencies();

        const transitionControl = await this.showTransitionDialog(this.transitions.length - 1);

        if (transitionControl) {
            this.service.refreshTransitionStatuses();
        } else {
            this.transitions.removeAt(this.transitions.length - 1);
            this.transitions.updateDependencies();
            this.transitions.updateValueAndValidity();
            this.status.edited = editedStatus;
        }

        this.service.refreshTransitionStatuses();
    }

    protected async editTransition(index: number) {
        const editedStatus = this.status.edited;
        const oldControl = this.transitions.at(index) as UfControlGroup;
        const editControl = this.fb.buildTransitionControl(this.meta, oldControl.getRawValue());

        this.transitions.setControl(index, editControl);
        this.transitions.updateDependencies();
        this.transitions.updateValueAndValidity();

        const result = await this.showTransitionDialog(index);

        if (!result) {
            this.transitions.setControl(index, oldControl);
            this.transitions.updateDependencies();
            this.transitions.updateValueAndValidity();
            this.status.edited = editedStatus;
        }

        this.service.refreshTransitionStatuses();
    }

    protected async removeTransition(index: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.transitions.removeAt(index);
        this.transitions.updateDependencies();
        this.service.refreshTransitionStatuses();
    }

    protected async copyTransition(index: number) {
        const transition = this.transitions.at(index).getRawValue() as FormEditorTransition;

        await this.clipboard.setText(JSON.stringify(transition));
    }

    protected async pasteTransition() {
        try {
            const text = await this.clipboard.getText();

            if (!text) {
                return;
            }

            const transition = JSON.parse(text) as FormEditorTransition;

            if (!transition.target || !transition.action) {
                // guard that the copied json is an actual FormEditorTransition
                return;
            }

            const transitionControl = this.fb.buildTransitionControl(this.meta, transition);

            this.transitions.push(transitionControl);
            this.transitions.updateDependencies();
            this.service.refreshTransitionStatuses();

        } catch (e) {
            this.toast.warning('Paste transition failed');
        }
    }

    protected getTransitionLabel(transition: UfControlGroup): string {
        const source = transition.get(TransitionControlKeys.Source)?.value;

        if (ValidatorFunctions.isEmpty(source)) {
            return '';
        }

        const target = transition.get(TransitionControlKeys.Target)?.value;
        const actionLabel = transition.get(TransitionControlKeys.ActionLabel)?.value;

        return `${source} > ${target} (${actionLabel})`;
    }

    protected getResultAndRolesLabel(transition: UfControlGroup): string {
        let label = (transition.get(TransitionControlKeys.Result)?.value ?? '') as string;
        const roles = transition.get(TransitionControlKeys.Roles)?.value as string[];
        const rolesLabel = roles.length ? roles.join(', ') : undefined;

        if (rolesLabel) {
            label = label ? `${label} - ${rolesLabel}` : rolesLabel;
        }

        return label;
    }

    private async showTransitionDialog(index: number): Promise<UfControlGroup | undefined> {

        if (!this.bucket) {
            return;
        }

        const transitionControl = this.transitions.at(index) as UfControlGroup;

        const transitionData: FormFieldTransitionEditorData = {
            bucket: this.bucket,
            transition: transitionControl,
            roles: new Set((this.field.roles.length ? this.field.roles : (await this.cache.getRoles()).map((r) => r.name))),
        };

        return this.modalService.openLarge(
            FormFieldTransitionEditorComponent,
            transitionData,
            { guard: true },
            undefined,
            this.injector,
        );
    }

    private get bucket(): string | null {
        return this.transitions.root.get(DefinitionControlKeys.Bucket)?.value as string | null;
    }

}
