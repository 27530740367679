import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { StorageWrapper, WindowWrapper } from '@unifii/library/common';

export class UfBrowserError extends Error {
}

@Injectable()
export class BrowserGuard {

    readonly message = `You are attempting to view this website in an <strong>unsupported</strong> browser.<br>
    Please download <a href="https://www.google.com/chrome/">Google Chrome</a> to view this website.`;

    allowed = new Promise<void>((resolve, reject) => {

        if (this.storage.DevMode || this.supported()) {
            resolve();
        } else {
            this.appendMessage();
            reject(new UfBrowserError('Unsupported browser'));
        }
    });

    constructor(
        @Inject(WindowWrapper) private window: Window,
        @Inject(DOCUMENT) private document: Document,
        @Inject(StorageWrapper) private storage: StorageWrapper,
    ) { }

    private appendMessage() {

        const paragraph = this.document.createElement('p');

        paragraph.setAttribute('style', 'position: absolute; margin: auto; left: 0; right: 0; top: 30px; text-align: center;padding: 16px;');
        paragraph.innerHTML = this.message;
        this.document.getElementsByTagName('body')[0]?.appendChild(paragraph);
    }

    private supported(): boolean {
        return (this.window.navigator.userAgent.includes('Chrome') && this.window.navigator.vendor && this.window.navigator.vendor.includes('Google Inc')) || this.window.navigator.userAgent.includes('Firefox');
    }

}
