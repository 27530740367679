import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { Subscription } from 'rxjs';

import { SystemRole, UcPage } from 'client';
import { BuilderService } from 'components/compound-builder/builder.service';
import { ArrayHelper, ContentDefinitionIdentifierValidators, IdentifierFunctions } from 'helpers/helpers';
import { ContextService } from 'services/context.service';

@Component({
    selector: 'uc-page-settings',
    templateUrl: './page-settings.html',
})
export class PageSettingsComponent implements OnInit, OnDestroy {

    @Input() page: UcPage;

    protected control = new UfControlGroup({
        title: new UfControl(ValidatorFunctions.required('Title is required.')),
        recordName: new UfControl(ValidatorFunctions.required('Record name is required.')),
        identifier: new UfControl(ContentDefinitionIdentifierValidators),
        tags: new UfControl(),
    });

    protected readonly maxLength = IdentifierFunctions.WARNING_IDENTIFIER_MAX_LENGTH;
    protected filteredTags: string[];

    private subscriptions = new Subscription();

    constructor(public builderService: BuilderService, private context: ContextService) { }

    ngOnInit() {

        if (!this.context.checkRoles(SystemRole.ProjectManager) || this.page.lastPublishedAt != null) {

            const identifierControl = (this.control.get('identifier') as UfControl);

            identifierControl.disable();
        }

        this.subscriptions.add(this.control.valueChanges.subscribe(() => this.valueChange()));

        this.subscriptions.add(this.builderService.submitted.subscribe(() => {
            this.control.setSubmitted(true);
        }));

        // New definition
        if (this.page.lastModifiedAt == null) {
            this.subscriptions.add((this.control.controls.title as UfControl).valueChanges.subscribe(this.autofillFields));
        }

        /**
         * // TODO remove when builder validation is refactored,
         * validation is backwards, now children control groups
         * should attach themselves to parent control which should validate on submit
         */
        setTimeout(() => this.valueChange(true), 0);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    filterTags(query: string | null) {
        this.filteredTags = ArrayHelper.filterList(this.builderService.tags, query ?? undefined);
    }

    private autofillFields = (value: string | undefined) => {

        const recordName = value;
        let identifier = '';

        if (value != null && value !== '') {
            identifier = IdentifierFunctions.kebabize(value || '')
                .substr(0, IdentifierFunctions.IDENTIFIER_MAX_LENGTH);
        }

        this.control.controls.recordName?.setValue(recordName, { onlySelf: false, emitResult: true });
        this.control.controls.identifier?.setValue(identifier, { onlySelf: false, emitResult: true });
    };

    private valueChange(skipEdit?: boolean) {

        /**
         * This is backwards submit should validate form then get errors
         */
        const errors = Object.keys(this.control.controls).map((key) => {
            const control = this.control.get(key) as UfControl;

            if (control.errors == null) {
                return null;
            }

            return control.errors.message;
        }).filter((err) => err != null).map((e) => e.message);

        this.builderService.setErrors(this.builderService.definition, errors, 'all');

        if (!skipEdit) {
            this.builderService.fieldEdit.next({ subject: this.builderService.definition, atomic: false });
        }
    }

}
