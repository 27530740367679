import { Resource } from 'client';

export function *resourceIterator(resource: Resource): Iterable<Resource> {
        
    for (const childResource of resource.children) {
        yield *resourceIterator(childResource);
    }

    yield resource;
}

export function *resourceWithParentIterator(input: Resource[] | { children: Resource[] }, parent?: Resource): Iterable<{resource: Resource; parent?: Resource}> {
    
    // Normalize input
    const resources = (Array.isArray(input) ? input : input?.children) ?? [];

    for (const resource of resources) {
        yield { resource, parent };
        yield *resourceWithParentIterator(resource.children, resource);
    }
}
