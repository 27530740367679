import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toDisplayName' })
export class ToDisplayNamePipe implements PipeTransform {

  transform(value: string): any {
    let result = '';

    if (value) {
      value.split(' ').forEach((part) => {
        result += ((part.charAt(0).toUpperCase() + part.slice(1)).match(/[A-Z][a-z]+|[0-9]+/g) || []).join(' ');
        result += ' ';
      });
      result = result.trim();
    }

    return result;
  }

}
