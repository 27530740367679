<div class="uf-app-bar">
    <h3>Conflict Detected</h3>
</div>
<div class="uf-grid pad gaps">
    <div class="col-12">
        <p>This item has been edited elsewhere and is no longer valid. To avoid a conflict it can't be submitted</p>
    </div>
</div>
<div class="uf-form-actions">
    <a (click)="runtime.close()" class="uf-button tertiary">
        Close
    </a>
    <a (click)="apply('Discard')" class="uf-button primary">
        Discard Changes & Reload
    </a>
    <a (click)="apply('OpenNewTab')" class="uf-button primary">
        Open Latest Version in New Tab
    </a>
</div>