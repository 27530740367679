import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';

import { BreadcrumbService } from 'services/breadcrumb.service';

import { BucketsTableManager } from './buckets-table-manager';

@Component({
    providers: [
        { provide: TableContainerManager, useClass: BucketsTableManager },
    ],
    selector: 'uc-bucket-table',
    template: `
    <div ufMasterDetail>
        <uf-table-container ufSyncRoute class="accent list-md">
            <uf-breadcrumbs [breadcrumbs]="breadcrumbs" title class="large" />
        </uf-table-container>
    </div>
    <router-outlet />
`,
})
export class BucketsComponent {

    @HostBinding('class.stretch-component') class = true;

    breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);

    constructor(
        private breadcrumbService: BreadcrumbService,
        private route: ActivatedRoute,
    ) { }

}
