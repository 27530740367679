import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuOption } from '@unifii/library/common';

export interface ButtonOption extends MenuOption {
    identifier: string;
}

export const SaveButton: ButtonOption = { identifier: 'save', label: 'Save' };
export const SaveAndCloseButton: ButtonOption = { identifier: 'saveAndClose', label: 'Save & Close' };
export const SaveAndNextButton: ButtonOption = { identifier: 'saveAndNext', label: 'Save & Next' };
export const SaveAndNewButton: ButtonOption = { identifier: 'saveAndNew', label: 'Save & Add New' };
export const SaveAndApproveButton: ButtonOption = { identifier: 'saveAndApprove', label: 'Save & Approve' };
export const SaveAndUnapproveButton: ButtonOption = { identifier: 'saveAndUnapprove', label: 'Save & Unapprove' };
export const ApproveButton: ButtonOption = { identifier: 'approve', label: 'Approve' };
export const UnapproveButton: ButtonOption = { identifier: 'unapprove', label: 'Unapprove' };

// TODO should the name of this class be more aligned with the save action?
@Component({
    selector: 'uc-drop-buttons',
    templateUrl: './drop-buttons.html',
    styleUrls: ['./drop-buttons.less'],
})
export class DropButtonsComponent {

    @Input() disabled: boolean | string;
    @Output() onClick = new EventEmitter<ButtonOption>();

    defaultOption: ButtonOption | null;
    dropOptions: ButtonOption[] = [];

    @Input() set options(v: ButtonOption[]) {
        const options = [...(v ?? [])];

        this.defaultOption = options.shift() ?? null;
        this.dropOptions = options;
    }

}
