import { Component, HostBinding, inject } from '@angular/core';
import { Modal, ModalRuntime } from '@unifii/library/common';

export type ConflictModalAction = 'Discard' | 'OpenNewTab';

@Component({
    selector: 'uc-conflict-modal',
    templateUrl: './conflict-modal.html',
})
export class ConflictModalComponent implements Modal<void, ConflictModalAction> {

    @HostBinding('class.uf-form-card') cardClass = true;

    runtime = inject<ModalRuntime<void, ConflictModalAction>>(ModalRuntime);

    protected apply(action: ConflictModalAction) {
        this.runtime.close(action);
    }

}
