<div class="uf-app-bar ">
    <button (click)="close()" type="button" class="uf-action tertiary" title="Close">
        <uf-icon name="close" />
    </button>
    <h3>External Input {{guard ? '*' : ''}}</h3>
</div>
<div [formGroup]="control" class="uf-grid pad gaps">
    <uf-data-display-list [items]="infoDescriptors" class="col-12" />
    <uf-text *ngIf="canEditValue" [formControlName]="externalInputKeys.Value" [label]="label"
        [placeholder]="info.placeholder" class="col-12" />
</div>

<div class="row space-children pad">
    <button (click)="close()" type="button" class="uf-button tertiary right">Cancel</button>
    <button (click)="submit()" type="button" class="uf-button primary">Save</button>
</div>