import { ActivityType } from 'client';

export const sortByActivityType = (a: {type: ActivityType}, b: {type: ActivityType}): number => {
    return getActivityTypeSortRanking(a.type) - getActivityTypeSortRanking(b.type);
};

const getActivityTypeSortRanking = (type: ActivityType): number => {
    switch (type) {
        case ActivityType.Notification: return 1;
        case ActivityType.Timer: return 2;
        case ActivityType.DataForwarder: return 3;
        case ActivityType.FormData: return 3;
    }
};

export const reduceExpressionToSingleLine = (expression: string): string => {
    return expression.trim().replace(/\n/g, ' ').replace(/ {2,}/g, ' ').trim();
};
