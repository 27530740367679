import { Component, Input, OnInit } from '@angular/core';
import { UfControl, UfControlGroup } from '@unifii/library/common';
import { AuthProvider, HierarchyUnitFormData } from '@unifii/sdk';

import { AuthProviderSourceGroup, SystemRole, UcAuthProviders, UcUserClaims } from 'client';
import { ArrayHelper } from 'helpers/array-helper';

import { AuthProviderMappingFilter, FilterControlKeys } from '../models';

@Component({
    selector: 'uc-auth-provider-mapping-filters',
    templateUrl: './auth-provider-mapping-filters.html',
})
export class AuthProviderMappingFiltersComponent implements OnInit {

    @Input() filters: UfControlGroup;
    @Input() customGroups: AuthProviderSourceGroup[] = [];
    @Input() sourceClaims: string[];
    @Input() type: AuthProvider;

    protected readonly controlKeys = FilterControlKeys;
    protected readonly authProviderTypes = AuthProvider;

    protected filteredSourceGroups: AuthProviderSourceGroup[];
    protected authProviderId: string;
    protected filteredSourceClaims: string[];
    protected filteredSourceClaimsFrom: string[];
    protected filteredSourceClaimsTo: string[];
    protected filteredRoles: string[];
    protected filteredSystemRoles: string[];
    protected hierarchyControl: UfControl;
    protected hierarchyValue?: HierarchyUnitFormData;

    private emptyFilter: AuthProviderMappingFilter = {
        authProviderId: '',
        actionSystemRoles: [],
    };

    constructor(
        private ucAuthProviders: UcAuthProviders,
        private ucUserClaims: UcUserClaims,
    ) { }

    ngOnInit() {
        this.authProviderId = this.filters.get(FilterControlKeys.AuthProviderId)?.value;
        this.hierarchyControl = this.filters.get(FilterControlKeys.HierarchyUnit) as UfControl;
        this.emptyFilter.authProviderId = this.authProviderId;
    }

    protected async findSourceGroups(query: string) {
        const providerGroups: AuthProviderSourceGroup[] = this.mapFilteredCustomGroups(query);

        if (query != null) {
            try {
                const groups = await this.ucAuthProviders.getAuthProviderGroups(this.authProviderId, query);

                providerGroups.push(...groups);
            } catch (e) {
                console.warn('Error loading provider groups - ', e);
            }
        }

        this.filteredSourceGroups = providerGroups.sort((group1, group2) => group1.name > group2.name ? 1 : -1);
    }

    protected changeGroup(control: UfControl, group?: AuthProviderSourceGroup | string) {
        if (typeof group === 'string') {
            control.setValue({ id: group, name: group });
        }
    }

    protected findSourceClaims(query: string) {
        this.filteredSourceClaims = ArrayHelper.filterList(this.sourceClaims, query);
    }

    protected async findSourceClaimsTo(query: string) {
        this.filteredSourceClaimsTo = (await this.ucUserClaims.list({ params: { q: query } })).map((claim) => claim.type);
    }

    protected findSystemRoles(query: string | null) {
        this.filteredSystemRoles = Object.keys(SystemRole).filter(
            (key) => key !== SystemRole.SuperUser && (!query || key.toLowerCase().includes(query.trim().toLowerCase())),
        );
    }

    protected clearFilter() {
        this.filters.reset(this.emptyFilter);
    }

    private mapFilteredCustomGroups(query?: string): AuthProviderSourceGroup[] {
        return this.customGroups.filter((v) => !query || v.name.toLowerCase().includes(query.toLowerCase()));
    }

}
