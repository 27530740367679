import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@unifii/library/common';
import { Device, ErrorType, UfError } from '@unifii/sdk';

import { UcApps } from 'client';
import { useDefaultErrorMessage } from 'components';
import { BreadcrumbService } from 'services/breadcrumb.service';

import { AppDetailsComponent } from './app-details.component';

@Component({
    templateUrl: './device-detail.html',
})
export class DeviceDetailComponent {

    protected error?: UfError;

    protected device: Device | null;
    protected loading = true;
    protected breadcrumbs: Breadcrumb[] = [];

    constructor(
        private route: ActivatedRoute,
        private ucApps: UcApps,
        private appDetails: AppDetailsComponent,
        private breadcrumbService: BreadcrumbService,
    ) {
        this.load();
    }

    get id() {
        return this.route.snapshot.params.id;
    }

    private async load() {
        let devices;

        try {
            devices = await this.ucApps.getDevices(this.appDetails.id);
        } catch (e) {
            this.error = useDefaultErrorMessage(e);

            return;
        }

        this.device = devices.find((d) => d.id === this.id) ?? null;
        if (this.device) {
            this.loading = false;
        } else {
            this.error = useDefaultErrorMessage(new UfError('Device not found', ErrorType.NotFound));

            return;
        }
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [this.appDetails.name, this.device?.name ?? '']);

    }

}
