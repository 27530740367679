<form *ngIf="ready && notifyEnabled" class="uf-grid pad">

    <!-- Details  -->
    <uf-text *ngIf="config.label.show && form.controls.label" [control]="form.controls.label | asUfControl"
        [(value)]="field.label" label="Label" class="col-12" />
    <uf-text *ngIf="config.shortLabel.show && form.controls.shortLabel" [control]="form.controls.shortLabel | asUfControl"
        [(value)]="field.shortLabel" label="Short label" class="col-12" />
    <uf-text *ngIf="config.identifier.show && form.controls.identifier" [control]="form.controls.identifier | asUfControl"
        [maxLength]="identifierMaxLength" [(value)]="field.identifier" label="Identifier"
        class="col-12" />

    <uf-message *ngIf="showWarningIdentifier" icon="warningSolid" class="col-12 x-small warning">
        <p>Identifier is too long</p>
    </uf-message>

    <!-- Link list for collections only -->
    <uf-select *ngIf="config.collection.show && form.controls.collection" [control]="form.controls.collection | asUfControl"
        [options]="builderService.collections" [(value)]="field.definitionIdentifier" nameProperty="name"
        valueProperty="identifier" disabled="true" label="Collection" class="col-12" />
    <uf-multi-choice *ngIf="config.types.show && form.controls.types" [control]="form.controls.types | asUfControl"
        [options]="builderService.collections" [(value)]="field.types" nameProperty="name"
        valueProperty="identifier" disabled="false" label="Types" class="col-12" />

    <uc-markdown *ngIf="config.helpText.show && field.type !== fieldType.Content && form.controls.helpText"
        [control]="form.controls.helpText | asUfControl" [showAlignmentOptions]="false" [(value)]="field.help"
        label="Help text" class="col-12" />

    <uc-markdown *ngIf="config.helpText.show && field.type === fieldType.Content && form.controls.helpText"
        [control]="form.controls.helpText | asUfControl" [showAlignmentOptions]="showAlignmentOptions"
        [(value)]="field.help" label="Content" class="col-12" />

    <!-- currency -->
    <uf-select *ngIf="config.currency.show && form.controls.currency" [control]="form.controls.currency | asUfControl"
        [options]="currencies" [(value)]="field.currency" valueProperty="value"
        nameProperty="name" label="Currency" class="col-12" />
    <uf-text *ngIf="config.placeholder.show && form.controls.placeholder" [control]="form.controls.placeholder | asUfControl"
        [(value)]="field.placeholder" label="Placeholder" class="col-12" />
    <uc-step *ngIf="config.step.show && form.controls.step" [control]="form.controls.step | asUfControl"
        [(value)]="field.step" class="col-12" />
    <uf-text *ngIf="config.format.show && form.controls.format" [control]="form.controls.format | asUfControl"
        [(value)]="field.format" label="Format" class="col-12" />
    <uf-text *ngIf="config.autofill.show && form.controls.autofill" [control]="form.controls.autofill | asUfControl"
        [(value)]="field.autofill" label="Autofill" class="col-12" />
    <uf-text *ngIf="config.bindTo.show && form.controls.bindTo" [control]="form.controls.bindTo | asUfControl"
        [(value)]="field.bindTo" label="Bind to" class="col-12" />
    <uf-chips *ngIf="config.tags.show && form.controls.tags" [control]="form.controls.tags | asUfControl" [allowCustom]="true"
        [options]="filteredTags" [(value)]="field.tags" (searchChange)="filterTags($event)"
        (valueChange)="builderService.refreshTags()" label="Tags" class="col-12" />

    <!-- Attributes -->
    <uf-checkbox *ngIf="config.isReadOnly.show && form.controls.isReadOnly" [control]="form.controls.isReadOnly | asUfControl"
        [(value)]="field.isReadOnly" label="Read only" class="col-12" />
    <uf-checkbox *ngIf="config.isRequired.show && form.controls.isRequired" [control]="form.controls.isRequired | asUfControl"
        [(value)]="field.isRequired" label="Required" class="col-12" />
    <uf-checkbox *ngIf="config.isOneToMany.show && form.controls.isOneToMany" [control]="form.controls.isOneToMany | asUfControl"
        [(value)]="field.isOneToMany" label="One to many" class="col-12" />
    <uf-checkbox *ngIf="config.autoDetect.show && form.controls.autoDetect" [control]="form.controls.autoDetect | asUfControl"
        [(value)]="field.autoDetect" label="AutoDetect" class="col-12" />
    <uf-number *ngIf="config.maxLength.show && form.controls.maxLength" [control]="form.controls.maxLength | asUfControl"
        [(value)]="field.maxLength" label="Maximum length" class="col-12" />
    <uf-number *ngIf="config.precision.show && form.controls.precision" [control]="form.controls.precision | asUfControl"
        [(value)]="field.precision" label="Precision" class="col-12" />
    <uf-checkbox *ngIf="config.searchable.show && form.controls.searchable" [control]="form.controls.searchable | asUfControl"
        [(value)]="field.isSearchable" label="Searchable" class="col-12" />

    <!-- TODO - Remove Reportable -->
    <!-- <uf-checkbox*ngIf="config.reportable.show" [(value)]="field.isReportable"
        [control]="form.controls.reportable | asUfControl" label="Reportable" class="col-12"/> -->

    <uf-checkbox *ngIf="config.translatable.show && form.controls.translatable" [control]="form.controls.translatable | asUfControl"
        [(value)]="field.isTranslatable" label="Translatable" class="col-12" />

    <uf-hierarchy-unit *ngIf="config.ceiling.show && form.controls.ceiling" [control]="form.controls.ceiling | asUfControl"
        [selectLeafsOnly]="false" [isRequired]="false"
        [(value)]="hierarchyCeiling" (valueChange)="updateFieldCeiling($event)" placeholder="Root" label="Starting Unit" class="col-12" />

    <uf-select *ngIf="config.selectionMode.show && field.hierarchyConfig != null"
        [options]="selectionModeOptions" [(value)]="$any(field.hierarchyConfig).selectionMode"
        valueProperty="identifier" nameProperty="name" label="Limit selection to" class="col-12" />

</form>