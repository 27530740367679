import { TableDataSource } from '@unifii/library/common';

import { IntegrationFeature } from 'client';

export class IntegrationFeaturesDataSource extends TableDataSource<IntegrationFeature> {

    filtered: boolean;
    sorted: boolean;

    constructor(
        private items: IntegrationFeature[],
    ) {
        super();
    }

    load() {
        this.stream.next({ data: this.items });
    }

}
