<uf-expander class="uf-box flat">
    <div expanderHeader class="uf-app-bar accent flat">
        <div class="title primary">{{title}}</div>
        <uc-expander-controls *ngIf="items.length" [container]="itemsContainer" />
    </div>
    <div #itemsContainer expanderBody class="pad-sm">
        <ng-container *ngIf="items.length; else noItems">
            <ng-template [ngForOf]="items" ngFor let-item let-i="index">
                <uf-expander [dragDisabled]="true" dragItem class="uf-box flat draggable gap-sm-bottom">
                    <div expanderHeader dragHandle class="uf-app-bar flat">
                        <div class="title primary">{{item.title}}</div>
                    </div>
                    <div expanderBody class="uf-grid pad">
                        <uf-data-display-list [items]="item.items" class="col-12" />
                    </div>
                </uf-expander>
            </ng-template>
        </ng-container>
    </div>
</uf-expander>
<ng-template #noItems>
    <span>No items to display</span>
</ng-template>