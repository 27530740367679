<div class="uf-app-bar">
    <button (click)="runtime.close()" title="Close" type="button" class="uf-action tertiary">
        <uf-icon name="close" />
    </button>
    <h3>Add Integration Feature</h3>
</div>
<div [formGroup]="form" class="uf-grid pad">
    <uf-text [formControlName]="integrationFeatureControlKeys.Name" label="Feature Name" class="col-12" />
    <uf-text [formControlName]="integrationFeatureControlKeys.Id" label="Identifier" class="col-12" />
    <uf-select [options]="integrationFeatureTypes" [formControlName]="integrationFeatureControlKeys.TypeKey"
        label="Type" nameProperty="name" valueProperty="identifier" class="col-12" />
</div>
<div class="row space-children pad">
    <button (click)="runtime.close()" type="button" class="uf-button tertiary right">Cancel</button>
    <button (click)="save()" type="button" class="uf-button primary">Save</button>
</div>