import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'uc-template-tabs',
    templateUrl: './template-tabs.html',
})
export class TemplateTabsComponent {

    @HostBinding('class.stretch-component') stretchComponentClass = true;

}

