<div *ngIf="!error" class="overlay-container">
    <div class="header-pane">
        <uc-builder-header />
    </div>
    <div class="content-pane scrollable">
        <div class="uf-container-lg">
            <div class="uf-grid row-gap-lg pad-top">
                <div class="uf-box col-12">
                    <div class="uf-app-bar flat accent">
                        <div class="title">Feature Details</div>
                    </div>
                    <div *ngIf="form" [formGroup]="form" class="content uf-grid pad">
                        <uf-data-display-list class="col-12 small-label gap-bottom">
                            <dt>Type</dt>
                            <dd>{{integrationFeature.type}}</dd>
                        </uf-data-display-list>
                        <uf-text [formControlName]="controlKeys.Name" label="Feature Name" class="col-12" />
                        <uf-text [formControlName]="controlKeys.Id" label="Identifier" class="col-12" />
                        <uf-text *ngIf="endpointPathControl.enabled" [control]="endpointPathControl"
                            label="Endpoint Path" class="col-12">
                            <uf-help [content]="helpMessages[controlKeys.EndpointPath]" />
                        </uf-text>
                        <uf-text *ngIf="previewEndpointPathControl.enabled" [control]="previewEndpointPathControl"
                            label="Preview Endpoint Path" class="col-12">
                            <uf-help [content]="helpMessages[controlKeys.PreviewEndpointPath]" />
                        </uf-text>
                        <uf-text *ngIf="secondaryEndpointPathControl.enabled" [control]="secondaryEndpointPathControl"
                            label="Secondary Endpoint Path (detail endpoint)" class="col-12">
                            <uf-help [content]="helpMessages[controlKeys.SecondaryEndpointPath]" />
                        </uf-text>
                        <uf-text *ngIf="displayExpControl.enabled" [control]="displayExpControl"
                            label="Display Expression" class="col-12">
                            <uf-help [content]="helpMessages[controlKeys.DisplayExp]" />
                        </uf-text>
                        <uf-text *ngIf="idExpControl.enabled" [control]="idExpControl" label="Id Expression"
                            class="col-12">
                            <uf-help [content]="helpMessages[controlKeys.IdExp]" />
                        </uf-text>
                        <uf-text *ngIf="correlationExpControl.enabled" [control]="correlationExpControl"
                            label="Correlation Expression" class="col-12">
                            <uf-help [content]="helpMessages[controlKeys.CorrelationExp]" />
                        </uf-text>
                    </div>
                </div>

                <div *ngIf="!isWebhook" class="uf-box accent col-12">
                    <uf-expander [isExpanded]="true">
                        <div expanderHeader class="uf-app-bar medium accent">
                            <div class="title">
                                Inputs
                            </div>
                        </div>
                        <div expanderBody class="uf-grid pad">
                            <table #inputs *ngIf="input" class="uf-table accent col-12">
                                <thead>
                                    <tr>
                                        <th>Identifier</th>
                                        <th>Type</th>
                                        <!-- <th>Required</th> -->
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody [root]="input" ufDirectoryRoot childrenProperty="children">
                                    <tr *ngFor="let item of input | flatten: 'children'" [item]="item" ufDirectory>
                                        <td class="no-padding">
                                            <uf-directory-indicator [icon]="item.icon" class="light">
                                                <code>{{item.identifier}}</code>
                                            </uf-directory-indicator>
                                        </td>
                                        <td>
                                            {{item.kindDescription}}
                                        </td>
                                        <!-- TODO Required field will be added later on, when expressions are updated to accept it -->
                                        <!-- <td>
                                            {{item.isRequired ? 'Yes' : 'No'}}
                                        </td> -->
                                        <td class="input-cell">
                                            <uf-drop-menu *ngIf="form.enabled" [options]="contextOptions"
                                                [context]="item" (selected)="executeContextOption(item, $event, true)"
                                                class="uf-action tertiary right" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="col-12">
                                <uf-error [control]="inputArgsControl" />
                                <button [disabled]="form.disabled" (click)="addInputArg(true)" type="button"
                                    class="uf-button right">
                                    Add Input Arg
                                </button>
                            </div>
                        </div>
                    </uf-expander>
                </div>

                <div *ngIf="outputArgsControl?.enabled" class="uf-box col-12">
                    <uf-expander [isExpanded]="true">
                        <div expanderHeader class="uf-app-bar medium accent">
                            <div class="title">
                                Outputs
                            </div>
                        </div>
                        <div expanderBody class="uf-grid pad">
                            <table #outputs *ngIf="output" class="uf-table accent col-12">
                                <thead>
                                    <tr>
                                        <th>Identifier</th>
                                        <th>Type</th>
                                        <!-- <th>Required</th> -->
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody [root]="output" ufDirectoryRoot childrenProperty="children">
                                    <tr *ngFor="let item of output | flatten: 'children'" [item]="item" ufDirectory>
                                        <td class="no-padding">
                                            <uf-directory-indicator [icon]="item.icon" class="light">
                                                <code>{{item.identifier}}</code>
                                            </uf-directory-indicator>
                                        </td>
                                        <td>
                                            {{item.kindDescription}}
                                        </td>
                                        <!-- TODO Required field will be added later on, when expressions are updated to accept it -->
                                        <!-- <td>
                                            {{item.isRequired ? 'Yes' : 'No'}}
                                        </td> -->
                                        <td class="input-cell">
                                            <div class="right">
                                                <uf-drop-menu *ngIf="form.enabled" [options]="contextOptions"
                                                    [context]="item"
                                                    (selected)="executeContextOption(item, $event, false)"
                                                    class="uf-action tertiary right" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="col-12">
                                <uf-error [control]="outputArgsControl" />
                                <button [disabled]="form.disabled" (click)="addInputArg(false)" type="button"
                                    class="uf-button right">
                                    Add Output Arg
                                </button>
                            </div>
                        </div>
                    </uf-expander>
                </div>
            </div>
        </div>
    </div>
</div>

<uc-error-message [error]="error" />