<ng-template #headerButtons>
    <button (click)="resetForm()" type="button" class="uf-button tertiary right">
        Reset
    </button>
    <button (click)="send()" type="button" class="uf-button primary">
        Send
    </button>
</ng-template>
<uf-panel class="content-pane container">
    <div class="uf-container-lg">
        <div class=" uf-grid row-gap-lg pad-top">

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Target Devices
                    </div>
                    <button *ngIf="selectedDevices.length" (click)="resetDevices()" title="Clear" type="button"
                        class="uf-action tertiary">
                        <uf-icon name="backspace" />
                    </button>
                </div>

                <div class="uf-grid pad">
                    <ul class="uc-list col-12">
                        <ng-template [ngForOf]="selectedDevices" ngFor let-device let-i="index">
                            <li class="uc-list-item--bottom-border--small row center-all">
                                {{ device.name }} - {{ device.model }} - {{ device.id }}
                                <button (click)="selectedDevices.splice(i, 1)" title="Delete" type="button"
                                    class="uf-action tertiary right">
                                    <uf-icon name="delete" />
                                </button>
                            </li>
                        </ng-template>
                    </ul>
                    <div class="col-12">
                        <button (click)="addDevices()" type="button" class="uf-button right">
                            Add Devices
                        </button>
                    </div>
                </div>
            </div>

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Target Users
                    </div>
                    <button *ngIf="roles?.length || userAstNode.args?.length ?? 0 > 1" (click)="resetUsers()"
                        title="Clear" type="button" class="uf-action tertiary">
                        <uf-icon name="backspace" />
                    </button>
                </div>

                <div class="uf-grid pad">
                    <ul class="uc-list col-12">
                        <ng-template [ngForOf]="roles" ngFor let-role let-i="index">
                            <li class="uc-list-item--bottom-border--small row center-all">
                                <div class="fieldset-item--small grow">
                                    <span>Role:</span>
                                    <span>{{role}}</span>
                                </div>
                                <button (click)="roles.splice(i, 1)" title="Delete" type="button"
                                    class="uf-action tertiary right">
                                    <uf-icon name="delete" />
                                </button>
                            </li>
                        </ng-template>
                        <ng-template [ngForOf]="userAstNode.args" let-claim ngFor let-i="index">
                            <li *ngIf="i > 0" class="uc-list-item--bottom-border--small row center-all">
                                <div class="fieldset-item--small grow">
                                    <span>Claim Type:</span>
                                    <span>{{$any(claim.args)[0].value}}</span>
                                </div>
                                <div class="fieldset-item--small grow">
                                    <span>Claim Type:</span>
                                    <span>{{$any(claim.args)[1].value}}</span>
                                </div>
                                <button (click)="userAstNode.args?.splice(i, 1)" title="Delete" type="button"
                                    class="uf-action tertiary right">
                                    <uf-icon name="delete" />
                                </button>
                            </li>
                        </ng-template>
                    </ul>
                    <div class="col-12">
                        <button (click)="openUserQueryBuilder()" type="button" class="uf-button right">
                            Add Roles/Claims
                        </button>
                    </div>
                </div>
            </div>

            <!-- Message Data -->
            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Message Data
                    </div>
                    <button (click)="messageControl.reset()" title="Clear" type="button" class="uf-action tertiary">
                        <uf-icon name="backspace" />
                    </button>
                </div>
                <div class="uf-grid pad">
                    <uf-text *ngIf="messageControl.controls.title" [control]="messageControl.controls.title | asUfControl"
                        [(value)]="notification.title" label="Title" class="col-12" />
                    <uf-textarea *ngIf="messageControl.controls.message" [control]="messageControl.controls.message | asUfControl"
                        [(value)]="notification.message" label="Message" class="col-12" />
                </div>
            </div>

            <!-- Routing -->
            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Routing
                    </div>
                    <button (click)="routingControl.reset()" title="Clear" type="button" class="uf-action tertiary">
                        <uf-icon name="backspace" />
                    </button>
                </div>
                <div *ngIf="notification.route" class="uf-grid pad">
                    <uf-text *ngIf="routingControl.controls.tenant" [control]="routingControl.controls.tenant | asUfControl"
                        [(value)]="notification.route.tenant" label="Tenant" class="col-4" />

                    <uf-text *ngIf="routingControl.controls.projectId" [control]="routingControl.controls.projectId | asUfControl"
                        [(value)]="notification.route.projectId" label="Project Id" class="col-4" />

                    <uf-text *ngIf="routingControl.controls.nodeId" [control]="routingControl.controls.nodeId | asUfControl"
                        [(value)]="notification.route.nodeId" label="Node Id" class="col-4" />

                    <uf-text *ngIf="routingControl.controls.bucket" [control]="routingControl.controls.bucket | asUfControl"
                        [(value)]="notification.route.bucket" label="Form Data Repository Id"
                        class="col-6" />

                    <uf-text *ngIf="routingControl.controls.id" [control]="routingControl.controls.id | asUfControl"
                        [(value)]="notification.route.id" label="Id" class="col-6" />
                </div>
            </div>
        </div>
    </div>
</uf-panel>