import { ColumnDescriptorEditorComponent } from './configuration/column-descriptor-editor.component';
import { SortPropertyInputComponent } from './configuration/sort-property-input.component';
import { TableColumnsComponent } from './configuration/table-columns.component';
import { TableConfigurationComponent } from './configuration/table-configuration.component';
import { TableDetailComponent } from './detail/table-detail.component';
import { TableModuleTableComponent } from './detail/table-module-table.component';
import { TableComponent } from './table.component';
import { TablesComponent } from './tables.component';

const TABLES_COMPONENTS = [
    TablesComponent,
    TableComponent,
    TableConfigurationComponent,
    TableColumnsComponent,
    SortPropertyInputComponent,
    ColumnDescriptorEditorComponent,
    TableDetailComponent,
    TableModuleTableComponent,
];

export {
    TABLES_COMPONENTS,
    TablesComponent,
    TableComponent,
    TableConfigurationComponent,
    TableColumnsComponent,
    SortPropertyInputComponent as TableSortComponent,
    ColumnDescriptorEditorComponent,
    TableDetailComponent,
    TableModuleTableComponent,
};
