<div *ngIf="control" class="uf-box flat pad-sm-sides uf-grid row-gap-sm pad-sm-top pad-sm-bottom">

    <uf-select *ngIf="hasVariations" [options]="variationOptions" [(value)]="selectedVariation"
        (valueChange)="selectVariation($event)" label="Variation" placeholder="Default" class="col-12 pad-sm-sides" />

    <ng-template [ngIf]="!variation">
        <uc-node-content-info *ngIf="hasContentLinked" [control]="control" class="col-12" />
        <uc-node-settings [control]="control" class="col-12" />
        <uc-node-roles *ngIf="!isHome" [control]="control" class="col-12" />
        <uc-node-variations *ngIf="isHome" [control]="variations" class="col-12" />
        <uc-node-dashboard-tables *ngIf="node.type === nodeTypes.Dashboard" [control]="bucketOptions" class="col-12" />
        <uc-node-args *ngIf="node.type === nodeTypes.IFrame" [control]="args" class="col-12" />
    </ng-template>

    <ng-template [ngIf]="variation">
        <uc-node-content-info *ngIf="hasVariationContentLinked" [control]="variation" class="col-12" />
        <uc-node-settings [control]="variation" class="col-12" />
        <uc-node-roles [control]="variation" class="col-12" />
        <uc-node-dashboard-tables *ngIf="variation.get(variationControlKeys.Type)?.value === nodeTypes.Dashboard"
            [control]="variation.get(variationControlKeys.BucketOptions) | asSafeAbstractControl | asUfControlArray"
            class="col-12" />
    </ng-template>

</div>