import { Injectable, OnDestroy, inject } from '@angular/core';
import { ControlAccessor, UfControl, UfControlArray, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { Option } from '@unifii/sdk';
import { Subscription, debounceTime } from 'rxjs';

import { UcPermission, UcPermissionActionInfo } from 'client';

import { ResourceCacheService } from '../resource-cache-service';

import { FieldsControlKeys, PermissionPathSegmentEnd, PermissionPathSegmentWildcard } from './permission-editor-constants';
import { PermissionActionControlKeys, PermissionControlKeys, PermissionStepControlKeys } from './permission-editor-control-keys';
import { FieldsControlKeysType, FieldsFlagsEditableStatus, PermissionEditorAction, PermissionEditorModel, PermissionEditorSegmentOption, PermissionEditorStep } from './permission-editor-model';
import { PermissionEditorService } from './permission-editor-service';

@Injectable()
export class PermissionEditorFormCtrl implements OnDestroy {
 
    private disabled: boolean;
    private subscription = new Subscription();
    private actionsSubscriptions = new Subscription();
    private ufb = inject(UfFormBuilder);
    private permissionEditorService = inject(PermissionEditorService);
    private resourceCacheService = inject(ResourceCacheService);

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.actionsSubscriptions.unsubscribe();
    }

    async mapDataToControlValue(permission: UcPermission): Promise<PermissionEditorModel> {
        
        const steps: PermissionEditorStep[] = [];
        const editorPath = [...permission.path];
        
        if (!editorPath.length || editorPath[editorPath.length - 1] !== PermissionPathSegmentWildcard) {
            editorPath.push('');
        }
        
        let resource = this.resourceCacheService.resource;

        for (const segment of editorPath) {
            steps.push(await this.permissionEditorService.getStep(steps, resource, segment));

            const nextResource = this.permissionEditorService.getNextResource(steps);
            
            if (!nextResource) {
                break;
            }
            
            resource = nextResource;
        }

        const actions = this.permissionEditorService.getActions(steps, permission.actions);
        
        return {
            id: permission.id,
            description: permission.description,
            principalType: permission.principalType,
            principalId: permission.principalId,
            steps,
            actions,
            editFields: permission.fields ?? [],
            lockedFields: permission.lockedFields ?? [],
            readFields: permission.readFields ?? [],
            deniedFields: permission.deniedFields ?? [],
        };
    }

    mapControlValueToData(permission: PermissionEditorModel): UcPermission {

        const path = this.permissionEditorService.getUrlPath(permission.steps).split('/').filter((v) => v != null && v !== '');

        const actions: UcPermissionActionInfo[] = permission.actions.filter((a) => a.selected).map((a) => ({
            name: a.name,
            condition: a.condition,
        }));

        return {
            id: permission.id,
            description: permission.description,
            principalType: permission.principalType,
            principalId: permission.principalId,
            path,
            actions,
            fields: permission.editFields.length ? permission.editFields : undefined,
            lockedFields: permission.lockedFields.length ? permission.lockedFields : undefined,
            readFields: permission.readFields.length ? permission.readFields : undefined,
            deniedFields: permission.deniedFields.length ? permission.deniedFields : undefined,
        };
    }

    buildRoot(permission: PermissionEditorModel, disabled = false, setSubmitted = false): UfControlGroup {

        this.disabled = disabled;

        const fieldsFlags = this.permissionEditorService.getFieldsFlagsEditableStatus(permission.actions);
        const editableFieldsCtrl = this.buildFieldsFlagsControl(permission.editFields, fieldsFlags, PermissionControlKeys.EditFields, PermissionControlKeys.LockedFields);
        const lockedFieldsCtrl = this.buildFieldsFlagsControl(permission.lockedFields, fieldsFlags, PermissionControlKeys.LockedFields, PermissionControlKeys.EditFields);       
        const deniedFieldsCtrl = this.buildFieldsFlagsControl(permission.deniedFields, fieldsFlags, PermissionControlKeys.DeniedFields, PermissionControlKeys.ReadFields);
        const readFieldsCtrl = this.buildFieldsFlagsControl(permission.readFields, fieldsFlags, PermissionControlKeys.ReadFields, PermissionControlKeys.DeniedFields);

        const root = this.ufb.group({
            [PermissionControlKeys.Id]: permission.id,
            [PermissionControlKeys.Description]: permission.description,
            [PermissionControlKeys.Steps]: this.buildSteps(permission.steps),
            [PermissionControlKeys.Actions]: this.buildActions(permission.actions),
            [PermissionControlKeys.EditFields]: editableFieldsCtrl,
            [PermissionControlKeys.LockedFields]: lockedFieldsCtrl,
            [PermissionControlKeys.DeniedFields]: deniedFieldsCtrl,
            [PermissionControlKeys.ReadFields]: readFieldsCtrl,
        });

        root.updateDependencies();

        if (disabled) {
            root.disable();
        }

        if (!disabled && setSubmitted) {
            root.setSubmitted();
        }

        return root;
    }

    getSegmentFirstOption(step: PermissionEditorStep): PermissionEditorSegmentOption | undefined {
        return (step.loader.options ?? [])[0];
    }

    private buildSteps(steps: PermissionEditorStep[]): UfControlArray {
        const stepsCtrl = this.ufb.array(steps.map((step, index) => this.buildStep(step, index)));
        
        if (!this.disabled) {
            this.subscription.add(stepsCtrl.valueChanges.pipe(debounceTime(0)).subscribe(() => {

                const actionsCtrl = new ControlAccessor(stepsCtrl).get(`../${PermissionControlKeys.Actions}`)[0] as UfControlArray;

                for (const actionCtrl of (actionsCtrl.controls as UfControlGroup[])) {
                    this.toggleActionSelectedCtrlEditability(actionCtrl, stepsCtrl.valid);
                }
            }));
        }

        return stepsCtrl;
    }

    private buildActions(actions: PermissionEditorAction[]): UfControlArray {
        const actionsCtrl = this.ufb.array(actions.map((action) => this.buildAction(action)));
        
        actionsCtrl.addValidators(ValidatorFunctions.custom((v: PermissionEditorAction[]) =>
            v.some((action) => action.selected),
            'At least one action is required',
        ));

        if (!this.disabled) {
            this.subscription.add(actionsCtrl.valueChanges.pipe(debounceTime(0)).subscribe(() => {
                this.toggleFieldsControlsEditability(actionsCtrl);
            }));
        }
        
        return actionsCtrl;
    }

    private buildStep(step: PermissionEditorStep, index: number): UfControlGroup {

        const segmentCtrl = this.ufb.control(step.segment, ValidatorFunctions.compose([
            ValidatorFunctions.required('A value is required'),
            ValidatorFunctions.custom((v: PermissionEditorSegmentOption) => !v?._notFound, 'Resource not found'),
        ]));
        
        const stepCtrl = this.ufb.group({
            [PermissionStepControlKeys.Segment]: segmentCtrl,
            [PermissionStepControlKeys.Loader]: step.loader,
            [PermissionStepControlKeys.Resource]: step.resource,
        });

        if (!this.disabled) {
            this.subscription.add(segmentCtrl.valueChanges.pipe(debounceTime(0)).subscribe(
                (v: Option | null) => this.onSegmentValueChange(segmentCtrl, index, v)),
            );
        }

        return stepCtrl;
    }

    private setDefaultSegmentValue(step: PermissionEditorStep) {
        if (step.segment) {
            return;
        }

        const firstOption = this.getSegmentFirstOption(step);

        if (!firstOption) {
            return;
        }

        step.segment = firstOption;
        step.loader.segmentIdentifier = firstOption.identifier;
    }

    private async onSegmentValueChange(segmentCtrl: UfControl, index: number, value: Option | null) {
        const controlAccessor = new ControlAccessor(segmentCtrl);
        const stepsCtrl = controlAccessor.get(`$.${PermissionControlKeys.Steps}`)[0] as UfControlArray;
                
        // Remove all steps after this step
        while (stepsCtrl.length > index + 1) {
            stepsCtrl.removeAt(stepsCtrl.length - 1);
        }

        const steps = stepsCtrl.value as PermissionEditorStep[];

        // Update the step loader segment identifier
        const lastStep = steps[steps.length - 1];

        if (lastStep) {
            lastStep.loader.segmentIdentifier = value?.identifier;
            lastStep.loader.updateActions();
        }

        // Optionally add a nextStep
        if (value && ![PermissionPathSegmentEnd, PermissionPathSegmentWildcard].includes(value.identifier)) {

            const nextResource = this.permissionEditorService.getNextResource(steps);
            
            if (nextResource) {
                const nextStep = await this.permissionEditorService.getStep(steps, nextResource);
                
                this.setDefaultSegmentValue(nextStep);
                // Update last step actions
                nextStep.loader.updateActions();

                steps.push(nextStep);
                stepsCtrl.push(this.buildStep(nextStep, steps.length - 1));
            }
        }

        // Set the new actions
        this.actionsSubscriptions.unsubscribe();
        this.actionsSubscriptions = new Subscription();
        
        const actions = this.permissionEditorService.getActions(steps);
        const actionsCtrl = controlAccessor.get(`$.${PermissionControlKeys.Actions}`)[0] as UfControlArray;                
        
        actionsCtrl.clear();
        
        for (const action of actions) {
            actionsCtrl.push(this.buildAction(action));
        }
    }

    private buildAction(action: PermissionEditorAction): UfControlGroup {

        const conditionDisabled = this.disabled || !action.selected;
        const selectedCtrl = this.ufb.control(action.selected);

        const actionCtrl = this.ufb.group({
            [PermissionActionControlKeys.Name]: action.name,
            [PermissionActionControlKeys.Descriptions]: this.ufb.control(action.descriptions),
            [PermissionActionControlKeys.Selected]: selectedCtrl,
            [PermissionActionControlKeys.AllowsCondition]: action.allowsCondition,
            [PermissionActionControlKeys.AllowsReadFields]: action.allowsReadFields,
            [PermissionActionControlKeys.AllowsEditFields]: action.allowsEditFields,
        });

        if (action.allowsCondition) {
            actionCtrl.addControl(PermissionActionControlKeys.Condition, this.ufb.control({ value: action.condition, disabled: conditionDisabled }));

            if (!this.disabled) {
                this.actionsSubscriptions.add(selectedCtrl.valueChanges.pipe(debounceTime(0)).subscribe((v) => {
                    this.toggleActionConditionCtrlEditability(actionCtrl, v);
                }));
            }
        }

        return actionCtrl;
    }

    private buildFieldsFlagsControl(value: string[], fieldsFlagsStatus: FieldsFlagsEditableStatus, controlKey: FieldsControlKeysType, dependsOnControlKey: FieldsControlKeysType): UfControl {
        const dependsOnControlPath = `../${dependsOnControlKey}`;
        const controlAccessor = new ControlAccessor();
        const fieldsFlagsControl = this.ufb.control(
            {
                value,
                disabled: this.disabled || !fieldsFlagsStatus[controlKey],
            },
            ValidatorFunctions.custom((fieldsFlags: string[]) => {
                if (!controlAccessor.control) {
                    return true;
                }

                const dependsOnControl = controlAccessor.get(dependsOnControlPath)[0] as UfControl | undefined;
                const dependsOnFieldsLength = dependsOnControl?.value.length;

                dependsOnControl?.setSubmitted(true);
                
                return !fieldsFlags.length || !dependsOnFieldsLength;
            },
            'Allow List and Block List are mutually exclusive',
            ),
            undefined,
            { deps: [dependsOnControlPath] },
        );

        controlAccessor.control = fieldsFlagsControl;

        return fieldsFlagsControl;
    }

    private toggleActionSelectedCtrlEditability(action: UfControlGroup, enable: boolean) {
        
        const selectedCtrl = action.get(PermissionActionControlKeys.Selected);
        
        if (!selectedCtrl || (selectedCtrl.disabled && !enable) || (selectedCtrl.enabled && enable)) {
            return;
        }
        
        if (enable) {
            selectedCtrl.enable();
        } else {
            selectedCtrl.disable();
        }
    }

    private toggleActionConditionCtrlEditability(action: UfControlGroup, enable: boolean) {
        
        const conditionCtrl = action.get(PermissionActionControlKeys.Condition);
        
        if (!conditionCtrl || (conditionCtrl.disabled && !enable) || (conditionCtrl.enabled && enable)) {
            return;
        }
        
        if (enable) {
            conditionCtrl.enable();
        } else {
            conditionCtrl.disable();
            if (!this.disabled) {
                conditionCtrl.setValue(null);
            }
        }
    }

    private toggleFieldsControlsEditability(actionsCtrl: UfControlArray) {

        const rootCtrl = actionsCtrl.root as UfControlGroup;
        const actions = rootCtrl.get(PermissionControlKeys.Actions)?.value as PermissionEditorAction[] | undefined;

        if (!actions) {
            return;
        }

        const flags = this.permissionEditorService.getFieldsFlagsEditableStatus(actions);

        for (const key of FieldsControlKeys) {
            const control = rootCtrl.get(key) as UfControl | undefined;
            
            if (!control) {
                continue;
            }

            if (flags[key] && control.disabled) {
                control.enable();
            }

            if (!flags[key] && control.enabled) {
                control.disable();
                control.setValue([]);
            }
        }
    }

}
