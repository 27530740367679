<uf-panel class="container">

    <div class="header-pane uf-app-bar transparent">
        <uf-breadcrumbs [breadcrumbs]="breadcrumbs" class="large" />
    </div>

    <div class="content-pane">
        <uf-tabs>
            <uf-tab *ngFor="let tab of tabs" [label]="tab.label" [path]="tab.path" />
        </uf-tabs>
    </div>

</uf-panel>