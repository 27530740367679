import { Component, TemplateRef } from '@angular/core';
import { Breadcrumb } from '@unifii/library/common';

import { SystemRole } from 'client';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { TabConfig } from 'pages/page-models';
import { TabsPage } from 'pages/tabs-page';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

@Component({
    selector: 'uc-tenant-settings',
    templateUrl: './tenant-settings.html',
})
export class TenantSettingsComponent extends TabsPage {
    
    override edited: boolean;
    tabs: TabConfig[] = [];
    templateRef?: TemplateRef<any>;
    breadcrumbs: Breadcrumb[] = this.breadcrumbService.getBreadcrumbs(this.route);

    constructor(
        private breadcrumbService: BreadcrumbService,
        private context: ContextService,
        private builderHeaderService: BuilderHeaderService,
    ) {
        super();
        if (this.context.checkRoles(SystemRole.SystemManager)) {
            this.tabs.push(
                { label: 'General', path: 'general' },
                { label: 'Identity', path: 'sso' },
                { label: 'Email Settings', path: 'email' },
                { label: 'SMTP Settings', path: 'smtp' },
                { label: 'Integrations', path: 'integrations' },
            );
        }
        if (this.context.checkRoles(SystemRole.SuperUser, SystemRole.AppManager)) {
            this.tabs.push(
                { label: 'Apps', path: 'apps' },
            );
        }
        this.buildHeaderConfig();
    }

    /**
     * @description
     * This method is public because it will be called by children components
     * when they're destroyed, so we can update the header
     */
    buildHeaderConfig() {
        this.builderHeaderService.init();
        this.builderHeaderService.buildConfig({
            breadcrumbs: this.breadcrumbs,
            hideSaveButton: true,
        });
        this.templateRef = undefined;
    }

}
