import { Injectable, inject } from '@angular/core';

import { Config } from 'app-config';

import { ContextService } from './context.service';

@Injectable({ providedIn: 'root' })
export class ConfigService {

    private config = inject(Config);
    private context = inject(ContextService);
    
    get tenant(): string | undefined {
        const url = this.config.baseUrl !== '' ? this.config.baseUrl : window.location.href;
        const domain = url.split('://')[1];

        if (domain) {
            return domain.split('.')[0];
        }

        return;
    }

    // TODO remove when no deployment need old crown data back-compatibility
    /**
     * Check if Console need to run in Crown back-compatibility mode
     */
    isCrownCompatible(): boolean {
        const projectId = this.context.project ? this.context.project.id as any as number : null;

        // Match for deploy on Crown server https://emp.tilt.crownresorts.com.au/ using environment.crown.ts
        return this.config.compatibility === 'crown' ||
            // Match https://crown.unifii.net (change this to Tenant = crown and prj 52)
            (this.tenant === 'crown' && projectId === 52) ||
            // Match https://dupont.unifii.net (change this to Tenant = dupont and prj 66)
            (this.tenant === 'dupont' && projectId === 66);
    }

}
