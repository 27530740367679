import { EventEmitter, InjectionToken } from '@angular/core';
import { UfControl } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

export type ContentChangeField = ContentField & {
    contentChange?: EventEmitter<any>;
    control: UfControl;
}

export interface ContentSettings {
    /** Editor is allowed to edit the Definition */
    canEditDefinition?: boolean;
    /** Checked when canEditDefinition is false to allow to reorder fields*/
    canReorderDefinition?: boolean;
    /** Editor is allowed to edit the Content */
    canEditContent?: boolean;
}

export const ContentSettings = new InjectionToken<ContentSettings>('ContentSettings');
