import { Component, Input, OnInit, inject } from '@angular/core';
import { DataDisplayListItem, DataDisplayRouterLinkValue, DataDisplayService, UfControl, UfControlGroup } from '@unifii/library/common';
import { DataSeed, DataType, Manager, UserInfo, getUserFullName, isNotNull } from '@unifii/sdk';
import { UserKeys } from '@unifii/user-provisioning';

import { UcUsers } from 'client';

@Component({
    selector: 'uc-user-manager',
    templateUrl: 'user-manager.html',
})
export class UserManagerComponent implements OnInit {

    @Input({ required: true }) form: UfControlGroup;
    @Input() cssClasses: string | string[] | undefined;

    protected managerControl: UfControl;
    protected managerDisplayItems: DataDisplayListItem[] | undefined;
    protected managerOptions: DataSeed[] = [];

    private managersLookup = new Map<string, Manager>();
    private ucUsers = inject(UcUsers);
    private dataDisplayService = inject(DataDisplayService);

    ngOnInit() {
        this.managerControl = this.form.get(UserKeys.Manager) as UfControl;
        this.updateManagerDisplay();
    }

    protected selectManager(seed?: DataSeed) {
        const manager = seed ? this.managersLookup.get(seed._id) : undefined;

        this.managerControl.setValue(manager ?? null);
        this.updateManagerDisplay();
    }

    protected async searchUsers(q: string) {
        try {
            this.managerOptions = (await this.ucUsers.get(q.trim()))
                .map((user) => this.mapUserDataSeed(user))
                .filter(isNotNull);

        } catch (e) {
            console.error('UserManagerComponent.searchUsers', e);
        }
    }

    private updateManagerDisplay() {

        const manager = this.managerControl.getRawValue() as Manager | null;

        if (!manager) {
            this.managerDisplayItems = undefined;

            return;
        }

        this.managerDisplayItems = ([
            { term: 'Username', data: { label: manager.username, routerLink: ['/', 'user-management', 'users', `${manager.id}`] } satisfies DataDisplayRouterLinkValue },
            { term: 'First Name', data: manager.firstName },
            { term: 'Last Name', data: manager.lastName },
            { term: 'Email', data: this.dataDisplayService.displayAsDataDisplayValue(manager.email, { type: DataType.Email }) },
            { term: 'Phone', data: this.dataDisplayService.displayAsDataDisplayValue(manager.phone, { type: DataType.Phone }) },
        ] satisfies DataDisplayListItem[]).filter((item) => !!item.data);
    }

    private mapUserDataSeed(user: UserInfo): DataSeed | undefined {

        if (!user.id) {
            return;
        }

        const manager: Manager = {
            id: user.id,
            username: user.username,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phone: user.phone,
            isActive: user.isActive,
            isExternal: user.isExternal,
        };

        const seed: DataSeed = {
            _display: `${getUserFullName(user)} (${user.username})`,
            _id: manager.id,
        };

        if (!this.managersLookup.get(seed._id)) {
            this.managersLookup.set(seed._id, manager);
        }

        return seed;
    }

}
