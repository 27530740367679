<uf-expander class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Workflow</div>
        <uf-icon *ngIf="transitions.invalid" name="error" class="error" />
        <a *ngIf="transitions.valid" (click)="pasteTransition()" title="Paste transition" class="uf-action tertiary">
            <uf-icon name="paste" />
        </a>
    </div>

    <div expanderBody class="pad-sm">

        <uf-blockquote *ngIf="!hasBucket" class="warning col-1of1" icon="warningSolid"
            content="The Form Data Repository is necessary in order to configure the workflow" />

        <uc-drag-list *ngIf="transitions.length" [parent]="transitions" [items]="transitions.controls" canDrop="false"
            canReorder="true">
            <ng-template [ngForOf]="transitions.controls | asUfControlsGroups" ngFor let-transition let-i="index">

                <div [dragDisabled]="transition.disabled" dragItem
                    class="uf-app-bar uf-box flat draggable gap-sm-bottom">
                    <div class="row grow center-all">
                        <div class="col grow">
                            <div class="grow pad-sm-left">
                                <div>{{getTransitionLabel(transition)}}</div>
                                <!-- TODO Wait for 2.1 release to actually show the deep error -->
                                <!-- <uf-error-deep [control]="transition"></uf-error-deep> -->
                            </div>
                            <div class="grow pad-sm-left">
                                {{getResultAndRolesLabel(transition)}}
                            </div>
                        </div>
                        <uf-icon *ngIf="transition.invalid" name="error" class="error" />
                        <uf-icon *ngIf="hasValidate(transition)" name="required" title="validate required fields"
                            class="gap-sm-sides primary" />
                        <uf-icon *ngIf="hasShowIf(transition)" name="showIf" title="show if"
                            class="gap-sm-sides primary" />
                        <uf-icon *ngIf="hasTags(transition)" name="tagged" title="tags" class="gap-sm-sides primary" />
                        <uf-icon *ngIf="hasDescription(transition)" name="notes" title="description"
                            class="gap-sm-sides primary" />
                        <uf-icon *ngIf="transition.get(transitionKeys.HasPersistentVisibility)?.value" name="view"
                            title="always visible" class="gap-sm-sides primary" />
                        <uf-icon *ngIf="transition.get(transitionKeys.KeepOpen)?.value" name="next" title="keep open"
                            class="gap-sm-sides primary" />
                        <button *ngIf="!transition.invalid" [disabled]="!hasBucket" (click)="copyTransition(i)"
                            type="button" title="Copy" class="uf-action">
                            <uf-icon name="copy" />
                        </button>
                        <button *ngIf="!transition.disabled" [disabled]="!hasBucket" (click)="editTransition(i)"
                            type="button" title="Edit" class="uf-action">
                            <uf-icon name="edit" />
                        </button>
                        <button *ngIf="!transition.disabled" (click)="removeTransition(i)" title="Delete" type="button"
                            class="uf-action">
                            <uf-icon name="delete" />
                        </button>
                    </div>

                </div>
            </ng-template>
        </uc-drag-list>

        <uf-error [control]="transitions" class="wrap-text" />

        <div class="row">
            <button *ngIf="!transitions.disabled" [disabled]="!hasBucket" (click)="addTransition()" type="button"
                class="uf-button x-small right">
                Add Transition
            </button>
        </div>

    </div>
</uf-expander>