import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreatePasswordConfig, CreatePasswordValue, UfControl, UfControlGroup, UfFormBuilder, ValidatorFunctions, WindowWrapper } from '@unifii/library/common';
import { AppAuthProviderConfiguration, AuthProvider, AuthProviderConfiguration, Client, PasswordDetails, TenantClient, TenantSettings, UfError, decrypt, ensureError, ensureUfError, isDictionary, isString } from '@unifii/sdk';

import { Config } from 'app-config';
import { UcClient } from 'client';
import { mapProviderLoginLabel } from 'pages/users/provider-utils';
import { ContextService } from 'services/context.service';
import { Auth0DirectoryURL, AzureDirectoryURL, SSOService } from 'services/sso.service';

enum LoginControlKeys {
    Username = 'username',
    Password = 'password'
}

@Component({
    templateUrl: './login.html',
    styleUrls: ['./login.less', '../../styles/external-branding.less'],
})
export class LoginComponent implements OnInit {

    @HostBinding('class.stretch-component') protected class = true;

    protected readonly changePasswordConfig: CreatePasswordConfig = {
        showStrengthIndicator: true,
        canGenerate: true,
        labels: {
            message: 'You are required to update your password',
        },
    };
    protected readonly controlKeys = LoginControlKeys;
    protected inProgress = true;
    protected error: string | null;
    protected settings: TenantSettings | undefined;
    protected authProviders: AppAuthProviderConfiguration[];
    protected changePasswordControl: UfControl;
    protected form: UfControlGroup;
    protected isUsernamePasswordAuthenticationEnabled: boolean;

    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private client = inject(UcClient);
    private sdkClient = inject(Client);
    private ufb = inject(UfFormBuilder);
    private ssoService = inject(SSOService);
    private context = inject(ContextService);
    private tenantClient = inject(TenantClient);
    private window = inject<Window>(WindowWrapper);
    private config = inject(Config);
    private responseURL: string;

    async ngOnInit() {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { reason, code, provider_id, state } = this.route.snapshot.queryParams;

        // SSO sign on pass errors via the reason url param
        if (reason) {
            throw new UfError(reason);
        }

        this.form = this.ufb.group({
            [LoginControlKeys.Username]: [null, ValidatorFunctions.required('Username is required')],
            [LoginControlKeys.Password]: [null, ValidatorFunctions.required('Password is required')],
        });
        this.changePasswordControl = this.ufb.control({ value: {} as CreatePasswordValue, disabled: true });
        this.responseURL = `${this.window.location.origin}/sso`;

        try {
            this.settings = await this.tenantClient.getSettings();
            this.ssoService.authProviders = this.settings.authProviders ?? [];
            this.authProviders = this.ssoService.providerList.map((provider, _, providers) => mapProviderLoginLabel(provider, providers));
            this.isUsernamePasswordAuthenticationEnabled = !!this.settings.isPasswordAuthSupported;

            if (!this.ssoService.authProviders.length && !this.isUsernamePasswordAuthenticationEnabled) {
                throw new UfError('No login options configured, please contact your administrator');
            }

            if (code) {
                await this.authorizeWithCode(code, provider_id, state);
            }

        } catch (error) {
            this.error = ensureUfError(error, 'Tenant configuration not available').message;
        } finally {
            this.inProgress = false;
        }
    }

    protected async login() {

        this.error = null;

        if (this.form.invalid) {
            this.form.setSubmitted();

            return;
        }

        this.inProgress = true;

        try {

            const username = this.form.get(LoginControlKeys.Username)?.value as string;
            const password = this.form.get(LoginControlKeys.Password)?.value as string;

            await this.client.authenticate(username, password);
            const account = await this.client.getMyAccount();

            if (!account.changePasswordOnNextLogin) {
                this.redirect();

                return;
            }

            this.changePasswordControl.enable();

        } catch (err) {

            const fallbackMessage = isDictionary(err) && isDictionary(err.data) && isString(err.data.error_description) ?
                err.data.error_description :
                'Authentication failed';

            this.error = ensureUfError(err, fallbackMessage).message;
        } finally {
            this.inProgress = false;
        }
    }

    protected async changePassword() {

        if (this.changePasswordControl.invalid) {
            this.changePasswordControl.setSubmitted();

            return;
        }

        this.inProgress = true;
        this.error = null;

        const passwordDetails: PasswordDetails = {
            oldPassword: this.form.get(LoginControlKeys.Password)?.value,
            password: (this.changePasswordControl.value as CreatePasswordValue).password,
        };

        try {
            await this.client.updateMyPassword(passwordDetails);
            this.redirect();
        } catch (error) {
            this.error = ensureError(error, 'Update password failed').message;
        } finally {
            this.inProgress = false;
        }
    }

    protected async providerSignIn(provider: AuthProviderConfiguration) {

        this.inProgress = true;
        this.error = null;

        try {
            const redirectUrl = await this.ssoService.getProviderUrl(provider, this.responseURL);

            if (!redirectUrl) {
                throw new UfError(`${provider.type} authentication failed`);
            }
            this.window.location.href = redirectUrl;

        } catch (e) {
            this.error = ensureUfError(e).message;
        } finally {
            this.inProgress = false;
        }
    }

    private async authorizeWithCode(code: string, providerId?: string, state?: string): Promise<void> {

        try {
            let redirectUri;

            if (state) {
                const decoded = await this.decodeState(state);

                providerId = decoded.providerId;
                redirectUri = decoded.redirectUri;
            } else if (providerId) {
                redirectUri = this.getRedirectUri(providerId);
            }

            await this.sdkClient.authenticateWithCode(code, redirectUri, providerId);
            const account = await this.client.getMyAccount();

            if (!account.roles.length) {
                throw new UfError('At least one role is required');
            }

            this.context.account = account;
            this.router.navigate(['']);

        } catch (error) {

            const providerType = this.authProviders.find((p) => `${p.id}` === providerId)?.type ?? 'External provider';

            throw ensureError(error, `${providerType} authentication failed`);
        }
    }

    private async decodeState(state: string): Promise<{ providerId: string; redirectUri: string }> {

        const decrypted = await decrypt({ byteString: decodeURIComponent(state), key: this.config.appId });
        const searchParams = new URLSearchParams(decodeURIComponent(decrypted));
        const providerId = searchParams.get('providerId') as string;
        const redirectUri = searchParams.get('redirectUri') as string;

        return {
            providerId, redirectUri,
        };
    }

    private getRedirectUri(providerId: string): string {

        const provider = this.authProviders.find((p) => '' + p.id === providerId);

        if (provider?.useDirectory === false) {
            return this.responseURL;
        }

        switch (provider?.type) {
            case AuthProvider.Azure: return AzureDirectoryURL;
            case AuthProvider.Auth0: return Auth0DirectoryURL;
            default: throw new UfError('Invalid URL, could not match provider id');
        }
    }

    private redirect() {
        this.router.navigateByUrl(this.route.snapshot.params.next || '/');
    }

}
