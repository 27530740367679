<div class="header">
    <button (click)="close()" title="Close" type="button" class="uf-action tertiary">
        <uf-icon name="close" />
    </button>
    <h3>{{title}}</h3>
</div>

<uf-panel *ngIf="form" [formGroup]="form">
    <div class="uf-grid pad">
        <uf-textarea [formControlName]="permissionKeys.Description" label="Description" class="col-12" />
        <div class="col-12">
            <uf-data-display-list [items]="[{term: 'Path', data: urlPath}]" class="columns" />
        </div>
        <div class="col-12">
            <h4>Path</h4>
        </div>
        <ng-template [ngForOf]="steps.controls | asUfControlsGroups" ngFor let-step let-i="index">
            <div *ngIf="getLoader(step); let loader" class="col-6">
                <div [formGroup]="step">
                    <uf-select *ngIf="loader.options" [formControlName]="stepKeys.Segment" [options]="loader.options"
                        nameProperty="name" />
                    <uf-autocomplete *ngIf="!loader.options" [formControlName]="stepKeys.Segment"
                        [options]="loader.resultOptions" (searchChange)="loader.load($event)" nameProperty="name" />
                </div>
            </div>
        </ng-template>
        <div class="col-12">
            <h4>Actions</h4>
            <ul *ngIf="actions.length" class="uf-list uf-box flat">
                <ng-template [ngForOf]="actions.controls | asUfControlsGroups" ngFor let-action let-i="index">
                    <li [formGroup]="action" class="uf-grid pad-sides pad-sm-top pad-sm-bottom">
                        <uf-checkbox [formControlName]="actionKeys.Selected"
                            [label]="action.get(actionKeys.Name)?.value" class="col-2" />
                        <div class="col-10">
                            <p *ngFor="let description of action.get(actionKeys.Descriptions)?.value" class="small">
                                {{description}}</p>
                        </div>
                        <ng-container *ngIf="action.get(actionKeys.Condition); let conditionControl">
                            <uf-textarea *ngIf="conditionControl.enabled || data.readonly"
                                [formControlName]="actionKeys.Condition" label="Condition" class="col-12" />
                        </ng-container>
                    </li>
                </ng-template>
            </ul>
            <uf-error [control]="actions" />
        </div>

        <div *ngIf="showEditFields" class="uf-box flat col-12">
            <div class="uf-app-bar accent">
                <div class="title">Editable Fields</div>
            </div>
            <div class="uf-grid pad">
                <!-- TODO restore once blacklists are restored <uf-blockquote content="Leave both blank for full access, otherwise use either Editable Fields to create an Allow List OR use Non-Editable Fields to create a Block List."
                    icon="infoSolid" class="info col-12"/> -->
                <uf-chips [formControlName]="permissionKeys.EditFields" [options]="fieldsResult"
                    (searchChange)="searchFields($event)" label="Editable Fields" class="col-12" />
                <!-- TODO Reflect Discover temporarily ignore field blacklists -->
                <!-- <uf-chips label="Non-Editable Fields" [formControlName]="permissionKeys.LockedFields" (searchChange)="searchFields($event)" [options]="fieldsResult" class="col-12"/> -->
            </div>
        </div>

        <div *ngIf="showReadFields" class="uf-box flat col-12">
            <div class="uf-app-bar accent">
                <div class="title">Visible Fields</div>
            </div>
            <div class="uf-grid pad">
                <!-- TODO restore once blacklists are restored <uf-blockquote content="Leave both blank for full visibility, otherwise use either Visible Fields to create an Allow List OR use Non-Visible Fields to create a Block List."
                    icon="infoSolid" class="info col-12"/> -->
                <uf-chips [formControlName]="permissionKeys.ReadFields" [options]="fieldsResult"
                    (searchChange)="searchFields($event)" label="Read fields" class="col-12" />
                <!-- TODO Reflect Discover temporarily ignore field blacklists -->
                <!-- <uf-chips label="Denied fields" [formControlName]="permissionKeys.DeniedFields" (searchChange)="searchFields($event)" [options]="fieldsResult" class="col-12"/> -->
            </div>
        </div>

        <uf-message *ngIf="saveValidationError" [content]="saveValidationError" icon="errorSolid"
            class="small error col-12">
            <h3>Validation error</h3>
        </uf-message>

    </div>
</uf-panel>

<div *ngIf="form" class="row space-children pad">
    <button (click)="close()" type="button" class="uf-button tertiary right">Cancel</button>
    <button *ngIf="form.enabled" (click)="save()" type="button" class="uf-button primary">Save</button>
</div>