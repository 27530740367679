<table class="uf-table inputs accent clickable small fixed">
    <thead>
        <tr>
            <th>Source Data</th>
            <th>Target Data</th>
        </tr>
    </thead>
    <tbody>
        <ng-template [ngIf]="control">
            <ng-template [ngForOf]="control.controls" let-ic ngFor>
                <tr *ngIf="(ic | asUfControl) as inputControl">
                    <td (click)="inputArgClick(inputControl)">
                        <div class="row space-children--small wrap">
                            <ng-container *ngTemplateOutlet="field; context: {
                                icon: getSourceFieldIcon(inputControl.value.field),
                                label: getSourceFieldLabel(inputControl.value.field),
                                field: inputControl.value.field,
                                expression: inputControl.value.expression
                            }" />
                            <span *ngIf="inputControl.showError" class="uf-lozenge error">{{
                                inputControl.errors?.message
                                }}</span>
                        </div>
                    </td>
                    <td (click)="inputArgClick(inputControl)">
                        <div class="row space-children--small wrap">
                            <ng-container *ngTemplateOutlet="field; context: {
                                icon: getTargetFieldIcon(inputControl.value.identifier),
                                label: getTargetFieldLabel(inputControl.value.identifier),
                                field: inputControl.value.identifier
                                }" />
                            <span *ngIf="inputControl.value?.isRequired" class="suffix">*</span>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </ng-template>
    </tbody>
</table>
<div *ngIf="!control || control.controls.length === 0" class="col center-all pad-top pad-sides">
    <uf-icon name="alert" class="x-large accent" />
    <h3 class="gap-none-bottom gap-sm-top">No Mappable Fields</h3>
    <p class="gap-sm-top">No Mappable fields for target</p>
</div>

<ng-template #field let-icon='icon' let-field="field" let-label="label" let-expression="expression">
    <uf-icon *ngIf="icon" [name]="icon" />
    <span *ngIf="label">{{label}}</span>
    <span *ngIf="label">(<code>{{field}}</code>)</span>
    <span *ngIf="!label">{{ field }}</span>
    <span *ngIf="expression"><code>{{ expression }}</code></span>
</ng-template>