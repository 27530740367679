import { Component, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalService, UfControlValueAccessor, UfMarkdownEditorComponent } from '@unifii/library/common';

import { UcMarkdownTokenizerComponent } from './uc-markdown-tokenizer.component';

@Component({
    selector: 'uc-markdown',
    templateUrl: './uc-markdown.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR, useExisting: UcMarkdownComponent, multi: true,
    }],
    styleUrls: ['./uc-markdown.less'],
})
export class UcMarkdownComponent extends UfControlValueAccessor<string> implements OnInit {

    @ViewChild(UfMarkdownEditorComponent, { static: true }) markdownEditor: UfMarkdownEditorComponent;

    @Input({ required: true }) label: string;
    @Input() help: string | null;
    @Input() showAlignmentOptions: boolean | null;

    @Output() override valueChange = new EventEmitter<string>();

    // list requires options
    readonly defaultOptions = [{ name: null, value: null }];

    private modalService = inject(ModalService);

    ngOnInit() {

        this.subscriptions.add(this.control.valueChanges.subscribe((v) => {
            if (v === '') {
                v = null;
            }
            this.valueChange.emit(v);
        }));
    }

    protected async addImage() {

        if (!this.markdownEditor) {
            return;
        }

        const data = {
            type: 'Image', showAlignmentOptions: !!this.showAlignmentOptions,
        };

        const value = await this.modalService.openLarge(UcMarkdownTokenizerComponent, data, { guard: true });

        if (value == null) {
            return;
        }

        this.markdownEditor.insertCustom(value);
    }

    protected async addVideo() {
        if (!this.markdownEditor) {
            return;
        }

        const data = {
            type: 'Video',
            showAlignmentOptions: !!this.showAlignmentOptions,
        };

        const value = await this.modalService.openLarge(UcMarkdownTokenizerComponent, data, { guard: true });

        if (value == null) {
            return;
        }

        this.markdownEditor.insertCustom(value);
    }

}
