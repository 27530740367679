<div *ngIf="ready && notifyEnabled" class="grid">

	<uf-text *ngIf="config.itemLabel.show && form.controls.itemLabel" [control]="form.controls.itemLabel | asUfControl"
		[(value)]="field.itemLabel" label="Item Label" class="col-1of1" />

	<uf-text *ngIf="config.addButtonLabel.show && form.controls.addButtonLabel" [control]="form.controls.addButtonLabel | asUfControl"
		[(value)]="field.addButtonLabel" label="Add Button Label" class="col-1of1" />

	<uf-select *ngIf="config.width.show && form.controls.width" [control]="form.controls.width | asUfControl"
		[disabled]="field.template === fieldTemplate.RadioWithContent" [options]="widthOptions"
		[(value)]="field.width" label="Field Width" valueProperty="value" nameProperty="name" class="col-1of1" />

	<uf-checkbox *ngIf="config.breakAfter.show && form.controls.breakAfter" [control]="form.controls.breakAfter | asUfControl"
		[disabled]="!field.width || field.width === fieldWidth.Stretch"
		[(value)]="field.breakAfter" label="Break after" class="col-1of1" />

	<uf-select *ngIf="config.template.show && form.controls.template" [options]="templateOptions"
		[control]="form.controls.template | asUfControl" [(value)]="field.template"
		(valueChange)="templateChange($event)" label="Template" valueProperty="value" nameProperty="name"
		class="col-1of1" />

	<div *ngIf="config.columnVisibility.show" class="col-1of1">
		<label class="x-small">Column Visibility</label>
		<div class="pad-sm-top">
			<ul class="uf-list uf-box flat column-toggles-list">
				<ng-template [ngForOf]="field.fields" let-child ngFor>
					<ng-container *ngIf="child.identifier">
						<li class="row center-all space-children pad-sm-sides">
							<div class="grow">
								<p>{{child.label}}</p>
							</div>
							<uf-checkbox *ngIf="columnVisibility?.hideFromColumnsOnMobile"
								[value]="!columnVisibility?.hideFromColumnsOnMobile?.includes(child.identifier)"
								(valueChange)="columnVisibilityChange($event, child.identifier, displayType.Mobile)"
								label="Mobile" />
							<uf-checkbox
								[value]="!columnVisibility?.hideFromColumnsOnDesktop?.includes(child.identifier)"
								(valueChange)="columnVisibilityChange($event, child.identifier, displayType.Desktop)"
								label="Desktop" />
						</li>
					</ng-container>
				</ng-template>
			</ul>
		</div>
	</div>

	<uf-select *ngIf="config.position.show && form.controls.position" [control]="form.controls.position | asUfControl"
		[options]="positionOptions" [(value)]="field.template" label="Position"
		valueProperty="value" nameProperty="name" class="col-1of1" />

	<uf-select *ngIf="config.columnCount.show && form.controls.columnCount" [control]="form.controls.columnCount | asUfControl"
		[options]="columnCountOptions" [(value)]="field.columnCount" label="Columns"
		valueProperty="value" nameProperty="name" class="col-1of1" />

	<uf-select *ngIf="config.layoutDirection.show && form.controls.layoutDirection" [control]="form.controls.layoutDirection | asUfControl"
		[options]="directionOptions" [(value)]="field.layoutDirection" label="Direction"
		valueProperty="value" nameProperty="name" class="col-1of1" />

	<uf-number *ngIf="config.minWidth.show && form.controls.minWidth" [control]="form.controls.minWidth | asUfControl"
		[(value)]="field.minWidth" label="Min Width" class="col-1of1" />

	<uf-number *ngIf="config.minHeight.show && form.controls.minHeight" [control]="form.controls.minHeight | asUfControl"
		[(value)]="field.minHeight" label="Min Height" class="col-1of1" />

	<div *ngIf="field.template === 'table' && config.columns.show" class="uc-group col-1of1--no-padding">
		<div class="uf-app-bar flat">
			<div class="title primary">
				Columns
			</div>
		</div>
		<div class="uc-group-content grid">
			<uf-search-result *ngIf="form.controls.columns" [control]="form.controls.columns | asUfControl"
				[options]="fieldColumns" (valueChange)="addColumn($event)"
				(searchChange)="filterColumns($event)" label="Add Columns" placeholder="Search for..." nameProperty="label"
				class="col-1of1" />
			<ng-template [ngIf]="field.columns && field.columns.length">
				<uc-drag-list [items]="field.columns" (moved)="notifyEdited()" canDrop="false" canReorder="true"
					class="col-1of1">
					<ul class="uc-list">
						<li class="uc-list-item--draggable--small row center-all"
							*ngFor="let column of field.columns, let i = index" dragItem>
							<div class="list-content">
								<div class="list-context">
									<button (click)="removeColumn(column)" type="button" title="Delete"
										class="uf-action tertiary">
										<uf-icon name="delete" />
									</button>
								</div>
								<div class="grow">{{ column.label }}</div>
							</div>
						</li>
					</ul>
				</uc-drag-list>
			</ng-template>
		</div>
	</div>

</div>