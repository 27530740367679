import { SortStatus, TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { RequestAnalyticsOrigin, mergeParams } from '@unifii/sdk';

import { ActivitiesParams, ActivityType, UcWorkflow, WorkflowActivityInfo } from 'client';

export class ActivityTableDataSource extends TableDataSource<WorkflowActivityInfo> {

    constructor(
        private ucWorkflow: UcWorkflow,
        private type: ActivityType,
        private params?: ActivitiesParams,
        private search?: string,
        private sort?: SortStatus,
    ) {
        super();
    }

    get filtered() {
        return this.search != null && this.search.trim().length > 0;
    }

    get sorted() {
        return this.sort != null;
    }

    load(options?: TableDataSourceQueryOptions) {
        this.ucWorkflow.getActivities({
            params: mergeParams(this.params as Record<string, unknown>, { offset: options?.offset, limit: options?.limit, type: this.type }),
            analytics: { origin: RequestAnalyticsOrigin.Table },
        }).then(
            (tables) => this.stream.next({ data: tables }),
            (error) => this.stream.next({ error }),
        );
    }

}
