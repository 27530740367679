import { Injectable, OnDestroy, inject } from '@angular/core';
import { TableContainerManager } from '@unifii/components';
import { DataDisplayService, FilterEntry, FilterValue, TableConfig } from '@unifii/library/common';
import { DataType } from '@unifii/sdk';
import { Subject, Subscription } from 'rxjs';

import { DataSourceInfo, UcDataTransactions } from 'client';
import { DefaultPaginationParams } from 'constant';

import { DataTransactionsTableDataSource } from './data-transaction-data-source';

@Injectable()
export class DataTransactionsTableManager implements TableContainerManager<DataSourceInfo, FilterValue, FilterEntry>, OnDestroy {

    addActionConfig = true;
    reload = new Subject<void>();
    updateItem = new Subject<DataSourceInfo | { item: DataSourceInfo; trackBy: keyof DataSourceInfo }>();

    readonly tableConfig: TableConfig<DataSourceInfo> = {
        id: 'data-transactions',
        columnToggles: false,
        selectable: false,
        pageSize: DefaultPaginationParams.limit,
        columns: [{
            name: 'name',
            label: 'Name',
        }, {
            name: 'integrationName',
            label: 'Integration',
        }, {
            name: 'integrationProviderName',
            label: 'Provider',
        }, {
            name: 'lastModifiedAt',
            label: 'Last Modified At',
            value: (item) => this.dataDisplayService.displayAsString(item.lastModifiedAt, { type: DataType.OffsetDateTime, asDistanceFromNow: true }),
        }],
        rowLink: (item) => '' + item.id,
    };

    private connection?: Subscription;
    private items: DataSourceInfo[] = [];

    private dataDisplayService = inject(DataDisplayService);
    private ucDataTransactions = inject(UcDataTransactions);

    ngOnDestroy() {
        this.connection?.unsubscribe();
    }

    createDataSource() {
        const dataSource = new DataTransactionsTableDataSource(this.ucDataTransactions);

        this.connection?.unsubscribe();

        this.items = [];
        this.connection = dataSource.connect().subscribe((items) => this.items.push(...(items.data ?? [])));

        return dataSource;
    }

    getNextItem(id?: string): DataSourceInfo | undefined {
        const itemIndex = this.items.findIndex((item) => item.id === id);

        if (itemIndex < 0) {
            return;
        }

        return this.items[itemIndex + 1];
    }

    getItem(id: string): DataSourceInfo | undefined {
        return this.items.find((item) => item.id === id);
    }

}
