<uf-panel *ngIf="!isNew" class="container">
    <div class="header-pane uf-app-bar transparent">
        <uc-builder-header [templateRef]="templateRef" />
    </div>
    <div *ngIf="id" class="content-pane scrollable">
        <uf-tabs>
            <uf-tab label="Settings" path="" />
            <ng-template [ngIf]="arePushNotificationsEnabled">
                <uf-tab label="Devices" path="devices" />
                <uf-tab label="Send Notification" path="send-notification" />
            </ng-template>
        </uf-tabs>
    </div>
</uf-panel>

<router-outlet *ngIf="isNew" />