import { Injectable } from '@angular/core';
import { ModalService } from '@unifii/library/common';
import { Field, FieldType } from '@unifii/sdk';

import { UcProject, UcProjectInfo } from 'client';

import { ContextService } from './context.service';

@Injectable({ providedIn: 'root' })
export class LimitService {

    constructor(
        private context: ContextService,
        private modalService: ModalService,
        private ucProject: UcProject,
    ) { }

    get projectsLimit() {
        return this.context.tenantSettings?.limits?.projectsLimit;
    }

    get formsLimit() {
        return this.context.tenantSettings?.limits?.formsLimit;
    }

    get pagesLimit() {
        return this.context.tenantSettings?.limits?.pagesLimit;
    }

    get viewsLimit() {
        return this.context.tenantSettings?.limits?.viewsLimit;
    }

    get sectionsLimit() {
        return this.context.tenantSettings?.limits?.sectionsLimit;
    }

    canAddProject(projects: UcProjectInfo[]) {
        if (!this.projectsLimit || projects.length < this.projectsLimit) {
            return true;
        }

        void this.modalService.openAlert({
            message: 'You have reached the maximum number of projects in this tenant',
        });

        return false;
    }

    async canAddForms(formsToAdd = 1) {

        if (!this.formsLimit || !this.ucProject) {
            return true;
        }

        const forms = await this.ucProject.getForms({ params: { limit: this.formsLimit } });

        if (this.formsLimit < (forms.length + formsToAdd)) {
            void this.modalService.openAlert({ message: 'You have reached the maximum number of forms in this project' });

            return false;
        }

        return true;
    }

    async canAddPages(pagesToAdd = 1) {
        if (!this.pagesLimit || !this.ucProject) {
            return true;
        }

        const pages = await this.ucProject.getPages({ params: { limit: this.pagesLimit } });

        if (this.pagesLimit < (pages.length + pagesToAdd)) {
            void this.modalService.openAlert({ message: 'You have reached the maximum number of pages in this project' });

            return false;
        }

        return true;
    }

    async canAddViews(viewsToAdd = 1) {
        if (!this.viewsLimit || !this.ucProject) {
            return true;
        }

        const views = await this.ucProject.getViews({ params: { limit: this.viewsLimit } });

        if (this.viewsLimit < (views.length + viewsToAdd)) {
            void this.modalService.openAlert({ message: 'You have reached the maximum number of views in this project' });

            return false;
        }

        return true;
    }

    canAddSection(field: Field, fields: Field[]) {
        if (!this.sectionsLimit) {
            return true;
        }

        if (field.type === FieldType.Section) {
            let sectionCount = 0;

            for (const item of this.fieldIterator(fields)) {
                if (item.type === FieldType.Section) {
                    sectionCount++;
                    if (sectionCount >= this.sectionsLimit) {
                        void this.modalService.openAlert({ message: 'You have reached the maximum number of sections in this form' });

                        return false;
                    }
                }
            }
        }

        return true;
    }

    private *fieldIterator(fields: Field[]): Iterable<Field> {

        for (const field of fields) {
            yield field;

            if (field.fields) {
                yield *this.fieldIterator(field.fields);
            }
        }
    }

}
