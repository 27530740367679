import { inject } from '@angular/core';
import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { ensureError } from '@unifii/sdk';

import { TokenService, UcClient } from 'client';

import { ContextService } from '../services/context.service';

export const canActivateAccount: CanActivateFn = async(_, state: RouterStateSnapshot) => {

    const client = inject(UcClient);
    const tokenService = inject(TokenService);
    const router = inject(Router);
    const context = inject(ContextService);

    const params = state.url !== '/' ? { next: state.url } : {};

    if (tokenService.token == null) {
        void router.navigate(['/login', params]);

        return false;
    }

    const isUpdate = context.account != null;
    let getAccountError: Error | undefined;
    let result: boolean | undefined;
    let raceTimeoutId: NodeJS.Timeout | undefined;

    try {
        const promises = [client.getMyAccount()];

        if (isUpdate) {
            promises.push(new Promise((_resolve, rej) => { raceTimeoutId = setTimeout(() => rej(), 500); }));
        }

        const getAccountPromise = Promise.race(promises);

        context.account = await getAccountPromise;

        clearTimeout(raceTimeoutId);

    } catch (error) {
        getAccountError = ensureError(error);
    } finally {
        if (
            (!isUpdate && getAccountError) ||
            result != null && context.account?.changePasswordOnNextLogin
        ) {
            void router.navigate(['/login', params]);
            result = false;
        }

        if (result == null) {
            result = true;
        }
    }

    return result;
};
