<uf-expander *ngIf="ready" class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Advanced</div>
        <uf-icon *ngIf="isInvalid" name="error" class="error" />
    </div>
    <div [formGroup]="control" expanderBody class="uf-grid pad-sides pad-sm-top pad-bottom">

        <uf-multi-choice [formControlName]="fieldKeys.DataCaptures"
            *ngIf="meta.dataCaptures && !control.get(fieldKeys.DataCaptures)?.disabled" [options]="dataCaptureTypes"
            label="Data Capture" valueProperty="identifier" />

        <uf-multi-choice *ngIf="meta.allowedTypes" [formControlName]="fieldKeys.AllowedTypes"
            [options]="linkAllowedTypes" label="Allowed Types" valueProperty="identifier" />

        <!-- TODO - remove Reportable & Sortable -->
        <!-- <uf-checkbox  *ngIf="meta.isReportable" label="Reportable" [formControlName]="fieldKeys.IsReportable"
            class="col-12"/>

        <uf-checkbox  *ngIf="meta.isSearchable" label="Searchable" [formControlName]="fieldKeys.IsSearchable"
            class="col-12"/> -->

        <uf-checkbox *ngIf="meta.isTranslatable" [formControlName]="fieldKeys.IsTranslatable" label="Translatable"
            class="col-12" />

        <uf-chips *ngIf="meta.tags" [formControlName]="fieldKeys.Tags" [allowCustom]="true"
            [options]="tagsResult" (searchChange)="searchTags($event)" label="Tags" class="col-12" />

    </div>
</uf-expander>