<div class="uf-box col-12">

    <div class="uf-app-bar flat accent">
        <div class="title">
            Columns
        </div>
        <uc-expander-controls [container]="expandAllContainer" class="primary" />
    </div>

    <div #expandAllContainer class="uc-group-content pad-sides pad-bottom pad-sm-top">
        <uc-drag-list *ngIf="cols.length" [items]="cols" (moved)="emit()" canDrop="false" canReorder="true">
            <ng-template [ngForOf]="cols" let-colInfo ngFor let-i="index">
                <uf-expander [isExpanded]="false" dragItem class="uf-box draggable flat gap-sm-top">
                    <div expanderHeader dragHandle class="uf-app-bar">
                        <uf-icon [name]="colInfo.icon" />
                        <div class="grow title primary">{{colInfo.label}}</div>
                        <uf-icon *ngIf="!!parentControl.get(i+'')?.invalid" name="error"
                            title="Column configuration is invalid" class="error" />
                        <span *ngIf="!colInfo.exists" class="uf-lozenge error">Missing</span>
                        <uf-icon *ngIf="hasVariations(colInfo)" name="variation" title="This node has variations" />
                        <button (click)="removeColumn(i)" title="Delete" type="button" class="uf-action tertiary">
                            <uf-icon name="delete" />
                        </button>
                    </div>
                    <ng-container expanderBody>
                        <uc-column-descriptor-editor *ngIf="colInfo.exists" [descriptor]="colInfo.dataPropertyDescriptor"
                            [index]="i" [parentControl]="parentControl" [(column)]="colInfo.descriptor"
                            (columnChange)="emit()" />
                    </ng-container>
                </uf-expander>
            </ng-template>
        </uc-drag-list>

        <uf-search-result [options]="columnResults"
            (valueChange)="addColumn({identifier: $event.identifier})" (searchChange)="filterColumns($event)" placeholder="Search column"
            nameProperty="display" class="gap-top" />

        <div class="row gap-top">
            <button (click)="addCustom()" type="button" class="uf-button right">Add Custom</button>
        </div>
    </div>

</div>