<uf-expander *ngIf="control" class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Variations</div>
        <uf-icon *ngIf="control.invalid" name="error" class="error" />
        <uc-expander-controls *ngIf="control.length" [container]="variationsWrap" />
    </div>

    <div #variationsWrap expanderBody class="pad small">
        <uc-drag-list *ngIf="control.length" [parent]="control" [items]="control.controls" canDrop="false"
            canReorder="true">
            <ng-container *ngFor="let variation of control.controls | asUfControlsGroups; let i = index">

                <uf-expander dragItem class="uf-box flat draggable gap-sm-bottom">
                    <div expanderHeader dragHandle class="uf-app-bar flat">
                        <uf-icon [name]="getIcon(variation)" />
                        <div class="title primary">{{variation.get(variationControlKeys.Name)?.value}}</div>
                        <uf-icon *ngIf="!getIsPublished(variation)" name="unpublished"
                            title="This content is unpublished" class="icon-warning" />
                        <uf-icon *ngIf="variation.invalid" name="error" class="error" />
                        <button *ngIf="!variation.disabled" (click)="remove(i)" type="button" title="Delete"
                            class="uf-action">
                            <uf-icon name="delete" />
                        </button>
                    </div>
                    <div expanderBody class="uf-grid pad">
                        <uf-data-display-list *ngIf="getVariationRolesDescriptionItem(variation)"
                            [items]="getVariationRolesDescriptionItem(variation)" class="small-label col-12" />
                    </div>
                </uf-expander>
            </ng-container>
        </uc-drag-list>
        <div class="row">
            <button *ngIf="!control.disabled" (click)="add()" type="button" class="uf-button x-small right">
                Add Variation
            </button>
        </div>
    </div>
</uf-expander>