<div [formGroup]="parentControl" class="uf-grid">
    <ng-template [ngIf]="ready">
        <uf-data-display-list class="col-6">
            <dt>Table</dt>
            <dd>{{table.title}}</dd>
        </uf-data-display-list>
        <a *ngIf="tableLink" [routerLink]="tableLink" target="_blank" class="uf-action tertiary col-6 right"
            title="Open">
            <uf-icon name="open" />
        </a>
        <uf-text [formControlName]="controlKeys.Title" [placeholder]="table.title" label="Title" class="col-12">
            <uf-help content="Leave blank to use the Table's title." />
        </uf-text>
        <uf-number *ngIf="isPageViewTemplate" [formControlName]="controlKeys.Limit" label="Limit" class="col-12" />
        <uf-chips [formControlName]="controlKeys.Roles" [options]="rolesResult" (searchChange)="findRoles($event)"
            label="Restrict to Roles" placeholder="Search Roles" class="col-12" />
        <uf-checkbox *ngIf="showCanAdd && isPageViewTemplate" [formControlName]="controlKeys.CanAdd"
            label="Enable Add Button" class="col-12" />
        <uc-filter-editor *ngIf="staticFilters.length" [dataProperties]="staticFilters" [parentControl]="parentControl"
            [(filter)]="module.filter" (filterChange)="onFilterChange()" heading="Filter" class="col-12" />
        <uf-error [control]="canAddControl" class="col-12" />
    </ng-template>
    <uf-message *ngIf="error" content="Table not found, please remove this module" class="error col-12" />
</div>