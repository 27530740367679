<div class="header">
    <button (click)="runtime.close()" title="Close" type="button" class="uf-action tertiary">
        <uf-icon name="close" />
    </button>
    <h3>{{modalTitle}}</h3>
</div>
<ng-template [ngIf]="form && typesControl">
    <div [formGroup]="form" class="uf-grid pad">
        <uf-text [formControlName]="controlKeys.Identifier" ufAutofocus label="Identifier" class="col-12" />

        <uf-select *ngFor="let value of typesControl.value; let i = index; trackBy: typeTrackBy"
            [options]="integrationFeatureArgTypes" [value]="value" (valueChange)="updateValue($event, i)" label="Type"
            class="col-12" />

        <!--TODO Required field will be added later on, when expressions are updated to accept it-->
        <!-- <uf-select label="Is Required" [options]="isRequiredOptions" nameProperty="name" valueProperty="identifier"
            [formControlName]="controlKeys.IsRequired" class="col-12" /> -->
    </div>
    <uf-error [control]="typesControl" class="row gap-sides" />
</ng-template>
<div class="row space-children pad">
    <button (click)="runtime.close()" type="button" class="uf-button tertiary right">Cancel</button>
    <button (click)="save()" type="button" class="uf-button primary">Save</button>
</div>