import { Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { Scope, UfControl, getSafeRuntimeField } from '@unifii/library/common';
import { Field, FieldType } from '@unifii/sdk';
import { Subscription, debounceTime } from 'rxjs';

import { BuilderService } from 'components/compound-builder/builder.service';

import * as content from '../content';

@Component({
    selector: 'uc-content-field',
    template: '<div #child></div>',
})
export class ContentFieldComponent implements OnInit, OnDestroy {

    @Input({ required: true }) field: Field;
    @Input() control: UfControl;

    @Output() contentChange = new EventEmitter();

    @ViewChild('child', { read: ViewContainerRef, static: true }) private container: ViewContainerRef;

    private component: content.Content;
    private _content: any;
    private _scope: Scope;
    private subscriptions = new Subscription();

    constructor(
        private element: ElementRef,
        private injector: Injector,
        private builderService: BuilderService,
    ) { }

    @Input() set scope(v: Scope) {

        this._scope = v || {};

        if (this.component) {
            this.component.scope = this._scope;
        }

        if (this.field?.identifier) {
            this.content = this._scope[this.field.identifier];
        }
    }

    get scope(): Scope {
        return this._scope;
    }

    @Input() set content(v: any) {

        this._content = v;

        if (this.component) {
            this.component.content = v;
        }
    }

    get content() {
        return this._content;
    }

    ngOnInit() {

        this.control = this.control ?? new UfControl();

        this.subscriptions.add(this.builderService.fieldEdited.pipe(debounceTime(50)).subscribe((i) => {
            if (i.subject === this.field) {
                console.log('ContentFieldComponent.fieldEdited', this.field.type);
                this.mirrorRuntimeField();
            }
        }));

        const component = this.getComponent(this.field.type);

        if (!component) {
            console.error('No component found');

            return;
        }

        this.container.clear();
        const componentRef = this.container.createComponent(component, { index: 0, injector: this.injector });

        this.component = componentRef.instance;
        this.component.editorField = this.field;
        this.component.content = this.content;
        this.component.contentChange = this.contentChange;
        this.component.control = this.control;

        // Guard element.naviteElement.dateset, not available in AngularUniversal
        if (this.field.identifier && this.element?.nativeElement?.dataset) {
            this.element.nativeElement.dataset.identifier = this.field.identifier;
        }

        this.initCssClasses();
        this.mirrorRuntimeField();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private mirrorRuntimeField() {
        const runtimeField = getSafeRuntimeField(JSON.parse(JSON.stringify(this.field)));

        this.component.field = runtimeField;
    }

    private initCssClasses() {
        const htmlElement: HTMLElement = this.element.nativeElement;
        const cssClasses: string[] = ['uf-field--' + this.field.type.toLowerCase()];

        const fieldWidthClass = 'col-1of1';

        // Element doesn't include a column class
        if (!htmlElement.className.includes('col-')) {
            cssClasses.push(fieldWidthClass);
        }

        htmlElement.classList.add(...cssClasses);
    }

    private getComponent(type: FieldType): Type<content.Content> | undefined {

        switch (type) {
            case FieldType.Text:
            case FieldType.MultiText:
            case FieldType.Number:
            case FieldType.Date:
            case FieldType.Time:
            case FieldType.DateTime:
            case FieldType.Phone:
            case FieldType.Email:
            case FieldType.Website:
            case FieldType.Address:
            case FieldType.GeoLocation:
                return content.GroupInputComponent;
            case FieldType.FileList:
                return content.FileListComponent;
            case FieldType.ImageList:
                return content.ImageListComponent;
            case FieldType.SoundList:
                return content.SoundListComponent;
            case FieldType.VideoList:
                return content.VideoListComponent;
            case FieldType.LinkList:
                return content.LinkListComponent;
            case FieldType.Link:
            case FieldType.DefinitionLink:
                return content.LinkComponent;
            default:
                console.log('ContentFieldComponent.getComponent no match for', type);

                return;
        }
    }

}
