import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { UcField } from 'client';
import { FORM_EDITOR_CONSTANTS } from 'pages/form-editor/form-editor-constants';
import { truncateStringWithEllipsis } from 'pages/utils';
import { DialogsService } from 'services/dialogs.service';

import { BuilderEventInfo, BuilderService } from '../../../components/compound-builder/builder.service';

@Component({
    selector: 'uc-collection-field',
    templateUrl: './collection-field.html',
    styleUrls: ['./collection-field.less'],
})
export class CollectionFieldComponent implements AfterViewInit, OnDestroy, OnInit {

    @Input() field: UcField;
    @Input() selected: boolean;

    protected readonly fieldTypes = FieldType;
    protected valid = true;
    protected ready = true;
    protected iconName: string;

    private subscriptions = new Subscription();

    constructor(
        private builderService: BuilderService,
        private cdr: ChangeDetectorRef,
        private dialogs: DialogsService,
    ) { }

    ngOnInit() {
        this.subscriptions.add(this.builderService.ready.subscribe(() => { this.init(); }));

        this.subscriptions.add(this.builderService.fieldSelected.subscribe((i) => {
            this.selected = this.isMe(i);
            this.cdr.detectChanges();
        }));

        this.subscriptions.add(this.builderService.fieldRefreshed.subscribe((i) => {
            if (this.isMe(i)) {
                this.valid = this.builderService.isValid(this.field);
            }
        }));
    }

    ngAfterViewInit() {
        this.builderService.fieldReady.next({ subject: this.field, atomic: true });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    get label() {

        if (this.field.type === FieldType.Content) {
            if (!this.field.help) {
                return this.field.type;
            }

            return truncateStringWithEllipsis(this.field.help, FORM_EDITOR_CONSTANTS.FIELD_RENDERED_LABEL_MAX_LENGTH);
        }

        return this.field.label;
    }

    init() {
        this.iconName = this.builderService.builder.getFieldRef(this.field).icon;
        this.ready = true;
    }

    select(event: Event) {
        event.stopPropagation();
        this.builderService.fieldSelect.next({ subject: this.field });
    }

    async remove(event: Event) {
        event.stopPropagation();

        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.builderService.fieldRemove.next({ subject: this.field, atomic: true });
    }

    private isMe(i?: BuilderEventInfo): boolean {
        return i?.subject === this.field;
    }

}
