<div class="header">
    <button (click)="close()" type="button" class="uf-action tertiary" title="Close">
        <uf-icon name="close" />
    </button>
    <h3>{{data.title}}</h3>
</div>

<uf-panel class="container">

    <div class="pad-sides">
        <ul class="uf-list">
            <li *ngFor="let option of data.types" (click)="close(option)" class="uf-list-button">
                <div *ngIf="option.logo" class="uc-image gap-right">
                    <img [src]="option.logo | bypassSanitizeUrl">
                </div>
                <uf-icon *ngIf="option.icon" [name]="option.icon" />
                <div>{{ option.label }}</div>
            </li>
        </ul>
    </div>

</uf-panel>