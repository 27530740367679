import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@unifii/library/common';

import { UcProjectInfo } from 'client';
import { ProjectComponent } from 'navigation/project.component';
import { TenantComponent } from 'navigation/tenant.component';
import { BucketComponent } from 'pages/form-data';

import { ContextService } from './context.service';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {

    constructor(
        private context: ContextService,
    ) { }

    /**
     * @param route ActivatedRoute of the page where the breadcrumbs are displayed
     * @param customNames An array containing name overrides of route titles.
     * The array replaces breadcrumb labels starting from innermost breadcrumb (on right).
     * Use undefined if you need to pad the array (undefined will leave original route name)
     */
    getBreadcrumbs(route: ActivatedRoute, customNames: (string | undefined)[] = []): Breadcrumb[] {
        const path = route.pathFromRoot;
        const pathSegments = path.map((activatedRoute) => activatedRoute.snapshot.url.map((urlSegment) => urlSegment.path).join('/'));

        const breadcrumbs: Breadcrumb[] = [];
        let tenantComponentIndex = 0;

        path.forEach((activatedRoute, index) => {
            // use custom name
            let name = customNames[index - (path.length - customNames.length)] ?? activatedRoute.snapshot.data.title;

            // use tenant name for tenant breadcrumb, save tenant component index for later
            if (activatedRoute.snapshot.component === TenantComponent) {
                name = this.context.tenantSettings?.name;
                tenantComponentIndex = index;
            }

            // if in a project, use project name and remove tenant breadcrumb
            if (activatedRoute.snapshot.component === ProjectComponent) {
                name = (this.context.project as UcProjectInfo)?.name;
                breadcrumbs.splice(tenantComponentIndex, 1); // remove tenant breadcrumb
            }

            // if in a bucket, use bucketId
            if (activatedRoute.snapshot.component === BucketComponent) {
                name = this.context.bucketId;
            }

            // make a url segment for this breadcrumb
            const urlSegments = [[...pathSegments.slice(0, index + 1)].join('/')];

            breadcrumbs.push({ name, urlSegments });
        });

        // filter out breadcrumbs without a name
        return breadcrumbs.filter((breadcrumb) => breadcrumb.name?.length);
    }

}
