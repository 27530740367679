import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ContextMenuOption, FieldTypeIcon, ModalService, ToastService, UfControl, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { Dictionary, ErrorType, FieldType, UfError } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { Integration, IntegrationFeature, IntegrationFeatureArg, IntegrationFeatureArgRoot, IntegrationFeatureArgType, IntegrationProviderFeatureType, UcIntegrations } from 'client';
import { EditData, SaveAndClose, SaveOption, SaveOptionType, useDefaultErrorMessage } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { DialogsService } from 'services/dialogs.service';

import { IntegrationFeatureArgModalComponent } from './integration-feature-arg-modal.component';
import { IntegrationFeaturesTableManager } from './integration-features-table-manager';
import { IntegrationFeaturesComponent } from './integration-features.component';
import { IntegrationFeatureArgData, IntegrationFeatureControlKeys } from './models';

enum ContextMenuActions {
    Add = 'Add',
    Edit = 'Edit',
    Delete = 'Delete'
}

interface ContextMenuOptions<T> extends ContextMenuOption<T> {
    action: ContextMenuActions;
}

const ObjectIcon = 'object';

@Component({
    templateUrl: './integration-feature.html',
    selector: 'uc-integration-feature',
})
export class IntegrationFeatureComponent implements OnInit, OnDestroy, EditData {

    @ViewChild('inputs') private inputTable?: ElementRef<HTMLElement>;
    @ViewChild('outputs') private outputTable?: ElementRef<HTMLElement>;

    protected readonly controlKeys = IntegrationFeatureControlKeys;
    protected readonly pathMessage = 'Relative path for the call, can contain variables (e.g. /books/{bookId} ). Variable has to refer to an input argument.';
    protected readonly helpMessages = {
        [IntegrationFeatureControlKeys.EndpointPath]: this.pathMessage,
        [IntegrationFeatureControlKeys.SecondaryEndpointPath]: `${this.pathMessage} Secondary endpoint is optional and is only used in Lookups for getting a specific object by ID`,
        [IntegrationFeatureControlKeys.IdExp]: 'Expression that refers to the output argument that represents the unique identifier (usually primary key) or an expression that constructs one',
        [IntegrationFeatureControlKeys.DisplayExp]: 'Expression that constructs a display value from the output',
        [IntegrationFeatureControlKeys.InputArgs]: 'Describes all input arguments',
        [IntegrationFeatureControlKeys.OutputArgs]: 'Describes output format and shape',
        [IntegrationFeatureControlKeys.PreviewEndpointPath]: `${this.pathMessage} Preview endpoint is optional and will be used in preview mode. If it is not provided, data will be forwarded to Endpoint Path by default.`,
        [IntegrationFeatureControlKeys.CorrelationExp]: 'For deduping purposes',
    };
    protected readonly contextOptions: ContextMenuOptions<IntegrationFeatureArgData>[] = [
        { label: 'Add', action: ContextMenuActions.Add, icon: 'add', predicate: (arg?: IntegrationFeatureArgData) => arg?.kind === IntegrationFeatureArgType.Object },
        { label: 'Edit', action: ContextMenuActions.Edit, icon: 'edit' },
        { label: 'Delete', action: ContextMenuActions.Delete, icon: 'delete' },
    ];

    protected error?: UfError;
    protected form: UfControlGroup;
    protected integrationFeature: IntegrationFeature;
    protected isWebhook: boolean;

    // the arguments aren't using controls to edit values, only to check if it's required or no.
    // because of the flatten table it's easier to manipulate object instead of controls
    protected input?: IntegrationFeatureArgData[];
    protected output?: IntegrationFeatureArgData[];
    protected inputArgsControl: UfControl;
    protected outputArgsControl: UfControl;
    protected endpointPathControl: UfControl;
    protected previewEndpointPathControl: UfControl;
    protected secondaryEndpointPathControl: UfControl;
    protected displayExpControl: UfControl;
    protected idExpControl: UfControl;
    protected correlationExpControl: UfControl;

    private readonly requiredField = ValidatorFunctions.required('Required, please complete.');
    private subscription = new Subscription();
    private integration: Integration;
    private isNew: boolean;
    private isSink: boolean;
    private emptyInput: IntegrationFeatureArgRoot = { attributes: {}, kind: IntegrationFeatureArgType.Object };
    private isHttpConnector = false;

    private ufb = inject(UfFormBuilder);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private toastService = inject(ToastService);
    private parent = inject(IntegrationFeaturesComponent);
    private breadcrumbService = inject(BreadcrumbService);
    private builderHeaderService = inject(BuilderHeaderService);
    private modalService = inject(ModalService);
    private dialogs = inject(DialogsService);
    private ucIntegrations = inject(UcIntegrations);
    private manager = inject(TableContainerManager) as IntegrationFeaturesTableManager;
    private location = inject(Location);

    get edited() {
        return !!this.builderHeaderService.config.edited;
    }

    set edited(edited: boolean) {
        this.builderHeaderService.config.edited = edited;
    }

    constructor() {
        // Integration feature when it's a new one and it's being added to an integration
        const { integrationFeature } = (this.location.getState() as { integrationFeature: IntegrationFeature } | undefined ?? {});

        if (integrationFeature) {
            this.isNew = true;
            this.integrationFeature = integrationFeature;
        }
    }

    ngOnInit() {
        const id = this.route.snapshot.params.id;
        // Integration feature when it's being edited
        const { integration, integrationFeature } = this.parent.getFeature(id);

        if (!integrationFeature && !this.isNew) {
            this.error = useDefaultErrorMessage(new UfError('Integration feature not found', ErrorType.NotFound));

            return;
        }

        this.integration = integration;
        this.isHttpConnector = this.integration.provider.id === 'http';
        this.integrationFeature = this.integrationFeature ?? integrationFeature;
        this.isSink = this.integrationFeature.type === IntegrationProviderFeatureType.FeatureTypeSink;
        this.isWebhook = this.integrationFeature.type === IntegrationProviderFeatureType.FeatureTypeWebhook;

        this.setup();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.parent.buildHeaderConfig();
    }

    protected async executeContextOption(arg: IntegrationFeatureArgData, option: ContextMenuOptions<IntegrationFeatureArgData>, isInput: boolean) {

        switch (option.action) {
            case ContextMenuActions.Add: {
                const response = await this.modalService.openFit(IntegrationFeatureArgModalComponent, { isInput });

                if (!response) {
                    return;
                }

                if (!arg.children) {
                    arg.children = [];
                }

                arg.children.push({ ...response, icon: this.getArgIcon(response), kindDescription: this.buildKindDescription(response.kind, response.listCount ?? 0) });
                break;
            }
            case ContextMenuActions.Edit: {
                const response = await this.modalService.openFit(IntegrationFeatureArgModalComponent, { isInput, arg });

                if (!response) {
                    return;
                }

                arg = Object.assign(arg, { children: undefined }, response, { icon: this.getArgIcon(response), kindDescription: this.buildKindDescription(response.kind, response.listCount) });
                break;
            }
            case ContextMenuActions.Delete: {
                if (!await this.dialogs.confirmDelete()) {
                    return;
                }

                if (isInput && this.input) {
                    this.input = this.removeArg(this.input, arg);
                } else if (this.output) {
                    this.output = this.removeArg(this.output, arg);
                }
            }

        }

        this.rebuildList(isInput);
    }

    protected async addInputArg(isInput: boolean) {
        const response = await this.modalService.openFit(IntegrationFeatureArgModalComponent, { isInput });

        if (!response) {
            return;
        }

        response.icon = this.getArgIcon(response);
        response.kindDescription = this.buildKindDescription(response.kind, response.listCount);

        if (isInput) {
            this.input?.push(response);
        } else {
            this.output?.push(response);
        }

        this.rebuildList(isInput);
    }

    private setup() {
        this.form = this.buildForm(this.integrationFeature);

        if (!this.isHttpConnector) {
            this.form.disable();
        } else {
            this.subscription.add(this.form.valueChanges.subscribe(() => (this.edited = true)));
        }

        const input = this.buildArgModel(this.integrationFeature.input ?? {} as any, 'root');

        const output = this.buildArgModel(this.integrationFeature.output ?? {} as any, 'root');

        this.input = input.children ?? [];
        this.output = output.children ?? [];

        if (this.isSink) {
            this.inputArgsControl = this.ufb.control(
                { value: this.input },
            );
            this.outputArgsControl = this.ufb.control(this.output);
        } else {
            this.inputArgsControl = this.ufb.control(
                { value: this.input, disabled: this.isWebhook },
                this.requiredField,
            );
            this.outputArgsControl = this.ufb.control(this.output, this.requiredField);
        }

        this.subscription.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => this.save(saveOption)));
        this.buildHeaderConfig();
    }

    private buildHeaderConfig() {
        if (!this.integrationFeature || !this.integration) {
            return;
        }

        this.builderHeaderService.buildConfig({
            title: this.integrationFeature.name,
            edited: this.isNew,
            cancelRoute: ['../'],
            lastModifiedAt: this.integrationFeature.lastModifiedAt,
            lastModifiedBy: this.integrationFeature.lastModifiedBy,
            hideSaveButton: this.form.disabled,
            saveOptions: this.form.disabled ? [] : [SaveAndClose],
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [this.integration.name, 'Features', this.integrationFeature.name]),
        });
    }

    private async save(saveOption?: SaveOption) {
        this.form.setSubmitted();

        if (this.checkArgsValidity() || this.form.invalid || !this.integration.id) {
            return;
        }

        const integrationFeature = this.form.getRawValue() as IntegrationFeature;

        if (this.inputArgsControl.enabled) {
            const rootInput = Object.assign({}, this.emptyInput);

            if (this.input?.length) {
                rootInput.attributes = this.buildObjectArg(this.input);
            }

            integrationFeature.input = rootInput;
        }

        if (this.outputArgsControl.enabled) {
            const rootOutput = Object.assign({}, this.emptyInput);

            if (this.output?.length) {
                rootOutput.attributes = this.buildObjectArg(this.output);
            }

            integrationFeature.output = rootOutput;
        }

        if (!this.integration.integrationFeatures) {
            this.integration.integrationFeatures = [];
        }

        if (this.isNew) {
            await this.ucIntegrations.updateFeature(integrationFeature, this.integration.id);

            this.integration.integrationFeatures.push(integrationFeature);
        } else {
            const featureIndex = (this.integration.integrationFeatures ?? []).findIndex((feature) => this.integrationFeature.id === feature.id);

            if (featureIndex < 0) {
                this.toastService.error('Error saving feature');

                return;
            }

            await this.ucIntegrations.updateFeature(integrationFeature, this.integration.id);

            this.integration.integrationFeatures[featureIndex] = integrationFeature;
        }

        this.toastService.success('Feature saved successfully');

        this.edited = false;

        this.manager.integrationFeatures = this.integration.integrationFeatures;
        if (this.isNew) {
            this.manager.reload.next();
        } else {
            this.manager.updateItem.next({ item: integrationFeature, trackBy: 'id' });
        }

        if (saveOption?.id === SaveOptionType.Close) {
            this.router.navigate(['..'], { relativeTo: this.route });

            return;
        }

        this.isNew = false;
        this.integrationFeature = integrationFeature;
        this.buildHeaderConfig();
    }

    private checkArgsValidity(): boolean {
        this.inputArgsControl.setSubmitted();
        this.outputArgsControl.setSubmitted();

        return this.inputArgsControl.invalid || this.outputArgsControl.invalid;
    }

    private buildForm(integrationFeature: IntegrationFeature): UfControlGroup {

        const endPointPathTypes = [IntegrationProviderFeatureType.FeatureTypeDataTransaction, IntegrationProviderFeatureType.FeatureTypeLookup, IntegrationProviderFeatureType.FeatureTypeSink];
        const secondaryEndpointPathTypes = [IntegrationProviderFeatureType.FeatureTypeLookup];
        const idExpTypes = [IntegrationProviderFeatureType.FeatureTypeLookup, IntegrationProviderFeatureType.FeatureTypeWebhook];
        const displayExpTypes = [IntegrationProviderFeatureType.FeatureTypeLookup];
        const previewEndpointPathTypes = [IntegrationProviderFeatureType.FeatureTypeDataTransaction, IntegrationProviderFeatureType.FeatureTypeSink];
        const correlationExpTypes = [IntegrationProviderFeatureType.FeatureTypeWebhook];

        this.endpointPathControl = this.ufb.control(
            { value: integrationFeature.endpointPath, disabled: !endPointPathTypes.includes(integrationFeature.type) },
            this.requiredField,
        );
        this.secondaryEndpointPathControl = this.ufb.control(
            { value: integrationFeature.secondaryEndpointPath, disabled: !secondaryEndpointPathTypes.includes(integrationFeature.type) },
            this.requiredField,
        );
        this.idExpControl = this.ufb.control(
            { value: integrationFeature.idExp, disabled: !idExpTypes.includes(integrationFeature.type) },
            this.requiredField,
        );
        this.displayExpControl = this.ufb.control(
            { value: integrationFeature.displayExp, disabled: !displayExpTypes.includes(integrationFeature.type) },
            this.requiredField,
        );
        this.previewEndpointPathControl = this.ufb.control({
            value: integrationFeature.previewEndpointPath,
            disabled: !previewEndpointPathTypes.includes(integrationFeature.type),
        });
        this.correlationExpControl = this.ufb.control(
            { value: integrationFeature.correlationExp, disabled: !correlationExpTypes.includes(integrationFeature.type) },
            this.requiredField,
        );

        return this.ufb.group({
            [IntegrationFeatureControlKeys.Id]: [{ value: integrationFeature.id, disabled: true }],
            [IntegrationFeatureControlKeys.TypeKey]: [{ value: integrationFeature.type, disabled: true }],
            [IntegrationFeatureControlKeys.Name]: [integrationFeature.name, this.requiredField],
            [IntegrationFeatureControlKeys.EndpointPath]: this.endpointPathControl,
            [IntegrationFeatureControlKeys.SecondaryEndpointPath]: this.secondaryEndpointPathControl,
            [IntegrationFeatureControlKeys.IdExp]: this.idExpControl,
            [IntegrationFeatureControlKeys.DisplayExp]: this.displayExpControl,
            [IntegrationFeatureControlKeys.PreviewEndpointPath]: this.previewEndpointPathControl,
            [IntegrationFeatureControlKeys.CorrelationExp]: this.correlationExpControl,
        });
    }

    private buildArgModel(arg: IntegrationFeatureArg, identifier: string): IntegrationFeatureArgData {
        let children;

        if (arg.kind === IntegrationFeatureArgType.Object && !!arg.attributes) {
            children = this.buildArgModelObjectChildren(arg);
        }

        let listArg;
        let listCount = 0;

        if (arg.kind === IntegrationFeatureArgType.List) {
            listCount = 1;
            listArg = arg.valueType;

            while (listArg && listArg.kind === IntegrationFeatureArgType.List) {
                listArg = listArg.valueType;
                listCount++;
            }

            if (listArg?.kind === IntegrationFeatureArgType.Object) {
                children = this.buildArgModelObjectChildren(listArg);
            }
        }

        return {
            ...arg,
            identifier,
            children,
            listCount,
            kind: listArg?.kind ?? arg.kind,
            kindDescription: this.buildKindDescription(listArg?.kind ?? arg.kind, listCount),
            icon: this.getArgIcon(listArg ?? arg),
        };
    }

    private buildArgModelObjectChildren(arg: IntegrationFeatureArg) {
        return Object.keys(arg.attributes ?? {}).map((key) => {
            return this.buildArgModel((arg.attributes ?? {} as any)[key], key);
        });
    }

    private buildKindDescription(kind: IntegrationFeatureArgType, listCount: number | undefined) {
        if (!listCount) {
            return kind;
        }

        return `${Array.from({ length: listCount }, () => 'List').join(' of ')} of ${kind}`;
    }

    private rebuildList(isInput: boolean) {

        this.inputArgsControl.setValue(this.input);
        this.inputArgsControl.setSubmitted();
        this.outputArgsControl.setValue(this.output);
        this.outputArgsControl.setSubmitted();

        if (isInput) {
            const v = this.input;

            this.input = undefined;
            window.setTimeout(() => {
                this.input = v;
                this.inputTable?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }, 0);
        } else {
            const v = this.output;

            this.output = undefined;
            window.setTimeout(() => {
                this.output = v;
                this.outputTable?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }, 0);
        }
        this.edited = true;
    }

    private buildArg(arg: IntegrationFeatureArgData): IntegrationFeatureArg {
        let attributes: Dictionary<IntegrationFeatureArg> | undefined;
        let valueType: IntegrationFeatureArg | undefined;

        if (arg.listCount != null && arg.listCount > 0) {
            arg.listCount--;
            valueType = this.buildArg(arg);
            arg.kind = IntegrationFeatureArgType.List;
        }

        if (arg.kind === IntegrationFeatureArgType.Object && arg.children?.length) {
            attributes = this.buildObjectArg(arg.children);
        }

        const response: IntegrationFeatureArg = {
            kind: arg.kind,
        };

        if (valueType) {
            response.valueType = valueType;
        }

        if (attributes) {
            response.attributes = attributes;
        }

        return response;
    }

    private buildObjectArg(args: IntegrationFeatureArgData[]) {
        return args.reduce((acc, children) => ({
            ...acc,
            [children.identifier ?? '']: this.buildArg(children),
        }), {});
    }

    private removeArg(args: IntegrationFeatureArgData[], argToBeRemoved: IntegrationFeatureArgData) {
        if (args.find((inputArg) => inputArg === argToBeRemoved)) {
            args = [...args.filter((inputArg) => inputArg !== argToBeRemoved)];

            return args;
        }

        for (const arg of this.argIterator(args)) {
            if (arg.children?.find((argChildren) => argChildren === argToBeRemoved)) {
                arg.children = arg.children.filter((argChildren) => argChildren !== argToBeRemoved);

                break;
            }
        }

        return args;
    }

    private *argIterator(args: IntegrationFeatureArgData[]): Iterable<IntegrationFeatureArgData> {

        for (const arg of args) {

            if (arg.children) {

                yield *this.argIterator(arg.children);
            }

            yield arg;
        }
    }

    private getArgIcon(arg?: IntegrationFeatureArgData | IntegrationFeatureArg): string | undefined {
        if (!arg?.kind) {
            return;
        }

        const listCount = (arg as IntegrationFeatureArgData)?.listCount ?? 0;

        if (listCount > 0) {
            return FieldTypeIcon.get(FieldType.Repeat);
        }

        switch (arg.kind) {
            case IntegrationFeatureArgType.Bool:
                return FieldTypeIcon.get(FieldType.Bool);
            case IntegrationFeatureArgType.Date:
                return FieldTypeIcon.get(FieldType.Date);
            case IntegrationFeatureArgType.DateTime:
                return FieldTypeIcon.get(FieldType.DateTime);
            case IntegrationFeatureArgType.Duration:
            case IntegrationFeatureArgType.Time:
                return FieldTypeIcon.get(FieldType.Time);
            case IntegrationFeatureArgType.Number:
                return FieldTypeIcon.get(FieldType.Number);
            case IntegrationFeatureArgType.Object:
                return ObjectIcon;
            case IntegrationFeatureArgType.OffsetDateTime:
                return FieldTypeIcon.get(FieldType.ZonedDateTime);
            case IntegrationFeatureArgType.String:
                return FieldTypeIcon.get(FieldType.Text);
            default:
                return;
        }
    }

}
