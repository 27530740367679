import { Injectable, inject } from '@angular/core';
import { ClientGetOptions, DefinitionPublishState, ProjectContentOptions, ProjectContentOptionsInterface, amendOptionsParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { SchemaInfo, SchemaSetting, SchemaSettingsUpdateDTO, UcSchema } from './content-models';
import { UcClient } from './uc-client';

@Injectable({ providedIn: 'root' })
export class UcFormBucketClient {

    private client = inject(UcClient);
    private options = inject(ProjectContentOptions) as ProjectContentOptionsInterface;

    /** q, sort, offset, limit*/
    list(options?: ClientGetOptions): Promise<SchemaInfo[]> {
        return this.client.get(this.url('form-buckets'), amendOptionsParams(DefaultPaginationParams, options));
    }

    get(id: string, options?: ClientGetOptions): Promise<UcSchema> {
        return this.client.get(this.url('form-buckets', id), options);
    }

    getSettings(id: string, options?: ClientGetOptions): Promise<SchemaSetting> {
        return this.client.get(this.url('form-buckets', id, 'settings'), options);
    }

    updateSettings(id: string, schemaSetting: SchemaSettingsUpdateDTO, options?: ClientGetOptions): Promise<SchemaSetting> {
        return this.client.put(this.url('form-buckets', id, 'settings'), schemaSetting, options);
    }

    updateSettingsPublishState(ids: string | string[], publishState: DefinitionPublishState, options?: ClientGetOptions): Promise<void> {

        const buckets: {id: string; publishState: DefinitionPublishState}[] = [];

        if (Array.isArray(ids)) {
            buckets.push(...ids.map((id) => ({ id, publishState })));
        } else {
            buckets.push({ id: ids, publishState });
        }

        return this.client.patch(this.url('form-buckets'), buckets, options);
    }

    private url(...extra: string[]): string {
        if (!this.options.projectId) {
            throw new Error('Project id not provided');
        }

        const urlParts = ['projects', this.options.projectId].concat(extra);

        return this.client.buildUrl(urlParts);
    }

}
