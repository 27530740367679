import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'uc-icons-library',
    template: '',
    // templateUrl: '../../../../node_modules/@unifii/library/src/common/components/icons/icons.html'
})
export class IconsLibraryComponent {

    @HostBinding('style.display') hostDisplay = 'none';

}
