import { Component, ElementRef, HostBinding, Injectable, OnDestroy, OnInit, RendererFactory2, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppContext, Breadcrumb, ContextProvider, DateAndTimeFormat, ModalService, RuntimeDefinition, ThemeProvider, ThemeService, WindowWrapper, fieldIterator } from '@unifii/library/common';
import { FormSettings } from '@unifii/library/smart-forms';
import { EmpFormUploader, InputFormSettings, UfFormComponent } from '@unifii/library/smart-forms/input';
import { FieldType, FormData, Option, UfError, ensureError, hasLengthAtLeast, isUfError } from '@unifii/sdk';
import { format, parseISO } from 'date-fns';
import { Subscription } from 'rxjs';

import { UcClient } from 'client';
import { FormBucketService } from 'pages/form-data/bucket-service';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

import { FormDataAndRuntimeDefinitionAndPdfUrl, FormDataComponentData, formDataComponentResolver } from './form-data-component-resolver';
import { MetadataModalComponent } from './metadata-modal.component';

const createThemeProvider = (context: ContextService) => ({
    theme: {
        formStyle: context.project?.theme?.formStyle,
    },
});

@Injectable()
export class FormDataContextProvider implements ContextProvider {

    private context: AppContext;

    constructor(route: ActivatedRoute) {
        this.context = { user: (route.snapshot.data.resolverData as FormDataComponentData).user };
    }

    get() {
        return this.context;
    }

}

@Component({
    selector: 'uc-form-data',
    templateUrl: './form-data.html',
    providers: [
        { provide: FormSettings, useClass: InputFormSettings },
        { provide: ThemeProvider, useFactory: createThemeProvider, deps: [ContextService] },
        { provide: ContextProvider, useClass: FormDataContextProvider },
    ],
})
export class FormDataComponent implements OnInit, OnDestroy {

    @HostBinding('class.stretch-component') class = true;

    @ViewChild(UfFormComponent, { read: ElementRef }) set formComponent(e: ElementRef) {
        if (!e) {
            return;
        }

        const themeService = new ThemeService(e.nativeElement, this.renderFactory);
        const projectTheme = this.context.project?.theme;

        if (projectTheme) {
            themeService.theme = projectTheme;
        }
    }

    protected error?: UfError;
    protected breadcrumbs: Breadcrumb[] = [];
    protected revisionEntries: FormDataAndRuntimeDefinitionAndPdfUrl[] = [];
    protected entry?: FormDataAndRuntimeDefinitionAndPdfUrl;
    protected revisionOptions: Option[] = [];
    protected selectedOption: Option;
    protected formData: FormData | undefined;
    protected runtimeDefinition: RuntimeDefinition | undefined;

    private subscriptions = new Subscription();

    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private context = inject(ContextService);
    private bucketService = inject(FormBucketService);
    private formSettings = inject(FormSettings);
    private modalService = inject(ModalService);
    private client = inject(UcClient);
    private renderFactory = inject(RendererFactory2);
    private window = inject(WindowWrapper) as Window;
    private breadcrumbService = inject(BreadcrumbService);

    ngOnInit() {

        this.setup();
        if (this.error) {
            return;
        }

        this.subscriptions.add(
            this.route.params.subscribe((params) => this.changeRevision(params.revision)),
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    protected onSelectedRevision(revision: string) {

        this.router.navigate([{ revision }], { relativeTo: this.route });
    }

    protected showMetadata() {
        if (!this.formData || !this.bucketService.dataDescriptor) {
            return;
        }

        this.modalService.openFit(MetadataModalComponent, { formData: this.formData, bucketDataDescriptor: this.bucketService.dataDescriptor });
    }

    protected async openPDF() {
        if (!this.runtimeDefinition || !this.formData || !this.context.project || !this.entry) {
            return;
        }

        const url = this.entry.printPdfUrl;
        const headers = new Headers({ Accept: 'application/pdf' });

        try {
            const data = await this.client.get(url, { headers });
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);

            this.window.open(fileURL);
        } catch (error) {
            this.modalService.openAlert({ title: 'Error loading Pdf', message: ensureError(error).message });
        }

    }

    private getRevisionLabel(data: FormData, index: number): string {
        const initialState = (data._history && hasLengthAtLeast(data._history, 1)) ? data._history[0].state : '';
        const currentState = data._state;
        const action = data._action;
        const lastModifiedAt = data._lastModifiedAt ? format(parseISO(data._lastModifiedAt), DateAndTimeFormat) : '';

        return `${index} - ${initialState} -> ${currentState} (${action}) - ${lastModifiedAt}`;
    }

    private changeRevision(revision?: string) {

        if (!revision) {
            revision = this.revisionEntries[this.revisionEntries.length - 1]?.formData._rev;
        }

        const entry = this.revisionEntries.find((revisionEntry) => revisionEntry.formData?._rev === revision);

        if (!entry) {
            return;
        }

        this.loadEntry(entry);
    }

    private loadEntry(entry: FormDataAndRuntimeDefinitionAndPdfUrl) {
        this.entry = entry;

        const selectedOption = this.revisionOptions.find((revisionOption) => revisionOption.identifier === entry.formData._rev);

        if (selectedOption) {
            this.selectedOption = selectedOption;
        }

        this.runtimeDefinition = this.enableExpandWhenInactive(this.entry.runtimeDefinition);

        this.formData = JSON.parse(JSON.stringify(this.entry.formData)) as FormData;
    }

    private setup() {
        const resolverData = this.route.snapshot.data.resolverData as Awaited<ReturnType<typeof formDataComponentResolver>>;

        if (isUfError(resolverData)) {
            this.error = resolverData;

            return;
        }

        this.formSettings.uploader = new EmpFormUploader(this.bucketService.formDataClient);
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [this.bucketService.schema.bucket, undefined, this.route.snapshot.params.id]);
        this.revisionEntries = (resolverData as FormDataComponentData).revisionsEntries ?? [];
        this.revisionOptions = this.revisionEntries.map((entry, index) => ({
            name: this.getRevisionLabel(entry.formData, index + 1),
            identifier: entry.formData._rev ?? '',
        }));
    }

    private enableExpandWhenInactive(definition: RuntimeDefinition) {
        for (const { field } of fieldIterator(definition.fields, undefined, {
            canDive: () => false,
            canIterate: (f) => f.type === FieldType.Section,
        })) {
            field.templateConfig = Object.assign({}, field.templateConfig, { expandWhenInactive: true });
        }

        return definition;
    }

}
