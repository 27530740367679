import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ModalService, ToastService } from '@unifii/library/common';
import { Subscription } from 'rxjs';

import { Config, ConsoleOptions } from 'app-config';
import { UcProject } from 'client';
import { BuilderService } from 'components/compound-builder/builder.service';
import { FieldReference, FieldReferenceGroup, FieldReferenceHelper } from 'helpers/helpers';

@Component({
    selector: 'uc-field-reference-picker',
    templateUrl: './field-reference-picker.html',
    styleUrls: ['./field-reference-picker.less'],
})
export class FieldReferencePickerComponent implements OnInit, OnDestroy {

    protected entries: FieldReferenceGroup[];
    protected groups: FieldReferenceGroup[];
    protected subscriptions = new Subscription();

    constructor(
        private builderService: BuilderService,
        private toastService: ToastService,
        private modalService: ModalService,
        @Inject(UcProject) private ucProject: UcProject,
        @Inject(Config) public config: ConsoleOptions,
    ) { }

    ngOnInit() {
        this.subscriptions.add(this.builderService.ready.subscribe(() => {
            this.entries = FieldReferenceHelper.getFieldReferenceGroups(this.builderService.builder.type);
            this.groups = this.entries;
        }));

        if (this.config.flags.customFields) {
            this.subscriptions.add(this.builderService.fieldTemplatesChanged.subscribe(() => {
                if (this.builderService.fieldTemplates.length) {
                    const customReferences: FieldReference[] = this.builderService.fieldTemplates.map((f) => ({
                        customId: f.id,
                        type: f.type,
                        label: f.label ?? '',
                        icon: FieldReferenceHelper.getFieldReference(f, this.builderService.builder.type).icon,
                    }));

                    this.groups = [{ title: 'Custom', entries: customReferences }, ...this.entries];
                } else {
                    this.groups = this.entries;
                }
            }));
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    async deleteCustomField(field: FieldReference) {

        if (field.customId == null) {
            return;
        }

        if (!await this.modalService.openConfirm({
            title: 'Delete Custom Field',
            message: `This deletion can't be undone.`,
            confirmLabel: 'Delete',
            cancelLabel: `Don't Delete`,
        })) {
            return;
        }

        await this.ucProject.deleteFieldTemplate(field.customId);
        this.builderService.fieldTemplatesChange.next(null);
        this.toastService.success(`Custom field ${field.label} deleted`);
    }

}
