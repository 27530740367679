<uf-panel class="container scrollable">
    <div class="uf-container">
        <div class="uf-grid gap-top">
            <div class="uf-form-card col-12" style="min-height: 700px;">
                <uf-panel class="grow">

                    <div class="uf-app-bar">
                        <!-- CC.A Use ControlsKey enum instead of 'literal key' -->
                        <!-- CC.B - Access data value via control -->
                        <h3>User Details - {{form.get(userControlKeys.Name)?.value}}</h3>
                    </div>

                    <!-- Use ReactiveForms APIs to bind the controls into the components - formGroup and formControlName -->
                    <div [formGroup]="form" class="uf-grid pad gaps">
                        <uf-text [formControlName]="userControlKeys.Name" label="Name" class="col-12" />
                        <uf-text [formControlName]="userControlKeys.Surname" label="Surname" class="col-12" />
                    </div>

                    <button (click)="addAddress()" type="button" class="uf-button right gap-right">
                        Add Address
                    </button>

                    <!-- LC.4 - UI reflects the ControlTree, here looping the addresses UfControlArray -->
                    <ng-template [ngForOf]="addresses.controls | asUfControlsGroups" let-address ngFor let-i="index">
                        <div [formGroup]="address" class="uf-box uf-grid col-12">

                            <div class="col-12">
                                <div>Address {{i}}</div>
                                <!-- Access control validity status to reflect it into the UI -->
                                <uf-icon *ngIf="!address.valid" name="warning" />

                                <button (click)="removeAddress(i)" type="button" title="Delete"
                                    class="uf-action tertiary right">
                                    <uf-icon name="delete" />
                                </button>
                            </div>

                            <uf-text [formControlName]="addressControlKeys.Street" class="col-12" label="Street" />
                            <uf-text [formControlName]="addressControlKeys.Suburb" class="col-12" label="Suburb" />
                        </div>
                    </ng-template>

                    <div class="row space-children pad">
                        <button type="button" class="uf-button tertiary right">
                            Cancel
                        </button>
                        <button (click)="save()" type="button" class="uf-button primary">
                            Submit
                        </button>
                    </div>

                </uf-panel>
            </div>
        </div>
    </div>
</uf-panel>