import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { UfControl, UfControlGroup, UfFormControl } from '@unifii/library/common';
import { FieldTemplate, FieldType, Option, isNotNull } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { sortGroupControlValue } from 'pages/utils';
import { ContextService } from 'services/context.service';

import { FORM_EDITOR_CONSTANTS } from '../form-editor-constants';
import { FieldControlKeys } from '../form-editor-control-keys';
import { FormEditorFunctions } from '../form-editor-functions';
import { FormEditorField, FormFieldMetadata } from '../form-editor-model';
import { FormEditorService } from '../form-editor.service';

@Component({
    selector: 'uc-form-field-details',
    templateUrl: './form-field-details.html',
})
export class FormFieldDetailsComponent implements OnInit, OnDestroy {

    @Input({ required: true }) control: UfControlGroup;

    protected readonly fieldKeys = FieldControlKeys;
    protected readonly identifierWarningLength = FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH;
    protected readonly identifierMaxLength = FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_MAX_LENGTH;
    protected ready: boolean;
    protected meta: FormFieldMetadata;
    protected showContent: boolean;
    protected rolesResult: string[] = [];
    protected templateOptions: Option[];
    protected templatePlaceholder: string | null;
    protected showIdentifierWarningLength: boolean;
    protected descriptionControl: UfControl;

    private controls: UfFormControl[];
    private service = inject(FormEditorService);
    private context = inject(ContextService);

    private identifierSub: Subscription | undefined;

    protected get isInvalid(): boolean {
        return this.controls.find((c) => c.invalid) != null;
    }

    private get field(): FormEditorField {
        return this.control.getRawValue() as FormEditorField;
    }

    private get identifierControl(): UfControl {
        return this.control.get(FieldControlKeys.Identifier) as UfControl;
    }

    ngOnInit() {

        this.meta = FormEditorFunctions.controlMetadata(this.control, this.context);
        this.controls = FORM_EDITOR_CONSTANTS.SECTION_DETAILS_FIELDS.map((k) => this.control.get(k) as UfFormControl | null).filter(isNotNull);
        this.showContent = this.meta.help && this.field.type === FieldType.Content;
        this.templateOptions = FormEditorFunctions.fieldTemplateOptions(this.field);
        this.templatePlaceholder = (this.templateOptions.length === 1 && this.templateOptions.some((option) => option.identifier === FieldTemplate.Hidden)) ? 'Default' : null;
        this.descriptionControl = this.control.get(FieldControlKeys.Description) as UfControl;
        this.checkIdentifierMaxLength();
        this.identifierSub = this.identifierControl.valueChanges.subscribe(this.checkIdentifierMaxLength.bind(this));

        sortGroupControlValue(this.control, FieldControlKeys.Roles);

        this.ready = true;
    }

    ngOnDestroy() {
        this.identifierSub?.unsubscribe();
    }

    protected async findRoles(query: string | null) {
        this.rolesResult = await this.service.queryProjectRoles(query ?? undefined);
    }

    protected save() {
        void this.service.saveTemplateField(this.field);
    }

    protected copy() {
        void this.service.copyFields([this.field]);
    }

    protected paste() {
        if (this.meta.isContainer) {
            void this.service.pasteFields(this.control);
        }
    }

    private checkIdentifierMaxLength() {
        this.showIdentifierWarningLength = (this.identifierControl.value ?? '').length > FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH &&
            !this.identifierControl.showError;
    }

}
