<div class="uf-box col-12">
    <div class="uf-app-bar flat accent">
        <div class="title">
            Feature Configuration
        </div>
    </div>
    <div [formGroup]="control" class="uf-grid pad">

        <uf-text [formControlName]="controlKeys.Name" label="Name" class="col-12">
            <span class="suffix">*</span>
        </uf-text>

        <uf-textarea [formControlName]="controlKeys.Description" label="Description" class="col-12" />

        <!-- TODO Restore once the wording has been decided
        <uf-blockquote icon="infoSolid" content="Default values have been configured for you." class="info col-12" /> -->
    </div>

    <uc-data-feature-parameters *ngIf="control.get(controlKeys.InputArgs) as inputArgsControl"
        [inputArgsControl]="inputArgsControl | asUfControlArray" />

    <uc-data-feature-display
        *ngIf="!hideDataSource && control.get(controlKeys.DataSeedOutputArgs) as featureDisplayControl"
        [feature]="feature" [featureDisplayControl]="featureDisplayControl | asUfControlArray" />

    <uc-data-feature-data-mapping *ngIf="control.get(controlKeys.OutputArgs) as outputArgsControl" [feature]="feature"
        [dataMappingControl]="outputArgsControl | asUfControlArray" />

</div>