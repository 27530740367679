import { Component, OnInit, inject } from '@angular/core';
import { Breadcrumb } from '@unifii/library/common';

import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { TabsPage } from 'pages/tabs-page';
import { BreadcrumbService } from 'services/breadcrumb.service';

@Component({
    templateUrl: './settings.html',
})
export class SettingsComponent extends TabsPage implements OnInit {
    
    override get edited(): boolean {
        return !!this.builderHeaderService.config.edited;
    }

    set edited(v: boolean) {
        this.builderHeaderService.config.edited = v;
    }

    breadcrumbs: Breadcrumb[];

    private breadcrumbService = inject(BreadcrumbService);
    private builderHeaderService = inject(BuilderHeaderService);

    override ngOnInit() {
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);
        super.ngOnInit();
        this.buildHeaderConfig();
    }
    
    buildHeaderConfig() {
        this.edited = false;
        this.builderHeaderService.buildConfig({
            hideSaveButton: true,
            breadcrumbs: this.breadcrumbs,
        });
    }

}
