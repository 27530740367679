import { InjectionToken } from '@angular/core';
import { TableContainerManager } from '@unifii/components';
import { FilterEntry, FilterValue } from '@unifii/library/common';
import { ClientGetOptions, DefinitionPublishState, Table } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { CompoundInfo, DefinitionInfo, FormDefinitionInfo, TableInfo, UcCompound, UcDefinition, UcPage, UcView } from 'client';

/** *
 * @description
 * Generic Table Manger interfaces that guarantees reload, updateItem and getNextItem are provided
 */
export interface UcTableManager<T> extends TableContainerManager<T, FilterValue, FilterEntry> {
    reload: Subject<void>;
    updateItem: Subject<T | { item: T; trackBy: keyof T }>;
    getNextItem(id?: string | number): T | undefined;
}

export type Info = CompoundInfo | DefinitionInfo | TableInfo | FormDefinitionInfo;

export enum InfoType {
    View = 'view',
    Page = 'page',
    Collection = 'collection',
    CollectionData = 'collectionData',
    Form = 'form',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Table = 'table'
}

export interface InfoQueryParams {
    state?: DefinitionPublishState;
    q?: string;
    offset?: number;
    limit?: number;
    sort?: string;
    bucket?: string;
    source?: string;
}

export interface InfoLoader {
    type: InfoType;
    list(params: InfoQueryParams, options?: ClientGetOptions): Promise<Info[]>;
    get?(id: number | string): Promise<UcView | UcPage | UcCompound | UcDefinition | Table>;
    approve?(id: number): Promise<Info>;
    revert?(id: number): Promise<Info>;
    archive?(id: number): Promise<Info>;
    delete?(id: number): Promise<void | any>;
}
export const InfoLoader = new InjectionToken<InfoLoader>('InfoLoader');

