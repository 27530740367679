<uf-expander *ngIf="ready" class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Details</div>
        <uc-content-description *ngIf="descriptionControl" [control]="descriptionControl" mode="icon" />
        <ng-template [ngIf]="isInvalid" [ngIfElse]="isValid">
            <uf-icon name="error" class="error" />
        </ng-template>
        <ng-template #isValid>
            <a *ngIf="meta.customFields" (click)="save()" title="Save as Custom Field" class="uf-action tertiary">
                <uf-icon name="save" />
            </a>
        </ng-template>
        <ng-template [ngIf]="control.valid">
            <a (click)="copy()" title="Copy field" class="uf-action tertiary">
                <uf-icon name="copy" />
            </a>
            <a *ngIf="meta.isContainer" (click)="paste()" title="Paste fields" class="uf-action tertiary">
                <uf-icon name="paste" />
            </a>
        </ng-template>

    </div>
    <div [formGroup]="control" expanderBody class="uc-group-content grid">

        <uc-content-description *ngIf="descriptionControl" [control]="descriptionControl" mode="view"
            class="col-1of1" />

        <uf-textarea *ngIf="meta.label" [formControlName]="fieldKeys.Label" label="Label" class="col-1of1" />

        <uf-text *ngIf="meta.shortLabel" [formControlName]="fieldKeys.ShortLabel" label="Short label"
            class="col-1of1" />

        <uf-text *ngIf="meta.identifier" [formControlName]="fieldKeys.Identifier" [maxLength]="identifierMaxLength"
            label="Identifier" class="col-1of1" />
        <uf-message *ngIf="showIdentifierWarningLength" class="col-1of1 x-small warning" icon="warningSolid">
            <p>Identifier is too long</p>
        </uf-message>

        <uc-markdown *ngIf="showContent" [formControlName]="fieldKeys.Help" [showAlignmentOptions]="true"
            label="Content" class="col-1of1" />

        <uf-select *ngIf="meta.template" [formControlName]="fieldKeys.Template" [options]="templateOptions"
            [placeholder]="templatePlaceholder" valueProperty="identifier" nameProperty="name" label="Template"
            class="col-1of1" />

        <div class="col-1of1 col">
            <uf-checkbox *ngIf="meta.alwaysExpanded" [formControlName]="fieldKeys.AlwaysExpanded"
                label="Always Expanded" />

            <uf-checkbox *ngIf="meta.expandWhenInactive" [formControlName]="fieldKeys.ExpandWhenInactive"
                label="Expand in Inactive Sections" />

            <uf-checkbox *ngIf="meta.hideWhenInactive" [formControlName]="fieldKeys.HideWhenInactive"
                label="Hide in Inactive Sections" />

            <uf-checkbox *ngIf="meta.activeBackgroundTinted" [formControlName]="fieldKeys.ActiveBackgroundTinted"
                label="Background Tinted in Active Sections" />
        </div>

        <uf-chips *ngIf="meta.role" [options]="rolesResult" [formControlName]="fieldKeys.Roles"
            (searchChange)="findRoles($event)" label="Roles" class="col-1of1" />

    </div>
</uf-expander>