import { Component, HostBinding, Inject, OnDestroy } from '@angular/core';
import { DataDisplayListItem, Modal, ModalData, ModalRuntime, UfControlGroup } from '@unifii/library/common';
import { Subscription } from 'rxjs';

import { DataSourceInputValueSource } from 'client';

import { DataSourceExternalInfo, DataSourceExternalInputControlKey } from './data-source-model';

@Component({
    selector: 'uc-data-source-external-input',
    templateUrl: './data-source-external-input-editor.html',
})
export class DataSourceExternalInputEditorComponent implements Modal<UfControlGroup, UfControlGroup>, OnDestroy {

    @HostBinding('class.uf-form-card') cardClass = true;

    guard: boolean;

    protected readonly externalInputKeys = DataSourceExternalInputControlKey;
    protected info: DataSourceExternalInfo;
    protected label: string;
    protected infoDescriptors: DataDisplayListItem[];
    protected canEditValue: boolean;

    private subscription = new Subscription();

    constructor(
        public runtime: ModalRuntime<UfControlGroup, UfControlGroup>,
        @Inject(ModalData) public control: UfControlGroup,
    ) {
        this.info = control.get(DataSourceExternalInputControlKey.Info)?.value;
        this.label = `${this.info.required ? '* ' : ''}Value or Expression`;

        this.infoDescriptors = [
            { term: 'Parameter', data: this.info.parameter },
            { term: 'Source', data: this.info.source },
            { term: 'Type', data: this.info.type },
            // TODO Required field is gonna be added later on next releases
            // { term: 'Required', data: this.info.required ? 'Yes' : 'No' },
        ];

        this.canEditValue = [DataSourceInputValueSource.Form, DataSourceInputValueSource.Default].includes(this.info.source);
        this.subscription.add(control.get(DataSourceExternalInputControlKey.Value)?.valueChanges.subscribe(() => { this.guard = true; } ));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    close() {
        this.runtime.close();
    }

    submit() {
        this.control.setSubmitted();
        if (this.control.invalid) {
            return;
        }

        this.runtime.close(this.control);
    }

}
