<div class="header">
    <button (click)="runtime.close()" type="button" class="uf-action tertiary" title="Close">
        <uf-icon name="close" />
    </button>
    <h3>{{data.isNewRole ? "New" : "Edit"}} Role </h3>
</div>

<uf-panel class="container">

    <div [formGroup]="form" class="uf-grid pad-sides">
        <uf-text [formControlName]="controlKeys.Name" [disabled]="!data.isNewRole" label="Name" class="col-12" />
        <uf-text [formControlName]="controlKeys.Label" label="Label" class="col-12" />
        <uf-textarea [formControlName]="controlKeys.Description" label="Description" class="col-12" />
    </div>

</uf-panel>

<div class="row space-children pad">
    <button (click)="runtime.close()" type="button" class="uf-button tertiary right">Cancel</button>
    <button (click)="save()" type="button" class="uf-button primary">Save</button>
</div>