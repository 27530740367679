import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'uc-icons-console',
    templateUrl: '../../components/common/icons.html',
})
export class IconsConsoleComponent {

    @HostBinding('style.display') hostDisplay = 'none';

}
