import { UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { DefinitionPublishState, StructureNode, StructureNodeType, objectKeys } from '@unifii/sdk';

import { UcStructure } from 'client';
import { ArrayHelper } from 'helpers/array-helper';

import { StructureNodeControlKeys } from './structure-control-keys';

/** Find all nodes of a specific type in the structure */
const getAllNodesOfType = (structure: UcStructure, type: StructureNodeType): StructureNode[] =>
    (ArrayHelper.flatTree(structure) as StructureNode[]).filter((n) => n.type === type);

/** Find the max nodeId in the structure and return the next one free to be used */
const getNextAvailableNodeId = (structure: UcStructure): number => {

    let lastUsed: number;

    if (structure.lastNodeId) {
        lastUsed = parseInt(structure.lastNodeId);
    } else {
        let ids = ArrayHelper.flatTree(structure)
            .filter((n) => n.nodeId)
            .map((n) => parseInt(n.nodeId));

        if (ids.length === 0) {
            ids = [0];
        }
        lastUsed = Math.max.apply(null, ids);
    }

    return lastUsed + 1;
};

/** Fix inconsistencies and errors */
const cleanUp = (structure: UcStructure): void => {

    // All nodes
    const nodes = ArrayHelper.flatTree(structure) as StructureNode[];

    // Apply nodeIds where missing and update lastNodeId
    structure.nodeId = '0'; // Default
    let next = getNextAvailableNodeId(structure);

    nodes.forEach((node) => {
        if (!node.nodeId) {
            node.nodeId = next.toString();
            next++;
        }
    });
    structure.lastNodeId = (next - 1).toString();

    // Fix node general attributes
    delete (structure as any).recordName;
    nodes.forEach((node) => {
        node.roles = node.roles ?? [];
        delete (node as any).recordName;
    });

    nodes.forEach((node) => {
        // Only Dashboards have buckets
        if (node.type !== StructureNodeType.Dashboard) {
            delete node.bucketOptions;
        }
    });
};

const isNodeRoot = (node: StructureNode): boolean =>
    node.nodeId == null || node.nodeId === '0';

const isNodeLinkedToContent = (node: StructureNode): boolean =>
    [
        StructureNodeType.Page,
        StructureNodeType.View,
        StructureNodeType.PdfViewer,
        StructureNodeType.Form,
        StructureNodeType.Collection,
        StructureNodeType.CollectionItem,
        StructureNodeType.FormBucket,
    ].includes(node.type);

/** The node content is published or doesn't need to be published */
const isNodeContentPublished = (node: StructureNode): boolean =>
    !isNodeLinkedToContent(node) || node.publishState === DefinitionPublishState.Published || node.type === StructureNodeType.PdfViewer;

const hasVariations = (node: StructureNode): boolean =>
    isNodeRoot(node) && !!(node as UcStructure).variations?.length;

const isNodeAccessRestricted = (node: StructureNode): boolean => !!node.roles?.length;

const cleanNodeAttributes = (structure: UcStructure) => {
    const nodes: StructureNode[] = ArrayHelper.flatTree(structure);

    for (const node of nodes) {
        objectKeys(node).forEach((k) => {
            const value = node[k];

            if ((Array.isArray(value) && value.length === 0) || ValidatorFunctions.isEmpty(value)) {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete node[k];
            }
        });
    }
};

const isANodeControl = (control: UfControlGroup): boolean =>
    control.controls[StructureNodeControlKeys.NodeId] != null &&
    control.controls[StructureNodeControlKeys.NodeId].value !== '0' &&
    control.controls[StructureNodeControlKeys.Type] != null;

export const StructureFunctions = {
    getAllNodesOfType,
    getNextAvailableNodeId,
    isNodeRoot,
    isNodeLinkedToContent,
    isNodeContentPublished,
    isNodeAccessRestricted,
    isANodeControl,
    hasVariations,
    cleanNodeAttributes,
    cleanUp,
};

