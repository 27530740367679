import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, FilterEntry, FilterValue, ToastService, UfControl, UfControlGroup, UfFormBuilder } from '@unifii/library/common';
import { AuthProviderConfiguration, ErrorType, PermissionAction, UfError, UserCreate, UserInfo, UserReference, UserStatus, ensureUfError, getUserStatus } from '@unifii/sdk';
import { LockedConfig, UserCreateFormControl, UserFormContext, UserFormResourceType, UserKeys, UserUpdateFormControl, UserUpdateMeFormControl } from '@unifii/user-provisioning';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SystemRole, UcClient, UcUserAuthProvider, UcUserInfo, UcUsers } from 'client';
import { EditData, SaveAndClose, SaveOption, SaveOptionType } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { UserComponent } from 'pages/users/user.component';
import { UsersComponent } from 'pages/users/users.component';
import { sortGroupControlValue } from 'pages/utils';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { DialogsService } from 'services/dialogs.service';

import { IsSuperUserControl, buildUserDataForm } from './user-utils';

type FormType = 'UserCreate' | 'UserUpdate' | 'MeUpdate';

@Component({
    selector: 'uc-user-details-page',
    templateUrl: './user-details-page.html',
    styleUrls: ['./../../styles/external-branding.less'],
})
export class UserDetailsPageComponent implements EditData, OnInit, OnDestroy {

    @ViewChild('headerButtons', { static: true }) headerButtonsTemplate: TemplateRef<Element>;

    protected readonly userStatus = UserStatus;

    protected userInfo: UcUserInfo;
    protected form: UfControlGroup | null;
    protected superUserControl: UfControl;
    protected userAuthProviders?: UcUserAuthProvider[];

    // Page status & info
    protected tenant = inject(ContextService).tenantSettings;
    protected error?: UfError;
    protected lockedConfig?: LockedConfig;
    protected status: UserStatus | null;
    protected loading = true; // this is important for refreshing binding of [formGroup] when we create new instance of form after save
    protected breadcrumbs: Breadcrumb[];
    protected inProgress: boolean;
    protected authProviderError: UfError;
    protected connectToExternal: boolean;
    protected saveOptions = [SaveAndClose];
    protected isNew: boolean;
    protected isMe: boolean;
    protected amIUserManager: boolean;
    protected amISuperUser: boolean;

    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private cdr = inject(ChangeDetectorRef);
    private ufb = inject(UfFormBuilder);
    private ucUsers = inject(UcUsers);
    private dialogs = inject(DialogsService);
    private toast = inject(ToastService);
    private context = inject(ContextService);
    private ucClient = inject(UcClient);
    private userFormContext = inject(UserFormContext);
    private breadcrumbService = inject(BreadcrumbService);
    private builderHeaderService = inject(BuilderHeaderService);
    private usersComponent = inject(UsersComponent, { optional: true });
    private parent = inject(UserComponent);
    private tableManager = inject<TableContainerManager<UcUserInfo, FilterValue, FilterEntry>>(TableContainerManager, { optional: true });
    private formType: FormType = this.parent.isNew ? 'UserCreate' : this.parent.isMyAccount ? 'MeUpdate' : 'UserUpdate';
    private userCreateFormCtrl = inject(UserCreateFormControl);
    private userUpdateFormCtrl = inject(UserUpdateFormControl);
    private userUpdateMeFormCtrl = inject(UserUpdateMeFormControl);
    private subscriptions = new Subscription();

    ngOnInit() {
        this.isNew = this.parent.isNew;
        this.isMe = this.parent.isMyAccount;
        this.amIUserManager = this.context.checkRoles(SystemRole.UserManager);
        this.amISuperUser = this.context.checkRoles(SystemRole.SuperUser);
        this.userFormContext.set(
            this.isMe ? UserFormResourceType.Me : UserFormResourceType.User,
            this.isNew ? PermissionAction.Add : PermissionAction.Update,
        );

        if (this.isNew) {
            this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, ['New']);
        }

        void this.init();
    }

    get edited(): boolean {
        return !!this.builderHeaderService.config.edited;
    }

    set edited(v: boolean) {
        this.builderHeaderService.config.edited = v;
    }

    get user(): UcUserInfo {
        return this.form?.getRawValue() as UcUserInfo;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.edited = false;
        this.parent.buildHeaderConfig();
    }

    protected async save(saveOption?: SaveOption) {

        if (!this.form) {
            return;
        }

        this.form.setSubmitted();

        if (this.form.invalid) {
            this.toast.error('There are errors in this form');

            return;
        }

        let user: UserCreate | UserInfo;

        switch (this.formType) {
            case 'UserCreate':
                user = this.userCreateFormCtrl.toDataModel(this.form);
                break;
            case 'UserUpdate':
                user = this.userUpdateFormCtrl.toDataModel(this.form, this.userInfo);
                break;
            case 'MeUpdate':
                user = this.userUpdateMeFormCtrl.toDataModel(this.form, this.userInfo);
                break;
        }

        // Super user value
        if (this.superUserControl.value) {
            user.systemRoles?.push(SystemRole.SuperUser);
        }

        try {
            this.inProgress = true;

            this.userInfo = await this.ucUsers.save(user as UserInfo);

            if (this.isMe) {
                this.context.account = await this.ucClient.getMyAccount();
            }

            this.toast.success('User details saved!');

            if (this.usersComponent) {
                this.refreshUsersList(this.userInfo);
            }

            this.edited = false;

            if (saveOption?.id === SaveOptionType.Close) {
                this.back();
            } else {
                if (this.isNew) {
                    const commands = ['../', this.userInfo.id];

                    void this.router.navigate(commands, { relativeTo: this.route });

                    return;
                }

                this.parent.user = this.userInfo;
                this.form = null;
                this.cdr.detectChanges();
                void this.init();
            }
        } catch (e) {

            const error = ensureUfError(e, 'Failed to save');

            const message = error.type === ErrorType.Validation && error.message === 'Invalid password' ?
                'Incorrect current password' :
                error.message;

            console.error(error);
            this.toast.error(message);
        } finally {
            this.inProgress = false;
        }
    }

    protected async delete() {

        if (!this.user.id || !await this.dialogs.confirmDelete()) {
            return;
        }

        try {
            await this.ucUsers.delete(this.user.id);
            this.toast.success('Delete succeed');
            this.tableManager?.reload?.next();
            this.back();
        } catch (error) {
            this.toast.error('Delete failed');
        }
    }

    protected back() {
        void this.router.navigate(['../'], { relativeTo: this.route });
    }

    private async initUser(user: UcUserInfo) {
        this.status = getUserStatus(user);

        const { lockedConfig, userAuthProviders } = await buildUserDataForm(this.ucUsers, user);

         this.lockedConfig = lockedConfig;
         this.userAuthProviders = userAuthProviders;
    }

    private async initPage() {
        const providerToLink = this.route.snapshot.params.id as string | undefined;

        if (providerToLink != null) {
            await this.linkExternalProvider(providerToLink);
        }
    }

    private initForm(user: UcUserInfo) {

        let form: UfControlGroup;

        switch (this.formType) {
            case 'UserCreate':
                form = this.userCreateFormCtrl.buildRoot(true, this.lockedConfig);
                break;
            case 'UserUpdate':
                form = this.userUpdateFormCtrl.buildRoot({ user, lockedConfig: this.lockedConfig, userAuthProvidersInfo: this.userAuthProviders });
                break;
            case 'MeUpdate':
                form = this.userUpdateMeFormCtrl.buildRoot({ user, lockedConfig: this.lockedConfig, userAuthProvidersInfo: this.userAuthProviders });
                break;
        }

        const systemRolesControl = form.get(UserKeys.SystemRoles) as UfControl | undefined;

        if (!this.amIUserManager) {
            form.get(UserKeys.Username)?.disable();
            form.get(UserKeys.Email)?.disable();
            form.get(UserKeys.FirstName)?.disable();
            form.get(UserKeys.LastName)?.disable();
            form.get(UserKeys.Phone)?.disable();
            form.get(UserKeys.Manager)?.disable();
            form.get(UserKeys.Company)?.disable();
            form.get(UserKeys.Claims)?.disable();
            form.get(UserKeys.Roles)?.disable();
            form.removeControl(UserKeys.Password);
            form.removeControl(UserKeys.OldPassword);
            form.removeControl(UserKeys.ChangePasswordOnNextLogin);
            form.removeControl(UserKeys.LastActivationReason);
        }

        // system roles logic
        if (this.amIUserManager && !this.isSuperUser(user)) {
            systemRolesControl?.enable();
        } else {
            systemRolesControl?.disable();
        }

        if (this.isSuperUser(user)) {
            systemRolesControl?.setValue(systemRolesControl.value.filter((r: SystemRole) => r !== SystemRole.SuperUser));
        }

        this.superUserControl = this.ufb.control({
            value: this.isSuperUser(user),
            disabled: !this.amISuperUser,
        });

        form.addControl(IsSuperUserControl, this.superUserControl);

        this.subscriptions.add(form.statusChanges.pipe(filter(() => !form.pristine)).subscribe(() => {
            this.edited = true;
        }));

        if (this.userInfo.lastLoginAt) {
            // there's no enum key because it's only used once, and there's no enum for UcUserInfo.
            form.addControl('lastLoginAt', this.ufb.control({ value: this.userInfo.lastLoginAt, disabled: true }));
        }

        this.form = form;

        sortGroupControlValue(this.form, UserKeys.Roles);
    }

    private async linkExternalProvider(id: string): Promise<void> {
        // check if any providers need to be linked
        const authProvider = this.tenant?.authProviders?.find((provider) => provider.id.toString() === id);
        const code = this.route.snapshot.queryParams.code;

        if (authProvider && code) {
            try {
                await this.linkUserToProvider(authProvider, code);
                await this.parent.reload();
                await this.init();
            } catch (e) {
                this.authProviderError = ensureUfError(e);
            }
        }
    }

    private refreshUsersList(user: UcUserInfo) {
        if (this.isNew) {
            this.tableManager?.reload?.next();

            return;
        }
        this.tableManager?.updateItem?.next(user);
    }

    private linkUserToProvider(provider: AuthProviderConfiguration, code: string): Promise<any> {
        return this.ucClient.post(this.ucClient.buildUrl(['my-account', 'logins']), {
            params: {
                auhProvider: provider.type,
                tenant: provider.tenant,
                code,
            },
        });
    }

    private isSuperUser(user: UcUserInfo) {
        return user.systemRoles?.includes(SystemRole.SuperUser);
    }

    private async init() {
        this.subscriptions.unsubscribe();
        this.subscriptions = new Subscription();
        this.loading = true;
        this.userInfo = this.isNew ? {} as UserInfo : this.parent.user;
        await this.initUser(this.userInfo);
        this.initForm(this.userInfo);
        await this.initPage();

        this.builderHeaderConfig();
        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => { void this.save(saveOption); }));

        this.parent.templateRef = this.headerButtonsTemplate;

        this.loading = false;
    }

    private builderHeaderConfig() {
        const lastModifiedBy: UserReference | undefined = this.userInfo.lastModifiedBy ? { username: this.userInfo.lastModifiedBy, id: '' } : undefined;

        this.builderHeaderService.buildConfig({
            lastModifiedBy,
            title: this.userInfo.firstName,
            lastModifiedAt: this.userInfo.lastModifiedAt,
            saveOptions: this.saveOptions,
            cancelRoute: ['..'],
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [this.userFullName]),
        });

    }

    private get userFullName() {
        if (this.user.firstName && this.user.lastName) {
            return this.user.firstName + ' ' + this.user.lastName;
        }

        return this.user.username;
    }

}
