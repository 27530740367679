/** @deprecated */
export class ArrayHelper {

    static getChildren(item: any): any[] | null {
        return item ? (item.fields || item.children) : null;
    }

    static findNext(isNext: boolean, curr: any, arr: any[], parent: any = null): any {

        const children = ArrayHelper.getChildren(curr);

        // If has children and down return first child
        if (children && children.length > 0 && isNext) {
            return children[0];
        }

        // Get sibling
        let sibling = this.getSibling(isNext, curr, arr);
        const siblingChildren = ArrayHelper.getChildren(sibling);
        // If moving up and sibling has children
        // select the last child

        if (sibling != null && !isNext && siblingChildren && siblingChildren.length > 0) {

            let lastChild = sibling;
            let lastChildChildren = ArrayHelper.getChildren(lastChild);
            let hasChild = lastChildChildren && lastChildChildren.length > 0;

            while (hasChild) {
                lastChild = this.getLastChild(lastChild);
                lastChildChildren = ArrayHelper.getChildren(lastChild);
                hasChild = lastChildChildren && lastChildChildren.length > 0;
            }

            return lastChild;
        }

        if (sibling != null) {
            return sibling;
        }

        // No Sibling now get parent sibling
        parent = this.getParent(curr, arr, null);

        if (parent && !isNext) {
            return parent;
        }

        // TODO: simplify code below

        // Now try the parents sibling
        sibling = this.getSibling(isNext, parent, arr);

        if (sibling != null) {
            return sibling;
        }

        // sibling is still undefined get parents sibling
        parent = this.getParent(parent, arr, null);
        sibling = this.getSibling(isNext, parent, arr);

        if (sibling != null) {
            return sibling;
        }

        // sibling is still undefined get parents sibling
        parent = this.getParent(parent, arr, null);
        sibling = this.getSibling(isNext, parent, arr);

        if (sibling != null) {
            return sibling;
        }

        return curr;
    }

    static getParent(curr: any, arr: any[], parent?: any): any {

        for (const item of arr) {

            if (curr === item && parent) {
                return parent;
            }

            const children = ArrayHelper.getChildren(item);

            if (children && children.length > 0) {

                const parent2 = this.getParent(curr, children, item);

                if (parent2) {
                    return parent2;
                }
            }
        }

        return null;
    }

    static getSibling(isNext: boolean, curr: any, arr: any[]): any {

        for (let i = 0; i < arr.length; i++) {
            const item = arr[i];

            if (curr === item) {
                return arr[i + (isNext ? 1 : -1)] || undefined;

            }

            const itemChildren = ArrayHelper.getChildren(item);

            if (itemChildren && itemChildren.length > 0) {
                const sibling = this.getSibling(isNext, curr, itemChildren);

                if (sibling) {
                    return sibling;
                }
            }
        }

        return undefined;
    }

    static getLastChild(curr: any): any {

        const currChildren = ArrayHelper.getChildren(curr);

        if (!currChildren || currChildren.length === 0) {
            return undefined;
        }

        return currChildren[currChildren.length - 1];
    }

    static hasDepthOf(curr: any, arr: any[], level: number): number {

        level = level || 1;

        for (const item of arr) {

            if (curr === item) {
                return level;
            }

            if (item.fields?.length) {
                level++;

                return this.hasDepthOf(curr, item.fields, level);
            }
        }

        return 0;
    }

    static getInnerDepth(curr?: any, level?: number | null | undefined): number {

        if (!curr) {
            return 0;
        }

        level = level ?? 1;

        const children = ArrayHelper.getChildren(curr);

        if (!children?.length) {
            return level;
        }

        const depths = children.map((c) => this.getInnerDepth(c, (level as number) + 1));

        return Math.max(...depths);
    }

    static flatTree(input: any): any[] {

        const results: any[] = [];

        const iterate = (current: any, list: any[]) => {
            list.push(current);

            const currentChildren = ArrayHelper.getChildren(current) ?? [];

            currentChildren.forEach((c: any) => { iterate(c, list); });
        };

        iterate(input, results);

        return results;
    }

    /** Filter items matching the query */
    static filterList(list: string[], query?: string) {
        if (!list || !query || query === '') {
            return list ? [...list] : [];
        }

        return list.filter((item) => item.toLowerCase().includes(query.toLowerCase()));
    }

}
