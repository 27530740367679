<uf-panel *ngIf="!error" class="container">

    <div class="header-pane uf-app-bar transparent">
        <uc-builder-header />
    </div>

    <div class="content-pane scrollable">
        <uf-tabs>
            <uf-tab label="Integration" path="" class="scrollable" />
            <uf-tab *ngIf="showFeaturesTab" label="Features" path="features" class="scrollable" />
        </uf-tabs>
    </div>

</uf-panel>

<uc-error-message [error]="error" />