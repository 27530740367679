<uf-expander *ngIf="ready" class="uf-box flat gap-sm-bottom">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Tables</div>
        <uf-icon *ngIf="control.invalid" name="error" class="error" />
        <uc-expander-controls *ngIf="control.length" [container]="tablesWrap" />
    </div>

    <div #tablesWrap expanderBody class="pad small">
        <uf-search-result *ngIf="!control.disabled" [options]="searchResults" [nameProperty]="mapDescription"
            (valueChange)="add($event)" (searchChange)="search($event)" label="Tables" placeholder="Search Tables"
            class="col-12 pad-bottom" />

        <uc-drag-list *ngIf="control.length" [items]="control.controls" canDrop="false" canReorder="true">
            <ng-template [ngForOf]="control.controls | asUfControlsGroups" ngFor let-table let-i="index">
                <uf-expander [dragDisabled]="table.disabled" dragItem class="uf-box flat draggable gap-sm-bottom">
                    <div expanderHeader dragHandle class="uf-app-bar flat">
                        <div class="title primary">
                            {{(tableDescriptions[getIdentifier(table) ?? ''])?.title}}
                        </div>
                        <uf-icon *ngIf="table.invalid" name="error" class="error" />
                        <a *ngIf="(tableDescriptions[getIdentifier(table) ?? ''])?.routerLink let routerLink"
                            [routerLink]="routerLink" target="_blank" class="uf-action tertiary" title="Open">
                            <uf-icon name="open" />
                        </a>
                        <button *ngIf="!table.disabled" (click)="remove(i)" type="button" title="Delete"
                            class="uf-action tertiary">
                            <uf-icon name="delete" />
                        </button>
                    </div>
                    <div [formGroup]="table" expanderBody class="uf-grid pad">
                        <div *ngIf="(tableDescriptions[getIdentifier(table) ?? '']); else noTableDescription; let tableDescription"
                            class="uf-grid col-12">
                            <uf-data-display-list class="col-12">
                                <dt>Identifier</dt>
                                <dd>{{tableDescription.identifier}}</dd>
                            </uf-data-display-list>
                            <uf-number [formControlName]="formKeys.PageSize" label="Rows" precision="0"
                                class="col-12" />
                            <!-- TODO: Replace this to formControlName when UNIFII-7632 is fixed -->
                            <ng-container *ngIf="table.get(formKeys.Roles) let roleControl">
                                <uf-chips [control]="roleControl | asUfControl" [options]="rolesResults"
                                    (searchChange)="findRoles($event)" label="Restrict to Roles"
                                    placeholder="Search Roles" class="col-12" />
                            </ng-container>
                        </div>
                        <ng-template #noTableDescription>
                            <uf-message [content]="'Table \''+ getIdentifier(table) + '\' not found'"
                                class="error col-12" />
                        </ng-template>
                    </div>
                </uf-expander>
            </ng-template>
        </uc-drag-list>

    </div>
</uf-expander>