import { FileUploader } from '@unifii/library/common';
import { DOMExceptionAbortErrorName, Progress } from '@unifii/sdk';

import { ConsoleInterceptor } from 'console-interceptor';

export class FormFakeUploader implements FileUploader {

    private interceptor = new ConsoleInterceptor();

    upload(file: File, progressCallback?: (progress: Progress) => void, signal?: AbortSignal): Promise<Progress> {

        const count = this.getRandomArbitrary(7, 25);
        const delay = this.getRandomArbitrary(150, 500);
        const progress: Progress = { total: file.size, done: 0 };

        const promise = new Promise<Progress>(async(resolve) => {

            for (let i = 0; i < count; i++) {

                if (signal && signal.aborted) {
                    new DOMException('Upload aborted', DOMExceptionAbortErrorName);
                }

                await new Promise((res) => setTimeout(res, delay));

                if (i === count - 1) {
                    progress.id = file.name;
                    progress.done = progress.total;
                    resolve(progress);
                } else {
                    if (progressCallback) {
                        progressCallback(progress);
                    }
                    progress.done = file.size / count * i;
                }
            }
        });

        return this.interceptor.intercept(promise);
    }

    getUrl(): Promise<string> {
        return Promise.resolve('/assets/img/file-uploaded.jpg');
    }

    private getRandomArbitrary(min: number, max: number) {
        return Math.floor(Math.random() * (max - min) + min);
    }

}
