<label *ngIf="label" class="grow">
    {{ label }}
    <uf-help *ngIf="help" [content]="help" />
</label>
<uf-markdown-editor [value]="value" [control]="control" [disabled]="disabled">
    <button [options]="defaultOptions" [template]="mediaOptions" compact type="button" listBox class="uf-action"
        title="Media">
        <uf-icon name="media" />
        <ng-template #mediaOptions>
            <ul class="uf-list uf-box">
                <li (click)="addImage()" title="Insert image" class="uf-list-button body-copy compact">
                    <uf-icon name="imageList" />
                    <span class="gap-sm-left">Insert image</span>
                </li>
                <li (click)="addVideo()" title="Insert video" class="uf-list-button body-copy compact">
                    <uf-icon name="video" />
                    <span class="gap-sm-left">Insert video</span>
                </li>
            </ul>
        </ng-template>
    </button>
    <button (click)="addImage()" normal type="button" title="Insert image" class="uf-action tertiary">
        <uf-icon name="imageList" />
    </button>
    <button (click)="addVideo()" normal type="button" title="Insert video" class="uf-action tertiary">
        <uf-icon name="video" />
    </button>
</uf-markdown-editor>