<ng-template [ngIf]="ready">

    <div class="header">
        <button (click)="close()" type="button" class="uf-action tertiary" title="Close">
            <uf-icon name="close" />
        </button>
        <h3>{{searchConfig.title}}</h3>
    </div>

    <div class="content">
        <uf-search [search]="query" [autofocus]="true" (searchChange)="filter($event)" />
    </div>

    <uf-panel [bottomThreshold]="50" (scrollBottom)="onScrolledToEnd()">

        <ul class="media-select">
            <li *ngIf="!busy && query?.length && !items.length">
                Sorry, there was no result for your search.
            </li>
            <li *ngFor="let item of items, let i = index">
                <a [ngClass]="{active: item.selected}" (click)="select(i)">
                    <p>{{item.name}} |
                        <span class="font-size-sm">
                            {{item.definitionLabel ? item.definitionLabel : searchConfig.type}}
                        </span>
                    </p>
                </a>
            </li>
            <li *ngIf="busy">
                <uf-spinner class="padded" />
            </li>
        </ul>

    </uf-panel>

    <div *ngIf="error" class="content">
        <div class="error-text">{{error}}</div>
    </div>

    <div class="row space-children pad">
        <button (click)="close()" type="button" class="uf-button tertiary right">Close</button>
        <button *ngIf="searchConfig.multiSelect" (click)="updateAll(!selectAll)" type="button" class="uf-button">
            {{selectAll ? 'Unselect All' : 'Select All'}}
        </button>
        <button (click)="save()" type="button" class="uf-button primary">
            {{ searchConfig.multiSelect ? 'Add' : 'Select' }}
        </button>
    </div>

</ng-template>