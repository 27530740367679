import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonTranslationKey, ModalService, UfControl, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { AuthProvider } from '@unifii/sdk';
import { Subscription, debounceTime } from 'rxjs';

import { AuthProviderMappingActionType, AuthProviderMappingConditionType, AuthProviderSourceGroup, FieldMapping, UcAuthProviders } from 'client';

import { ActionsTypesDescription, AuthProviderMappingActionModel, AuthProviderMappingConditionFormModel, AuthProviderMappingFilter,
    AuthProviderMappingModel, ConditionTypesDescription, CustomFieldMapping, FieldMappingOption, ManagerFieldMapping, MappingConfig,
    MappingsControlKeys } from '../models';

import { AuthProviderMappingModalComponent } from './auth-provider-mapping-modal.component';
import { AuthProviderMappingsController } from './auth-provider-mapping.controller';

interface Row {
    conditionDescription: string;
    actionDescription: string;
    conditions: AuthProviderMappingConditionFormModel[];
    actions: AuthProviderMappingActionModel[];
    position: number;
}

@Component({
    selector: 'uc-auth-provider-mappings',
    templateUrl: 'auth-provider-mappings.html',
})
export class AuthProviderMappingsComponent implements OnInit, OnDestroy {

    @Input({ required: true }) form: UfControlGroup;
    @Input({ required: true }) config: MappingConfig;

    protected readonly controlKeys = MappingsControlKeys;
    protected readonly commonTK = CommonTranslationKey;

    protected sourceClaims: string[] = [];
    protected authProviderId: string;
    protected filters: UfControlGroup;
    protected customGroups: AuthProviderSourceGroup[] = [];
    protected type: AuthProvider;
    protected rows: Row[] = [];
    protected filteredRows: Row[] = [];
    protected userMappingsKeys: string[] = [];
    protected allMappingFieldsOptions: Record<string, FieldMappingOption[]> = {};

    private subscriptions: Subscription = new Subscription();

    constructor(
        private ucAuthProviders: UcAuthProviders,
        private modalService: ModalService,
        private mappingsController: AuthProviderMappingsController,
    ) { }

    async ngOnInit() {
        const { authProviderId, mappings, userFieldsMapping, customGroups, type } = this.form.getRawValue();

        this.authProviderId = authProviderId;
        this.customGroups = customGroups;
        this.type = type;

        await this.loadSourceClaims();

        this.userMappingsKeys = Object.keys(userFieldsMapping ?? {});
        this.setupManagerControl();

        this.setupTable(mappings);
        this.subscriptions.add(this.filters.valueChanges.pipe(debounceTime(500)).subscribe((v) => this.filterMappings(v)));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    protected async addProviderValue() {
        const response = await this.modalService.openLarge(
            AuthProviderMappingModalComponent, {
            sourceClaims: this.sourceClaims,
            authProviderType: this.form.get(MappingsControlKeys.Type)?.value,
            mapping: {
                actions: [],
                condition: [],
                id: this.authProviderId,
            },
            config: this.config,
        });

        if (!response) {
            return;
        }

        this.mappings.push(this.mappingsController.buildMapping(response));
        const row = this.buildTableRow(response, this.rows.length);

        this.rows.push(row);
        this.filterMappings();
    }

    protected get fieldMappings(): UfControlGroup {
        return this.form.get(MappingsControlKeys.UserFieldsMapping) as UfControlGroup;
    }

    private get mappings(): UfControlArray {
        return this.form.get(MappingsControlKeys.Mappings) as UfControlArray;
    }

    protected async rowClick(rowClicked: Row) {
        const mapping = this.mappings.at(rowClicked.position) as UfControlGroup;

        const response = await this.modalService.openLarge(
            AuthProviderMappingModalComponent, {
            sourceClaims: this.sourceClaims,
            mapping: mapping.getRawValue(),
            authProviderType: this.form.get(MappingsControlKeys.Type)?.value,
            config: this.config,
            edit: true,
        });

        if (!response) {
            return;
        }

        this.mappings.setControl(rowClicked.position, this.mappingsController.buildMapping(response));

        const row = this.buildTableRow(response, rowClicked.position);

        this.rows[rowClicked.position] = row;
        this.filterMappings();
    }

    protected async deleteMapping(rowClicked: Row) {
        const response = await this.modalService.openConfirm({
            cancelLabel: `Don't delete`,
            confirmLabel: `Delete`,
            message: 'Are you sure you want to delete the mapping?',
        });

        if (!response) {
            return;
        }

        this.mappings.removeAt(rowClicked.position);
        this.setupTable(this.mappings.getRawValue());
    }

    protected allowCustom(key: string) {
        // UNIFII-5036 - manager is the only property that allows for custom values
        return key === 'manager';
    }

    protected async findFieldMapping(field: string, query: string) {
        const options = (await this.ucAuthProviders.getMappingFields(this.authProviderId || '', field))
            .map(this.mapMappingFieldDisplay)
            .filter((f) => (!query || f.display.toLowerCase().includes(query.trim().toLowerCase())) && this.filterCustomManagerField(f, field));

        this.allMappingFieldsOptions[field] = [...options];
    }

    private mapMappingFieldDisplay(data: FieldMapping): FieldMappingOption {
        return {
            source: data.source,
            label: data.label,
            display: data.label ? `${data.source} (${data.label})` : data.source,
        };
    }

    private setupTable(mappings: AuthProviderMappingModel[]) {
        if (!this.filters) {
            this.filters = this.mappingsController.buildFilter({
                authProviderId: this.authProviderId,
            });
        }

        this.rows = mappings.map(
            (value: AuthProviderMappingModel, position: number) => this.buildTableRow(value, position),
        );

        this.filterMappings(this.filters.getRawValue());
    }

    private buildTableRow(value: AuthProviderMappingModel, position: number): Row {
        const conditionDescription = value.condition.map((c) => this.buildConditionRow(c)).join(', ');
        const actionDescription = value.actions.map((a) => this.buildActionRow(a)).join(', ');

        return {
            conditionDescription,
            actionDescription,
            conditions: value.condition,
            actions: value.actions,
            position,
        };
    }

    private buildConditionRow(condition: AuthProviderMappingConditionFormModel): string {
        const title = ConditionTypesDescription[condition.type] ?? '';

        switch (condition.type) {
            case AuthProviderMappingConditionType.ClaimValue:
                return `Claim: (${condition.identifier}: ${condition.value})`.trim();
            case AuthProviderMappingConditionType.ClaimFrom:
                return `${title}: ${condition.identifier}`.trim();
            case AuthProviderMappingConditionType.RoleAssignment:
                return `${title}: ${condition.identifier}`.trim();
            case AuthProviderMappingConditionType.GroupMembership:
                return `${title}: ${condition?.group?.name}`;
            default:
                return `${condition.type}(${(condition.children ?? []).map((children) => this.buildConditionRow(children)).join(', ')})`;
        }
    }

    private buildActionRow(action: AuthProviderMappingActionModel): string {
        const title: string | undefined = ActionsTypesDescription[action.type];

        switch (action.type) {
            case AuthProviderMappingActionType.AssignUnit:
                return `Unit: ${action.units?.map((unit) => `${unit.label} (${unit.id})`).join(', ')}`;
            case AuthProviderMappingActionType.AssignClaim:
                return `Claim: (${action.claim?.type ?? action.identifier}: ${action.value})`;
            case AuthProviderMappingActionType.AssignClaimFrom:
                return `Claim From: (${action.claim?.type}: ${action.claimTo?.type})`;
            case AuthProviderMappingActionType.AssignRole:
            case AuthProviderMappingActionType.AssignSystemRole:
                return `${title}: ${action.roles?.join(', ')}`;
            default:
                return `${title}: ${action.identifier}`;
        }
    }

    private filterCustomManagerField(option: FieldMappingOption, field: string): boolean {
        return field !== ManagerFieldMapping || option.display.toLowerCase() !== CustomFieldMapping;
    }

    private setupManagerControl() {
        const managerControl = this.fieldMappings.get(ManagerFieldMapping) as UfControl;

        if (!managerControl) {
            return;
        }

        this.subscriptions.add(managerControl.valueChanges.subscribe((v) => {
            if (typeof v === 'string') {
                managerControl.setValue({ source: v }, { emitEvent: false });
            }
        }));
    }

    private filterMappings(filters?: AuthProviderMappingFilter) {
        filters = filters ?? this.filters.getRawValue();

        this.filteredRows = this.rows.filter((row: Row) => {
            if (filters == null) {
                return true;
            }

            if (!this.filterAction(row, filters)) {
                return false;
            }

            return this.filterCondition(row, filters);
        });
    }

    private *conditionIterator(conditions: AuthProviderMappingConditionFormModel[]): Iterable<AuthProviderMappingConditionFormModel> {

        for (const condition of conditions) {
            yield condition;

            if (condition.children) {
                yield *this.conditionIterator(condition.children);
            }
        }
    }

    private async loadSourceClaims() {
        if (this.config.sourceClaims) {
            try {
                this.sourceClaims = await this.ucAuthProviders.getAuthProviderClaims(this.authProviderId);
            } catch (e) {
                this.sourceClaims = [];
            }
        }
    }

    private filterCondition(row: Row, filters?: AuthProviderMappingFilter) {
        let filteredCondition = true;

        // since conditions are in a tree structure, we need to flatten it first
        const flattenedConditions = [...this.conditionIterator(row.conditions)];

        if (filters?.group) {
            filteredCondition = flattenedConditions.some((condition) => this.filterConditionGroupMembership(condition, filters));
        }

        if (filteredCondition && filters?.conditionClaimIdentifier) {
            filteredCondition = flattenedConditions.some((condition) => this.filterConditionClaim(condition, filters));
        }

        if (filteredCondition && filters?.conditionClaimValue) {
            filteredCondition = flattenedConditions.some((condition) => this.filterConditionClaimValue(condition, filters));
        }

        if (filteredCondition && filters?.rolesAssigned) {
            filteredCondition = flattenedConditions.some((condition) => this.filterConditionRoleAssigned(condition, filters));
        }

        return filteredCondition;
    }

    private filterConditionGroupMembership(condition: AuthProviderMappingConditionFormModel, filters: AuthProviderMappingFilter): boolean {
        return condition.group?.id === filters.group?.id && condition.type === AuthProviderMappingConditionType.GroupMembership;
    }

    private filterConditionRoleAssigned(condition: AuthProviderMappingConditionFormModel, filters: AuthProviderMappingFilter): boolean {
        return !!(condition.identifier && (filters?.rolesAssigned ?? []).includes(condition.identifier)) && condition.type === AuthProviderMappingConditionType.RoleAssignment;
    }

    private filterConditionClaimValue(condition: AuthProviderMappingConditionFormModel, filters: AuthProviderMappingFilter): boolean {
        return condition.value === filters.conditionClaimValue && condition.type === AuthProviderMappingConditionType.ClaimValue;
    }

    private filterConditionClaim(condition: AuthProviderMappingConditionFormModel, filters: AuthProviderMappingFilter): boolean {
        return condition.identifier === filters.conditionClaimIdentifier && [AuthProviderMappingConditionType.ClaimValue, AuthProviderMappingConditionType.ClaimFrom].includes(condition.type);
    }

    private filterAction(row: Row, filters: AuthProviderMappingFilter): boolean {
        let filteredAction = true;

        if (filters.hierarchyUnit) {
            filteredAction = row.actions.some((action) => this.filterActionHierarchyUnit(action, filters));
        }

        if (filteredAction && filters.actionClaimIdentifier) {
            filteredAction = row.actions.some((action) => this.filterActionClaim(action, filters));
        }

        if (filteredAction && filters.actionClaimValue) {
            filteredAction = row.actions.some((action) => this.filterActionClaimValue(action, filters));
        }

        if (filteredAction && filters.actionClaimFrom) {
            filteredAction = row.actions.some((action) => this.filterActionClaimFrom(action, filters));
        }

        if (filteredAction && filters.actionClaimTo) {
            filteredAction = row.actions.some((action) => this.filterActionClaimTo(action, filters));
        }

        if (filteredAction && filters.actionRoles?.length) {
            filteredAction = row.actions.some((action) => this.filterActionRole(action, filters));
        }

        if (filteredAction && filters.actionSystemRoles?.length) {
            filteredAction = row.actions.some((action) => this.filterActionSystemRole(action, filters));
        }

        return filteredAction;
    }

    private filterActionSystemRole(action: AuthProviderMappingActionModel, filters: AuthProviderMappingFilter) {
        return action.type === AuthProviderMappingActionType.AssignSystemRole && filters?.actionSystemRoles?.every((actionSystemRole) => action.roles?.includes(actionSystemRole));
    }

    private filterActionRole(action: AuthProviderMappingActionModel, filters: AuthProviderMappingFilter) {
        return action.type === AuthProviderMappingActionType.AssignRole && filters.actionRoles && filters.actionRoles.every((actionRole) => action.roles?.includes(actionRole));
    }

    private filterActionClaimTo(action: AuthProviderMappingActionModel, filters: AuthProviderMappingFilter): boolean {
        return action.type === AuthProviderMappingActionType.AssignClaimFrom && action.identifier === filters.actionClaimTo;
    }

    private filterActionClaimFrom(action: AuthProviderMappingActionModel, filters: AuthProviderMappingFilter): boolean {
        return action.type === AuthProviderMappingActionType.AssignClaimFrom && action.value === filters.actionClaimFrom;
    }

    private filterActionClaimValue(action: AuthProviderMappingActionModel, filters: AuthProviderMappingFilter): boolean {
        return action.type === AuthProviderMappingActionType.AssignClaim && action.value === filters.actionClaimValue;
    }

    private filterActionClaim(action: AuthProviderMappingActionModel, filters: AuthProviderMappingFilter): boolean {
        return action.type === AuthProviderMappingActionType.AssignClaim && action.identifier === filters.actionClaimIdentifier;
    }

    private filterActionHierarchyUnit(action: AuthProviderMappingActionModel, filters: AuthProviderMappingFilter): boolean {
        return action.type === AuthProviderMappingActionType.AssignUnit && (action.units ?? []).some((u) => u?.id === filters?.hierarchyUnit?.id);
    }

}
