<ng-container *ngIf="link">
    <a [routerLink]="link" routerLinkActive="router-link-active">
        <ng-template *ngTemplateOutlet="menuItemContainer"></ng-template>
    </a>
</ng-container>
<ng-container *ngIf="!link">
    <a>
        <ng-template *ngTemplateOutlet="menuItemContainer"></ng-template>
    </a>
</ng-container>

<ng-template #menuItemContainer>
    <div class="col center-all">
        <img *ngIf="icon" src="/assets/svg/new-icons/{{icon}}.svg" />
        <div *ngIf="showLabel" class="label">{{label}}</div>
        <ng-content></ng-content>
    </div>
</ng-template>