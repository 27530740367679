import { Option, PermissionAction } from '@unifii/sdk';

import { ResourcePath } from 'client';

import { PermissionControlKeys } from './permission-editor-control-keys';
import { FieldsControlKeysType } from './permission-editor-model';

export const ResourcePathsWithDynamicOptions = [
    ResourcePath.Projects,
    ResourcePath.Collections,
    ResourcePath.CollectionItems,
    ResourcePath.Views,
    ResourcePath.Pages,
    ResourcePath.Forms,
    ResourcePath.View,
    ResourcePath.FormDataRepositories,
    ResourcePath.Tables,
    ResourcePath.ExternalDataSources,
    ResourcePath.ExternalDataTransactions,
    ResourcePath.Companies,
    ResourcePath.Users,
    ResourcePath.UserClaims,
    ResourcePath.AuthProviders,
];

export const ReadActions = [
    PermissionAction.Read,
    PermissionAction.List,
];

export const EditActions = [
    PermissionAction.Add,
    PermissionAction.Invite,
    PermissionAction.Update,
];

export const FieldsControlKeys: FieldsControlKeysType[] = [
    PermissionControlKeys.EditFields,
    PermissionControlKeys.ReadFields,
    PermissionControlKeys.LockedFields,
    PermissionControlKeys.DeniedFields,
];

export const PermissionPathSegmentAny = '?';
export const PermissionPathSegmentWildcard = '*';
export const PermissionPathSegmentEnd = '';

export const PermissionStepOptionEndOfPath: Option = { identifier: PermissionPathSegmentEnd, name: 'End of Path' };
export const PermissionStepOptionAllDescendants: Option = { identifier: PermissionPathSegmentWildcard, name: 'All Descendants' };
