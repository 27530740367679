import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum SaveOptionType {
    Next = 'next',
    Close = 'close',
    New = 'new',
    Approve = 'approve',
    Unapprove = 'unapprove'
}

export interface SaveOption {
    label: string;
    id: SaveOptionType;
}

export const SaveAndClose: SaveOption = { label: 'Save & Close', id: SaveOptionType.Close };
export const SaveAndNext: SaveOption = { label: 'Save & Next', id: SaveOptionType.Next };
export const SaveAndNew: SaveOption = { label: 'Save & Add New', id: SaveOptionType.New };
export const SaveAndApprove: SaveOption = { label: 'Save & Approve', id: SaveOptionType.Approve };
export const SaveAndUnapprove: SaveOption = { label: 'Save & Unapprove', id: SaveOptionType.Unapprove };

@Component({
    selector: 'uc-save-options',
    templateUrl: './save-options.html',
    styleUrls: ['./save-options.less'],
})
export class SaveOptionsComponent {

    @Input() options: SaveOption[];
    @Input() disabled: boolean | string;
    @Output() onSave = new EventEmitter<SaveOption>();

}
