<uf-panel *ngIf="!error && definition && entries.length" class="content-pane container">
    <div class="uf-app-bar default flat transparent pad-sides">
        <uc-builder-header />
    </div>

    <div class="uf-grid row-gap-lg gap-top gap-sides">
        <div class="uf-container-lg col-12">

            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Details
                    </div>
                </div>
                <div class="col-12 pad">
                    <uf-data-display-list class="col-12">
                        <dt>Form</dt>
                        <dd>{{definition.label}}</dd>
                        <dt>Identifier</dt>
                        <dd>{{definition.identifier}}</dd>
                        <dt>Form Data Repository</dt>
                        <dd>{{definition.bucket}}</dd>
                        <dt>Form Number Format</dt>
                        <dd>{{definition.sequenceNumberFormat}}</dd>
                    </uf-data-display-list>
                </div>
            </div>
        </div>
        <div class="uf-box col-12 summary-table">
            <div class="uf-app-bar flat accent">
                <div class="title">
                    Definition
                </div>
            </div>
            <div *ngIf="entries" class="col-12 pad">
                <div class="uf-grid">
                    <table class="col-12 small uf-table outline clickable ">
                        <thead>
                            <tr>
                                <th>Label</th>
                                <th>Short Label</th>
                                <th>Identifier</th>
                                <th>Required</th>
                                <th>ReadOnly</th>
                                <th>Autofill</th>
                                <th>Bind To</th>
                                <th>Show If</th>
                                <th>Template</th>
                                <th>Field Width</th>
                                <th>Visible To</th>
                                <th>Variations</th>
                                <th>Validators</th>
                                <th>Help</th>
                                <th>Tags</th>
                            </tr>
                        </thead>
                        <tbody [root]="entries" ufDirectoryRoot childrenProperty="children">
                            <tr *ngFor="let entry of entries | flatten: 'children'" [item]="entry"
                                (click)="rowClick(entry)" ufDirectory>
                                <td class="no-padding">
                                    <uf-directory-indicator [icon]="entry.icon"
                                        class="light">{{entry.label}}</uf-directory-indicator>
                                </td>
                                <td>{{entry.shortLabel}}</td>
                                <td><code *ngIf="entry.identifier">{{entry.identifier}}</code></td>
                                <td><uf-checkbox *ngIf="entry.isRequired" [value]="true" [disabled]="true"
                                        class="cell" /></td>
                                <td><uf-checkbox *ngIf="entry.isReadOnly" [value]="true" [disabled]="true"
                                        class="cell" /></td>
                                <td><code *ngIf="entry.autofill">{{entry.autofill}}</code></td>
                                <td><code *ngIf="entry.bindTo">{{entry.bindTo}}</code></td>
                                <td><code *ngIf="entry.showIf">{{entry.showIf}}</code></td>
                                <td>{{entry.template}}</td>
                                <td>{{entry.width}}</td>
                                <td>{{entry.visibleTo}}</td>
                                <td><uf-checkbox *ngIf="entry.variations" [value]="true" [disabled]="true"
                                        class="cell" /></td>
                                <td><uf-checkbox *ngIf="entry.validators" [value]="true" [disabled]="true"
                                        class="cell" /></td>
                                <td><uf-checkbox *ngIf="entry.help" [value]="true" [disabled]="true" class="cell" />
                                </td>
                                <td>
                                    <ng-container *ngFor="let tag of entry.tags">
                                        <code>{{tag}}</code>&nbsp;
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</uf-panel>
<uc-error-message [error]="error" />