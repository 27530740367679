<h4>Inputs</h4>
<table class="uf-table inputs accent clickable">
    <thead>
        <tr>
            <th>Parameter</th>
            <th>Source</th>
            <th>Type</th>
            <!-- TODO Required field is gonna be added later on next releases -->
            <!-- <th>
                <div class="row">
                    Required
                    <uf-help content="This parameter is required, please provide a value"></uf-help>
                </div>
            </th> -->
            <th>
                Value or Expression
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let externalInput of externalInputs.controls | asUfControlsGroups; let i = index" [formGroup]="externalInput"
            (click)="edit(i)" class="body-row">
            <ng-container *ngIf="getInfo(i); let info">
                <td><span *ngIf="info.required === true">* </span>{{ info.parameter }}</td>
                <td>{{ info.source }}</td>
                <td>{{ info.type }}</td>
                <!-- TODO Required field is gonna be added later on next releases -->
                <!-- <td>
                    <uf-icon *ngIf="info.required" name="tick"></uf-icon>
                </td> -->
                <td>
                    {{ externalInput.get(externalInputsKeys.Value)?.value }}
                    <span *ngIf="externalInput.get(externalInputsKeys.Value)?.invalid"
                        class="uf-lozenge error">Required</span>
                </td>
            </ng-container>
        </tr>
    </tbody>
</table>