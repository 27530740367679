import { Injectable, Type } from '@angular/core';
import { ComponentRegistry, ComponentRegistryType } from '@unifii/library/smart-forms';
import { UfFormComponentRegistry } from '@unifii/library/smart-forms/input';
import { FieldType } from '@unifii/sdk';

import { UcMarkdownWrapperComponent } from './form/uc-markdown-wrapper.component';

@Injectable()
export class MarkdownComponentRegistry extends UfFormComponentRegistry implements ComponentRegistry {

    override readonly type = ComponentRegistryType.Input;

    override get(type: string): Type<any> {

        if (type === FieldType.MultiText) {
            return UcMarkdownWrapperComponent;
        }

        return super.get(type);
    }

}
