<uf-expander class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">
            Restrict to Roles
        </div>
    </div>
    <div [formGroup]="control" expanderBody class="uc-group-content grid">
        <uf-chips [formControlName]="formKeys.Roles" [options]="rolesResult" (searchChange)="findRoles($event)"
            class="col-1of1" placeholder="Search Roles" />
    </div>
</uf-expander>