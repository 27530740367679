<form *ngIf="ready && notifyEnabled" class="grid">
	<uf-chips *ngIf="config.role.show && form.controls.role" [value]="roles"
		[options]="rolesResult" [control]="form.controls.role | asUfControl" (valueChange)="mapRoles($event, 'role')"
		(searchChange)="findRoles($event)" label="Roles" class="col-1of1" />

	<uf-textarea *ngIf="config.showIf.show && form.controls.showIf" [control]="form.controls.showIf | asUfControl"
		[(value)]="field.showIf" label="Show If" class="col-1of1" />

	<uf-chips *ngIf="config.visibleTo.show && form.controls.visibleTo" [value]="visibleTos"
		[options]="rolesResult" [control]="form.controls.visibleTo | asUfControl" (valueChange)="mapRoles($event, 'visibleTo')"
		(searchChange)="findRoles($event)" label="Visible to" class="col-1of1" />

	<uf-autocomplete *ngIf="config.showOn.show && form.controls.showOn" [options]="actions"
		[control]="form.controls.showOn | asUfControl" [(value)]="field.showOn" (searchChange)="searchActions($event)"
		label="Show On" class="col-1of1" />
</form>