import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ClipboardService, DataDisplayIconValue, FilterEntry, FilterValue, ModalService, TableAction, TableConfig, TableConfigColumn, ToastService } from '@unifii/library/common';
import { stringsCaseInsensitiveLocalCompare } from '@unifii/library/smart-forms';
import { Subject } from 'rxjs';

import { PermissionPrincipalType, Resource, ResourceElement, ResourcePath, UcPermission, UcPermissionTableEntry, UcPermissionsClient } from 'client';
import { DialogsService } from 'services/dialogs.service';

import { PermissionEditorComponent, PermissionEditorData } from './editor';
import { PermissionsClonerComponent } from './permissions-cloner.component';
import { PermissionsDataSource } from './permissions-datasource';
import { toUcPermission, toUcPermissionTableEntry } from './permissions-functions';
import { PermissionChangeAction, PermissionsManagerService } from './permissions-manager.service';
import { ResourceCacheService } from './resource-cache-service';
import { resourceIterator } from './resource-functions';

@Injectable()
export class PermissionsTableManager implements TableContainerManager<UcPermissionTableEntry, FilterValue, FilterEntry> {

    tableConfig: TableConfig<UcPermissionTableEntry>;
    addActionConfig = true;
    reload = new Subject<void>();
    updateItem = new Subject<UcPermissionTableEntry | { item: UcPermissionTableEntry; trackBy: keyof UcPermissionTableEntry }>();
    readonly: boolean;

    constructor(
        route: ActivatedRoute,
        private ucPermissions: UcPermissionsClient,
        private permissionsManager: PermissionsManagerService,
        private modalService: ModalService,
        private dialogs: DialogsService,
        private clipboard: ClipboardService,
        private toastService: ToastService,
        private resourceService: ResourceCacheService,
    ) {

        this.readonly = route.snapshot.data.inherited === true;
        this.addActionConfig = !this.readonly;

        this.tableConfig = {
            id: `permissions-${this.ucPermissions.principalType}`,
            columns: this.getColumns(),
            actions: this.getActions(),
            pageSize: 100,
            columnToggles: true,
            selectable: !this.readonly,
            row: { action: (permission) => void this.edit(permission) },
        };
    }

    createDataSource() {
        return new PermissionsDataSource(this.ucPermissions);
    }

    private getColumns(): TableConfigColumn<UcPermissionTableEntry>[] {
        const replacers = this.getReplacers();

        const columns: TableConfigColumn<UcPermissionTableEntry>[] = [{
            name: 'path',
            label: 'Path',
            value: (item) => {
                const parts = [...item.path];

                for (const replacer of replacers) {
                    const idx = parts.indexOf(replacer.parentResource.segment);

                    if (parts.length > idx + 1) {
                        const replaceItem = replacer.items.find((i) => `${i.id}` === parts[idx + 1]);

                        if (replaceItem) {
                            parts[idx + 1] = replaceItem.name;
                        }
                    }
                }

                return '/' + parts.join('/');
            },
        }, {
            name: 'description',
            label: 'Description',
        }, {
            name: 'actions',
            label: 'Actions',
            value: (item) => item.actions.map((a) => a.name).sort(stringsCaseInsensitiveLocalCompare).join(', '),
        }, {
            name: 'hasConditionalActions',
            label: 'Conditions',
            value: (item) => item.hasConditionalActions ? {
                icon: 'radioTick',
                colour: 'success',
            } satisfies DataDisplayIconValue : null,
        }, {
            name: 'hasConfiguredFields',
            label: 'Fields',
            value: (item) => item.hasConfiguredFields ? {
                icon: 'radioTick',
                colour: 'success',
            } satisfies DataDisplayIconValue : null,
        }];

        if (this.readonly) {
            columns.unshift({
                name: 'inheritedPrincipal',
                label: 'Role',
                value: (item) => item.source?.name,
            });
        }

        return columns;
    }

    private getActions(): TableAction<UcPermissionTableEntry>[] {
        return [
            {
                label: 'Copy',
                action: (rows) => this.copy(rows.map((row) => row.$implicit)),
                predicate: () => this.ucPermissions.principalType === PermissionPrincipalType.Role,
            },
            {
                label: 'Duplicate',
                action: (rows) => this.duplicate(rows.map((row) => row.$implicit)),
                predicate: () => !this.readonly,
            },
            {
                label: 'Delete',
                action: (rows) => this.delete(rows.map((row) => row.$implicit.id as string)),
                predicate: () => !this.readonly,
            },
        ];
    }

    private async edit(permission: UcPermissionTableEntry) {

        const data: PermissionEditorData = {
            service: this.ucPermissions,
            permission: toUcPermission(permission),
            readonly: this.readonly,
        };

        const edited = await this.modalService.openLarge(PermissionEditorComponent, data);

        if (!edited) {
            return;
        }

        this.updateItem.next(toUcPermissionTableEntry(edited));

        this.permissionsManager.notify.next({
            action: PermissionChangeAction.Edited,
            principalType: this.ucPermissions.principalType,
            principalId: this.ucPermissions.principalId,
        });

        this.toastService.success('Permission modified');
    }

    private async delete(ids: string[]) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        try {
            for (const id of ids) {
                await this.ucPermissions.delete(id);
                this.reload.next();
                this.permissionsManager.notify.next({
                    action: PermissionChangeAction.Deleted,
                    principalType: this.ucPermissions.principalType,
                    principalId: this.ucPermissions.principalId,
                });
            }
        } catch (error) {
            console.error(error);
            this.toastService.error('Failed to delete');
        }
    }

    private duplicate(permissions: UcPermissionTableEntry[]) {
        void this.modalService.openLarge(PermissionsClonerComponent, permissions.map(toUcPermission));
    }

    private copy(permissions: UcPermissionTableEntry[]) {
        const copies = permissions.map((p) => {
            const copy = Object.assign({}, p);

            delete copy.id;
            delete copy.hasConditionalActions;

            return copy;
        }) as UcPermission[];

        const text = JSON.stringify(copies);

        void this.clipboard.setText(text);
    }

    private getReplacers(): { parentResource: Resource; items: ResourceElement[] }[] {
        const flatResources = [...resourceIterator(this.resourceService.resource)];

        const replacers: { parentResource: Resource; items: ResourceElement[] }[] = [];

        const projectsResource = flatResources.find((r) => r.path === ResourcePath.Projects);

        if (projectsResource) {
            replacers.push({ parentResource: projectsResource, items: this.resourceService.projectResources });
        }

        const userClaimsResource = flatResources.find((r) => r.path === ResourcePath.UserClaims);

        if (userClaimsResource) {
            replacers.push({ parentResource: userClaimsResource, items: this.resourceService.userClaimResources });
        }

        return replacers;
    }

}
