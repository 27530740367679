<div *ngIf="!error" class="overlay-container">
    <div class="header-pane">
        <uc-builder-header />
    </div>
    <div class="content-pane">
        <uf-panel *ngIf="form" class="container">
            <uc-auth-provider-detail [form]="form" (deleteClicked)="delete()" (activateClicked)="activate()"
                (deactivateClicked)="deactivate()" />
            <uc-auth-provider-mappings *ngIf="mappingsControl" [form]="mappingsControl" [config]="config" />
        </uf-panel>
    </div>
</div>

<uc-error-message [error]="error" />