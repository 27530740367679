import { Component, HostBinding, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ModalService, ToastService } from '@unifii/library/common';

import { UcClient } from 'client';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

import { LimitService } from 'services/limit.service';

import { ProjectTableManager } from './project-table-manager';
import { NewProjectModalComponent } from './new-project-modal.component';
import { ensureUfError } from '@unifii/sdk';


@Component({
    selector: 'uc-projects',
    template: `
        <uf-table-container ufSyncRoute (addItem)="addProject()" class="cards">
            <uf-breadcrumbs title [breadcrumbs]="breadcrumbs" class="large" />
        </uf-table-container>
    `,
    providers: [
        { provide: TableContainerManager, useClass: ProjectTableManager }
    ]
})
export class ProjectsComponent {

    @HostBinding('class.stretch-component') class = true;

    protected breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);

    constructor(
        private modalService: ModalService,
        private toastService: ToastService,
        private ucClient: UcClient,
        private limitService: LimitService,
        private context: ContextService,
        private breadcrumbService: BreadcrumbService,
        private route: ActivatedRoute,
        @Inject(TableContainerManager) private tableManager: ProjectTableManager
    ) {
        this.context.project = null;
    }

    async addProject() {
        try {
            const projects = await this.tableManager.getProjects();
            if (!this.limitService.canAddProject(projects)) {
                this.toastService.warning('Project limit reached');
                return;
            }

            const projectName = await this.modalService.openMedium(NewProjectModalComponent);

            if (!projectName) {
                return;
            }

            await this.ucClient.addProject(projectName);
            this.toastService.success('Project added');
            this.tableManager.refreshList();
        } catch (e) {
            const error = ensureUfError(e);
            this.toastService.error(error.message);
        }
    }

}
