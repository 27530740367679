import { ErrorType, UfError, ensureUfError } from '@unifii/sdk';

export const useDefaultErrorMessage = (error: unknown): UfError => {
    const err = ensureUfError(error);

    if (err.type === ErrorType.NotFound) {
        err.message = 'Requested resource not found';
    }

    return err;
};

